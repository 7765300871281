export const adminViewActions = {
  GET_APPROVED_USERS: 'get_approved_users',
  GET_SAMBANDH_APPROVALS: 'get_sambandh_approvals',
  SHOW_HIDE_ADMIN_LOADER: 'show_hide_admin_loader'
};

export const albumActions = {
  GET_ALL_ALBUMS: 'get_all_albums',
  SELECT_ALBUM: 'select_album'
};

export const approvalFormActions = {
  APPROVED_INVITEE_OPTIONS_LOADING_COMPLETED: 'approved_invitee_options_loading_completed',
  APPROVED_INVITEE_OPTIONS_LOADING: 'approved_invitee_options_loading',
  CLEAR_FORM_INITIAL_VALUES: 'clear_form_initial_values',
  ERROR_INITIALIZING_APPROVAL_FORM: 'error_initializing_approval_form',
  FORM_SUBMITTED: 'sampark_form_submitted',
  GET_PERMISSIONS_TO_MANAGE: 'get_permissions_to_manage',
  INITIALIZE_APPROVAL_FORM: 'initialize_approval_form',
  UPDATE_USER_ACCESS: 'update_user_access'
};

export const authActions = {
  AUTH_ERROR: 'auth_error',
  AUTH_USER_ACCESS: 'auth_user_access',
  AUTH_USER_GOOGLE: 'auth_user_google',
  AUTH_USER_SAMPARK: 'auth_user_sampark',
  UNAUTH_USER: 'unauth_user',
  REDIRECT_401: 'redirect_401'
};

export const broadcastFormActions = {
  CLEAR_INITIAL_VALUES: 'clear_initial_values',
  BROADCAST_FORM_INITIALIZING: 'broadcast_form_initializing',
  BROADCAST_FORM_INITIALIZED: 'broadcast_form_initialized',
  INITIALIZE_BROADCAST_FORM: 'initialize_broadcast_form',
  SUBMIT_BROADCAST_FORM: 'submit_broadcast_form',
  RESET_BROADCAST_FORM: 'reset_broadcast_form'
};

export const broadcastViewActions = {
  GET_BROADCAST_APPROVAL_MESSAGES: 'get_broadcast_approval_message',
  GET_BROADCAST_MESSAGES: 'get_broadcast_message',
  SHOW_HIDE_BROADCAST_LOADER: 'show_hide_broadcast_loader',
  CANCEL_BROADCAST_MESSAGE: 'CANCEL_BROADCAST_MESSAGE'
};

export const commonActions = {
  CLOSE_DRAWER: 'close_drawer',
  CLOSE_NAV_DRAWER: 'close_nav_drawer',
  CLOSE_MODAL: 'close_modal',
  CLEAR_MODAL_COMPONENT: 'clear_modal_component',
  DISPLAY_GLOBAL_LOADER: 'display_global_loader',
  HIDE_GLOBAL_LOADER: 'hide_global_loader',
  ERROR_TOASTER: 'error_toaster',
  OPEN_DRAWER: 'open_drawer',
  OPEN_NAV_DRAWER: 'open_nav_drawer',
  OPEN_MODAL: 'open_modal',
  SEND_CONTACT_US_EMAIL: 'send_contact_us_email',
  SHOW_HIDE_TOASTER: 'show_hide_toaster',
  SUCCESS_TOASTER: 'success_toaster',
  SHOW_DEV_MODE: 'show_dev_mode'
};

export const contactActions = {
  APPEND_CONTACTS_DATA: 'append_contacts_data',
  CLEAR_SELECTED_CONTACTS: 'clear_selected_contacts',
  DOWNLOAD_CONTACT_REPORT: 'download_contact_report',
  GET_CONTACT_OPTIONS: 'get_contact_options',
  GET_CONTACTS_DATA: 'get_contacts_data',
  GET_ACTIVE_KARYAKARTAS: 'get_active_karyakartas',
  INCREMENT_CONTACTS_VIEW_CURRENT_PAGE_NUMBER: 'increment_contacts_view_current_page_number',
  RESET_CONTACTS_VIEW_CURRENT_PAGE_NUMBER: 'reset_contacts_view_current_page_number',
  SELECT_ALL_CONTACTS: 'select_all_contacts',
  SELECT_CONTACT: 'select_contact',
  SHOW_HIDE_CONTACTS_LOADER: 'show_hide_contacts_loader',
  UNSELECT_CONTACT: 'unselect_contact',
  UPDATE_CONTACTS: 'update_contacts',
  UPDATE_SEARCH_STATE: 'update_search_state',
  UPDATE_SORT_VALUE: 'update_sort_value',
  UPLOAD_CONTACT_AVATAR: 'upload_contact_avatar',
  CLEAR_FILTER_CITY_SUGGESTIONS: 'clear_filter_city_suggestions',
  GET_FILTER_CITY_SUGGESTIONS: 'get_filter_city_suggestion',
  UPDATE_CITY_FILTER_VALUE: 'update_city_filter_value',
  UPDATE_STATE_FILTER_VALUE: 'update_state_filter_value'
};

export const contactFormActions = {
  INITIALIZE_CONTACT_FORM: 'initialize_contact_form',
  INITIALIZE_READ_ONLY_CONTACT_FORM: 'initialize_read_only_contact_form',
  STORE_AVATAR_IMAGE: 'store_avatar_image',
  SUBMIT_CONTACT_FORM: 'submit_contact_form'
};

export const dashboardActions = {
  GET_DASHBOARD_DATA: 'get_dashboard_data',
  SHOW_HIDE_DASHBOARD_LOADER: 'show_hide_dashboard_loader'
};

export const eventActions = {
  GET_EVENTS_DATA: 'get_events_data',
  SELECT_UNSELECT_EVENT: 'select_unselect_event',
  SHOW_HIDE_EVENTS_LOADER: 'show_hide_events_loader'
};

export const eventFormActions = {
  CLEAR_EVENT_IMAGES: 'clear_event_images',
  CLEAR_INITIAL_VALUES: 'clear_initial_values',
  INITIALIZE_EVENT_START: 'initalize_event_start',
  INITIALIZE_EVENT_DONE: 'initalize_event_done',
  INITIALIZE_EVENT_FORM: 'initialize_event_form',
  RESET_EVENT_FORM: 'reset_event_form',
  SHOW_HIDE_EVENT_FORM_LOADER: 'show_hide_event_form_loader',
  SUBMIT_EVENT_FORM: 'submit_event_form',
  UPDATE_EVENT_IMAGES: 'update_event_images',
  UPLOAD_EVENT_IMAGE: 'upload_event_image'
};

export const filterFormActions = {
  SELECT_CONTACT_FILTER_OPTION: 'select_contact_filter_option',
  UNSELECT_CONTACT_FILTER_OPTION: 'unselect_contact_filter_option'
};

export const readingViewActions = {
  CLEAR_INITIAL_VALUES_READING: 'clear_initial_values_reading',
  INITIALIZE_READING_FORM: 'initialize_reading_form',
  GET_READING_DATA_ALL: 'get_reading_data_all',
  GET_READING_DATA_WORDS: 'get_reading_data_words',
  GET_READING_DATA_SUTRO: 'get_reading_data_sutro',
  GET_READING_DATA_PRASANG: 'get_reading_data_prasang',
  GET_READING_DATA_STORIES: 'get_reading_data_stories',
  SHOW_READING_LOADER: 'show_reading_loader',
  HIDE_READING_LOADER: 'hide_reading_loader'
};

export const readingPanelActions = {
  CLEAR_INITIAL_VALUES_READING: 'clear_initial_values_reading',
  INITIALIZE_READING_FORM: 'initialize_reading_form',
  SUBMIT_READING_FORM: 'submit_reading_form',
  SET_CONTENT_TYPE: 'set_content_type'
};

export const registrationActions = {
  SIGNUP_USER: 'signup_user',
  STORE_INVITED_USER: 'store_invited_user'
};

export const sambandhApprovalActions = {
  INITIALIZE_SAMBANDH_FORM: 'initialize_sambandh_form',
  SUBMIT_SAMBANDH_APPROVAL: 'submit_sambandh_approval'
};

export const userAccessActions = {
  GET_IS_SUPER_ADMIN: 'get_is_super_admin',
  GET_USER_ACCESS_OBJECT: 'get_user_access_object',
  GET_MY_USER_ACCOUNT: 'get_my_user_account',
  GET_MY_USER_ACCOUNT_ERROR: 'get_my_user_accoun_error'
};

export const yogActions = {
  GET_YOG_REPORT: 'get_yog_data',
  DOWNLOAD_YOG_REPORT: 'download_yog_data',
  GOTO_NEXT_PAGE: 'goto_next_page',
  GOTO_PREV_PAGE: 'goto_prev_page',
  GET_CURRENT_PAGE: 'get_current_page',
  SET_CURRENT_PAGE: 'set_current_page'
};
