import { eventActions } from '../constants/actions';

export default function eventsReducer (state = { events: [], showEventsLoader: false }, action) {

  switch (action.type) {
    case eventActions.GET_EVENTS_DATA:
      return { ...state, events: action.value };
    case eventActions.SELECT_UNSELECT_EVENT:
      return { ...state, events: action.value };
    case eventActions.SHOW_HIDE_EVENTS_LOADER:
      return { ...state, showEventsLoader: action.value };
    default:
      return { ...state };
  }
}
