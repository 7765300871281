import { GrowthBook } from '@growthbook/growthbook-react';
import { GROWTHBOOK_API } from '../config/config.constants';

// Create a GrowthBook instance
export const growthbook = new GrowthBook({
  enableDevMode:
    window.location.host.includes('localhost') || window.location.host.includes('sampark-dev')
});

//Load feature definitions from API
//Convert it to action/redux
export function initializeGrowthBook(isSuperAdmin: boolean, memberId: string) {
  fetch(GROWTHBOOK_API)
    .then((res) => res.json())
    .then((json) => {
      growthbook.setFeatures(json.features);
    })
    .catch(() => console.error('Failed to fetch features from growthbook api'));
  // TODO: replace with real targeting attributes
  growthbook.setAttributes({
    isSuperAdmin,
    id: memberId
  });
}
