import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  MenuItem,
  Icon
} from '@material-ui/core';
import Autosuggest from 'react-autosuggest';
import { AvatarWrapper } from '../../../../components/Avatar/AvatarWrapper';
import icon_search_inactive from '../../../../assets/icons/icon_search_inactive.svg';

const useStyles = withStyles({
  autoSuggestContainer: {
    marginBottom: '1rem'
  },
  listName: {
    textTransform: 'capitalize'
  },
  searchContainer: {
    border: '1px solid #C3D2DF',
    padding: '0.5rem 1rem',
    borderRadius: '4px',
    display: 'flex',
    height: '4rem',
    alignItems: 'center'
  },
  searchInput: {
    fontSize: '1rem',
    border: 'none',
    width: '100%',
    paddingLeft: '0.5rem'
  },
  state: {
    textTransform: 'uppercase'
  },
  suggestion: {
    minWidth: '300px',
    padding: '1rem',
    '&:disabled': {
      cursor: 'not-allowed'
    }
  }
});

const AssignKK = (props) => {
  const {
    isKKSelected,
    listOfKaryakartas,
    setSelectedKK,
    kkNumber,
    classes
  } = props;

  const [filteredKK, setFilteredKK] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const inputProps = {
    value: searchValue,
    onChange: handleInputChangeFollowUp,
    placeholder: `Follow-up Karyakarta #${kkNumber}`
  };

  function handleInputChangeFollowUp (event, { newValue }) {
    setSearchValue(newValue);
    setFilteredKK(listOfKaryakartas.filter((kk) => {
      const fullName = kk.firstName + ' ' + kk.lastName;
      return fullName.includes(newValue.toLowerCase());
    }));
  }

  function getSuggestionValue (suggestion) {
    return `${suggestion.firstName.toUpperCase()} ${suggestion.lastName.toUpperCase()}`;
  }

  function renderSuggestion (suggestion) {
    return (
      <MenuItem
        component='div'
        className={classes.suggestion}
        disabled={isKKSelected(suggestion)}
      >
        <Grid container>
          <Grid item xs={2} style={{margin: 'auto'}}>
            <AvatarWrapper size='36' contact={suggestion} />
          </Grid>
          <Grid item xs={10} className={classes.listName}>
            <div>
              {suggestion.firstName} {suggestion.lastName}
            </div>
            <div>
              {suggestion.addressDetails.city}, <span className={classes.state}>{suggestion.addressDetails.state}</span>
            </div>
          </Grid>
        </Grid>
      </MenuItem>
    );
  }
  function renderInputComponent (inputProps) {
    return (
      <div className={classes.searchContainer}>
        <Icon>
          <img src={icon_search_inactive} />
        </Icon>
        <input {...inputProps} className={classes.searchInput} />
      </div>
    );
  }

  function handleSelectedFollowUp (event, { suggestion }) {
    if (isKKSelected(suggestion)) {
      setSearchValue('');
      return;
    }
    setSelectedKK(suggestion);
  }

  return (<div className={classes.autoSuggestContainer}>
    <Autosuggest
      suggestions={filteredKK}
      inputProps={inputProps}
      onSuggestionsFetchRequested={() => {}}
      onSuggestionsClearRequested={() => {}}
      shouldRenderSuggestions={() => true}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderInputComponent={renderInputComponent}
      onSuggestionSelected={handleSelectedFollowUp}
    />
  </div>);
};

AssignKK.propTypes = {
  isKKSelected: PropTypes.func.isRequired,
  listOfKaryakartas: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSelectedKK: PropTypes.func.isRequired,
  kkNumber: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired
};
export default useStyles(AssignKK);
