import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, AppBar, Toolbar } from '@material-ui/core';
import { closeDrawer } from '../../../actions/common.actions';
import { submitVerifiedInviteeApprovalForm } from '../../../actions/approvalForm.actions';

const AccessContainerFooter = (props) => {
  let buttonDisabled = !props.isFormValid || !props.isFormDirty;
  return (
    <AppBar
      className='panel-footer'>
      <Toolbar>
        <div
          className='contact-form-button-container padding-sm'>
          {SecondaryButton(props)}
          {PrimaryButton(buttonDisabled, props)}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default connect(null, {
  closeDrawer,
  submitVerifiedInviteeApprovalForm
})(AccessContainerFooter);

AccessContainerFooter.propTypes = {
  isFormValid: PropTypes.bool,
  isFormDirty: PropTypes.bool
};

function SecondaryButton(props) {

  let buttonLabel = '';
  let buttonClasses = [];
  let labelClasses = [];
  buttonLabel = 'Cancel';
  buttonClasses.push('cancel-button');
  const handleClick = () => {
    return props.onClose ? props.onClose() : props.closeDrawer();
  };

  return (
    <Button
      className={['button-primary', ...buttonClasses].join(' ')}
      onClick={handleClick}
      disableRipple
      color='primary'>
      <span className={['button-primary-label', ...labelClasses].join(' ')}>{buttonLabel}</span>
    </Button>
  );
}

function PrimaryButton(buttonDisabled, props) {
  let buttonLabel = '';
  let classes = [];
  buttonLabel = 'Assign Access';
  classes.push('button-orange');
  if (buttonDisabled) {
    classes.push('button-primary-disabled');
  }
  const handleClick = () => {
    const submissionObject = props.onSubmit();
    if (!submissionObject) return undefined;
    if (props.onClose) props.onClose();
    const { manageUserId, manageUserPermissions, isSuperAdmin } = submissionObject;
    return props.submitVerifiedInviteeApprovalForm(manageUserId, manageUserPermissions, isSuperAdmin);
  };
  return (
    <Button
      disableRipple
      onClick={handleClick}
      disabled={buttonDisabled}
      className={['button-primary', ...classes].join(' ')}
      color='primary'>
      <span className='button-primary-label label-white'>{buttonLabel}</span>
    </Button>
  );
}

PrimaryButton.propTypes = {
  submitVerifiedInviteeApprovalForm: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func
};

SecondaryButton.propTypes = {
  onClose: PropTypes.func,
  closeDrawer: PropTypes.func.isRequired
};
