import * as React from 'react';
import PropTypes from 'prop-types';
import { SectionSelectorField } from './SectionSelectorField';
import { PermissionSelectorField } from './PermissionSelectorField';
import './index.scss';
import { AudienceSelectorField } from './AudienceSelectorField';

export const AccessControls = ({
  disabled,
  onChange,
  approval,
  manageUserPermissions,
  userPermissions,
  accessConfig,
  name,
  isSuperAdmin,
  audiencesList,
}) => {
  const inputCheckbox = !!(manageUserPermissions && manageUserPermissions.checked);
  const checkboxName = accessConfig.displayLabel;
  if (accessConfig.cannotManage) {
    return (<div className='container-cannotManage'><div className='div-cannotManage'>Permissions for this user have been changed.</div>
      <div className='div-cannotManage'>For any questions, please contact us via info@yds.org</div></div>);
  }
  const _onChange = (event, value, formValid) => {

    let newManageUserPermissions = { ...manageUserPermissions };
    switch (event) {
      case accessConfig.displayLabel:
        newManageUserPermissions.checked = value;
        if (accessConfig.actions.length > 0 &&
          (newManageUserPermissions.actions === {} || !newManageUserPermissions.actions)) {
          newManageUserPermissions.actions = {};
          accessConfig.actions.forEach((item) => {
            if (item.default) {
              newManageUserPermissions.actions[item.key] = true;
            }
          });
        }
        break;
      case 'audiences':
        newManageUserPermissions.audiences = value;
        break;
      case 'actions':
        newManageUserPermissions.actions = value;
    }
    onChange(name, newManageUserPermissions, formValid);
  };
  return (
    <React.Fragment>
      <SectionSelectorField
        onChange={_onChange}
        name={checkboxName}
        label={checkboxName}
        input={inputCheckbox}
        disabled={disabled}
      />
      <div className={'access-container__panel-control-description'}>{accessConfig.description}</div>
      {inputCheckbox && (
        <div>
          {accessConfig.hasAudiences ?
            <AudienceSelectorField
              disabled={false}
              isSuperAdmin={isSuperAdmin}
              manageUserPermissions={manageUserPermissions.audiences}
              audiences={audiencesList}
              onChange={_onChange}
              audiencesConfig={accessConfig.audiences}
            /> : null
          }
          <PermissionSelectorField
            onChange={_onChange}
            render={accessConfig.actions.length > 0}
            accessConfig={accessConfig.actions}
            displayLabel={accessConfig.displayLabel}
            name={'actions'}
            manageUserPermissions={manageUserPermissions.actions}
            disabled={disabled}
            required
            label={'Permissions'}
            tooltip={'You can select permission(s)'}
          />
        </div>
      )}


    </React.Fragment>
  );
};

AccessControls.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  approval: PropTypes.object,
  isSuperAdmin: PropTypes.bool,
  manageUserPermissions: PropTypes.object,
  userPermissions: PropTypes.object,
  accessConfig: PropTypes.object,
  name: PropTypes.string,
  audiencesList: PropTypes.array,
};
