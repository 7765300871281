import { WarningModal } from '../Modal/WarningModal/WarningModal';
import { useState } from 'react';
import { ReactComponent as AlertIcon } from '../../../../../assets/icons/icon_alert.svg';
import { IAttributes } from '../../attributes.types';
import { useStyles } from './attributeList.styles';

export const AttributeList = ({
  updatedAttributes,
  openModalHandler
}: {
  updatedAttributes: Record<string, IAttributes[]>;
  openModalHandler: (value: IAttributes) => void;
}) => {
  const [isWarningModalOpen, setIsWarningModalOpen] = useState<boolean>(false);

  const resultList = Object.keys(updatedAttributes);
  const { classes } = useStyles();

  const handleSubmitModal = () => {
    handleCloseModal();
  };

  const handleCloseModal = () => setIsWarningModalOpen(false);

  return (
    <>
      <WarningModal
        isOpen={isWarningModalOpen}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmitModal}
        variant="warning"
      >
        Do you really want to delete this attribute?
      </WarningModal>
      {resultList.length === 0 ? (
        <div className={classes.alertBanner}>
          <AlertIcon className={classes.alertIcon} />
          <p className={classes.alertText}>No attribute found</p>
        </div>
      ) : (
        resultList.map((groupName, index) => (
          <div key={`${index}-${groupName}`}>
            <h3>{groupName === 'undefined' ? 'No group selected' : groupName}</h3>
            <div className={classes.wrapper}>
              {updatedAttributes[groupName].map((item: IAttributes) => (
                <button
                  key={item._id}
                  className={classes.listWrapper}
                  onClick={() => openModalHandler(item)}
                  disabled={item['key'] === 'MUKT_TYPE_NOT_NO_MAILER'}
                >
                  <label className={classes.item}>
                    <div className={classes.itemLabel}>
                      <label style={{ cursor: 'pointer' }}>{item['key']}</label>
                      <div className={classes.itemDescription}>{item['description']}</div>
                    </div>
                  </label>
                </button>
              ))}
            </div>
          </div>
        ))
      )}
    </>
  );
};
