import { approvalFormActions } from '../constants/actions';

export default function approvalFormReducer (
  state = {
    initialFormValues: {},
    permissionsToManage: [],
    manageUserPermission: {},
    approval: {},
    formSubmitted: false,
    approvedInviteeOptionsLoading: false,
    error: ''
  },
  action) {
  switch (action.type) {
    case approvalFormActions.INITIALIZE_APPROVAL_FORM:
      return { ...state, initialFormValues: action.value, formSubmitted: false };
    case approvalFormActions.FORM_SUBMITTED:
      return { ...state, formSubmitted: action.value };
    case approvalFormActions.GET_PERMISSIONS_TO_MANAGE:
      return { ...state, permissionsToManage: action.value };
    case approvalFormActions.APPROVED_INVITEE_OPTIONS_LOADING:
      return { ...state, approvedInviteeOptionsLoading: true };
    case approvalFormActions.APPROVED_INVITEE_OPTIONS_LOADING_COMPLETED:
      return { ...state, approvedInviteeOptionsLoading: false };
    case approvalFormActions.CLEAR_FORM_INITIAL_VALUES:
      return { ...state, initialFormValues: {} };
    default:
      return state;
  }
}
