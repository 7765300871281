import { makeStyles } from 'tss-react/mui';
import { ISamparkLayoutStyleProps } from './SamparkLayout.types';

export const useSamparkLayoutStyles = makeStyles<ISamparkLayoutStyleProps>()(
  (theme, { isSideNavExpanded }) => ({
    samparkUi: {
      maxWidth: 1750,
      maxHeight: '100%',
      margin: 'auto'
    },
    samparkLayout: {
      [theme.breakpoints.up(600)]: {
        display: 'grid',
        gridTemplateAreas: `
        'side-nav header-content'
        'side-nav content'
      `,
        gap: '1px',
        transition: '0.2s',
        borderColor: theme.palette.grey[300],
        backgroundColor: theme.palette.background.default,
        padding: '1px',
        justifyContent: 'flex-start',
        gridTemplateColumns: isSideNavExpanded ? '14rem 1fr' : '88px 1fr',
        gridTemplateRows: '48px 1fr',
        height: '100vh',
        '& .header': {
          gridArea: 'header-content'
        },
        '& .content-sampark': {
          gridArea: 'content',
          width: '100%'
        },
        '& .sideNav-sampark': {
          gridArea: 'side-nav',
          height: '100%',
          width: '100%',
          backgroundColor: theme.palette.background.default,
          borderRight: '1px solid #E8EBF4',
          boxShadow: '0px 2px 10px 0px rgba(42, 61, 76, 0.05)'
        }
      },
      [theme.breakpoints.down(600)]: {
        '& .sideNav-sampark': {
          display: 'none'
        }
      }
    }
  })
);

export const useUnderMaintenanceStyles = makeStyles()(() => ({
  underMaintenanceContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    fontSize: '36px'
  }
}));
