import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, isValid } from 'redux-form';
import PropTypes from 'prop-types';
// material
import Button from '@material-ui/core/Button';
// project files
import * as FORMS from '../../constants/forms';
import { STATES } from '../../assets/data/us_states';
import { AvatarUploader } from '../Avatar/AvatarUploader';
import {
  renderTextField,
  renderAddressField,
  renderSelectField,
  renderMaskedTextField
} from '../form-fields';
import { normalizePhone } from '../../util/normalizePhone';
import { submitVerificationForm } from '../../actions/registration.actions';

let SignUpForm = (props) => {
  return (
    <Fragment>
      <div
        className='signup-container'>
        <div className='field-avatar-uploader'>
          <Field
            name='profileImageUrl'
            component={AvatarUploader}
            contact={props.initialValues}
            displayCameraIcon
            avatarComponentClasses='custom-box-shadow'
            height={128}
            width={128}
          />
        </div>
        <div className='signup-form-container'>
          <div className='signup-form-fields'>
            <div
              className='field-first-name'>
              <Field
                name='firstName'
                component={renderTextField}
                placeholder='First Name'
              // readOnly
              />
            </div>
            <div
              className='field-last-name'>
              <Field
                name='lastName'
                component={renderTextField}
                placeholder='Last Name'
              // readOnly
              />
            </div>
            <div
              className='field-email'>
              <Field
                name='email'
                component={renderTextField}
                placeholder='Email Address'
                readOnly
              />
            </div>
            <div
              className='field-phone'>
              <Field
                name='cellPhone'
                placeholder='Cell number'
                mask='(999) 999 9999'
                normalize={normalizePhone}
                component={renderMaskedTextField} />
            </div>
            <div
              className='field-address-1'>
              <Field
                // label='Address 1'
                name='addressDetails.address1'
                component={renderAddressField}
                placeholder='Address 1'
              />
            </div>
            <div
              className='field-address-2'>
              <Field
                // label='Address 2'
                name='addressDetails.address2'
                component={renderTextField}
                placeholder='Address 2'
              />
            </div>
            <div
              className='field-address-city'>
              <Field
                // label='City'
                name='addressDetails.city'
                component={renderTextField}
                placeholder='City'
              />
            </div>
            <div
              className='field-address-state-zip'>
              <div
                className='field-address-state'>
                <Field
                  // label='State'
                  name='addressDetails.state'
                  component={renderSelectField}
                  placeholder='State'
                  options={STATES}
                />
              </div>
              <div
                className='field-address-zip'>
                <Field
                  // label='Zip Code'
                  name='addressDetails.zip'
                  component={renderTextField}
                  placeholder='Zip Code'
                />
              </div>
            </div>
          </div>
          <Button
            // onClick={() => {
            //   /* TODO:
            //   *  1. upload photo on submit
            //   *  2. make photo required
            //   *  3. photo uploader styling
            //   */
            //   props.submitVerificationForm();
            // }}
            onClick={props.submitVerificationForm}
            disableRipple
            disabled={!props.isFormValid}
            className={'signup-form-button ' + (!props.isFormValid ? 'button-disabled' : 'button-active')}>
            Confirm
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

const validate = (values) => {
  const errors = {};
  const isEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!values.firstName) {
    errors.firstName = 'Please enter a first name';
  }

  if (!values.profileImageUrl) {
    errors.profileImageUrl = 'Please upload a profile image';
  }

  if (!values.lastName) {
    errors.lastName = 'Please enter a last name';
  }

  if (!values.cellPhone) {
    errors.cellPhone = 'Please enter a cell-phone number';
  }
  if (values.cellPhone && values.cellPhone.length < 10) {
    errors.cellPhone = 'Please enter a valid phone number';
  }

  if (values.addressDetails && !values.addressDetails.city) {
    errors.addressDetails = {
      city: 'Please enter a city'
    };
  }

  if (values.addressDetails && !values.addressDetails.state) {
    errors.addressDetails = {
      state: 'Please select a state'
    };
  }

  if (values.email && !isEmail.test(values.email)) {
    errors.email = 'Please enter a valid email address';
  }

  return errors;
};

SignUpForm = reduxForm({
  form: FORMS.VERIFY_USER,
  validate
})(SignUpForm);

function mapStateToProps(state) {
  return {
    isFormValid: isValid(FORMS.VERIFY_USER)(state)
  };
}

export default connect(mapStateToProps, { submitVerificationForm })(SignUpForm);

SignUpForm.propTypes = {
  submitVerificationForm: PropTypes.func.isRequired,
  isFormValid: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired
};
