import { iconProps } from './icons-types';

export const iconContracts = ({ stroke }: iconProps) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2005_1507)">
        <path
          d="M6.375 9C7.41053 9 8.25 8.16053 8.25 7.125C8.25 6.08947 7.41053 5.25 6.375 5.25C5.33947 5.25 4.5 6.08947 4.5 7.125C4.5 8.16053 5.33947 9 6.375 9Z"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.75 15.75V13.875C3.75 12.4253 4.92525 11.25 6.375 11.25C7.82475 11.25 9 12.4253 9 13.875V15.75"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.25 6.75H14.25"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.25 9.75H14.25"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.75 15.75H2.25C1.42125 15.75 0.75 15.0788 0.75 14.25V3.75C0.75 2.92125 1.42125 2.25 2.25 2.25H15.75C16.5788 2.25 17.25 2.92125 17.25 3.75V14.25C17.25 15.0788 16.5788 15.75 15.75 15.75Z"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2005_1507">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
