import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// MATERIAL UI
import { Button, AppBar, Toolbar } from '@material-ui/core';
// PROJECT FILES
import { submitReadingForm } from '../../actions/reading.panel.actions';

const ReadingCreateFooter = (props) => {
  let buttonDisabled = !props.isFormValid || !props.isFormDirty;
  return (
    <AppBar
      position='sticky'
      className='panel-footer'>
      <Toolbar>
        <div
          className='contact-form-button-container padding-sm'>
          {SecondaryButton(props)}
          {PrimaryButton(buttonDisabled, props)}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default connect(null, {
  submitReadingForm
})(ReadingCreateFooter);

ReadingCreateFooter.propTypes = {
  isFormValid: PropTypes.bool,
  isFormDirty: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

function SecondaryButton (props) {

  const buttonLabel = 'Cancel';
  let buttonClasses = [];
  buttonClasses.push('cancel-button');

  const handleClick = () => {
    return props.onClose()
  };

  return (
    <Button
      className={['button-primary', ...buttonClasses].join(' ')}
      onClick={handleClick}
      disableRipple
      color='primary'>
      <span className={['button-primary-label'].join(' ')}>{buttonLabel}</span>
    </Button>
  );
}

function PrimaryButton (buttonDisabled, props) {
  const buttonLabel = 'Send';
  let classes = [];
  classes.push('button-orange');
  if (buttonDisabled) {
    classes.push('button-primary-disabled');
  }
  const handleClick = () => {
    return props.submitReadingForm();
  };

  return (
    <Button
      disableRipple
      onClick={handleClick}
      disabled={buttonDisabled}
      className={['button-primary', ...classes].join(' ')}
      color='primary'>
      <span className='button-primary-label label-white'>{buttonLabel}</span>
    </Button>
  );
}

PrimaryButton.propTypes = {
  submitReadingForm: PropTypes.func.isRequired
};

SecondaryButton.propTypes = {
  onClose: PropTypes.func.isRequired
};
