import { MembersType } from '../types';

export function isSamparkUser(contact: MembersType) {
  if (contact.userID) {
    return true;
  } else {
    return false;
  }
}

export function isSambandhUser(contact: MembersType) {
  if (contact.isSambandhUser) {
    return true;
  } else {
    return false;
  }
}

export function doesListContainUser(contactList: MembersType[]) {
  return contactList.some((contact) => isSambandhUser(contact) || isSamparkUser(contact));
}
