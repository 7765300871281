import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change, touch } from 'redux-form';
import { isEqual } from 'lodash/lang';
// Material UI
import { Menu, MenuItem, List, ListSubheader, Checkbox, Button, Chip } from '@material-ui/core';
// Project Files
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
// icons
import icon_select_arrow from '../../assets/icons/icon_select_arrow.svg';
import icon_checked_small from '../../assets/icons/icon_checked_small.svg';
import icon_unchecked_small from '../../assets/icons/icon_unchecked_small.svg';
import icon_close_mini from '../../assets/icons/icon_close_mini.svg';

/*
 * Needs a prop called 'options' of structure:
 * [
 *  {
 *    name: <NAME OF LIST>
 *    value: [
 *      {
 *        name: <option 1 label>,
 *        value: <option 1 value>
 *      },
 *      {
 *        name: <option 1 label>,
 *        value: <option 1 value>
 *      },
 *    ]
 *  }
 * ]
 */

class CheckboxSelectField extends Component {

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      checked: [],
      searchValue: '',
      selectAllSelected: false,
      isLoading: false,
      componentMounted: false,
      touched: false
    };
  }

  componentDidMount() {
    // if the checked values in state do not reflect the actual checked values
    if (!isEqual(this.props.input.value, this.state.checked)) {
      this.setState({
        checked: this.props.input.value,
        componentMounted: true
      });
    }
  }

  resetAllCheckedValues(form, name) {
    this.props.change(form, name, []);
    this.props.input.onChange([]);
  }

  componentDidUpdate(prevProps) {
    /* eslint-disable */

    // if it was not disabled and now it is disabled - uncheck all boxes
    if (!prevProps.disabled && this.props.disabled) {
      this.setState({
        checked: []
      }, this.resetAllCheckedValues);
    }
    // if the list of options changes, unselect all options
    if (prevProps.options && prevProps.options.length && this.props.options && this.props.options.length) {
      if ((prevProps.options.length !== this.props.options.length) && !isEqual(prevProps.options, this.props.options)) {

        const prevGroups = prevProps.options.map(group => group.name);
        const currGroups = this.props.options.map(group => group.name);

        const uniqGroups = currGroups.filter((name, i) => {
          if (prevGroups.indexOf(name) !== -1) return false;
          return true;
        });

        if (uniqGroups.length === 0) {
          this.setState({
            checked: []
          }, this.resetAllCheckedValues);
        }

      }
    }

    // if input value changes, change checked values
    if (!isEqual(this.props.input.value, prevProps.input.value)) {
      this.setState({
        checked: this.props.input.value
      });
    }
    /* eslint-enable */
  }

  handleOpen = event => {
    this.setState({
      anchorEl: event.currentTarget,
      searchValue: ''
    }, () => {
      if (!this.state.touched) {
        this.setState({ touched: true },
          () => this.props.touch(this.props.meta.form, this.props.input.name));
      }
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  indexOfObject = (array, object) => {
    let index = -1;
    if (array && array instanceof Array && array.length) {
      array.forEach((element, i) => {
        if (element.name === object.name) {
          index = i;
        }
      });
    }
    return index;
  }

  handleSelectOption = (option) => {
    const { checked } = this.state;
    const { input } = this.props;
    let currentIndex;
    if (!option.name) {
      currentIndex = -2;
    } else {
      currentIndex = this.indexOfObject(checked, option);
    }
    const newChecked = [...checked];

    if (currentIndex === -1) { // if checking a new item
      newChecked.push(option);
      this.setState({
        checked: newChecked
      }, () => {
        this.props.change(this.props.meta.form, this.props.input.name, newChecked);
        input.onChange(newChecked);
      });
    } else if (currentIndex === -2) { // if no item was clicked
      this.handleClose();
    } else { // if unchecking item
      newChecked.splice(currentIndex, 1);
      this.setState({
        checked: newChecked
      }, () => {
        this.props.change(this.props.meta.form, this.props.input.name, newChecked);
        input.onChange(newChecked);
      });
    }
  }

  handleSelectAll = (value) => {
    const { input } = this.props;
    const options = this.filterOptions('').filter((option, index) => {
      if (option.type === 'option') return true;
      return false;
    });

    if (value) {
      this.setState({
        checked: options,
        selectAllSelected: true
      }, () => {
        this.props.change(this.props.meta.form, this.props.input.name, options);
        input.onChange(options);
      });
    } else {
      this.setState({
        checked: [],
        selectAllSelected: false
      }, () => {
        this.props.change(this.props.meta.form, this.props.input.name, []);
        input.onChange([]);
      });
    }
  }

  updateSearchState = (value) => {
    this.setState({
      searchValue: value.toLowerCase()
    });
  }

  filterOptions = (searchValue) => {
    const concatOptions = [];
    this.props.options.map(list => {
      concatOptions.push({
        name: list.name,
        value: list.name,
        type: 'title'
      });
      list.value.map(option => {
        option.type = 'option';
        option.group = list.name;
        concatOptions.push(option);
      });
    });

    return concatOptions.filter((option, index) => {
      const optionParts = option.name.split(' ');
      const searchLength = searchValue.length;
      let keep = false;

      for (const part of optionParts) {
        const parsedPart = part.trim().toLowerCase();
        keep = parsedPart.slice(0, searchLength) === searchValue;
        if (keep) return keep;
      }
      keep = option.name.trim().toLowerCase().slice(0, searchLength) === searchValue;
      if (option.type === 'title') keep = true;
      return keep;
    });
  }

  isChecked = (option) => {
    const { checked } = this.state;
    let isChecked = false;
    if (checked.length > 0) {
      const { name, group } = option;
      checked.forEach(item => {
        if (item.name === name && item.group === group) {
          isChecked = true;
        }
      });
    }
    return isChecked;
  }

  renderMenuItems() {
    const { maxSelect } = this.props;
    const { checked, searchValue, isLoading } = this.state;
    const limitReached = checked.length === maxSelect;
    const filteredOptions = this.filterOptions(searchValue);
    if (isLoading) {
      return (
        <MenuItem
          className='checkbox-menu-item'
          style={{ display: 'flex', justifyContent: 'center' }}
          disabled>
          <span style={{ fontWeight: '400' }}><LoadingSpinner /></span>
        </MenuItem>
      );
    }
    if (filteredOptions.length > 0) {
      return filteredOptions.map((option, index) => {
        // const isChecked = checked.indexOf(option) !== -1;
        const isChecked = this.isChecked(option);
        const optionType = option.type === 'option';
        return (
          <MenuItem
            key={index}
            value={option.value}
            disableRipple={!optionType}
            className='checkbox-menu-item'
            disabled={limitReached && !isChecked}
            onClick={() => {
              if (optionType) return this.handleSelectOption(option);
            }}>
            {optionType &&
              <Checkbox
                disableRipple
                checkedIcon={<img src={icon_checked_small} />}
                icon={<img src={icon_unchecked_small} />}
                checked={isChecked}
              />}
            <div style={{ display: 'flex', flexDirection: 'column', }}>
              <div style={{ fontWeight: optionType ? '400' : 'bold' }}>{option.name}</div>
              {option.description ? <div style={{ fontSize: 12 }} >{option.description}</div> : null}
            </div>
          </MenuItem>
        );
      });
    } else {
      // if no search results found
      return (
        <MenuItem
          className='checkbox-menu-item'
          disabled>
          No search results found
        </MenuItem>
      );
    }
  }

  renderSelectedChips() {
    const { checked } = this.state;

    if (checked && checked.length) {
      return (
        <div className='form-field-tags-list'>
          {checked.map((tag, index) => {
            return (
              <Chip
                key={index}
                clickable={false}
                disabled={this.props.disabled}
                className='chip chip-attributes'
                label={<span className='chip-label'>{tag.name}</span>}
                deleteIcon={<img src={icon_close_mini} style={{ height: '10px', margin: '0' }} />}
                onDelete={() => {
                  this.handleSelectOption(tag)
                }}
              />
            );
          })}
        </div>
      )
    } else {
      return <></>
    }
  }

  render() {
    const { label, buttonLabel, buttonId, maxSelect, disabled, displaySelectedCount = false, dropdownMaxHeight, renderSelectedChipsBelow } = this.props;
    const { anchorEl, searchValue, selectAllSelected, isLoading, checked } = this.state;
    const buttonDisabled = disabled || isLoading;
    return (
      <Fragment>
        {label && <div className='form-field-label-row'>
          {label && <label>{label}</label>}
        </div>}
        <div className='form-field-text-container'>
          <Button
            disableRipple
            disabled={buttonDisabled}
            id={buttonId}
            aria-owns={anchorEl ? { buttonId } : null}
            aria-haspopup='true'
            onClick={this.handleOpen}
            // onBlur={this.handleBlur}
            className={'form-field-text padding-xsm-left ' + (buttonDisabled ? 'button-disabled' : '')}
            color='primary'>
            {isLoading ? <div style={{ 'position': 'absolute' }}><LoadingSpinner /> </div> : null}
            <span className='form-field-button-label'>{displaySelectedCount ? `${checked.length} ` : null}{buttonLabel} <img src={icon_select_arrow} /></span>
          </Button>
        </div>
        <Menu
          MenuListProps={{
            disablePadding: true
          }}
          PaperProps={{
            style: {
              maxHeight: dropdownMaxHeight ? dropdownMaxHeight : undefined
            }
          }}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          getContentAnchorEl={null}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}>
          <List
            disablePadding
            className='checkbox-field-list'
            subheader={!isLoading &&
              <Fragment>
                <ListSubheader
                  className='checkbox-header-search padding-sm-top'>
                  <input
                    placeholder='Search'
                    value={searchValue}
                    onChange={(event) => this.updateSearchState(event.target.value)}
                    className='form-field-text padding-xsm-left'
                    type='text' />
                </ListSubheader>
                {!maxSelect && <ListSubheader
                  disableSticky
                  className='checkbox-header-select-all'>
                  <Checkbox
                    disableRipple
                    // disabled={filteredOptions.length === 0}
                    checked={selectAllSelected}
                    onChange={(event) => {
                      this.handleSelectAll(event.target.checked);
                    }}
                    checkedIcon={<img src={icon_checked_small} />}
                    icon={<img src={icon_unchecked_small} />} />
                  Select All
                </ListSubheader>}
              </Fragment>
            }>
            {this.renderMenuItems()}
          </List>
        </Menu>
        {renderSelectedChipsBelow ? this.renderSelectedChips() : null}
      </Fragment>
    );
  }
}

export default connect(null, { change, touch })(CheckboxSelectField);

CheckboxSelectField.propTypes = {
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  displaySelectedCount: PropTypes.bool,
  dropdownMaxHeight: PropTypes.number,
  options: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonId: PropTypes.string.isRequired,
  maxSelect: PropTypes.number,
  renderSelectedChipsBelow: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  touch: PropTypes.func.isRequired
};
