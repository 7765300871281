import React, { Fragment } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, isValid } from 'redux-form';
// material
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@material-ui/core';
// Project Files
import * as FORMS from '../../constants/forms';
import { DECLINED } from '../../constants/status';
import { renderTextAreaField } from '../form-fields';
import { setPendingSambandhUserStatus } from '../../actions/sambandhApproval.actions';
import { getSambandhApprovalsData } from '../../actions/admin.actions';
import { closeDrawer, closeModal } from '../../actions/common.actions';

class RejectSambandhModal extends React.Component {
  constructor (props) {
    super(props);
  }

  handleClick = async () => {
    const approvalResults = await this.props.setPendingSambandhUserStatus(DECLINED.status);
    if (!approvalResults.error) {
      this.props.getSambandhApprovalsData();
      this.props.closeModal();
      return this.props.closeDrawer();
    }
  }

  render () {
    const isFormValid = this.props.isFormValid;
    let primaryButtonClasses = [];
    if (!isFormValid) {
      primaryButtonClasses.push('button-primary-disabled');
    }
    return (
      <Fragment>
        <DialogTitle>Decline Sambandh Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Reason for declining the user.
          </DialogContentText>
        </DialogContent>
        <RejectSambandhForm />
        <DialogActions disableActionSpacing>
          <Button disableRipple className='button-primary cancel-button' onClick={this.props.closeModal}>
            <span className='button-primary-label'>Cancel</span>
          </Button>
          <Button
            disabled={!isFormValid}
            disableRipple
            className={['button-primary', 'button-orange', ...primaryButtonClasses].join(' ')}
            onClick={this.handleClick}>
            <span className='button-primary-label label-white'>Confirm</span>
          </Button>
        </DialogActions>
      </Fragment>
    );
  }
}

function mapStateToProps (state) {
  return {
    isFormValid: isValid(FORMS.REJECT_SAMBANDH_FORM)(state)
  };
}

export default connect(mapStateToProps, {
  closeModal,
  setPendingSambandhUserStatus,
  getSambandhApprovalsData,
  closeDrawer
})(RejectSambandhModal);

RejectSambandhModal.propTypes = {
  closeModal: PropType.func.isRequired,
  isFormValid: PropType.bool.isRequired,
  setPendingSambandhUserStatus: PropType.func.isRequired,
  getSambandhApprovalsData: PropType.func.isRequired,
  closeDrawer: PropType.func.isRequired
};

let RejectSambandhForm = (props) => {
  return (
    <Field
      name='notes'
      component={renderTextAreaField}
      placeholder='Eg: Users information is incorrect'
    />
  );
};

const validate = (values) => {
  let errors = {};

  if (!values.notes) {
    errors.notes = 'Reason for declining user is mandatory';
  }

  return errors;
};

RejectSambandhForm = reduxForm({
  form: FORMS.REJECT_SAMBANDH_FORM,
  validate
})(RejectSambandhForm);
