import { readingPanelActions } from '../constants/actions';
import { WORDOFTHEWEEK } from '../constants/contentTypes';
export default function readingPanelReducer (
  state = { initialValues: null, intent: '', formContentType: WORDOFTHEWEEK, formSubmitted: false },
  action) {
  switch (action.type) {
    case readingPanelActions.CLEAR_INITIAL_VALUES_READING:
      return { ...state, initialValues: null, intent: '', formSubmitted: false };
    case readingPanelActions.INITIALIZE_READING_FORM:
      return { ...state, initialValues: action.value.clickedItem, intent: action.value.intent, formSubmitted: false };
    case readingPanelActions.SET_CONTENT_TYPE:
      return { ...state, formContentType: action.value };
    case readingPanelActions.SUBMIT_READING_FORM:
      return { ...state, formSubmitted: true };
    default:
      return state;
  }
};
