import Stage from '../../util/stage';
import http from '../../helpers/http';
import { generateTypes, makeAction } from '../../util/actions';
import { API } from '../../config/config.constants';

const TYPES = generateTypes(
  'GET_FOLLOWUP_LIST',
  'SET_FOLLOWUP_LIST',
  'RESET_FOLLOWUP_LIST'
);

const Actions = Object.freeze({
  get: () => makeAction(TYPES.GET_FOLLOWUP_LIST),
  set: (data) => makeAction(TYPES.SET_FOLLOWUP_LIST, { data }),
  reset: () => makeAction(TYPES.RESET_FOLLOWUP_LIST)
});

export const ActionCreators = Object.freeze({
  fetchFollowUpList: () => (dispatch) => {
    dispatch(Actions.get());

    http
      .get(API.FOLLOW_UP.GET_FOLLOW_UP_CONTACTS)
      .then(response => {
        const { data } = response;
        dispatch(Actions.set(data));
      })
      .catch(error => {
        console.log(error);
      });
  },
  resetFollowUpList: () => (dispatch) => dispatch(Actions.reset())
});

const initialState = Object.freeze({
  stage: Stage.READY,
  list: []
});

const reducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case TYPES.GET_FOLLOWUP_LIST:
      return {
        ...state,
        stage: Stage.LOADING
      };
    case TYPES.SET_FOLLOWUP_LIST:
      return {
        ...state,
        stage: Stage.LOADED,
        list: payload.data
      };
    case TYPES.RESET_FOLLOWUP_LIST:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
