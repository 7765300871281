import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from 'tss-react/mui';

interface ILoadingSpinnerProps {
  className?: string;
}

export const LoadingSpinner = ({ className }: ILoadingSpinnerProps) => {
  const { classes, cx } = useLoadingSpinnerStyles();
  return (
    <div className={cx(classes.container, className)}>
      <CircularProgress className="loading-spinner" />
    </div>
  );
};

const useLoadingSpinnerStyles = makeStyles()({
  container: {
    alignItems: 'center',
    display: 'flex',
    height: 'inherit',
    justifyContent: 'center'
  }
});
