import React, { Component, useEffect } from 'react';
import { isValid, isDirty } from 'redux-form';
// PROJECT FILES
import { closeDrawer } from '../../actions/common.actions';
import BroadcastForm from './BroadcastForm';
import BroadcastApproveFooter from './BroadcastFooter.Approve';
import BroadcastCreateFooter from './BroadcastFooter.Create';
import { BROADCAST_FORM } from '../../constants/forms';
import { AvatarWrapper } from '../Avatar/AvatarWrapper';
import { getBroadcastData, getBroadcastApprovalData } from '../../actions/broadcast.actions';
import PanelLoading from '../PanelLoading';
// icons
import icon_close from '../../assets/icons/icon_cross.svg';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import * as STATUS from '../../constants/messageStatus';
import { useAppSelector } from '../../helpers/store';
import { resetBroadcastForm } from '../../actions/broadcastForm.actions';

export const BroadcastPanel = ({ onCancel }) => {
  const dispatch = useDispatch();
  const actions = bindActionCreators({
    closeDrawer,
    getBroadcastApprovalData,
    getBroadcastData,
    resetBroadcastForm
  }, dispatch)
  const { isSuperAdmin, userAccessObject } = useAppSelector((state) => state.userAccess);
  const user_id = userAccessObject?.user_id;

  const {
    clickedMessage: message,
    formSubmitted,
    initializing
  } = useSelector((state) => state[BROADCAST_FORM]);

  const isNewMessage = !message?._id;
  const isCreatedBySelf = message?.createdBy?._id === user_id;
  const isPendingMessage = message?.status === STATUS.PENDING;
  const showButtons = isNewMessage || (isPendingMessage && (isSuperAdmin || !isCreatedBySelf))

  const isFormDirty = useSelector((state) => isDirty(BROADCAST_FORM)(state))
  const isFormValid = useSelector((state) => isValid(BROADCAST_FORM)(state))

  useEffect(() => {
    if (formSubmitted) {
      onCancel()
      actions.resetBroadcastForm();
      actions.getBroadcastApprovalData();
      actions.getBroadcastData();
    }
  }, [formSubmitted])

  if (initializing) {
    return (<PanelLoading />);
  }

  const { createdBy } = message;

  return (
    <div className='broadcast-panel'>
      <div className='padding-left padding-right padding-sm-bottom'>
        <div className={'broadcast-panel-top-section ' + (createdBy ? 'grey-border-bottom' : '')}>
          <div className='broadcast-panel-top-row'>
            <h4 className='title-text padding-xsm-right'>DETAILS</h4>
            <div className='broadcast-form-top-row-buttons'>
              <img
                className='broadcast-form-dismiss'
                onClick={onCancel}
                src={icon_close} />
            </div>
          </div>
          {message._id && <div className='broadcast-form-info'>
            <AvatarWrapper size={'84'} classes={['broadcast-form-avatar']} contact={createdBy} />
            {createdBy ? <div className='broadcast-info'>
              <span
                style={{fontWeight: '500', fontSize: '18px', marginBottom: '0.5rem'}}>{`${createdBy.firstName} ${createdBy.lastName}`}</span>
              <span style={{marginBottom: '0.5rem', textTransform: 'none'}}>{`${createdBy.email}`}</span>
              {/* Only display city & state if we have them */}
              {createdBy.city && createdBy.state ? <span style={{marginBottom: '0.5rem', textTransform: 'none'}}>{createdBy.city}, <span style={{textTransform: 'uppercase'}}>{createdBy.state}</span></span> : null}
            </div> : null}
          </div>}
        </div>
        <BroadcastForm initialValues={message} />
      </div>
      {showButtons ? (
        isPendingMessage ? <BroadcastApproveFooter
          isFormDirty={true}
          isFormValid={true}
          onCancel={onCancel}
        />
      : <BroadcastCreateFooter
          isFormDirty={isFormDirty}
          isFormValid={isFormValid}
          onCancel={onCancel}
        />
      ) : null}
    </div>
  )
}
