import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getEvents } from './selector';

export const useRegistrationOptions = (eventId: string) => {
  const events = useSelector(getEvents);
  const registrationOptions = useMemo(() => {
    if (events) {
      const event = events.find((event) => event._id === eventId);
      // for Youth Sibhir 2024, we turned off accomodation right before shibir.
      // but we wanted capability in Yog Admin to turn off and on accomdation and override payment
      if (event?.registrationOptions && eventId === '65da4c9290131f1a6a819738') {
        return { ...event.registrationOptions, accommodation: true };
      }
      return event.registrationOptions;
    }
  }, [events, eventId]);
  return registrationOptions;
};
