import React, { Fragment } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
// material
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@material-ui/core';
// Project Files
import { closeModal } from '../../actions/common.actions';
import { cancelBroadcast } from '../../actions/broadcast.actions';

class CancelBroadcastModal extends React.Component {
  constructor (props) {
    super(props);
  }

  handleClick = () => {
    this.props.cancelBroadcast(this.props.message._id);
    return this.props.closeModal();
  }

  render () {

    return (
      <Fragment>
        <DialogTitle>Cancel Message</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel this message?
          </DialogContentText>
        </DialogContent>
        <DialogActions disableActionSpacing>
          <Button disableRipple className='button-primary cancel-button' onClick={this.props.closeModal}>
            <span className='button-primary-label'>Cancel</span>
          </Button>
          <Button
            disableRipple
            className='button-primary button-orange'
            onClick={this.handleClick}>
            <span className='button-primary-label label-white'>Confirm</span>
          </Button>
        </DialogActions>
      </Fragment>
    );
  }
}

export default connect(null, { closeModal, cancelBroadcast })(CancelBroadcastModal);

CancelBroadcastModal.propTypes = {
  closeModal: PropType.func.isRequired,
  cancelBroadcast: PropType.func.isRequired,
  message: PropType.object.isRequired
};
