import { iconProps } from './icons-types';

export const iconEvents = ({ stroke }: iconProps) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2005_1513)">
        <path
          d="M0.75 7.5H17.25"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.75 17.25H2.25C1.42125 17.25 0.75 16.578 0.75 15.75V3.75C0.75 2.92125 1.422 2.25 2.25 2.25H15.75C16.5788 2.25 17.25 2.922 17.25 3.75V15.75C17.25 16.578 16.578 17.25 15.75 17.25Z"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.5 0.75V4.5"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.5 0.75V4.5"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.375 14.25C13.4105 14.25 14.25 13.4105 14.25 12.375C14.25 11.3395 13.4105 10.5 12.375 10.5C11.3395 10.5 10.5 11.3395 10.5 12.375C10.5 13.4105 11.3395 14.25 12.375 14.25Z"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2005_1513">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
