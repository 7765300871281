import Stage from '../../util/stage';
import http from '../../helpers/http';
import { generateTypes, makeAction } from '../../util/actions';
import { API } from '../../config/config.constants';

// Actions Types defined in an immutable object
const TYPES = generateTypes(
  'GET_FOLLOWUP_DETAILS', 'SET_FOLLOWUP_DETAILS', 'RESET_FOLLOWUP_DETAILS',
  'UPDATE_SABHA_TAGS', 'SABHA_TAGS_UPDATED', 'SABHA_TAGS_UPDATE_ERROR', 'GET_FOLLOWUP_UNAUTHORIZED'
);

// Object containing actual Actions
const Actions = Object.freeze({
  get: () => makeAction(TYPES.GET_FOLLOWUP_DETAILS),
  set: (data) => makeAction(TYPES.SET_FOLLOWUP_DETAILS, { data }),
  reset: () => makeAction(TYPES.RESET_FOLLOWUP_DETAILS),
  update: () => makeAction(TYPES.UPDATE_SABHA_TAGS),
  updated: () => makeAction(TYPES.SABHA_TAGS_UPDATED),
  updateError: () => makeAction(TYPES.SABHA_TAGS_UPDATE_ERROR),
  loadError: () => makeAction(TYPES.GET_FOLLOWUP_UNAUTHORIZED)
});

// Object with functions called by UI
export const ActionCreators = Object.freeze({
  fetchFollowUpDetails: (id) => (dispatch) => {
    dispatch(Actions.get());
    /**
     * dispatch(
     *  {
     *    type: 'GET_FOLLOWUP_DETAILS,
     *    payload: undefined
     *  }
     * )
     */

    http
      .get(API.CONTACTS_VIEW.GET_CONTACT_BY_ID(id, true))
      .then(response => {
        const { data } = response;
        dispatch(Actions.set(data));
      })
      .catch(error => {
        console.log(error);
        dispatch(Actions.loadError());
      });
  },
  resetFollowUpDetails: () => (dispatch) => dispatch(Actions.reset()),
  updateSabhaTags: (tags, id) => (dispatch) => {
    dispatch(Actions.update());
    const data = {
      members: [id],
      tags: {
        followUp: tags
      }
    };
    http
      .patch(API.FOLLOW_UP.TAGS, data)
      .then(() => {
        dispatch(Actions.updated());
      })
      .catch(error => {
        console.log(error);
        dispatch(Actions.updateError());
      });
  }
});

const initialState = Object.freeze({
  stage: Stage.READY,
  contact: {},
  updateStage: Stage.READY
});

const reducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case TYPES.GET_FOLLOWUP_DETAILS:
      return {
        ...state,
        stage: Stage.LOADING
      };
    case TYPES.SET_FOLLOWUP_DETAILS:
      return {
        ...state,
        stage: Stage.LOADED,
        contact: payload.data
      };
    case TYPES.RESET_FOLLOWUP_DETAILS:
      return initialState;
    case TYPES.UPDATE_SABHA_TAGS:
      return {
        ...state,
        updateStage: Stage.UPDATING
      };
    case TYPES.SABHA_TAGS_UPDATED:
      return {
        ...state,
        updateStage: Stage.UPDATED
      };
    case TYPES.SABHA_TAGS_UPDATE_ERROR:
      return {
        ...state,
        updateStage: Stage.UPDATE_ERROR
      };
    case TYPES.GET_FOLLOWUP_UNAUTHORIZED:
      return {
        ...state,
        updateStage: 'LOAD_ERROR'
      };
    default:
      return state;
  }
};

export default reducer;
