import http from '../helpers/http';
import queryString from 'query-string';
import { API } from '../config/config.constants';
import { saveCSV } from '../util/saveCSV';
import dayjs from 'dayjs';

export function fetchRegisteredMembers(pageNo, pageSize) {
  return http
    .get(`${API.YOG_REPORT.YOG_REPORT_API}?pageSize=${pageSize}&pageNumber=${pageNo}`)
    .then((response) => {
      if (response.status === 200 && response.data) {
        const { registeredMembers, count } = response.data;
        return { registeredMembers, count };
      }
    })
    .catch((error) => {
      return { error };
    });
}

export function exportRegisteredMembers() {
  return http
    .get(`${API.YOG_REPORT.YOG_REPORT_API}?format=csv`)
    .then((response) => {
      if (response.status === 200 && response.data) {
        const filename = `report-${dayjs().format('MM-DD-YYYY')}.csv`;
        saveCSV(response.data, filename);
      }
    })
    .catch((error) => {
      return { error };
    });
}

export function searchRegistrationsAPI({
  searchValue,
  filterValues,
  currentPageNo,
  sortValue,
  eventId
}) {
  return http
    .get(getRegistrationSearchURL(searchValue, currentPageNo, filterValues, sortValue, eventId))
    .then((response) => {
      if (response.status === 200 && response.data) {
        return response.data
      }
    })
    .catch((error) => {
      return { error };
    });
}


function getRegistrationSearchURL(searchValue = '', pageNumber, filterValues, sortValue, eventId) {
  let API_URL = API.YOG_ADMIN.EVENT_REGISTRATIONS(eventId);
  let query = {};

  if (typeof searchValue === 'string' && searchValue !== '') {
    query.q = searchValue;
  }

  query.pageNo = pageNumber;
  query.size = 50;

  query = getQuery(filterValues, query);

  API_URL = queryString.stringifyUrl({
    url: API_URL,
    query
  });

  if (sortValue && typeof sortValue === 'string' && sortValue.length) {
    API_URL += `&sort=${sortValue}`;
  }

  return API_URL;
};

function getQuery(filterValues, query) {
  if (filterValues && typeof filterValues === 'object' && Object.keys(filterValues).length > 0) {
    const { familyId, gender, state, mandal, status, country, ...otherFilterValues } = filterValues;

    query = {
      ...query,
      ...otherFilterValues
    };

    if (gender) {
      query.gender = gender;
    }

    if (state) {
      query.state = state;
    }

    if (mandal) {
      query.mandal = mandal;
    }

    if (familyId) {
      query.familyId = familyId;
    }

    if (status) {
      query.status = status;
    }
    if (country) {
      query.country = country;
    }


  }
  return query;
}

export function updateRegistrationsAPI(updatedRegistration, registrationId) {
  return http
    .put(API.YOG_ADMIN.UPDATE_REGISTRATION(registrationId), updatedRegistration)
    .then((response) => {
      if (response.status === 200 && response.data) {
        return response.data
      }
    })
    .catch((error) => {
      return { error };
    });
}

export function getRegisterableEvents() {
  return http
    .get(API.YOG_ADMIN.GET_REGISTERABLE_EVENTS)
    .then((response) => {
      if (response.status === 200 && response.data) {
        return response.data
      }
    })
    .catch((error) => {
      return { error };
    });
}