import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { renderTextField } from '../form-fields';
import { AvatarUploader } from '../Avatar/AvatarUploader';
import { SAMBANDH_APPROVAL_FORM } from '../../constants/forms';

let BasicInfoSection = (props) => {
  return (
    <div className='contact-form-top-info'>
      <div
        className='contact-form-profile-image'>
        <Field
          name='profileImageUrl'
          component={AvatarUploader}
          contact={props.initialValues}
          height={84}
          width={84}
        />
      </div>
      <div
        className='field-first-name padding-sm-right'>
        <Field
          name='firstName'
          component={renderTextField}
          placeholder='First Name'
        />
      </div>
      <div
        className='field-last-name padding-sm-right'>
        <Field
          name='lastName'
          component={renderTextField}
          placeholder='Last Name'
        />
      </div>
      <div
        className='field-nickname padding-sm-right'>
        <Field
          name='contactDetails.email'
          readOnly
          component={renderTextField}
          placeholder='email'
        />
      </div>
    </div>
  );
};

BasicInfoSection = reduxForm({
  form: SAMBANDH_APPROVAL_FORM
})(BasicInfoSection);

export default BasicInfoSection;

BasicInfoSection.propTypes = {
  initialValues: PropTypes.object
};
