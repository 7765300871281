import { useStyles } from './footer.styles';

export const Footer = ({ closeHandler }: { closeHandler: () => void }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.footer} data-testid="footer">
      <button className={classes.cancelButton} type="button" onClick={closeHandler} formNoValidate>
        Cancel
      </button>
      <button className={classes.submitButton} type="submit">
        Save
      </button>
    </div>
  );
};
