import { makeStyles } from 'tss-react/mui';
import { IFilters } from '../yogAdmin.duck';
import { ReactComponent as ClearIcon } from '../../../../assets/icons/icon_close_small_black.svg';
import classNames from 'classnames';
import { getCountryNameFromAbbreviation } from '../../../../util/countries';

interface ActiveFilterChipGroupProps {
  activeFilters: IFilters;
  handleClearFilterByField: (field: keyof IFilters) => void;
}

const ActiveFilterChipGroup = ({
  activeFilters,
  handleClearFilterByField
}: ActiveFilterChipGroupProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      {activeFilters.familyId ? (
        <div className={classNames(classes.activeFilterChip, classes.upperCase)}>
          FAMILY-{activeFilters?.familyId.slice(-5)}
          <button
            onClick={() => handleClearFilterByField('familyId')}
            className={classes.clearFilterButton}
          >
            <ClearIcon className={classes.clearIcon} />
          </button>
        </div>
      ) : null}
      {activeFilters.name ? (
        <div className={classes.activeFilterChip}>
          {`"${activeFilters.name}"`}
          <button
            onClick={() => handleClearFilterByField('name')}
            className={classes.clearFilterButton}
          >
            <ClearIcon className={classes.clearIcon} />
          </button>
        </div>
      ) : null}
      {activeFilters.mandal ? (
        <div className={classes.activeFilterChip}>
          {`Mandal: "${activeFilters.mandal}"`}
          <button
            onClick={() => handleClearFilterByField('mandal')}
            className={classes.clearFilterButton}
          >
            <ClearIcon className={classes.clearIcon} />
          </button>
        </div>
      ) : null}
      {activeFilters.gender ? (
        <div className={classNames(classes.activeFilterChip, classes.capitalize)}>
          {activeFilters.gender}
          <button
            onClick={() => handleClearFilterByField('gender')}
            className={classes.clearFilterButton}
          >
            <ClearIcon className={classes.clearIcon} />
          </button>
        </div>
      ) : null}
      {activeFilters.isStudent ? (
        <div className={classNames(classes.activeFilterChip, classes.capitalize)}>
          Student
          <button
            onClick={() => handleClearFilterByField('isStudent')}
            className={classes.clearFilterButton}
          >
            <ClearIcon className={classes.clearIcon} />
          </button>
        </div>
      ) : null}
      {activeFilters.status ? (
        <div className={classNames(classes.activeFilterChip, classes.capitalize)}>
          {activeFilters.status}
          <button
            onClick={() => handleClearFilterByField('status')}
            className={classes.clearFilterButton}
          >
            <ClearIcon className={classes.clearIcon} />
          </button>
        </div>
      ) : null}
      {activeFilters.state ? (
        <div className={classNames(classes.activeFilterChip, classes.upperCase)}>
          State: {activeFilters.state}
          <button
            onClick={() => handleClearFilterByField('state')}
            className={classes.clearFilterButton}
          >
            <ClearIcon className={classes.clearIcon} />
          </button>
        </div>
      ) : null}
      {activeFilters.country ? (
        <div className={classNames(classes.activeFilterChip, classes.capitalize)}>
          Country: {getCountryNameFromAbbreviation(activeFilters.country)}
          <button
            onClick={() => handleClearFilterByField('country')}
            className={classes.clearFilterButton}
          >
            <ClearIcon className={classes.clearIcon} />
          </button>
        </div>
      ) : null}
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    gap: 8,

    [theme.breakpoints.up('sm')]: {
      flexWrap: 'wrap'
    }
  },
  activeFilterChip: {
    fontSize: '12px',
    backgroundColor: '#ff5a52',
    borderRadius: '4px',
    color: 'white',
    width: 'fit-content',
    height: 'fit-content',
    padding: '4px 2px 4px 8px',
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    whiteSpace: 'nowrap'
  },
  upperCase: {
    textTransform: 'uppercase'
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  clearIcon: {
    height: '8px',
    filter: 'brightness(100)'
  },
  clearFilterButton: {
    background: 'transparent',
    border: '1px solid transparent',
    cursor: 'pointer'
  }
}));

export default ActiveFilterChipGroup;
