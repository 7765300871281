import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, FieldArray, Fields } from 'redux-form';
import dayjs from 'dayjs';
import { WORDOFTHEWEEK, SUTRA, STORY, PRASANG } from '../../constants/contentTypes';
// PROJECT FILES
import { READING_FORM } from '../../constants/forms';
import validate from './validate';
import {
  renderTextField,
  renderDatePicker,
  renderInterests,
  renderDateAndTimeFields
} from '../form-fields';
import { MdxEditorWrapper } from '../form-fields/MDXEditorWrapper';

let ContentForm = (props) => {
  const { type } = props;
  const language = props.language ? 'gujarati' : 'english';
  let dateLimit = ['', ''];
  let timeLimit = ['', ''];
  const lowerLimit = dayjs().add(10, 'm');
  dateLimit[0] = lowerLimit.format('YYYY-MM-DD');
  timeLimit[0] = lowerLimit.format('hh:mm');

  return (
    <div className='content-form'>
      <div className='field-content'>
        <Field
          required
          readOnly={props.readOnly}
          name={`content.${language}`}
          component={MdxEditorWrapper}
          formName='swadhyay'
          placeholder={`Enter ${language} content here...`} />
      </div>
      <div className='field-title'>
        <Field
          required
          readOnly={props.readOnly}
          label='Cover Title (max 40 characters)'
          name={`title.${language}`}
          component={renderTextField}
          maxChars={40}
          placeholder={getCoverTitlePlaceHolder(type)} />
      </div>
      <div className='field-publish-schedule'>
        <Fields
          required
          label='Publish Date'
          readOnly={props.readOnly}
          names={['publishFormDate', 'publishFormTime']}
          dateLimit={dateLimit}
          timeLimit={timeLimit}
          component={renderDateAndTimeFields} />
      </div>
      <div className='field-tags'>
        <FieldArray
          max={2}
          disabled={props.readOnly}
          label='Tags/Keywords (Max 2 separated by comma)'
          name='tags'
          placeholder='Ex. Suhradbhav, Dasatva'
          component={renderInterests} />
      </div>
      {type === SUTRA && <div>
        <div className='fields-reference'>
          <div className='field-ref-location'>
            <Field
              label='Where was it said?'
              name='reference'
              readOnly={props.readOnly}
              component={renderTextField}
              placeholder='Ex. Rajni Uncles home, Parsippany, NJ' />
          </div>
          <div className='field-ref-date'>
            <Field
              label='When was it said?'
              name='sourceDate'
              readOnly={props.readOnly}
              maxDate={dayjs().format('YYYY-MM-DD')}
              component={renderDatePicker} />
          </div>
        </div>
        <div className='field-author'>
          <Field
            label='Who said it?'
            name='author'
            readOnly={props.readOnly}
            component={renderTextField}
            placeholder='Ex. P.P. Yogiji Maharaj' />
        </div>
      </div>}
    </div>
  );
};

ContentForm = reduxForm({
  form: READING_FORM,
  enableReinitialize: true,
  validate
})(ContentForm);

export default connect(null, {})(ContentForm);

ContentForm.propTypes = {
  language: PropTypes.number,
  readOnly: PropTypes.bool,
  type: PropTypes.object
};

function getCoverTitlePlaceHolder (type) {
  switch (type) {
    case WORDOFTHEWEEK:
      return 'Enter the “word of the week” for the cover';
    case SUTRA:
      return 'Enter the full “sutra” for the cover';
    case STORY:
      return 'Enter the “story” title for the cover';
    case PRASANG:
      return 'Enter the “prasang” title for the cover';
  }
}
