import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  p: {
    margin: 0
  },
  span: {
    fontWeight: 500
  },
  container: {
    display: 'flex',
    gap: '7px',
    isolation: 'isolate'
  },
  toaster: {
    position: 'fixed',
    width: '300px',
    bottom: '50px',
    right: '-310px',
    zIndex: 1
  }
}));
