import { adminViewActions } from '../constants/actions';

export default function adminReducer (
  state = {approvedUsers: [], sambandhData: [] },
  action) {
  switch (action.type) {
    case adminViewActions.GET_APPROVED_USERS:
      return { ...state, approvedUsers: action.value };
    case adminViewActions.GET_SAMBANDH_APPROVALS:
      return { ...state, sambandhData: action.value };
    default:
      return state;
  }
}
