import { Button } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { openModal } from '../../actions/common.actions';
import { initializeEventForm } from '../../actions/eventForm.actions';
import EventMenu from '../../components/menus/EventMenu';
import { EVENT_GALLERY_MODAL } from '../../constants/modals';
import { useAppDispatch } from '../../helpers/store';
import { EventsType } from './eventsView.types';

dayjs.extend(utc);

interface IEventListCardProps {
  event: EventsType;
  onClickDetails: () => void;
}

export const EventListCard = (props: IEventListCardProps) => {
  const { event, onClickDetails } = props;

  const dispatch = useAppDispatch();
  const actions = bindActionCreators({ initializeEventForm, openModal }, dispatch);

  const openDetailsPanel = () => {
    actions.initializeEventForm(event);
    onClickDetails();
  };

  const openDetailsPanelForCopy = () => {
    const {
      audiences,
      contact,
      description,
      duration,
      end_date,
      images,
      schedule,
      start_date,
      title,
      type,
      venue
    } = event;

    actions.initializeEventForm({
      audiences,
      contact,
      description,
      duration,
      end_date,
      images,
      schedule,
      start_date,
      title,
      type,
      venue
    });
    onClickDetails();
  };

  const openEventGallery = () => {
    actions.openModal(EVENT_GALLERY_MODAL, { event });
  };

  const isEventInPast = useMemo(() => {
    const todayDate = dayjs();
    const eventEndDate = dayjs(event.end_date);

    return todayDate > eventEndDate;
  }, [event]);

  return (
    <div className="event-list-card-container">
      <div className="event-list-left">
        <div className="event-name-location-container">
          <div className="event-card-long-content label">{event.title}</div>
          <div className="event-card-long-content">
            <span className="capitalize">
              {event.venue.name ? `${event.venue.name}, ` : null}
              {event.venue.address1}
            </span>
            , <span className="capitalize">{event.venue.city}, </span>
            <span className="uppercase">{event.venue.state}, </span>
            <span className="capitalize">{event.venue.zip}</span>
          </div>
        </div>
        <div className="event-date-container">
          <div className="label">Date</div>
          <div>{dayjs(event.start_date).utc().format('MMM D, YYYY')}</div>
        </div>
        <div className="event-createdBy-container">
          <div className="label">Created By</div>
          <div className="createdBy-value">{event.createdBy}</div>
        </div>
        <div className="event-createdBy-container">
          <div className="label">RSVP (Yes)</div>
          <div className="rsvp-value">{event.rsvp.yes}</div>
        </div>
      </div>
      <div className="event-button-container">
        <Button className="event-manage-button" onClick={openDetailsPanel}>
          Details
        </Button>
        {isEventInPast ? (
          <Button className="event-manage-button" onClick={openEventGallery}>
            Upload Gallery
          </Button>
        ) : null}
        <EventMenu onCreateCopy={openDetailsPanelForCopy} />
      </div>
    </div>
  );
};
