import { dashboardActions } from '../constants/actions';

export default function dashboardReducer (
  state = { birthdays: [], recentlyUpdated: [], showDashboardLoader: false },
  action) {

  switch (action.type) {
    case dashboardActions.GET_DASHBOARD_DATA:
      return { ...state, birthdays: action.value.birthdays, recentlyUpdated: action.value.recentlyUpdated };
    case dashboardActions.SHOW_HIDE_DASHBOARD_LOADER:
      return { ...state, showDashboardLoader: action.value };
    default:
      return { ...state };
  }
}
