import { broadcastViewActions } from '../constants/actions';

export default function broadcastReducer (state = { broadcasts: [], broadcastApprovalMessages: [], isLoading: false }, action) {

  switch (action.type) {
    case broadcastViewActions.GET_BROADCAST_MESSAGES:
      return { ...state, broadcasts: action.value, updated: false };
    case broadcastViewActions.GET_BROADCAST_MESSAGES_ERROR:
      return { ...state, error: action.value };
    case broadcastViewActions.GET_BROADCAST_APPROVAL_MESSAGES:
      return { ...state, broadcastApprovalMessages: action.value };
    case broadcastViewActions.SHOW_HIDE_BROADCAST_LOADER:
      return { ...state, isLoading: action.value };
    case broadcastViewActions.CANCEL_BROADCAST_MESSAGE: {
      const newBroadcastMsg = action.value;
      const updatedBroadcasts = state.broadcasts.map((b) => 
        newBroadcastMsg && b._id === newBroadcastMsg._id ? newBroadcastMsg : b
      );
        return { ...state, broadcasts: updatedBroadcasts };
    }
      
    default:
      return { ...state }; 
  }
}
