import { isEqual as loadashEqual, cloneDeep } from 'lodash/lang';
import { accessConfig } from '../containers/AccessContainer/accessConfig';

const validate = (tab, permission) => {
  // when we add new form 
  if (!accessConfig[tab]) {
    return true;
  }
  const { requiredFields } = accessConfig[tab];
  if (requiredFields && requiredFields.length > 0) {
    const isValid = requiredFields
      .map(field => {
        if (permission.checked) {
          if (permission[field] !== undefined) {
            if (field === 'audiences') {
              const { audiences } = permission;
              if (audiences && audiences.length > 0) {
                return true;
              }
              return false;
            }
            return true;
          }
          return false;
        }
      })
      .find(t => !t);
    return isValid === false ? false : true;
  }
  return true;
};

export const validateAllFields = (manageUserPermissions) => {
  let isFormValid = true;
  for (const item in manageUserPermissions) {
    if (manageUserPermissions[item].checked) {
      isFormValid = validate(item, manageUserPermissions[item]);
      if (!isFormValid) {
        return isFormValid;
      }
    }
  }
  return isFormValid;
};

const sortAudiences = (audiencesPermission) => {
  if (audiencesPermission && audiencesPermission.audiences) {
    if (Array.isArray(audiencesPermission.audiences)) audiencesPermission.audiences.sort();
  }
};

const filterPermissions = (originalPermissions, manageUserPermissions) => {
  const keys = Object.keys(manageUserPermissions);
  const originalKeys = Object.keys(originalPermissions);
  let permissions;
  if (keys.length !== originalKeys.length) {
    const checkedKeys = keys.filter(key => manageUserPermissions[key].checked);
    const uncheckedKeys = keys.filter(key => !manageUserPermissions[key].checked && originalPermissions[key]);
    const totalKeys = [...checkedKeys, ...uncheckedKeys];
    totalKeys.forEach(key => {
      permissions = {
        ...permissions,
        [key]: manageUserPermissions[key]
      };
    });
  }

  return permissions || manageUserPermissions;
};

export const checkFormDirty = (key, permissions, originalPermissions) => {
  let manageUserPermissions = permissions;
  let isEqual = true;
  if (!key) {
    // check for all permissions
    manageUserPermissions = filterPermissions(originalPermissions, manageUserPermissions);
    isEqual = (loadashEqual(originalPermissions, manageUserPermissions));
  }

  if (key && manageUserPermissions[key]) { // For each tab (ex Contacts)
    // If new tab added and then removed
    if (originalPermissions[key] === undefined && !manageUserPermissions[key].checked) return true;

    // for sorting manageUser AreaPermissions {}
    if (manageUserPermissions[key] && manageUserPermissions[key].audiences) {
      sortAudiences(manageUserPermissions[key]);
    }
    isEqual = (loadashEqual(originalPermissions[key], manageUserPermissions[key]));
  }
  return isEqual;
};

export const formatPermissionsFromAPI = (approval) => {
  let samparkApplicationData;
  const formatApprovalObj = {
    formStatus: '',
    manageUserName: undefined,
    manageUserId: undefined,
    manageUserPermissions: {}
  };
  if (approval && approval.applications) {
    samparkApplicationData = approval.applications.find(application => application.name === 'sampark');
    if (samparkApplicationData && samparkApplicationData.permissions) {
      const { permissions = {} } = samparkApplicationData;

      formatApprovalObj.manageUserPermissions = permissions !== undefined && permissions.access ? cloneDeep(permissions.access) : {};

      formatApprovalObj.manageUserId = permissions !== undefined && permissions.user_id && permissions.user_id._id;

      for (const item in formatApprovalObj.manageUserPermissions) {
        formatApprovalObj.manageUserPermissions[item].checked = true;
      }
    }
    formatApprovalObj.formStatus = samparkApplicationData.status;
  }

  if (!formatApprovalObj.manageUserId) {
    formatApprovalObj.manageUserId = approval._id;
  }
  if (!formatApprovalObj.manageUserName) {
    formatApprovalObj.manageUserName = approval && `${approval.firstName} ${approval.lastName}`;
  }

  return formatApprovalObj;
};

export const formatPermissionsToAPI = (manageUserPermissions) => {
  let formatPermissions = {};
  for (const item in manageUserPermissions) {
    if (manageUserPermissions[item].checked) {
      const currValue = manageUserPermissions[item];
      for (const innerItem in currValue) {
        if (formatPermissions[item] === undefined) formatPermissions[item] = {};
        if (innerItem !== 'checked') formatPermissions[item] = { ...formatPermissions[item], [innerItem]: currValue[innerItem] };
      }
    }
  }
  return formatPermissions === {} ? manageUserPermissions : formatPermissions;
};