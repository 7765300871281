import { Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Fragment } from 'react';
import { makeStyles } from 'tss-react/mui';
import { IRegistration } from '../../yogAdmin.duck';

type IModalProps = {
  cancelCallBack: () => void;
  confirmCallBack: (registration: IRegistration) => void;
  registration: IRegistration;
  isUpdatingRegistration: boolean;
};

export function ConfirmRejectModal({
  cancelCallBack,
  confirmCallBack,
  registration,
  isUpdatingRegistration
}: IModalProps) {
  const { classes } = useStyles();
  const {
    member: { firstName, lastName }
  } = registration;
  return (
    <Fragment>
      <DialogTitle className={classes.dialogTitle}>Are you sure you want to reject?</DialogTitle>
      <DialogContent className={classes.container}>
        <div className={classes.text}>
          You are rejecting registration for:{' '}
          <span className={classes.name}>
            {firstName} {lastName}
          </span>
          <div className={classes.note}>Note: This can only be reverted by the Sampark Admin.</div>
        </div>
      </DialogContent>
      <DialogActions disableSpacing>
        <Button disableRipple className="button-primary cancel-button" onClick={cancelCallBack}>
          <span className="button-primary-label">Cancel</span>
        </Button>
        <Button
          disableRipple
          disabled={isUpdatingRegistration}
          className="button-primary"
          style={{ backgroundColor: '#C84240' }}
          onClick={() => confirmCallBack(registration)}
        >
          <span className="button-primary-label label-white">Reject</span>
        </Button>
      </DialogActions>
    </Fragment>
  );
}

const useStyles = makeStyles()(() => ({
  dialogTitle: {
    '> h2': {
      fontSize: '18px',
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontFamily: 'Rubik'
    }
  },
  note: {
    fontSize: '14px',
    marginTop: '16px',
    backgroundColor: '#E8EBF4',
    padding: '4px 8px',
    borderRadius: '4px'
  },
  text: {
    fontFamily: 'Rubik'
  },
  closeButton: {
    background: 'transparent',
    border: '1px solid transparent',
    cursor: 'pointer'
  },
  rejectButton: {
    flexGrow: 1
  },
  button: {
    backgroundColor: '#E8EBF4',
    borderRadius: '8px',
    color: '#5F698B',
    fontSize: '18px',
    padding: '16px 32px',
    fontWeight: 'bold',
    border: '1px solid transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    cursor: 'pointer'
  },
  buttonGroup: {
    display: 'flex',
    gap: 8,
    padding: '16px 24px',
    justifyContent: 'space-evenly'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    fontFamily: 'Rubik',
    marginBottom: '16px'
  },
  name: {
    fontWeight: 'bold',
    textTransform: 'capitalize'
  }
}));
