import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// material ui
import { Drawer, withStyles } from '@material-ui/core';

// project components
import EventsPanel from '../components/events-panel/EventsPanel';
import NavPanel from '../components/nav-panel/NavPanel';
import { closeDrawer } from '../actions/common.actions';
import { DRAWER_COMPONENTS } from '../config/config.constants';
import SambandhPanel from '../components/sambandh-panel/SambandhPanel';

const styles = {
  widePaper: {
    width: '100%',
    minWidth: '36rem'
  },
  narrowPaper: {
    width: '36%',
    minWidth: '36rem'
  },
  mobileDrawer: {
    width: '100%',
  },
};

class DrawerController extends Component {
  constructor(props) {
    super(props);
  }

  getDrawerConfig = _ => {
    let config = {};
    config.classes = 'narrowPaper';
    config.anchor = 'right';
    switch (this.props.drawerComponent) {
      case DRAWER_COMPONENTS.EVENT_FORM:
        config.panel = <EventsPanel />;
        config.classes = 'widePaper';
        break;
      case DRAWER_COMPONENTS.NAV_PANEL:
        config.panel = <NavPanel />;
        config.anchor = 'left';
        config.classes = 'mobileDrawer';
        break;
      case DRAWER_COMPONENTS.SAMBANDH_APPROVAL:
        config.panel = <SambandhPanel />;
        break;
      default:
        return {
          panel: '',
          classes: ''
        };
    }
    // for mobile, let drawer take full screen.
    const width = window ? window.innerWidth : 600;
    if (width <= 600) {
      config.classes = 'mobileDrawer';
    }
    return config;

  }

  handleDrawerClose = _ => {
    return this.props.closeDrawer();
  }

  render() {
    const drawerConfig = this.getDrawerConfig();
    return (
      <Drawer
        anchor={drawerConfig.anchor}
        open={this.props.showDrawer}
        onClose={this.handleDrawerClose}
        classes={{
          paper: this.props.classes[drawerConfig.classes]
        }}
        PaperProps={{
          elevation: 16
        }}
        style={{
          // for MDX Editor to work, this wapper need to lowest.
          // for navbar it need to 1, otherwise contact will override.
          zIndex: (this.props.drawerComponent === DRAWER_COMPONENTS.NAV_PANEL) ? 1 : 0,
        }}
        ModalProps={{
          disableEscapeKeyDown: true
        }}>
        {this.props.showDrawer && drawerConfig.panel}
      </Drawer>
    );
  }
}

DrawerController.propTypes = {
  showDrawer: PropTypes.bool.isRequired,
  drawerComponent: PropTypes.string.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    showDrawer: state.commonData.showDrawer,
    drawerComponent: state.commonData.drawerComponent
  };
}
// export default AuthorizedLayout;
export default connect(mapStateToProps, { closeDrawer })(withStyles(styles)(DrawerController));
