import React, { FC } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

export const CardsListHeaders: FC = () => {
  const inviteToWorkshopFlag = useFeatureIsOn('sampark.invite-to-workshop');
  const tagsColumnHeader = inviteToWorkshopFlag ? 'Shibir Status' : 'Tags';

  return (
    <div className="contact-list-headers">
      <div className="left-group">
        <div className="basic-info">Basic Info</div>
        <div className="nickname">Nickname</div>
        <div className="contact-details">Contact info</div>
        <div className="follow-up">Follow-Up</div>
        <div className="tags">{tagsColumnHeader}</div>
        <div className="app">App</div>
      </div>
    </div>
  );
};
