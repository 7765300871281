import React, { Fragment, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Grid
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Color from 'color';
import { ALLOWED_STATES_HARIDHAM_SUNDAY_SABHA } from '../../config/config.sabhaTags';
import { Cancel } from '../../v2/components/svg-icons';

const useStyles = withStyles({
  controls: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '24px 24px 0 24px'
  },
  close: {
    cursor: 'pointer',
    '&:hover': {
      fill: '#067BC2'
    }
  },
  title: {
    fontWeight: '500',
    fontSize: '22px',
    paddingTop: 0
  },
  descriptionText: {
    marginBottom: '1rem',
    color: '#4E5D6A'
  },
  inactiveTag: {
    color: '#5C85A7',
    border: '2px solid #C3D2DF',
    backgroundColor: 'white',
    height: '52px',
    padding: '1rem',
    fontSize: '18px',
    borderRadius: '3px',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  activeTag: {
    color: '#2256BF',
    backgroundColor: '#DDE9FD',
    border: '2px solid #DDE9FD',
    fontWeight: '500',
    height: '52px',
    padding: '1rem',
    fontSize: '18px',
    borderRadius: '3px',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  subtext: {
    padding: '8px 0',
    color: '#5C85A7'
  },
  submitButton: {
    backgroundColor: '#FF5A52',
    borderRadius: '3px',
    padding: '12px',
    color: 'white',
    fontSize: '16px',
    textTransform: 'none',
    fontFamily: 'Rubik',

    '&:hover': {
      background: Color('#FF5A52').darken(0.2).hex()
    }
  },
  submitButtonDisabled: {
    backgroundColor: '#EBF0F5',
    color: '#899EB0'
  }
});

function AddSabhaModal (props) {
  const { classes, onSubmit, contactTags, addressDetails } = props;

  const {
    state
  } = addressDetails;

  // memo-izing the tags so it doesnt get re-created every render
  // only recreated when address state changes
  const tags = useMemo(() => {
    return [
      {
        name: 'Youth Sabha',
        value: 'Youth'
      },
      {
        name: 'Family Sabha',
        value: 'Family'
      },
      ALLOWED_STATES_HARIDHAM_SUNDAY_SABHA.includes(state) ? {
        name: 'Haridham Sunday Sabha',
        value: 'HaridhamSundaySabha'
      } : null,
      {
        name: 'Potential Sabha Member',
        value: 'Potential',
        subtext: 'If a person is not a regular sabha member'
      }
    // this filter simply removes the null value in case contact is not from allowed states
    ].filter(t => t);
  }, [state]);

  const handleTagClicked = (tag) => {
    if (tag === 'Potential') {
      setActiveTags({
        Potential: !activeTags['Potential']
      });
      return;
    }
    setActiveTags({
      ...activeTags,
      [tag]: !activeTags[tag],
      Potential: false
    });
  };

  const handleSubmit = () => {
    const selectedValues = Object.keys(activeTags).filter(tag => activeTags[tag]);
    onSubmit(selectedValues);
  };

  const initialTags = contactTags.reduce((acc, tag) => {
    return {
      ...acc,
      [tag]: true
    };
  }, {});

  const [ activeTags, setActiveTags ] = useState(initialTags);

  const canSubmit = () => {
    const selectedValues = Object.keys(activeTags).filter(tag => activeTags[tag]);
    if (
      (
        selectedValues.every(value => contactTags.includes(value)) &&
      selectedValues.length === contactTags.length
      ) ||
      selectedValues.length === 0
    ) {
      return false;
    }
    return true;
  };

  return (
    <Fragment>
      <div className={classes.controls}>
        <Cancel className={classes.close} onClick={props.onClose} />
      </div>
      <DialogTitle classes={{root: classes.title}}>Sabha Tags</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.descriptionText}>
          Select the sabha this person <span style={{fontWeight: 'bold'}}>regularly</span> attends
        </DialogContentText>
        <Grid container spacing={2}>
          {tags.map(tag => {
            return (
              <Grid
                item
                key={tag.value}
                xs={12}
              >
                <div
                  // classes={{root: classes.tagRoot}}
                  className={activeTags[tag.value] ? classes.activeTag : classes.inactiveTag}
                  onClick={() => handleTagClicked(tag.value)}
                >
                  {tag.name}
                </div>
                {tag.subtext && <div className={classes.subtext}>{tag.subtext}</div>}
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
      <DialogActions style={{padding: '2rem 1.5rem'}}>
        <Button
          disableRipple
          classes={{
            root: classes.submitButton,
            disabled: classes.submitButtonDisabled
          }}
          onClick={handleSubmit}
          disabled={!canSubmit()}
          fullWidth
        >
          Apply Tags
        </Button>
      </DialogActions>
    </Fragment>
  );
}
AddSabhaModal.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  contactTags: PropTypes.array.isRequired,
  addressDetails: PropTypes.shape({
    state: PropTypes.string.isRequired
  }).isRequired
};
export default useStyles(AddSabhaModal);
