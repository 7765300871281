import { iconProps } from './icons-types';

export const iconFollowup = ({ stroke }: iconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.7277 1.83325L10.0913 5.46962L13.7277 9.10598"
        stroke={stroke}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 5.46948H14.6364C16.6445 5.46948 18.2727 7.09766 18.2727 9.10585V16.3786"
        stroke={stroke}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.27246 21.8333L11.9088 18.1969L8.27246 14.5605"
        stroke={stroke}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9998 18.1969H7.36341C5.35523 18.1969 3.72705 16.5688 3.72705 14.5606V7.28784"
        stroke={stroke}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.2727 21.8332C19.7789 21.8332 21 20.6122 21 19.1059C21 17.5997 19.7789 16.3787 18.2727 16.3787C16.7665 16.3787 15.5454 17.5997 15.5454 19.1059C15.5454 20.6122 16.7665 21.8332 18.2727 21.8332Z"
        stroke={stroke}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.72727 7.2878C5.2335 7.2878 6.45455 6.06676 6.45455 4.56052C6.45455 3.05429 5.2335 1.83325 3.72727 1.83325C2.22104 1.83325 1 3.05429 1 4.56052C1 6.06676 2.22104 7.2878 3.72727 7.2878Z"
        stroke={stroke}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
