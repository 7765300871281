import { makeStyles } from 'tss-react/mui';

export const useSearchStyles = makeStyles()({
  clearIcon: {
    top: '11px',
    right: '10px'
  },
  container: {
    backgroundColor: 'white',
    position: 'relative',
    width: '100%'
  },

  icon: {
    cursor: 'pointer',
    height: '18px',
    position: 'absolute'
  },
  searchIcon: {
    top: '11px',
    left: '10px',
    '&.inactive': {
      cursor: 'not-allowed'
    }
  },
  searchInput: {
    alignItems: 'center',
    background: 'none',
    border: '1px solid #c3d2df',
    borderRadius: '3px',
    color: '#C3D2DF',
    display: 'flex',
    fontFamily: 'Rubik',
    fontSize: '16px',
    height: '40px',
    outline: 'none',
    padding: '0 36px',
    width: '100%',

    '&.active': {
      color: '#5C85A7'
    },
    '&:focus': {
      borderColor: '#067bc2'
    }
  }
});
