import axios from 'axios';
import { registrationActions, commonActions } from '../constants/actions';
import { API } from '../config/config.constants';
import { TEMPTOKEN } from '../config/config.tokens';
import { VERIFICATION_FORM_SUBMIT_ERROR, VERIFICATION_FORM_SUBMIT_SUCCESS } from '../constants/toasterMessages';
/*
 * called when user clicks on 'Request Acces' button on /auth/signup
 * makes post call to sampark backend and dispatches action based on response
 * uses TEMPTOKEN to make API call
 * @param: takes in all paramaters from the redux form on /auth/signup
 */
export function submitVerificationForm () {
  return (dispatch, getState) => {
    const { values } = getState().form.verifyUser;

    const userID = values.id;
    const profileImage = values.profileImageUrl;

    const JSONObject = JSON.parse(JSON.stringify(values));
    delete JSONObject.token;
    delete JSONObject.id;
    delete JSONObject.memberID;

    const formDataObject = new FormData();
    formDataObject.append('id', userID);
    formDataObject.append('member', JSON.stringify(JSONObject));
    formDataObject.append('profileImage', profileImage);

    axios
      .put(
        API.REGISTRATION.SIGNUP,
        formDataObject,
        {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem(TEMPTOKEN),
            'sourceapp': 'sampark'
          }
        })
      .then((response) => {
        if (response && response.data) {
          dispatch({ type: registrationActions.SIGNUP_USER });
        } else {
          return dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: VERIFICATION_FORM_SUBMIT_SUCCESS, type: commonActions.ERROR_TOASTER } });
        }
      })
      .catch(({ response }) => {
        return dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: VERIFICATION_FORM_SUBMIT_ERROR, type: commonActions.ERROR_TOASTER } });
      });
  };
}
