import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    paddingBottom: '16px',
    marginBottom: '16px',
    borderBottom: '2px solid hsla(225, 19%, 60%, 0.2)'
  },
  header: {
    gap: '5px',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '1.5rem',
    fontWeight: '500'
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    alignItems: 'center',
    marginTop: '21px',
    marginBottom: '28px'
  },
  searchInput: {
    width: '432px',
    height: '50px',
    border: '1px solid hsla(225, 19%, 60%, 1) ',
    borderRadius: '3px',
    paddingLeft: '54px',
    fontSize: '1rem',
    '&:hover': {
      border: '1px solid hsla(225, 19%, 40%, 1) '
    }
  },
  fieldInput: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    color: 'hsla(226, 19%, 60%, 1)',
    ':hover': {
      color: 'hsla(225, 19%, 40%, 1)'
    }
  },
  iconSearch: {
    position: 'absolute',
    left: '16px',
    cursor: 'pointer'
  },
  iconClose: {
    position: 'relative',
    right: '40px',
    cursor: 'pointer'
  },
  button: {
    width: '148px',
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
    height: '50px',
    padding: '14px 16px',
    gap: '10px',
    borderRadius: '3px',
    backgroundColor: 'hsl(3, 100%, 66%)',
    border: 'none',
    color: 'white',
    fontSize: '1.125rem',
    fontWeight: 400,
    '&:hover': {
      backgroundColor: 'hsl(3, 100%, 57%)'
    }
  },
  submitButton: {
    width: '148px',
    height: '50px',
    padding: '14px 16px',
    gap: '10px',
    borderRadius: '3px',
    backgroundColor: 'hsl(3, 100%, 66%)',
    border: 'none',
    color: 'white',
    fontSize: '18px',
    fontWeight: 500,
    fontFamily: 'Rubik'
  }
}));
