import { Fade, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { ITextFieldProps } from '../../attributes.types';
import { useStyles } from './textField.styles';

export const TextField = ({ label, tooltipValue, children, width }: ITextFieldProps) => {
  const { classes } = useStyles();
  const style = width === 'large' ? classes.field : classes.smallField;

  return (
    <div className={style}>
      <div className={classes.labelHeader}>
        <label role="label">{label}</label>

        {tooltipValue ? (
          <Tooltip
            role="tooltip"
            title={tooltipValue}
            placement="right"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
          >
            <HelpIcon className={classes.iconHelp} />
          </Tooltip>
        ) : null}
      </div>
      {children}
    </div>
  );
};
