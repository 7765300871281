import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrapper: {
    paddingBottom: '16px',
    display: 'grid',
    gridTemplateRows: 'repeat(auto-fill, minmax(40px, 1fr))',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: '14px',
    borderBottom: '1px solid hsla(226, 19%, 46%, 0.2)',
    //For phones
    [theme.breakpoints.down('md')]: {
      height: '100%',
      padding: '14px'
    },
    //For tablets
    [theme.breakpoints.down('lg')]: {
      height: '100vh',
      minHeight: '60vh'
    }
  },
  item: {
    cursor: 'pointer',
    borderRadius: '3px',
    padding: '8px',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: '25%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%'
    }
  },
  itemLabel: {
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '230px',
    textAlign: 'left',
    color: 'inherit'
  },
  itemDescription: {
    fontSize: '10px',
    color: 'hsla(226, 19%, 46%, 0.8)'
  },
  icons: {
    display: 'flex',
    gap: '8px',
    color: 'inherit'
  },
  icon: {
    height: '16px',
    width: '16px',
    cursor: 'pointer',
    '&:hover': {
      filter: 'contrast(250%)'
    }
  },
  listWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    border: '2px solid hsl(216, 15%, 95%)',
    borderRadius: '7px',
    width: '268px',
    backgroundColor: 'white',
    color: 'hsla(226, 19%, 46%, 1)',
    '&:hover': {
      color: 'hsla(226, 19%, 35%, 1)',
      backgroundColor: 'hsla(216, 15%, 95%, 0.1)',
      border: '2px solid hsla(226, 19%, 46%, 1)',
      '&:disabled': {
        border: '2px solid hsl(216, 15%, 95%)'
      }
    },
    '&:focus': {
      border: '2px solid hsla(226, 19%, 46%, 0.8)',
      backgroundColor: 'hsl(216, 15%, 95%)'
    },
    '&:disabled': {
      backgroundColor: 'hsl(0, 0%, 98%)'
    }
  },
  loading: {
    position: 'fixed',
    top: '355px',
    bottom: 0,
    left: 0,
    right: 0,
    background: 'rgba(0, 0, 0, 0.4)'
  },
  alertIcon: {
    width: '20px',
    height: '20px'
  },
  alertText: {
    fontSize: '${21/16}rem',
    paddingLeft: '7px'
  },
  alertBanner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '7px',
    borderRadius: '7px'
  }
}));
