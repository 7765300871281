import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// PROJECT FILES
import { closeDrawer } from '../actions/common.actions';
import { LoadingSpinner } from './LoadingSpinner/LoadingSpinner';
// icons
import icon_close from '../assets/icons/icon_cross.svg';

class PanelLoading extends Component {

  render () {
    return (
      <div className='loading-panel'>
        <div className='loading-panel-top-section'>
          <img
            className='loading-form-dismiss'
            onClick={this.props.closeDrawer}
            src={icon_close} />
        </div>
        <LoadingSpinner />
      </div>
    );
  }
}

export default connect(null, {closeDrawer})(PanelLoading);

PanelLoading.propTypes = {
  closeDrawer: PropTypes.func.isRequired
};
