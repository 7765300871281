import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';

const renderRadioGroup = ({
  className,
  label = '',
  input,
  meta: { touched, error, warning },
  required = false,
  ...rest
}) => (
  <Fragment>
    {label && <div className='form-field-label-row'>
      <label
        style={{marginRight: '0.5rem', textTransform: 'capitalize'}}>{label} {required && <span className='form-field-required'>*</span>}</label>
    </div>}
    <div className='form-field-text-container'>
      <RadioGroup
        {...input}
        {...rest}
        className={['form-radio-group', className].join(' ')}
        onChange={(event, value) => input.onChange(value)}
        value={input.value} />
      {touched &&
        ((error && <div className='form-field-error'>{error}</div>) ||
          (warning && <div className='form-field-error'>{warning}</div>))}
    </div>
  </Fragment>
);

export default renderRadioGroup;

renderRadioGroup.propTypes = {
  className: PropTypes.string,
  input: PropTypes.any,
  meta: PropTypes.any,
  label: PropTypes.string,
  options: PropTypes.any,
  required: PropTypes.bool
};
