export const ALL = {
  name: 'All content',
  value: 'all'
};

export const WORDOFTHEWEEK = {
  name: 'Word of the week',
  value: 'word'
};

export const PRASANG = {
  name: 'Prasang',
  value: 'prasang'
};

export const SUTRA = {
  name: 'Sutra',
  value: 'sutra'
};

export const STORY = {
  name: 'Inspiring stories',
  value: 'story'
};
