import http from '../helpers/http';
import { API } from '../config/config.constants';

export function submitContactUsFormAPI (formValues) {
  return http
    .post(API.CONTACT_US_FORM.SUBMIT_CONTACT_US_EMAIL,
      {
        subject: formValues.issueSelect,
        message: formValues.issueDetails
      })
    .then((response) => {
      const data = response.data;
      if (data) {
        return data;
      } else {
        return { error: response };
      }
    })
    .catch((error) => {
      return { error };
    });
}

export function uploadImagesAPI (eventFormDataImages) {
  return http
    .post(API.IMAGE_UPLOAD, eventFormDataImages)
    .then((response) => {
      const data = response.data;
      if (data) {
        return data;
      } else {
        return { error: response };
      }
    }).catch((error) => {
      return { error };
    });
}

export function getMyUserAccountDataAPI () {
  return http
    .get(API.AUTHENTICATION.MY_USER_ACCOUNT)
    .then((response) => {
      const data = response.data;
      if (data) {
        return data;
      } else {
        return { error: response };
      }
    })
    .catch(error => {
      console.error(error)
      throw error
    })
}
