import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  jsonInput: {
    color: 'hsl(206, 29%, 23%)',
    fontSize: '1rem',
    width: '432px',
    height: '424px',
    resize: 'none',
    border: 'none',
    padding: '8px',
    ':invalid': {
      borderColor: 'red'
    }
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '24px'
  },
  success: {
    backgroundColor: 'hsl(120, 90%, 96%)',
    color: 'hsl(120, 80%, 35%)'
  },
  error: {
    backgroundColor: 'hsl(345, 90%, 96%)',
    color: 'hsl(345, 100%, 50%)'
  },
  alert: {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',
    borderRadius: '5px',
    fontSize: `${16 / 16}rem`,
    padding: '8px'
  }
}));
