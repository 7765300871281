import React, { BaseSyntheticEvent } from 'react';
import { CloseIcon } from '../../../../../v2/components/svg-icons';
import { useStyles } from './header.styles';

export const Header = ({
  title,
  closeHandler,
  handleFormType,
  isJsonType
}: {
  title: string;
  subTitle?: string;
  closeHandler: () => void;
  handleFormType: (value: boolean) => void;
  isJsonType: boolean;
}) => {
  const { classes } = useStyles();
  const handleChange = (e: BaseSyntheticEvent, value: boolean) => {
    e.preventDefault();
    handleFormType(value);
  };
  return (
    <div className={classes.header}>
      <div className={classes.headerClose}>
        <div className={classes.headerTitle}>{title}</div>
        <button className={classes.closeIcon} onClick={closeHandler}>
          <CloseIcon />
        </button>
      </div>
      <div className={classes.formatter}>
        <button
          className={isJsonType ? classes.button : classes.selectedButton}
          onClick={(e) => handleChange(e, false)}
          formNoValidate
        >
          HTML Form
        </button>
        <button
          className={isJsonType ? classes.selectedButton : classes.button}
          onClick={(e) => handleChange(e, true)}
          formNoValidate
        >
          JSON Form
        </button>
      </div>
    </div>
  );
};
