import http from '../helpers/http';
import { API } from '../config/config.constants';

export function getBroadcastMessages (status, id) {
  return http
    .get(`${API.BROADCAST_VIEW.BROADCAST_MESSAGES_API}?${buildQueryStringForGetBroadcastMessages(status, id)}`)
    .then((response) => {
      let broadcasts = response.data;
      if (broadcasts) {
        return broadcasts;
      } else {
        return { error: response };
      }
    })
    .catch((error) => {
      return { error };
    });
}

export function createBroadcastMessage (message) {
  return http
    .post(API.BROADCAST_VIEW.BROADCAST_MESSAGES_API, message)
    .then((response) => {
      let broadcastMessage = response.data;
      if (broadcastMessage) {
        return broadcastMessage;
      } else {
        return { error: response };
      }
    })
    .catch((error) => {
      return { error };
    });
}

function buildQueryStringForGetBroadcastMessages (status, id) {
  let queryString = '';
  if (status) {
    queryString += `status=${status.toUpperCase()}&`;
  }
  if (id) {
    queryString += `skip=${id}&`;
  }

  return queryString;

}

export function changeBroadcastMessageStatus (id, status, declinedReason) {
  return http
    .put(`${API.BROADCAST_VIEW.BROADCAST_MESSAGES_API}/${id}`, {
      status,
      declinedReason
    })
    .then((response) => {
      let broadcastMessage = response.data;
      if (broadcastMessage) {
        return broadcastMessage;
      } else {
        return { error: response };
      }
    })
    .catch((error) => {
      return { error };
    });
}
