import { FieldLabel } from '../access-controls/FieldLabel';
import { MultiSelectorField } from '../MultiSelectorField';
import { useMultiSelectDropDownChips } from './hooks/useMultiSelectDropdown';
import Chips from './component/Chips/index';
const MultiSelectDropDownWithChips = ({
  onChange,
  disabled,
  displayConfig,
  value = [],
  eventName,
  options = []
}: IMultiSelectDropDownWithChips) => {
  const { inputValue, onMultiSelectDropdown, onDeleteClick } = useMultiSelectDropDownChips({
    onChange,
    options,
    value,
    eventName,
    isRequired: displayConfig.required
  });

  return (
    <div className="access-container__panel-control access-container__level-selector-container">
      <FieldLabel
        label={displayConfig.displayLabel}
        required={displayConfig.required}
        tooltip={displayConfig.tooltip}
      />
      <MultiSelectorField
        options={[{ name: displayConfig.displayLabel, value: options }]}
        input={{ name: displayConfig.displayLabel, value: inputValue }}
        buttonLabel={displayConfig.placeholder}
        readOnly={disabled}
        required={displayConfig.required}
        disabled={disabled}
        onChange={onMultiSelectDropdown}
        placeholder={displayConfig.placeholder}
      />
      <Chips disabled={disabled} tags={inputValue} onDeleteClick={onDeleteClick} />
    </div>
  );
};

export interface IMultiSelectDropDownWithChips {
  disabled: boolean;
  onChange: (event: string, value: IOption[], formValid: boolean) => void;
  displayConfig: IDisplayConfig;
  options: IOption[];
  value: IOption[] | undefined;
  eventName: string;
}

interface IDisplayConfig {
  displayLabel: string;
  description: string;
  tooltip: string;
  required: boolean;
  key: string;
  placeholder: string;
}

export interface IOption {
  name: string;
  value: string;
}

export default MultiSelectDropDownWithChips;
