import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  toaster: {
    position: 'fixed',
    width: '300px',
    bottom: '50px',
    right: '-310px'
  }
}));
