import { NavLink } from 'react-router-dom';
import icon_hamburger from '../../assets/icons/icon_hamburger_blue.svg';
import sampark from '../../assets/icons/sampark.svg';
import { SAMPARK_ROUTE } from '../../constants/routes';
import { useHeaderTabs } from '../../v2/hooks/useHeaderTabs';
import { useSideNavStyles } from './SideNav.styles';

interface ISideNav {
  isExpanded: boolean;
  toggleExpanded: () => void;
}

export const SideNav = ({ isExpanded, toggleExpanded }: ISideNav) => {
  const tabValues: SAMPARK_ROUTE[] = useHeaderTabs();
  const { classes } = useSideNavStyles();

  return (
    <div className="sideNav-sampark">
      <a className={classes.link} key={'top'}>
        <div onClick={toggleExpanded} className={classes.linkContainer}>
          <div className={classes.topIconContainer}>
            <img src={icon_hamburger} />
          </div>
          {isExpanded ? (
            <div className={classes.topIconContainer}>
              <img src={sampark} className={classes.samparkIcon} />
            </div>
          ) : null}
        </div>
      </a>
      {tabValues.map((tab: any) => {
        return (
          <NavLink
            key={tab.value}
            to={tab.value}
            className={classes.link}
            activeClassName={classes.activeLink}
          >
            <div className={classes.linkContainer} key={tab.value}>
              <div className={classes.icon}>{tab.icon({ stroke: 'currentColor' })}</div>
              {isExpanded ? <span className={classes.text}> {tab.name} </span> : null}
            </div>
          </NavLink>
        );
      })}
    </div>
  );
};
