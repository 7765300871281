import { BaseSyntheticEvent } from 'react';

export enum RespState {
  READY,
  LOADING,
  LOADED,
  LOAD_ERROR,
  CREATING,
  CREATED,
  CREATE_ERROR,
  UPDATING,
  UPDATED,
  UPDATE_ERROR,
  DELETING,
  DELETED,
  DELETED_ERROR,
  DELETE_CONFIRM,
  ROLLING_BACK,
  ROLLED_BACK,
  ROLLBACK_ERROR
}

export interface ICreateRule {
  rule: any;
  method: string;
}

export interface ICondition {
  [index: string]: string[];
}

export interface Event {
  type: string;
}

export interface Rule {
  event: Event;
  conditions: ICondition;
}

export interface IRuleObject {
  rule: Rule;
  _id: string;
  id?: string;
  name: string;
}

export interface IRuleAdminRedux {
  rule: IRuleObject[];
  previousRule: any;
  rulesApiState: RespState;
  rules: IRuleObject[];
}

export interface IAttributeList {
  activeId: any;
  editHandler: (value?: string) => void;
}

export interface IAttributes {
  _id?: string;
  key: string;
  description?: string;
  group?: string;
  definition: {
    fact: string;
    operator: string;
    value: string[] | string;
    path?: string;
    field?: string;
  };
  field?: any;
}

export interface IRule {
  attributeList: IAttributes[];
  operator: string;
}

export interface IEditRule {
  globalOperator: string;
  handleGlobalOperator: (value: string) => void;
  ruleHandler: () => void;
  rule: IRule[];
  removeAttributeHandler: (value1: number, value2: number) => void;
  removeRule: (value: number) => void;
  editHandler: (value?: string) => void;
  ruleSelected: IRuleObject[];
  handleRule: any;
  closeRuleHandler: any;
}

export interface IFormRule {
  ruleSelected?: IRuleObject[];
  name: string;
  handleName: (name: string) => void;
  description: string;
  handleDescription: (description: string) => void;
  isGlobalOperator: boolean;
  globalOperator: string;
  onChange: (operator: string) => void;
  rule: IRule[];
  operator: string[];
  handleOperator: (operator: string, index: number) => void;
  removeAttributeHandler: (attributeIndex: number, index: number) => void;
  closeHandler: (index: number) => void;
  addHandler: () => void;
}

export interface IJsonRule {
  jsonData?: string;
  isError: boolean;
  errorMessage: string;
  handleJsonData: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export interface IHeader {
  ruleSelected?: IRuleObject[];
  handleOpenModal: (name: string) => void;
  isJsonType: boolean;
  handleFormatType: (e: BaseSyntheticEvent, value: boolean) => void;
  name: string;
}
