import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import icon_close_mini from '../../assets/icons/icon_close_mini.svg';

const alphabetRegEx = /^[A-Za-z ]+$/;
const renderInterests = ({
  label = null,
  fields,
  max = 5,
  disabled = false,
  placeholder
}) => {
  disabled = fields.length >= max || disabled;

  function addInterest (event) {
    // Only allow add if we have less than 5 interest
    if (fields.length < max) {
      let trimmedValue = event.target.value.trim();

      // Remove comma if present
      if (trimmedValue.charAt(trimmedValue.length - 1) === ',') {
        trimmedValue = trimmedValue.substring(0, trimmedValue.length - 1);
      }

      // If value is not an empty string and contains only letter characters, then add interest
      if (trimmedValue && trimmedValue !== '' && alphabetRegEx.test(trimmedValue)) {
        fields.push(trimmedValue);
      }
      event.target.value = '';
    }
  }

  function handleOnKeyUp (event) {
    // 188 (comma), 13 (enter);
    if (event.which === 188 || event.which === 13) {
      addInterest(event);
    }
  }

  return (
    <Fragment>
      {label && <div className='form-field-label-row'>
        {label && <label
          style={{marginRight: '0.5rem'}}>{label}</label>}
      </div>}
      <div className='form-field-tags-container'>
        <input
          type='text'
          name='interestPusher'
          className={['form-field-text padding-xsm-left', disabled ? 'disabled-input' : null].join(' ')}
          placeholder={placeholder}
          onBlur={addInterest}
          onKeyUp={handleOnKeyUp}
          disabled={disabled}
        />
        <div className='form-field-tags-list'>
          {fields.getAll() && fields.getAll().map((tag, index) => {
            return (
              <Chip
                onDelete={() => {
                  fields.remove(index);
                }}
                deleteIcon={<img src={icon_close_mini} style={{ height: '10px', margin: '0' }} />}
                className='chip'
                key={index}
                label={<span className='chip-label'>{tag}</span>} />
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};

export default renderInterests;

renderInterests.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  fields: PropTypes.object,
  placeholder: PropTypes.string,
  max: PropTypes.number
};
