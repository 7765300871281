import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },

  alertIcon: {
    width: '18px',
    height: '18px'
  },
  alertText: {
    fontSize: '1rem',
    paddingLeft: '7px'
  },
  banner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '7px',
    borderRadius: '7px'
  },
  searchInput: {
    marginBottom: '4px',
    marginTop: '7px',
    border: 'none',
    width: '50%',
    fontSize: '1rem'
  },
  ruleLabel: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '230px',
    cursor: 'pointer'
  },
  ruleContainer: {
    cursor: 'pointer',
    padding: '8px',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '260px',
    borderRadius: '7px',
    fontSize: '1rem',
    fontFamily: 'Rubik',
    color: 'hsla(226, 19%, 46%, 1)',
    backgroundColor: 'hsla(216, 15%, 95%, 0.3)',
    border: '2px solid hsla(226, 19%, 46%, 0.1)',
    '&:hover': {
      color: 'hsla(226, 19%, 35%, 1)',
      backgroundColor: 'hsl(216, 15%, 95%)',
      border: '2px solid hsla(226, 19%, 46%, 0.8)'
    },
    '&:focus': {
      color: 'hsla(226, 19%, 35%, 1)',
      border: '2px solid hsla(226, 19%, 46%, 0.8)',
      backgroundColor: 'hsl(216, 15%, 95%)'
    }
  },
  bodyContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gridTemplateRows: 'repeat(auto-fill, minmax(40px, 1fr))',
    overflowY: 'scroll',
    height: '60vh',
    gap: '14px',
    animation: '300ms fadeIn ease-in-out',
    animationFillMode: 'both',
    padding: '7px 0px'
  },
  header: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid hsla(225, 35%, 93%, 1)',
    marginBottom: '7px',
    backgroundColor: 'white'
  },
  createButton: {
    minWidth: '120px',
    height: '40px',
    padding: '8px 12px',
    gap: '10px',
    borderRadius: '3px',
    backgroundColor: 'hsla(3, 100%, 66%, 1)',
    border: 'none',
    color: 'white',
    fontSize: '1rem',
    fontWeight: 400,
    '&:hover': {
      backgroundColor: 'hsl(3, 100%, 57%)'
    },
    position: 'absolute',
    right: '20px',
    cursor: 'pointer'
  },
  container: {
    border: '1px solid hsla(208, 30%, 82%, 1)',
    height: '72vh',
    borderRadius: '20px',
    backgroundColor: 'white',
    flex: 4,
    padding: '24px 16px'
  }
}));
