import { useEffect } from 'react';

export const useKeyPress = (key: string, onKeyPress: () => void) => {
  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === key) {
        onKeyPress();
      }
    };

    window.addEventListener('keydown', handleKeydown);
    return () => window.removeEventListener('keydown', handleKeydown);
  }, [onKeyPress]);
};
