import CheckCircle from '@mui/icons-material/CheckCircle';
import Report from '@mui/icons-material/Report';
import { IJsonRule } from '../../../rules.types';
import { useStyles } from './jsonRule.styles';

export const JsonRule = ({ jsonData, isError, errorMessage, handleJsonData }: IJsonRule) => {
  const { classes } = useStyles();

  return (
    <>
      <div className={classes.formWrapper}>
        <textarea
          className={classes.jsonInput}
          value={jsonData}
          placeholder="Paste your json Rule here. "
          onChange={handleJsonData}
          required
        />
      </div>
      {isError && (
        <div
          className={classes.alert}
          style={{
            backgroundColor: 'hsl(345, 90%, 96%)',
            color: 'hsl(345, 100%, 50%)',
            position: 'absolute',
            bottom: '120px',
            right: '10px',
            width: '300px',
            gap: '14px',
            paddingLeft: '20px'
          }}
        >
          <Report />
          <p style={{ color: 'hsl(345, 100%, 50%)' }}>{errorMessage}</p>
        </div>
      )}
      {jsonData === undefined ||
        (isError !== undefined && !isError && (
          <div
            className={classes.alert}
            style={{
              backgroundColor: 'hsl(120, 90%, 96%)',
              color: 'hsl(120, 80%, 35%)',
              position: 'absolute',
              bottom: '120px',
              right: '10px',
              width: '300px',
              gap: '14px',
              paddingLeft: '20px'
            }}
          >
            <CheckCircle />
            <p style={{ color: 'hsl(120, 80%, 35%)' }}>Your JSON looks good !!</p>
          </div>
        ))}
    </>
  );
};
