import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import icon_copy from '../assets/icons/icon_copy.svg';
import icon_tick from '../assets/icons/icon_tick.svg';

interface ICopyIconProps {
  content: string;
}

const useStyles = makeStyles({
  container: {
    minWidth: '24px',
    minHeight: '24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  }
});

export const CopyIcon = (props: ICopyIconProps) => {
  const { content } = props;
  const classes = useStyles();

  const [clickedCopy, setClickedCopy] = useState(false);

  useEffect(() => {
    let resetCopyInterval: ReturnType<typeof setInterval>;
    if (clickedCopy) {
      resetCopyInterval = setInterval(() => {
        setClickedCopy(false);
      }, 2000);
    }

    return () => clearInterval(resetCopyInterval);
  }, [clickedCopy]);

  const copyIdToClipboard = () => {
    if (navigator && content) {
      navigator.clipboard.writeText(content);
      setClickedCopy(true);
    }
  };

  return (
    <div className={classes.container} onClick={copyIdToClipboard}>
      <img
        src={clickedCopy ? icon_tick : icon_copy}
        style={{
          cursor: 'pointer'
        }}
      />
    </div>
  );
};
