import http from '../helpers/http';
import { API } from '../config/config.constants';
import { SAMPARKTOKEN } from '../config/config.tokens';

export function getDashboard () {
  return http.get(API.DASHBOARD_VIEW.GET_DASHBOARD_DATA, {
    headers: { 'Authorization': 'Bearer ' + localStorage.getItem(SAMPARKTOKEN) }
  }).then((response) => {
    const data = response.data;
    if (data) {
      return data;
    } else {
      return { error: response };
    }
  }).catch((error) => {
    return { error };
  });
}
