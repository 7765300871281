import { useCallback, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Dialog, useMediaQuery } from '@mui/material';
import { useFeature } from '@growthbook/growthbook-react';
import YogAdminFilters from './YogAdminFilters';
import YogAdminResults from './YogAdminResults';
import {
  IRegistration,
  RespState,
  searchRegistrations,
  updateRegistration,
  setSelectedRegistration,
  resetSelectedRegistration,
  updateRegistrationsState,
  setActiveFilters,
  InitialActiveFilterValues,
  IFilters
} from './yogAdmin.duck';
import { useAppDispatch, useAppSelector } from '../../../helpers/store';
import { ViewRegistrationModal } from './components/Modal/ViewRegistrationModal';
import { ConfirmRejectModal } from './components/Modal/ConfirmRejectModal';
import { selectMandalListByGender } from '../../Contacts/contacts.ducks';
import { REGISTRATION_STATUS } from './constants/RegistrationStatus';
import { ConfirmRemoveHoldModal } from './components/Modal/ConfirmRemoveHoldModal';

interface IYogAdminProps {
  eventId: string;
}

const YogAdmin = ({ eventId }: IYogAdminProps) => {
  const { classes, theme } = useStyles();
  const dispatch = useAppDispatch();
  const yogAdmin = useAppSelector((state) => state.admin.yogAdmin);
  const mandalList = useAppSelector(selectMandalListByGender);
  const { isSuperAdmin } = useAppSelector((state) => state.userAccess);
  const {
    paginationMeta,
    registrations,
    selectedRegistration,
    activeFilters,
    getRegistrationsApiState,
    updateRegistrationApiState
  } = yogAdmin;

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [showConfirmRejectModal, setShowConfirmRejectModal] = useState<IRegistration | null>(null);
  const [showConfirmRemoveHoldModal, setShowConfirmRemoveHoldModal] =
    useState<IRegistration | null>(null);
  const [showFilterModal, setShowFilterModal] = useState(false);

  useEffect(() => {
    dispatch(searchRegistrations({ pageNumber: 1, eventId: eventId }));
  }, [eventId]);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const yogAdminActions = useFeature('sampark.yog-admin-actions').on;

  const isSearchLoading = getRegistrationsApiState === RespState.PENDING;
  const isUpdatingRegistration = updateRegistrationApiState === RespState.PENDING;

  const onFilterSubmit = (values: IFilters) => {
    setPageNumber(1);
    dispatch(setActiveFilters(values));
    dispatch(searchRegistrations({ pageNumber: 1, eventId: eventId }));
  };

  const fetchMore = useCallback(() => {
    if (!isSearchLoading) {
      setPageNumber(pageNumber + 1);
      dispatch(searchRegistrations({ pageNumber: pageNumber + 1, eventId: eventId }));
    }
  }, [paginationMeta.maxPageNumber, pageNumber]);

  const handleApproveRegistration = (registration: IRegistration) => {
    if (yogAdminActions) {
      dispatch(
        updateRegistration({
          ...registration,
          status: REGISTRATION_STATUS.APPROVED
        })
      );
    }
  };

  const handlePaidRegistration = (registration: IRegistration, paid: boolean) => {
    if (yogAdminActions) {
      dispatch(
        updateRegistration({
          ...registration,
          status: paid ? REGISTRATION_STATUS.PAID_OVERRIDE : REGISTRATION_STATUS.APPROVED
        })
      );
    }
  };

  const openConfirmRejectModal = (registration: IRegistration) => {
    if (yogAdminActions) {
      setShowConfirmRejectModal(registration);
    }
  };

  const openConfirmRemoveHoldModal = (registration: IRegistration) => {
    setShowConfirmRemoveHoldModal(registration);
  };

  const handleRejectRegistration = (registration: IRegistration) => {
    if (yogAdminActions) {
      dispatch(
        updateRegistration({
          ...registration,
          status: REGISTRATION_STATUS.REJECTED
        })
      );
      //Add async then after fixing ReduxJS Toolkit
      setShowConfirmRejectModal(null);
    }
  };

  const handleUpdateRegistration = (registration: IRegistration) => {
    if (yogAdminActions) {
      dispatch(
        updateRegistration({
          ...registration
        })
      );
      dispatch(updateRegistrationsState(registration));
    }
  };

  const handleClickFamilyId = (familyId: string) => {
    onFilterSubmit({ ...activeFilters, familyId });
  };

  const handleClearFilterByField = (field: keyof IFilters) => {
    onFilterSubmit({ ...activeFilters, [field]: InitialActiveFilterValues[field] });
  };

  const handleClearAllFilters = () => {
    dispatch(setActiveFilters(InitialActiveFilterValues));
    dispatch(searchRegistrations({ pageNumber: 1, eventId: eventId }));
  };

  const handleToggleFilterModal = () => {
    setShowFilterModal(!showFilterModal);
  };

  return (
    <div className={classes.container}>
      {!isMobile ? (
        <YogAdminFilters
          eventId={eventId}
          isSuperAdmin={isSuperAdmin}
          isLoading={isSearchLoading}
          maxPageNumbers={paginationMeta.maxPageNumber}
          isError={getRegistrationsApiState === RespState.FAILED}
          handleClearAllFilters={handleClearAllFilters}
          onFilterSubmit={onFilterSubmit}
          mandalOptions={mandalList}
          activeFilters={activeFilters}
        />
      ) : null}
      <YogAdminResults
        eventId={eventId}
        registrations={registrations}
        totalResults={paginationMeta.total}
        fetchMore={fetchMore}
        onClickRegistration={(registration) => {
          dispatch(setSelectedRegistration(registration));
        }}
        openConfirmRejectModal={openConfirmRejectModal}
        openConfirmRemoveHoldModal={openConfirmRemoveHoldModal}
        approveRegistration={handleApproveRegistration}
        rejectRegistration={handleRejectRegistration}
        onClickFamilyId={handleClickFamilyId}
        handleClearFilterByField={handleClearFilterByField}
        activeFilters={activeFilters}
        isUpdatingRegistration={isUpdatingRegistration}
        isMobile={isMobile}
        toggleFilterModal={handleToggleFilterModal}
      />
      <Dialog
        fullScreen={isMobile}
        open={!!selectedRegistration}
        PaperProps={{
          classes: {
            root: classes.dialogContainer
          }
        }}
        onClose={() => dispatch(resetSelectedRegistration())}
      >
        {selectedRegistration ? (
          <ViewRegistrationModal
            cancelCallBack={() => {
              dispatch(resetSelectedRegistration());
            }}
            updateRegistration={handleUpdateRegistration}
            approveRegistration={handleApproveRegistration}
            rejectRegistration={openConfirmRejectModal}
            handlePaidRegistration={handlePaidRegistration}
            openConfirmRemoveHoldModal={openConfirmRemoveHoldModal}
            isUpdatingRegistration={isUpdatingRegistration}
            hideActions={!yogAdminActions}
            eventId={eventId}
          />
        ) : null}
      </Dialog>
      <Dialog
        fullScreen={false}
        open={!!showConfirmRejectModal}
        PaperProps={{
          classes: {
            root: classes.dialogContainer
          }
        }}
      >
        {showConfirmRejectModal ? (
          <ConfirmRejectModal
            cancelCallBack={() => {
              setShowConfirmRejectModal(null);
            }}
            confirmCallBack={handleRejectRegistration}
            registration={showConfirmRejectModal}
            isUpdatingRegistration={isUpdatingRegistration}
          />
        ) : null}
      </Dialog>
      <Dialog
        fullScreen={false}
        open={!!showConfirmRemoveHoldModal}
        PaperProps={{
          classes: {
            root: classes.dialogContainer
          }
        }}
      >
        {showConfirmRemoveHoldModal ? (
          <ConfirmRemoveHoldModal
            cancelCallBack={() => {
              setShowConfirmRemoveHoldModal(null);
            }}
            confirmCallBack={(registration) => {
              setShowConfirmRemoveHoldModal(null);
              handleApproveRegistration(registration);
            }}
            registration={showConfirmRemoveHoldModal}
            isUpdatingRegistration={isUpdatingRegistration}
          />
        ) : null}
      </Dialog>
      <Dialog
        fullScreen={isMobile}
        open={showFilterModal}
        PaperProps={{
          classes: {
            root: classes.dialogContainer
          }
        }}
        onClose={handleToggleFilterModal}
      >
        <YogAdminFilters
          isSuperAdmin={isSuperAdmin}
          isLoading={isSearchLoading}
          maxPageNumbers={paginationMeta.maxPageNumber}
          isError={getRegistrationsApiState === RespState.FAILED}
          handleClearAllFilters={handleClearAllFilters}
          onFilterSubmit={onFilterSubmit}
          mandalOptions={mandalList}
          activeFilters={activeFilters}
          onCancel={handleToggleFilterModal}
          eventId={eventId}
        />
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    background: 'white'
  },
  dialogContainer: {
    width: '400px'
  }
}));

export default YogAdmin;
