import * as TAB_GROUPS from '../constants/tabGroups';

export default function getTabOptions (isSuperAdmin, tabGroup, userRoutes) {
  if (tabGroup === TAB_GROUPS.HEADER_TABS) {
    return getHeaderTabs(userRoutes);
  }
};

function getHeaderTabs (userRoutes) {
  return userRoutes.filter((r, i) => {
    return r.permission && !r.hideInNav;
  });
}
