import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import icon_close_small_black from '../../assets/icons/icon_close_small_black.svg';
import renderTextField from './renderTextField';

const renderEventNumbers = ({
  label = null,
  phoneTypes,
  input,
  placeholder,
  meta: { touched, error, warning },
  addNew = true,
  fields
}) => (
  <Fragment>
    <div className='event-numbers-container'>
      {fields && fields.map((field, index) => {
        return (
          <div
            className='event-numbers-row'
            key={index}>
            <div>
              <Field
                required={index === 0}
                label='Name/Department'
                name={`${fields.name}[${index}].name`}
                component={renderTextField}
                placeholder='Enter name or dept'
              />
              {touched &&
              ((error && <div className='form-field-error'>{error}</div>) ||
                (warning && <div className='form-field-error'>{warning}</div>))}
            </div>
            <div className='event-number-and-dismiss'>
              <div>
                <Field
                  required={index === 0}
                  label='Number'
                  name={`${fields.name}[${index}].phone`}
                  component={renderTextField}
                  placeholder='(xxx) xxx-xxx'
                  format={formatPhoneNumber}
                  parse={parsePhoneNumber}
                />
                {touched &&
                ((error && <div className='form-field-error'>{error}</div>) ||
                  (warning && <div className='form-field-error'>{warning}</div>))}
              </div>
              {index !== 0 && <img
                onClick={() => fields.remove(index)}
                className='event-number-dismiss'
                src={icon_close_small_black} />}
            </div>
          </div>
        );
      })}
      <div>
        <span className='event-numbers-add' onClick={() => fields.push({ label: '', value: '' })}>
          + Add contact number
        </span>
      </div>
    </div>
  </Fragment>
);

renderEventNumbers.propTypes = {
  input: PropTypes.any,
  fields: PropTypes.any,
  placeholder: PropTypes.string,
  meta: PropTypes.any,
  label: PropTypes.string,
  addNew: PropTypes.string,
  phoneTypes: PropTypes.array
};

export default renderEventNumbers;

const formatPhoneNumber = (value) => {
  if (value && value.length === 10) {
    return `(${value.substring(0, 3)}) ${value.substring(3, 6)}-${value.substring(6, 10)}`;
  } else {
    return value;
  }
};
const parsePhoneNumber = (value) => {
  const onlyNumbers = new RegExp('^[0-9]+$');
  let parsed = '';
  [...value].forEach((char) => {
    if (onlyNumbers.test(char)) {
      parsed += char;
    }
  });
  return parsed;
};
