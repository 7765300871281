import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const renderDatePicker = ({
  label = null,
  input,
  placeholder,
  meta: { touched, error, warning, dirty },
  readOnly = false,
  required = false,
  maxDate = '',
  minDate = ''
}) => {
  let classes = ['form-field-text', 'padding-xsm-left'];
  if (readOnly) classes.push('disabled-text-field');
  if (touched && error) classes.push('form-field-text-error-state');
  return (
    <Fragment>
      {label && <div className='form-field-label-row'>
        <label>{label} {required && <span className='form-field-required'>*</span>}</label>
      </div>}
      <div className='form-field-text-container'>
        <input
          type='date'
          style={{
            fontFamily: 'Rubik',
            textTransform: 'none'
          }}
          disabled={readOnly}
          className={classes.join(' ')}
          {...input}
          min={minDate}
          max={maxDate}
          placeholder={placeholder} />
        {touched &&
          ((error && <div className='form-field-error'>{error}</div>) ||
            (warning && <div className='form-field-error'>{warning}</div>))}
      </div>
    </Fragment>
  );
};

export default renderDatePicker;

renderDatePicker.propTypes = {
  input: PropTypes.any,
  placeholder: PropTypes.string,
  meta: PropTypes.any,
  label: PropTypes.string,
  addNew: PropTypes.string,
  readOnly: PropTypes.bool,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  required: PropTypes.bool
};
