import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';
// material ui
import { withStyles } from '@material-ui/core/styles';
import { Modal } from '@material-ui/core';
// project files
import { checkUserPermission, ADMINTABS, ISSUPERADMIN, CANUPDATECONTACTS } from '../../util/userPermission';
import icon_close from '../../assets/icons/icon_cross.svg';
import { AccessContainer } from '../../v2/containers';
import SamparkAdmin from './SamparkAdmin';
import SambandhAdmin from './SambandhAdmin';
import FollowUpAdmin from './FollowUpAdmin';
import FamilyTree from './FamilyTree';
import { isMobileDevice } from '../../util/windowUtils';
import YogAdmin from './YogAdmin';
import {Attributes} from './Attributes';
import {Rules} from './Rules';

const useStyles = withStyles({
  container: {
    margin: '0rem auto',
    maxWidth: '650px'
  },
  view: {
    margin: '1.5rem'
  },
  navContainer: {
    display: 'inline-flex',
    paddingLeft: '0',
    '& li': {
      listStyleType: 'none'
    }
  },
  link: {
    textDecoration: 'none',
    fontSize: '16px',
    fontWeight: '500',
    color: '#2a3d4c',
    marginRight: '24px',
    paddingBottom: '8px'
  },
  activeLink: {
    color: '#067bc2',
    borderBottom: '2px solid #ff5a52'
  },
  '@media (max-device-width : 736px)': {
    link: {
      paddingBottom: 0
    },
    navContainer: {
      maxWidth: 'calc(100vw - 2rem)',
      overflowX: 'scroll'
    }
  }
});

class AdminView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValues: props.adminTabs,
      accessModalOpen: false,
      selectedApproval: null
    };
  }

  componentDidMount() {
    document.title = 'Sampark | Admin';
  }

  setAccessModalOpen = (accessObj) => {
    this.setState({
      selectedApproval: accessObj,
      accessModalOpen: true
    });
  }

  setAccessModalClosed = () => {
    this.setState({
      selectedApproval: null,
      accessModalOpen: false
    });
  }

  render() {
    const {
      tabValues,
      accessModalOpen,
      selectedApproval
    } = this.state;

    const {
      classes
    } = this.props;

    const isMobile = isMobileDevice();

    return (
      <div className='admin-view-container'>
        <header className='admin-header'>
          {
            !isMobile ? (
              <h2 className='admin-title'>Manage Access</h2>
            ) : null
          }
          <nav>
            <ul className={classes.navContainer}>
              {tabValues.map((tab, index) => {
                return (
                  <li key={tab.value}>
                    <NavLink
                      to={tab.value}
                      className={classes.link}
                      activeClassName={classes.activeLink}
                    >{tab.name}</NavLink>
                  </li>
                );
              })}
            </ul>
          </nav>
        </header>
        <section className='admin-data-container'>
          {this.renderAdminDataContainer()}
          <Modal
            aria-labelledby='simple-modal-title'
            aria-describedby='simple-modal-description'
            open={accessModalOpen}
            onClose={this.setAccessModalClosed}>
            <div className={'access-container__modal-style'} >
              <a className='contact-form-dismiss' onClick={(e) => { e.preventDefault(); this.setAccessModalClosed(); }}><img
                aria-label='dismiss'
                src={icon_close} /></a>
              <AccessContainer approval={selectedApproval} onClose={this.setAccessModalClosed} />
            </div>
          </Modal>
        </section>
      </div>
    );
  }

  renderAdminDataContainer = () => {
    const {
      canUpdateContacts,
      isSuperAdmin
    } = this.props;

    const baseAdminPath = '/sampark/admin/';
    const defaultActiveTab = isSuperAdmin ? 'samparkAdmin' : 'sambandhApprovals';

    return (
      <Switch>
        {isSuperAdmin ? (
          <Route
            path={`${baseAdminPath}samparkAdmin`}
            render={(routeProps) => (
              <SamparkAdmin
                {...routeProps}
                setAccessModalOpen={this.setAccessModalOpen}
              />
            )}
          />
        ) : null}
        {isSuperAdmin ? <Route
          path={`${baseAdminPath}familyTree`}
          render={(routeProps) => (
            <FamilyTree
              {...routeProps}
            />
          )}
        /> : null}
        {isSuperAdmin ? <Route
          path={`${baseAdminPath}attributes`}
          render={(routeProps) => (
            <Attributes
              {...routeProps}
            />
          )}
        /> : null}
        {isSuperAdmin ? <Route
          path={`${baseAdminPath}rules`}
          render={(routeProps) => (
            <Rules
              {...routeProps}
            />
          )}
        /> : null}
        {isSuperAdmin || canUpdateContacts ? (
          <Route
            path={`${baseAdminPath}sambandhApprovals`}
            render={(routeProps) => (
              <SambandhAdmin
                {...routeProps}
                setAccessModalOpen={this.setAccessModalOpen}
              />
            )}
          />) : null}
        {isSuperAdmin || canUpdateContacts ? (
          <Route
            path={`${baseAdminPath}followupkk`}
            component={FollowUpAdmin}
          />) : null}
        <Redirect to={`${baseAdminPath}${defaultActiveTab}`} />
      </Switch >
    );
  }
}

function mapStateToProps(state) {
  return {
    approvedUsers: state.adminData.approvedUsers,
    sambandhData: state.adminData.sambandhData,
    declinedUsers: state.adminData.declinedUsers,
    isSuperAdmin: checkUserPermission(state.userAccess, ISSUPERADMIN),
    canUpdateContacts: checkUserPermission(state.userAccess, CANUPDATECONTACTS),
    adminTabs: checkUserPermission(state.userAccess, ADMINTABS)
  };
}

AdminView.propTypes = {
  approvedUsers: PropTypes.arrayOf(PropTypes.object),
  sambandhData: PropTypes.array.isRequired,
  canUpdateContacts: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  adminTabs: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default connect(mapStateToProps, null)(useStyles(AdminView));
