import { TextField } from '../../../TextField/TextField';
import { MultiSelect } from '../../../SearchSelect/MultiSelect';
import { SingleSelect } from '../../../SearchSelect/SingleSelect';
import { IAllFields } from '../../../../attributes.types';
import { useStyles } from './field.styles';
interface IValueDropdown {
  field: IAllFields | undefined;
  fieldValue: string | string[];
  handleFieldValueChange: (val: string | string[] | React.ChangeEvent<HTMLInputElement>) => void;
}

const ValueDropdown = ({ field, fieldValue, handleFieldValueChange }: IValueDropdown) => {
  const { classes } = useStyles();

  if (field?.datatype === 'labelValues' && field?.multiSelect) {
    return (
      <TextField label="Value" tooltipValue="Field Value">
        <MultiSelect
          inputValue={Array.isArray(fieldValue) ? fieldValue : fieldValue ? [fieldValue] : []}
          options={field?.labelValues}
          onChange={handleFieldValueChange}
          multiple={field?.multiSelect}
        />
      </TextField>
    );
  }
  if (field?.datatype === 'labelValues') {
    return (
      <TextField label="Value" tooltipValue="Field Value">
        <SingleSelect
          placeholder="Value"
          inputValue={fieldValue}
          options={field?.labelValues}
          onChange={handleFieldValueChange}
        />
      </TextField>
    );
  }

  if (field?.datatype === 'number') {
    return (
      <TextField label="Value" tooltipValue="Field Value">
        <input
          className={classes.numberInput}
          type="number"
          value={fieldValue}
          onChange={(event) => handleFieldValueChange(event.target.value)}
          required
        />
      </TextField>
    );
  }

  return (
    <TextField label="Value" tooltipValue="Field Value">
      <input
        className={classes.fieldInput}
        placeholder="Value"
        value={fieldValue}
        onChange={(event) => handleFieldValueChange(event.target.value)}
        type="text"
        pattern="^\S+$"
        title="No spaces allowed"
        required
      />
    </TextField>
  );
};

export default ValueDropdown;
