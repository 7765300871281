import http from '../helpers/http';
import { API } from '../config/config.constants';
import { ICreateRule } from '../views/Admin/Rules/rules.types';

export const createOrUpdateRule = async (rules: ICreateRule) => {
  let response;
  if (rules.method === 'POST') {
    response = await http.post(API.RULE.CREATE_RULE, rules.rule);
  } else {
    response = await http.put(API.RULE.CREATE_RULE, rules.rule);
  }
  if (response && response.data) {
    return response.data;
  } else {
    return { error: response };
  }
};

export const getAllRules = () => {
  return http
    .get(API.RULE.GET_ALL_RULES)
    .then((response) => {
      const data = response.data;
      if (data) {
        return data;
      } else {
        return { error: response };
      }
    })
    .catch(({ error }) => {
      console.error('No data found', error);
      return { error: 'No data found' };
    });
};

export const removeRule = async (id: string) => {
  return http
    .delete(API.RULE.REMOVE_RULE(id))
    .then((response) => {
      const data = response.data;
      if (data) {
        return data;
      } else {
        return { error: response };
      }
    })
    .catch(({ error }) => {
      return { error: error };
    });
};
