const validate = (values, props) => {
  let errors = {};

  if (!values.type) {
    errors.type = 'Please select an event type';
  }
  if (!values.title) {
    errors.title = 'Please enter a title for the event';
  }

  if (!values.audiences || values.audiences.length === 0) {
    errors.audiences = 'Please select audiences';
  }

  if (!values.venue) {
    errors.venue = 'Please enter venue details';
    errors.venue = {
      address1: 'Please enter a street address',
      city: 'Please enter a city',
      state: 'Please enter a state',
      zip: 'Please enter a zip code'
    };
  }

  const { target } = values;
  if (!target) {
    errors.target = {

    };
  } else {
    if (target.locations) {
      const { locations } = target;
      if (!locations || !locations.states || !locations.states.length) {
        errors.target = {
          ...errors.target,
          locations: {
            states: 'Please select a state'
          }
        };
      }
    }
    if (!target.gender) {
      errors.target = {
        ...errors.target,
        gender: 'Please select a gender'
      };
    }
  }

  if (values.venue) {
    const { venue } = values;
    if (!venue.address1 || !venue.city || !venue.state || !venue.zip) {
      errors.venue = {};
      if (!venue.address1) {
        errors.venue.address1 = 'Please enter a street address';
      }
      if (!venue.city) {
        errors.venue.city = 'Please enter a city';
      }
      if (!venue.state) {
        errors.venue.state = 'Please enter a state';
      }
      if (!venue.zip) {
        errors.venue.zip = 'Please enter a zip code';
      }
    }

  }

  if (!values.duration) {
    errors.duration = 'Please select the event duration';
  }

  if (!values.start_date) {
    errors.start_date = 'Please enter the event start date';
  }

  if (values.duration === 'multi day') {
    if (!values.end_date) {
      errors.end_date = 'Please enter the event end date';
    }
  }

  if (values.contact) {
    if (!values.contact.length) {
      errors.contact = [{}];
      errors.contact[0].name = 'Please enter a name for this contact';
      errors.contact[0].phone = 'Please enter a number for this contact';
    } else if (!values.contact[0].name || !values.contact[0].phone) {
      errors.contact = [{}];
      if (!values.contact[0].name) {
        errors.contact[0].name = 'Please enter a name for this contact';
      }
      if (!values.contact[0].phone) {
        errors.contact[0].phone = 'Please enter a number for this contact';
      }
    }
  }

  if (values.enableTravelArrangements) {
    if (!values.travelArrangementInstruction) {
      errors.travelArrangementInstruction = 'Please enter travel arrangement instructions';
    }
  }

  if (values.enableCheckinInstructions) {
    if (!values.checkinInstructions) {
      errors.checkinInstructions = 'Please enter travel arrangement instructions';
    }
  }

  if (values.images && !(values.images.length > 0)) {
    errors.images = 'Please upload at least one image';
  } else if (!values.images) {
    errors.images = 'Please upload at least one image';
  }
  return errors;
};

export default validate;
