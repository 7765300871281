import { makeStyles } from 'tss-react/mui';
import { ReactComponent as SamparkUserBadge } from '../../assets/icons/icon_sampark_kk_badge.svg';
import { ReactComponent as SamparkInvitedBadge } from '../../assets/icons/icon_sampark_kk_pending_badge.svg';
import { MembersType } from '../../types';
import { isSamparkUser } from '../../util/UserUtils';
import { isContactInvited } from '../../util/isContactInvited';

interface IAvatarBadgeProps {
  badgeClasses?: string[];
  contact: MembersType;
}

export const AvatarBadge = ({ badgeClasses = [], contact }: IAvatarBadgeProps) => {
  const { classes } = useStyles();

  if (isSamparkUser(contact)) {
    return (
      <div className={classes.container}>
        <SamparkUserBadge className={['contact-avatar-icon', ...badgeClasses].join(' ')} />
      </div>
    );
  }

  if (isContactInvited(contact)) {
    return (
      <div className={classes.container}>
        <SamparkInvitedBadge className={['contact-avatar-icon', ...badgeClasses].join(' ')} />
      </div>
    );
  }

  return null;
};

const useStyles = makeStyles()(() => ({
  container: {
    position: 'relative',
    width: 0,
    height: 0
  }
}));
