import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Loader, LoaderOptions } from '@googlemaps/js-api-loader';

export type GoogleAutocompleteHandle = {
  getSessionToken: () => google.maps.places.AutocompleteSessionToken | undefined;
  refreshSessionToken: () => void;
};

export interface LatLng {
  lat: number;
  lng: number;
}

export interface GoogleAutocompleteProps {
  apiKey?: string;
  apiOptions?: Partial<LoaderOptions>;
  className?: string;
  value?: string;
  placeholder?: string;
  handleChange?: (value: string) => void;
  handleBlur?: () => void;
  onLoadFailed?: (error: Error) => void;
  onSuggestionClick?: (suggestion: any) => void;
}

const GoogleAutocomplete: React.ForwardRefRenderFunction<
  GoogleAutocompleteHandle,
  GoogleAutocompleteProps
> = (
  {
    apiKey = '',
    apiOptions = {},
    className,
    placeholder = 'Address',
    value = '',
    handleBlur,
    handleChange,
    onLoadFailed = console.error,
    onSuggestionClick
  }: GoogleAutocompleteProps,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref
): React.ReactElement => {
  const autoCompleteRef = useRef(null);
  const autoCompleteMounted = useRef(false);

  const [autoCompleteControl, setAutoCompleteControl] = useState<
    google.maps.places.Autocomplete | undefined
  >(undefined);

  const initializeService = () => {
    if (!window.google)
      throw new Error('[react-google-places-autocomplete]: Google script not loaded');
    if (!window.google.maps)
      throw new Error('[react-google-places-autocomplete]: Google maps script not loaded');
    if (!window.google.maps.places)
      throw new Error('[react-google-places-autocomplete]: Google maps places script not loaded');

    if (autoCompleteRef && autoCompleteRef.current) {
      setAutoCompleteControl(
        new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
          types: ['address'],
          componentRestrictions: { country: 'us' }
        })
      );
    }
  };

  useEffect(() => {
    const init = async () => {
      try {
        if (!window.google || !window.google.maps || !window.google.maps.places) {
          await new Loader({ apiKey, ...{ libraries: ['places'], ...apiOptions } }).load();
        }
        initializeService();
      } catch (error: unknown) {
        onLoadFailed(error as Error);
      }
    };

    if (apiKey) init();
    else initializeService();
  }, []);

  useEffect(() => {
    if (autoCompleteControl !== undefined && autoCompleteMounted.current === false) {
      autoCompleteControl.setFields(['address_components', 'formatted_address']);
      autoCompleteControl.addListener('place_changed', handleSuggestedAddressClick);
      autoCompleteMounted.current = true;
    }
  }, [autoCompleteControl, autoCompleteMounted]);

  const handleInputChange = (event: any) => {
    if (handleChange) {
      handleChange(event.target.value);
    }
  };

  const handleInputBlur = () => {
    if (handleBlur) {
      handleBlur();
    }
  };

  const componentForm: Record<string, string> = {
    subpremise: 'short_name',
    street_number: 'short_name', // address line 2
    route: 'long_name',
    neighborhood: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'long_name' // this is for state
  };

  const handleSuggestedAddressClick = async () => {
    const place = autoCompleteControl?.getPlace();
    const addressComponents: any = place?.address_components;
    const formatted_address = place?.formatted_address;
    const suggestion = {
      street_line: '',
      address2: '',
      city: formatted_address?.split(',')[1].trim() ?? '',
      state: ''
    };
    //0, 1, 2, 4 indexes have following data street number, route, locality, state
    //administrative_area_level_1 is state
    if (addressComponents) {
      const streetNumber =
        addressComponents.find((a: any) => a.types[0] === 'street_number')?.[
          componentForm['street_number']
        ] ?? '';
      const route =
        addressComponents.find((a: any) => a.types[0] === 'route')?.[componentForm['route']] ?? '';
      const subpremise =
        addressComponents.find((a: any) => a.types[0] === 'subpremise')?.[
          componentForm['subpremise']
        ] ?? '';
      suggestion.street_line = `${streetNumber} ${route}`;
      suggestion.address2 = subpremise;

      if (!suggestion.city) {
        const neighborhood =
          addressComponents.find((a: any) => a.types[0] === 'neighborhood')?.[
            componentForm['neighborhood']
          ] ?? '';

        const locality =
          addressComponents.find((a: any) => a.types[0] === 'locality')?.[
            componentForm['locality']
          ] ?? '';

        suggestion.city = neighborhood || locality || '';
      }

      suggestion.state =
        addressComponents.find((a: any) => a.types[0] === 'administrative_area_level_1')?.[
          componentForm['administrative_area_level_1']
        ] ?? '';
    }
    if (onSuggestionClick) {
      onSuggestionClick(suggestion);
    }
  };

  return (
    <input
      className={className}
      ref={autoCompleteRef}
      onChange={handleInputChange}
      placeholder={placeholder}
      value={value}
      onBlur={handleInputBlur}
    />
  );
};

export default forwardRef(GoogleAutocomplete);
