import { AdminListItem } from '../..';
import { Search } from '../../../../components/Search/Search';
import { SAMPARK } from '../../../../constants/applications';
import { useFilter } from '../../../../hooks/useFilter';
import '../index.scss';
import { ManageAccessButton } from './ManageAccessButton';
import { ISamparkAdminTabProps, IUserAccess } from './SamparkAdminTab.types';

export const SamparkAdminTab = (props: ISamparkAdminTabProps) => {
  const { items = [], title, onModalOpen, isSuperAdmin } = props;

  const [filteredData, handleSetSearchValue, searchValue] = useFilter<
    IUserAccess,
    'firstName' | 'lastName'
  >({
    data: items,
    debounce: true,
    initialSearchValue: '',
    keys: ['firstName', 'lastName']
  });

  const renderManageItems = () => {
    if (filteredData && filteredData instanceof Array && filteredData.length) {
      return filteredData.map((obj: IUserAccess) => {
        let showManageButton = true;
        const addressDetails = obj.memberID.addressDetails;
        obj.profileImageUrl = obj.memberID.profileImageUrl;
        const locationCity = addressDetails?.city || '';
        const locationState = addressDetails?.state || '';
        const samparkApplication = obj.applications.find(
          (application) => application.name === SAMPARK
        );
        if (samparkApplication) {
          const permissionObject = samparkApplication.permissions;
          if (permissionObject && permissionObject.isSuperAdmin) {
            showManageButton = false;
          }
        }
        showManageButton = !!(isSuperAdmin && showManageButton);
        const name = obj.firstName + ' ' + obj.lastName;
        const getActionButton = () => {
          return showManageButton ? <ManageAccessButton obj={obj} onClick={onModalOpen} /> : null;
        };

        return (
          <AdminListItem
            city={locationCity}
            contact={obj}
            getActionButton={getActionButton}
            key={obj._id}
            name={name}
            state={locationState}
          />
        );
      });
    }

    return null;
  };

  return (
    <div className="manage-data-card-container">
      <header className="manage-data-card-header">
        <h3 className="manage-data-card-title">{title}</h3>
      </header>
      <div className="search">
        <Search searchValue={searchValue} onInputChange={handleSetSearchValue} />
      </div>
      <div className="manage-data-items">{renderManageItems()}</div>
    </div>
  );
};
