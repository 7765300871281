import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Menu, MenuItem } from '@material-ui/core';
import icon_sort from '../../../../../assets/icons/icon_sort.svg';

type SortOption = {
  label: string;
  value: string;
};

export const sortOptions: SortOption[] = [
  {
    label: 'Last Updated',
    value: '-updatedAt'
  },
  {
    label: 'First Name (A-Z)',
    value: 'firstName'
  },
  {
    label: 'First Name (Z-A)',
    value: '-firstName'
  },
  {
    label: 'Last Name (A-Z)',
    value: 'lastName'
  },
  {
    label: 'Last Name (Z-A)',
    value: '-lastName'
  },
  {
    label: 'State (A-Z)',
    value: 'state'
  },
  {
    label: 'State (Z-A)',
    value: '-state'
  }
];

interface SortButtonProps {
  onClickHandler: (filterValue: string) => void;
}

const SortButton = ({ onClickHandler }: SortButtonProps) => {
  const { classes } = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [sortValue, setSortValue] = useState<string>('');

  const openSortMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const closeSortMenu = () => {
    setAnchorEl(null);
  };

  const handleSortMenuClick = (value: string) => {
    setAnchorEl(null);
    setSortValue(value);
    onClickHandler(value);
  };

  return (
    <>
      <div className={[classes.button].join(' ')} onClick={openSortMenu}>
        <img className={classes.icon} height={14} width={14} src={icon_sort} />
        Sort
      </div>
      <Menu
        id="sortMenu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={closeSortMenu}
      >
        {sortOptions.map((option, index) => {
          return (
            <MenuItem
              key={index}
              selected={sortValue === option.value}
              value={option.value}
              onClick={() => {
                handleSortMenuClick(option.value);
              }}
            >
              {option.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

const useStyles = makeStyles()({
  button: {
    backgroundColor: '#E8EBF4',
    borderRadius: '8px',
    color: '#5F698B',
    cursor: 'pointer',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 10px',

    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '20px'
  },
  icon: {
    marginRight: '0.5rem',
    color: '#3A4463'
  }
});

export default SortButton;
