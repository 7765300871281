import { ReactComponent as AlertIcon } from '../../../../../assets/icons/icon_alert.svg';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../helpers/store';
import { deleteRule, getRules, storeRule } from '../../ruleAdmin.ducks';
import { WarningModal } from '../../../Attributes/components/Modal/WarningModal/WarningModal';
import { CircularProgress } from '@mui/material';
import { RespState } from '../../rules.types';
import { useStyles } from './ruleList.styles';
import { useKeyPress } from '../../../../../v2/hooks/useKeyPress';

export const RuleList = ({ openRuleHandler }: { openRuleHandler: (value?: string) => void }) => {
  const [isWarningModalOpen, setIsWarningModalOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const { classes } = useStyles();

  //getting all the rules
  let { rules: rulesList } = useAppSelector((state) => state.admin.rule);
  const { rulesApiState } = useAppSelector((state) => state.admin.rule);

  const dispatch = useAppDispatch();

  const deleteHandler = (ruleId: string) => {
    dispatch(storeRule(ruleId));
    dispatch(deleteRule(ruleId));
    setDeleteId('');
  };

  useEffect(() => {
    dispatch(getRules());
  }, []);

  useKeyPress('Escape', () => setSearchValue(''));

  const handleCloseModal = () => setIsWarningModalOpen(false);

  const handleSubmitModal = () => {
    handleCloseModal();
    // handlerDismissToast();
    deleteHandler(deleteId);
  };

  if (rulesList.length > 0) {
    rulesList = rulesList.filter(({ _id: ruleName }) =>
      ruleName?.toLowerCase().includes(searchValue.toLowerCase())
    );
    //sorting rulesList alphabetically
    rulesList = rulesList.sort((a, b) => a.name.localeCompare(b.name));
  }

  if (['LOADING'].includes(RespState[rulesApiState])) {
    return <CircularProgress size="2rem" />;
  }

  return (
    <div className={classes.container}>
      <WarningModal
        isOpen={isWarningModalOpen}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmitModal}
        variant="warning"
      >
        Do you really want to delete this rule?
      </WarningModal>
      <div className={classes.header}>
        <h3>My Rules</h3>
        <input
          className={classes.searchInput}
          placeholder="Search your rule here..."
          onChange={(event) => setSearchValue(event.target.value)}
          value={searchValue}
        />
        <button className={classes.createButton} onClick={() => openRuleHandler()}>
          Create Rule
        </button>
      </div>
      {rulesList.length === 0 && (
        <div className={classes.banner}>
          <AlertIcon className={classes.alertIcon} />
          <p className={classes.alertText}>No rules found</p>
        </div>
      )}
      <div className={classes.bodyContainer} key={rulesList.length}>
        {rulesList.length > 0 &&
          rulesList?.map(({ name: ruleName, _id: index }) => (
            <button
              className={classes.ruleContainer}
              key={index}
              onClick={() => openRuleHandler(index)}
            >
              <label className={classes.ruleLabel}>{ruleName}</label>
            </button>
          ))}
      </div>
    </div>
  );
};
