import { AutoComplete } from '../../../AutoComplete/AutoComplete';
import { TextField } from '../../../TextField/TextField';
import { IAttributeGroupDropdown } from '../../../../attributes.types';

export const AttributeGroupDropdown = ({
  attributeGroup,
  handleAttributeGroup,
  allAttributeGroups
}: IAttributeGroupDropdown) => {
  return (
    <TextField label="Attribute Group">
      <AutoComplete
        value={attributeGroup?.toLowerCase()}
        handleAttributeGroup={handleAttributeGroup}
        options={allAttributeGroups}
        isOptionEqualToValue={(option: any, value: string) => option === value || value === ''}
      />
    </TextField>
  );
};
