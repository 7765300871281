import { useEffect } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import ReportIcon from '@mui/icons-material/Report';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from '../../../../../helpers/store';
import { hideToast } from './toaster.ducks';
import { COLOR_BY_VARIANT } from './toaster.constants';
import { useStyles } from './toaster.styles';
import { IToast, IVariant } from './toaster.types';

const ICONS_BY_VARIANT = {
  notice: InfoIcon,
  warning: WarningIcon,
  success: CheckCircleIcon,
  error: ReportIcon
};

export const Toaster = ({ handleUndo }: IToast) => {
  const {
    show,
    hide,
    toastMessage: message,
    toastVariant: variant
  } = useAppSelector((state) => state.admin.toaster);
  const Tag = ICONS_BY_VARIANT[variant as keyof IVariant];
  const theme = COLOR_BY_VARIANT[variant as keyof IVariant];

  const dispatch = useAppDispatch();

  const handlerDismiss = () => {
    dispatch(hideToast());
  };

  useEffect(() => {
    if (show) {
      const timeout = setTimeout(() => {
        handlerDismiss();
      }, 10000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [show]);

  const { classes } = useStyles({
    textColor: theme['color'],
    backgroundColor: theme['background-color'],
    toast: show ? 'showToast' : hide ? 'hideToast' : undefined
  });

  return (
    <div className={classes.container} role="container">
      <div className={classes.wrapper}>
        <div className={classes.message}>
          <Tag data-testid={variant} />
          <div className={classes.messageText}>{message}</div>
        </div>
        <div className={classes.actionHandlers}>
          {variant === 'warning' && (
            <button className={classes.undoButton} onClick={handleUndo}>
              Undo
            </button>
          )}
          <div className={classes.iconWrapper} role="hideToast" onClick={handlerDismiss}>
            <CloseIcon style={{ color: `hsl(${theme['color']})` }} />
          </div>
        </div>
      </div>
    </div>
  );
};
