import { Dialog } from '@mui/material';
import { useState, BaseSyntheticEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../helpers/store';
import { createAttributes, updateAttributes } from '../../../attributeAdmin.ducks';
import { Footer } from '../../Footer/Footer';
import { Header } from '../../Header/Header';
import { WarningModal } from '../WarningModal/WarningModal';
import { useKeyPress } from '../../../../../../v2/hooks/useKeyPress';
import { IAllFields, IAttributes, IValueType } from '../../../attributes.types';
import { ALL_OPERATORS } from './FormAttribute/operatorDropdown.constants';
import { FormAttribute } from './FormAttribute/FormAttribute';
import { JsonAttribute } from './JsonAttribute/JsonAttribute';
import { useStyles } from './createAttributeModal.styles';

const initialField = {
  name: '',
  datatype: 'labelValues',
  labelValues: [],
  path: '',
  multiSelect: false,
  label: ''
};

const getInitialField = (initialPath: string, allFields: IAllFields[]) => {
  const selectedField = allFields.find((field: IAllFields) => field.path == initialPath);

  if (!selectedField) {
    return initialField;
  }
  const { name, datatype, labelValues, path, multiSelect, label } = selectedField;
  const nextField = {
    name,
    datatype,
    labelValues,
    path,
    multiSelect,
    label
  };
  return nextField;
};

const initialOperator = { val: '', label: '' };

const getInitialOperator = (operator: string) => {
  const selectedOperator = ALL_OPERATORS.find((item) => item.val === operator);

  return selectedOperator || initialOperator;
};

export const CreateAttributeModal = ({
  closeModalHandler,
  isModalOpen,
  allFields,
  allAttributeGroups
}: {
  allFields: IAllFields[];
  allAttributeGroups: { value: string; label: string }[];
  closeModalHandler: () => void;
  isModalOpen: boolean;
}) => {
  const { attribute } = useAppSelector((state) => state.admin.attribute);
  const [name, setName] = useState(attribute.key || '');
  const [description, setDescription] = useState(attribute?.description || '');
  const [attributeGroup, setAttributeGroup] = useState(attribute?.group || '');
  const [field, setField] = useState<IAllFields | undefined>(
    attribute?.definition.path
      ? getInitialField(attribute?.definition.path, allFields)
      : initialField
  );
  const [operator, setOperator] = useState(getInitialOperator(attribute?.definition.operator));
  const [fieldValue, setFieldValue] = useState<string[] | string>(
    attribute?.definition.value || []
  );
  const [isWarningModalOpen, setIsWarningModalOpen] = useState<boolean>(false);
  const [isJsonType, setIsJsonType] = useState<boolean>(false);
  const [jsonData, setJsonData] = useState<string>('');
  const [isError, setIsError] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState<string>('');

  const dispatch = useAppDispatch();
  const { classes } = useStyles();

  const initializeJsonForm = () => {
    const nextAttribute: IAttributes = {
      key: name,
      description,
      definition: {
        fact: 'memberDetails',
        operator: operator.val,
        value: fieldValue,
        path: field?.path,
        field: field?.name
      }
    };
    if (attributeGroup) {
      nextAttribute['group'] = attributeGroup;
    }
    const { _id, key } = attribute;
    if (_id) {
      nextAttribute['_id'] = _id;
    }
    setJsonData(JSON.stringify(nextAttribute, null, 2));
    setErrorMessage('Looks good');
    key && setIsError(false);
  };
  const updateFormFromJson = () => {
    try {
      if (jsonData && JSON.parse(jsonData)) {
        const attribute = JSON.parse(jsonData);
        if (attribute.key !== '') {
          setFieldValue(attribute?.definition.value || []);
          setField(getInitialField(attribute?.definition.path, allFields));
          setOperator(getInitialOperator(attribute?.definition.operator));
          setJsonData(JSON.stringify(attribute, null, 2));
          setIsError(false);
        }
        setName(attribute.key);
        setDescription(attribute?.description || '');
        setAttributeGroup(attribute?.group || '');
      }
    } catch (e) {
      console.error(e);
      setIsError(true);
      setErrorMessage('Invalid JSON');
    }
  };

  const handleFormType = (value: boolean) => {
    setIsJsonType(value);
    if (value) {
      initializeJsonForm();
    } else {
      updateFormFromJson();
    }
  };

  const handleJsonData = (event: BaseSyntheticEvent) => {
    setJsonData(event.target.value);
    try {
      if (JSON.parse(event.target.value)) {
        setIsError(false);
        setErrorMessage('Looks good');
      }
    } catch (error) {
      setIsError(true);
      setErrorMessage('Invalid JSON');
    }
  };

  const handleAttributeGroup = (groupObject: IValueType) => {
    setAttributeGroup(groupObject.value);
  };

  const handleDescription = (value: string) => {
    setDescription(value);
  };

  const handleName = (value: string) => {
    setName(value);
  };

  const handleOperator = (valueObject: { val: string; label: string }) => {
    setOperator(valueObject);
  };

  const handleField = (value: string | string[]) => {
    const selectedField = allFields.find((listItem: IAllFields) => listItem.label === value);
    setField(selectedField);
    setFieldValue([]);
    setOperator({ val: '', label: '' });
  };

  const handleFieldValueChange = (val: any) => {
    setFieldValue(val);
  };

  const clearForm = () => {
    setName('');
    setJsonData('');
    setErrorMessage('');
  };

  const submitHtmlForm = () => {
    const nextAttribute: IAttributes = {
      key: name,
      description,
      definition: {
        fact: 'memberDetails',
        operator: operator.val,
        value:
          typeof fieldValue === 'string' && fieldValue.includes(',')
            ? fieldValue.split(',').map((item: string) => item.trim())
            : fieldValue,
        path: field?.path,
        field: field?.name
      }
    };
    if (attributeGroup) {
      nextAttribute['group'] = attributeGroup;
    }

    const { _id, key } = attribute;
    if (_id) {
      nextAttribute['_id'] = _id;
      // We are considering Name as unique indentifier for CRUD operations
      dispatch(updateAttributes({ value: nextAttribute, oldKey: key }));
    } else {
      dispatch(createAttributes({ value: nextAttribute }));
    }
  };

  const submitJsonForm = () => {
    const data = jsonData ? JSON.parse(jsonData) : null;
    dispatch(createAttributes({ value: data, oldKey: data.key }));
  };

  const handleSubmit = (event: BaseSyntheticEvent) => {
    event.preventDefault();
    if (isJsonType && isError) {
      return;
    }
    isJsonType ? submitJsonForm() : submitHtmlForm();
    closeModalHandler();
    clearForm();
  };

  const handleCloseModal = () => setIsWarningModalOpen(false);

  const handleSubmitModal = () => {
    handleCloseModal();
    closeModalHandler();
  };

  const handleOpenModal = () => {
    setIsWarningModalOpen(true);
  };

  useKeyPress('Escape', handleOpenModal);

  return (
    <Dialog
      classes={{ paper: classes.paperDialog }}
      onClose={closeModalHandler}
      aria-labelledby="customized-dialog-title"
      open={isModalOpen}
    >
      <WarningModal
        isOpen={isWarningModalOpen}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmitModal}
        variant="warning"
      >
        Your changes are not saved
      </WarningModal>
      <form onSubmit={handleSubmit}>
        <Header
          title="Create Attribute"
          subTitle="Attribute"
          closeHandler={closeModalHandler}
          handleFormType={handleFormType}
          isJsonType={isJsonType}
        />
        {!isJsonType ? (
          <FormAttribute
            name={name}
            handleName={handleName}
            description={description}
            handleDescription={handleDescription}
            attributeGroup={attributeGroup}
            handleAttributeGroup={handleAttributeGroup}
            allAttributeGroups={allAttributeGroups}
            field={field}
            handleField={handleField}
            operator={operator}
            handleOperator={handleOperator}
            fieldValue={fieldValue}
            handleFieldValueChange={handleFieldValueChange}
            allFields={allFields}
          />
        ) : (
          <JsonAttribute
            jsonData={jsonData}
            handleJsonData={handleJsonData}
            isError={isError}
            errorMessage={errorMessage}
          />
        )}
        <Footer closeHandler={handleOpenModal} />
      </form>
    </Dialog>
  );
};
