import dayjs from 'dayjs';
import { readingPanelActions, commonActions } from '../constants/actions';
import { READING_SUBMIT_SUCCESS, READING_SUBMIT_ERROR, READING_APPROVE_ERROR, READING_APPROVE_SUCCESS, READING_DECLINE_SUCCESS, READING_DECLINE_ERROR } from '../constants/toasterMessages';
import { READING_FORM, REJECT_READING_FORM } from '../constants/forms';
import { submitReadingContentAPI, changeReadingStatus } from '../apis/ReadingAPIs';
import { getReadingTemplate } from '../views/ReadingView/getReadingTemplate';
import * as INTENT from '../constants/panelIntents';
import * as STATUS from '../constants/status';

export function initializeReadingForm (clickedItem, intent) {
  return (dispatch) => {
    dispatch({
      type: readingPanelActions.CLEAR_INITIAL_VALUES_READING
    });
    switch (intent) {
      case INTENT.CREATE:
        clickedItem = null;
        break;
      case INTENT.VIEW:
        clickedItem = mapContentToForm(clickedItem);
        break;
      case INTENT.MANAGE:
        clickedItem = mapContentToForm(clickedItem);
        break;
    }
    dispatch({
      type: readingPanelActions.INITIALIZE_READING_FORM,
      value: { clickedItem, intent }
    });
  };
};

function mapContentToForm (item) {
  const dateObj = dayjs(item.publishDate);
  const template = getReadingTemplate(item.type);
  return {
    ...item,
    content: {
      // if no content is present, load in the template
      english: item.content.english.trim() !== '' ? item.content.english : template.content.english,
      gujarati: item.content.gujarati.trim() !== '' ? item.content.gujarati : template.content.gujarati
    },
    sourceDate: item.sourceDate ? dayjs(item.sourceDate).format('YYYY-MM-DD') : '',
    publishFormDate: dateObj.format('YYYY-MM-DD'),
    publishFormTime: {
      value: dateObj.format('hh:mm'),
      meridian: dateObj.format('a')
    }
  };
};

export function submitReadingForm () {
  return async (dispatch, getState) => {
    const currentState = getState();
    const readingFormValues = currentState.form[READING_FORM].values;
    const selectedContentType = currentState.readingPanel.formContentType.value;
    const valuesToSubmit = mapFormToContent(readingFormValues, selectedContentType);
    const readingPublication = await submitReadingContentAPI(valuesToSubmit);
    if (readingPublication && !readingPublication.error) {
      dispatch({
        type: readingPanelActions.SUBMIT_READING_FORM
      });
      dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: READING_SUBMIT_SUCCESS, type: commonActions.SUCCESS_TOASTER } });
    } else {
      dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: READING_SUBMIT_ERROR, type: commonActions.ERROR_TOASTER } });
    }
  };
}

function mapFormToContent (formValues, type) {
  const publishDateString = `${formValues.publishFormDate} ${formValues.publishFormTime.value}${formValues.publishFormTime.meridian}`; // `
  const publishDate = dayjs(publishDateString, 'YYYY-MM-DD hh:mma').toISOString();
  // if markdown for any of the languages is not edited, remove from submit
  ['english', 'gujarati'].forEach(lang => {
    if (formValues.content[lang] === getReadingTemplate(type).content[lang]) {
      formValues.content[lang] = '';
    }
  });
  return {
    ...formValues,
    content: {
      english: formValues.content.english,
      gujarati: formValues.content.gujarati
    },
    type,
    publishDate
  };
}

export function setFormContentType (type) {
  return (dispatch) => {
    dispatch({
      type: readingPanelActions.SET_CONTENT_TYPE,
      value: type
    });
  };
}

export function approveReadingForm () {
  return async (dispatch, getState) => {
    const readingFormValues = getState().form[READING_FORM].values;
    const approveReadingContent = await changeReadingStatus(readingFormValues._id, STATUS.APPROVED.status);
    if (approveReadingContent && !approveReadingContent.error) {
      dispatch({
        type: readingPanelActions.SUBMIT_READING_FORM
      });
      dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: READING_APPROVE_SUCCESS, type: commonActions.SUCCESS_TOASTER } });
    } else {
      dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: READING_APPROVE_ERROR, type: commonActions.ERROR_TOASTER } });
    }
  };
}

export function declineReadingForm () {
  return async (dispatch, getState) => {
    const currentState = getState();
    const readingFormValues = currentState.form[READING_FORM].values;
    const declineReadingContent = await changeReadingStatus(readingFormValues._id, STATUS.DECLINED.status, '');
    if (declineReadingContent && !declineReadingContent.error) {
      dispatch({
        type: readingPanelActions.SUBMIT_READING_FORM
      });
      dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: READING_DECLINE_SUCCESS, type: commonActions.SUCCESS_TOASTER } });
    } else {
      dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: READING_DECLINE_ERROR, type: commonActions.ERROR_TOASTER } });
    }
  };
}
