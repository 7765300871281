import * as React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';
import icon_checked from '../../../assets/icons/checkbox-active.svg';
import icon_unchecked from '../../../assets/icons/checkbox-inactive.svg';

export const CheckboxField = ({ name, onChange, input, label, disabled }) => {
  const onSectionChange = (e, value) => {
    onChange(e.target.name, value);
  };
  let classes = ['section-checkbox-field-container'];
  if (disabled) {
    classes.push('disabled-checkbox')
  }
  return (
    <div className={[...classes].join(' ')}>
      <Checkbox
        checked={input}
        checkedIcon={<img src={icon_checked} />}
        disableRipple
        icon={<img src={icon_unchecked} />}
        name={name}
        disabled={disabled}
        color='primary'
        onChange={onSectionChange}
      />{label
        ? <div style={{ marginBottom: '0' }} className='form-field-label-row'>
          <label style={{marginRight: '0.5rem'}}>{label}</label>
        </div> : null
      }
    </div>
  );
};

CheckboxField.propTypes = {
  name: PropTypes.string,
  input: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};
