import * as OPTIONS from '../constants/menuOptions';
import * as MENUS from '../constants/menus';
import * as MESSAGE_STATUS from '../constants/messageStatus';
import { isSamparkUser } from './UserUtils';
import { isContactInvited } from './isContactInvited';
import { growthbook } from '../helpers/growthbook';

export default function getMenuOptions (component, prop, isSuperAdmin, audiences) {
  switch (component) {
    case MENUS.USER_MENU:
      return getUserMenuOptions(prop);
    case MENUS.CONTACT_CARD_MENU:
    case MENUS.CONTACT_PANEL_MENU:
      return getContactCardMenu(prop, isSuperAdmin, audiences);
    case MENUS.CONTACT_US_MENU:
      return getContactUsMenu();
    case MENUS.RESPONSIVE_HEADER_MENU:
      return getResponsiveHeaderMenu();
    case MENUS.BROADCAST_MENU:
      return getBroadcastMessageOptions(prop);
    case MENUS.READING_MORE_MENU:
      return getReadingMenuOptions();
    case MENUS.EVENT_MENU:
      return getEventMenuOptions();
    default:
      return [];
  }
};

function getReadingMenuOptions () {
  return [OPTIONS.SETTINGS];
}

function getBroadcastMessageOptions (message) {
  if (message.status === MESSAGE_STATUS.EXPIRED ||
    message.status === MESSAGE_STATUS.DECLINED ||
    message.status === MESSAGE_STATUS.SENT ||
    message.status === MESSAGE_STATUS.CANCELLED) {
    return [
      {
        ...OPTIONS.CANCEL_BROADCAST,
        disabled: true
      },
      OPTIONS.COPY_BROADCAST
    ];
  }

  return [OPTIONS.CANCEL_BROADCAST, OPTIONS.COPY_BROADCAST];

}

function getUserMenuOptions (props) {
  const { pathname, userAccessObject } = props;
  const options = [
    {...OPTIONS.MY_ACCOUNT, disabled: ((userAccessObject && userAccessObject.isSuperAdmin) || (userAccessObject && userAccessObject.permissions && userAccessObject.permissions.contacts)) ? false : true},
    OPTIONS.SETTINGS
  ];

  options.push(OPTIONS.LOGOUT);

  return options;
}

function getEventMenuOptions () {
  return [OPTIONS.CREATE_COPY_EVENT];
}

function getInviteContactWorkshopOption(contact, optionText) {
  const { contactDetails, profileImageUrl, workshopInvitationStatus } = contact;

  const inviteContactWorkshopOption = {
    name: optionText,
    value: OPTIONS.INVITE_CONTACT_TO_WORKSHOP.value,
    disabled: false,
    tooltip: false
  };

  if (!contact?.contactDetails?.email) {
    inviteContactWorkshopOption.disabled = true;
    inviteContactWorkshopOption.tooltip = 'Contact must have an email';
  }

  if (!contact.profileImageUrl) {
    inviteContactWorkshopOption.disabled = true;
    inviteContactWorkshopOption.tooltip = 'Contact must have a profile image'
  }

  if (workshopInvitationStatus && (workshopInvitationStatus === 'registered')) {
    inviteContactWorkshopOption.disabled = true;
    inviteContactWorkshopOption.tooltip = 'Contact already registered'
  }

  return inviteContactWorkshopOption;
}

function getContactCardMenu (contact, isSuperAdmin, audiences) {
  const options = [];

  // If sampark user, only provide option to delete contact
  if (isSamparkUser(contact)) {
    options.push({...OPTIONS.DELETE_CONTACT, disabled: true});
  } else if (isContactInvited(contact)) {
    // If contact has been invited, provide options to cancel invite & delete contact
    options.push(OPTIONS.CANCEL_INVITE);
    options.push({...OPTIONS.DELETE_CONTACT, disabled: false});
  } else if (contact.id) {
    // If regular contact, provide options to invite contact to Sampark & delete contact
    options.push(OPTIONS.INVITE_CONTACT);
    options.push({...OPTIONS.DELETE_CONTACT, disabled: false});
  }

  // Criteria for inviting contacts to workshops driven by Growthbook config list
  const workshopConfigList = growthbook.getFeatureValue('sampark.invite-to-workshop-config');
  if (workshopConfigList?.length) {
    // 1. Workshop config must be active
    workshopConfigList
    .filter((workshopConfig) => workshopConfig.isActive === true)
    .forEach(({ optionText, sending_audiences, receiving_audiences }) => {
      // 2. Inviter (contact who is sending invite) is either super admin or has audience that matches sending_audiences
      const inviterHasAudience = audiences?.some(audience => sending_audiences.includes(audience));
      // 3. Receiver (contact who is receiving invite) has audience that matches receiving_audiences
      const receiverHasAudience = contact.audiences?.some(audience => receiving_audiences.includes(audience));

      if ((isSuperAdmin || inviterHasAudience) && (receiverHasAudience)) {
        const { muktType } = contact;
        if (!muktType.includes('No Mailer')) {
          const inviteContactWorkshopOption = getInviteContactWorkshopOption(contact, optionText);
          options.unshift(inviteContactWorkshopOption);
        }
      }
    });
  }

  return options;
}

function getContactUsMenu () {
  return [
    OPTIONS.CONTACT_US,
    OPTIONS.FAQ
  ];
}

function getResponsiveHeaderMenu () {
  return [
    OPTIONS.MY_ACCOUNT,
    OPTIONS.CONTACT_US,
    OPTIONS.LOGOUT
  ];
};
