import { styled } from '@mui/material';

export const StyledCardsViewContainer = styled('div', { name: 'StyledCardsViewContainer' })(() => ({
  display: 'grid',
  gridColumnGap: '2rem',
  gridRowGap: '2rem',
  gridTemplateColumns: 'repeat(auto-fit, minmax(16rem, 16rem))',
  justifyContent: 'center'
}));

export const StyledListViewContainer = styled('div', { name: 'StyledListViewContainer' })(() => ({
  display: 'grid',
  gap: '0.5rem'
}));

export const StyledMoreLoadingContainer = styled('div', { name: 'StyledMoreLoadingContainer' })({
  display: 'flex',
  justifyContent: 'center',
  margin: '24px 0px'
});
