import { Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Fragment } from 'react';
import { IRegistration } from '../../yogAdmin.duck';
import { useStyles } from './ConfirmRemoveHoldModal.style';

type IModalProps = {
  cancelCallBack: () => void;
  confirmCallBack: (registration: IRegistration) => void;
  registration: IRegistration;
  isUpdatingRegistration: boolean;
};

export function ConfirmRemoveHoldModal({
  cancelCallBack,
  confirmCallBack,
  registration,
  isUpdatingRegistration
}: IModalProps) {
  const { classes } = useStyles();
  const {
    member: { firstName, lastName }
  } = registration;
  return (
    <Fragment>
      <DialogTitle className={classes.dialogTitle}>
        Are you sure you want to Remove Hold?
      </DialogTitle>
      <DialogContent className={classes.container}>
        <div className={classes.text}>
          You are Remove Holding registration for:{' '}
          <span className={classes.name}>
            {firstName} {lastName}
          </span>
          <div className={classes.note}>
            Note: The hold on this registration will be lifted for one day.
          </div>
        </div>
      </DialogContent>
      <DialogActions disableSpacing>
        <Button disableRipple className="button-primary cancel-button" onClick={cancelCallBack}>
          <span className="button-primary-label">Cancel</span>
        </Button>
        <Button
          disableRipple
          disabled={isUpdatingRegistration}
          className="button-primary"
          style={{ backgroundColor: '#C84240' }}
          onClick={() => confirmCallBack(registration)}
        >
          <span className="button-primary-label label-white">Remove Hold</span>
        </Button>
      </DialogActions>
    </Fragment>
  );
}
