import { useEffect, useState } from 'react';
import { EditRule } from './components/EditRule/EditRule';
import { AttributeList } from './components/AttributeList/AttributeList';
import { useAppDispatch, useAppSelector } from '../../../helpers/store';
import { getAttributes } from '../Attributes/attributeAdmin.ducks';
import { DndContext } from '@dnd-kit/core';
import { RuleList } from './components/RuleList/RuleList';
import { getRules, rollBackRule } from './ruleAdmin.ducks';
import { WarningModal } from '../Attributes/components/Modal/WarningModal/WarningModal';
import { Toaster } from '../Attributes/components/Toaster/toaster';
import { hideToast, resetToast } from '../Attributes/components/Toaster/toaster.ducks';
import { useStyles } from './styles';
import { IAttributes, IRule, IRuleObject } from './rules.types';
import { OPERATOR } from './components/EditRule/editRule.constants';

export const Rules = () => {
  const [, setDropId] = useState(false);
  const dispatch = useAppDispatch();
  const [globalOperator, setGlobalOperator] = useState<string>(OPERATOR.OR);
  const [rule, setRule] = useState<IRule[]>([{ attributeList: [], operator: OPERATOR.OR }]);
  const [activeId, setActiveId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [ruleSelected, setRuleSelected] = useState<IRuleObject[]>([]);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const { classes } = useStyles();

  //getting all the rules
  const { rules: rulesList } = useAppSelector((state) => state.admin.rule);
  const attributeList = useAppSelector((state) => state.admin.attribute.attributes);

  const { previousRule } = useAppSelector((state) => state.admin.rule);

  const handleGlobalOperator = (value: string): void => {
    setGlobalOperator(value);
  };

  const handleRule = (value: any): void => {
    setRule(value);
  };

  const ruleHandler = (): void => {
    setRule([...rule, { attributeList: [], operator: OPERATOR.OR }]);
  };

  useEffect(() => {
    dispatch(resetToast());
    dispatch(getAttributes());
    dispatch(getRules());
  }, []);

  const handleDragEnd = (event: any): void => {
    if (event.over) {
      setDropId(event.collisions[0].id);
      const newList = [...rule];
      newList.forEach((attributes, index) => {
        if (index === event.collisions[0].id) {
          attributes.attributeList = [...attributes.attributeList, event.active.data.current];
          attributes.operator = event.over.data.current;
        }
      });
      setRule(newList);
      setActiveId(null);
    }
  };

  const handleDragStart = (event: any): void => {
    setActiveId(event.active.id);
  };

  const removeAttributeHandler = (attributeIndex: number, ruleIndex: number): void => {
    const newList = [...rule];
    newList.forEach((attributes, index) => {
      if (index === ruleIndex) {
        if (attributes.attributeList.length == 0) {
          return newList.splice(ruleIndex, 1);
        }
        attributes.attributeList.splice(attributeIndex, 1);
      }
    });
    setRule(newList);
  };

  const removeRule = (ruleIndex: number): void => {
    const newList = [...rule];
    newList.splice(ruleIndex, 1);
    setRule(newList);
  };

  const openRuleHandler = (name?: string): void => {
    if (name === undefined) {
      setIsEdit(() => !isEdit);
      setRuleSelected([]);
    } else {
      const ruleToEdit = rulesList.filter(
        ({ _id: ruleName }: { _id: string }) => ruleName === name
      );
      setRuleSelected(ruleToEdit);
      setIsEdit(true);
      handleGlobalOperator(
        Object.keys(ruleToEdit[0].rule.conditions)[0] === OPERATOR.ANY ? OPERATOR.OR : OPERATOR.AND
      );
      const rules = Object.values(ruleToEdit[0].rule.conditions)[0];
      let newRule: IRule[] = [];
      let rawlist: string[] = [];
      let list: IAttributes[] = [];
      let rawOperator: string;
      const operatorList: string[] = [];
      rules.map((rule: any) => {
        if (typeof rule === 'string') {
          const item = attributeList.filter((attribute) => attribute.key === rule?.slice(1, -1));
          newRule = [
            ...newRule,
            {
              operator:
                Object.keys(ruleToEdit[0].rule.conditions)[0] === OPERATOR.ANY
                  ? OPERATOR.OR
                  : OPERATOR.AND,
              attributeList: item
            }
          ];
          operatorList.push(
            Object.keys(ruleToEdit[0].rule.conditions)[0] === OPERATOR.ANY
              ? OPERATOR.OR
              : OPERATOR.AND
          );
        } else {
          rawOperator = Object.keys(rule)[0];
          rawlist = rule[rawOperator];
          rawlist.map((ruleKey: string) => {
            const item = attributeList.filter(
              (attribute) => attribute.key === ruleKey.slice(1, -1)
            );
            list = [...list, ...item];
          });
          newRule = [
            ...newRule,
            {
              operator: rawOperator === OPERATOR.ANY ? OPERATOR.OR : OPERATOR.AND,
              attributeList: list
            }
          ];
          list = [];
          operatorList.push(rawOperator === OPERATOR.ANY ? OPERATOR.OR : OPERATOR.AND);
        }
      });
      handleRule(newRule);
    }
  };

  const closeRuleHandler = (): void => {
    setIsEdit(false);
    setRule([{ attributeList: [], operator: OPERATOR.OR }]);
  };

  const handleOpenModal = (): void => {
    setIsWarningModalOpen(true);
  };

  const handleCloseModal = (): void => setIsWarningModalOpen(false);

  const handleWarningModalOnSubmit = (): void => {
    closeRuleHandler();
    handleCloseModal();
  };

  const handleUndo = (): void => {
    dispatch(rollBackRule({ rule: previousRule[0], method: 'POST' }));
    dispatch(hideToast());
  };

  return (
    <>
      <div className={classes.toaster}>
        <Toaster handleUndo={handleUndo} />
      </div>
      <div className={classes.container}>
        <WarningModal
          isOpen={isWarningModalOpen}
          handleClose={handleCloseModal}
          handleSubmit={handleWarningModalOnSubmit}
          variant="warning"
        >
          Your changes are not saved
        </WarningModal>
        <DndContext autoScroll={false} onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
          {isEdit && <AttributeList activeId={activeId} editHandler={openRuleHandler} />}
          {isEdit ? (
            <EditRule
              ruleSelected={ruleSelected}
              handleGlobalOperator={handleGlobalOperator}
              globalOperator={globalOperator}
              ruleHandler={ruleHandler}
              rule={rule}
              removeAttributeHandler={removeAttributeHandler}
              removeRule={removeRule}
              closeRuleHandler={handleOpenModal}
              handleRule={handleRule}
              editHandler={openRuleHandler}
            />
          ) : (
            <RuleList openRuleHandler={openRuleHandler} />
          )}
        </DndContext>
      </div>
    </>
  );
};
