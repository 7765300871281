import React, { Fragment } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import { isValid } from 'redux-form';
// material
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@material-ui/core';
// project files
import { closeModal, submitContactUsForm } from '../../actions/common.actions';
import * as FORMS from '../../constants/forms';
import ContactUsForm from '../auth/ContactUs/ContactUs_Form';

class ContactUsModal extends React.Component {
  constructor (props) {
    super(props);
  }

  handleClick = () => {
    this.props.submitContactUsForm(this.props.loggedInUser);
    return this.props.closeModal();
  }

  render () {
    const isFormValid = this.props.isFormValid;
    let primaryButtonClasses = [];
    if (!isFormValid) {
      primaryButtonClasses.push('button-primary-disabled');
    }
    return (
      <Fragment>
        <DialogTitle>
          Contact Us
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Explain your issue or concern so we can address it accordingly.
          </DialogContentText>
        </DialogContent>
        <ContactUsForm />
        <DialogActions disableActionSpacing>
          <Button disableRipple className='button-primary cancel-button' onClick={this.props.closeModal}>
            <span className='button-primary-label'>Cancel</span>
          </Button>
          <Button
            disabled={!isFormValid}
            disableRipple
            // className={'confirm-button ' + (!this.props.isFormValid ? 'button-disabled' : 'button-active')}
            className={['button-primary', 'button-orange', ...primaryButtonClasses].join(' ')}
            onClick={this.handleClick}>
            <span className='button-primary-label label-white'>Confirm</span>
          </Button>
        </DialogActions>
      </Fragment>
    );
  }
}

function mapStateToProps (state) {
  return {
    isFormValid: isValid(FORMS.CONTACT_US_FORM)(state)
  };
}

export default connect(mapStateToProps, { closeModal, submitContactUsForm })(ContactUsModal);

ContactUsModal.propTypes = {
  closeModal: PropType.func.isRequired,
  isFormValid: PropType.bool.isRequired,
  submitContactUsForm: PropType.func.isRequired,
  loggedInUser: PropType.object.isRequired
};
