import { Chip } from '@material-ui/core';

const ChipList = ({ list, label }: { list: string[]; label: string }) => {
  return (
    <div className="audience-list-container">
      <div className="form-field-label-row">
        <label>{label}</label>
      </div>
      {list?.length
        ? list.map((group) => (
            <Chip
              className="chip"
              key={group}
              style={{ marginRight: '1rem' }}
              label={<span className="chip-label">{group}</span>}
            />
          ))
        : 'This user is not part of any ' + label}
    </div>
  );
};

export default ChipList;
