import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  iconHelp: {
    width: '16px',
    cursor: 'pointer'
  },
  labelHeader: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px'
  },
  smallField: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '342px'
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '432px'
  }
}));
