import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  getRegisterableEvents,
  searchRegistrationsAPI,
  updateRegistrationsAPI
} from '../../../apis/yogAPIs';
import { RootState } from '../../../reducers/index.types';
import { commonActions } from '../../../constants/actions';
import { REGISTRATION_STATUS } from './constants/RegistrationStatus';

interface IPhone {
  type: string;
  value: string;
}
export interface IMemberType {
  city: string;
  country: string;
  email: string;
  familyId: string;
  firstName: string;
  lastName: string;
  gender: 'Male' | 'Female';
  mandal: string;
  memberId: string;
  phones: IPhone[];
  profileImageUrl: string;
  state: string;
}

export interface IRegistration {
  accommodationNeeded: boolean;
  hasMysteryGift: boolean;
  isStudent: boolean;
  member: IMemberType;
  registrationId: string;
  status: REGISTRATION_STATUS;
  isEditable?: boolean;
  onHoldStartDate?: string;
}

interface IUpdateRegistrationResp {
  registration: Omit<IRegistration, 'member'>;
}

interface ISearchRegistrarionsRespMeta {
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface ISearchRegistrationsResp {
  registrations: IRegistration[];
  metadata: ISearchRegistrarionsRespMeta;
}

export interface IFilters {
  name: string;
  mandal: string;
  gender: string;
  state: string;
  isStudent: boolean;
  familyId: string;
  status: string;
  country: string;
  accommodationNeeded: string;
}

export enum RespState {
  IDLE,
  PENDING,
  SUCCEEDED,
  FAILED
}

interface IPaginationMeta {
  maxPageNumber: number | null;
  total: number;
}

export const fetchYogEvents = createAsyncThunk(
  'yogAdmin/events',
  async (__, { rejectWithValue }) => {
    try {
      const response = await getRegisterableEvents();
      if (response.error) {
        throw response.error;
      }
      return response;
    } catch (e) {
      return rejectWithValue('rejected');
    }
  }
);

export const searchRegistrations = createAsyncThunk(
  'yogAdmin/registrations',
  async (
    { pageNumber, eventId }: { pageNumber: number; eventId: string },
    { getState, rejectWithValue }
  ) => {
    const {
      admin: {
        yogAdmin: { activeFilters }
      }
    } = getState() as RootState;
    try {
      const response = await searchRegistrationsAPI({
        searchValue: activeFilters.name,
        currentPageNo: pageNumber,
        filterValues: {
          state: activeFilters.state,
          mandal: activeFilters.mandal,
          gender: activeFilters.gender,
          // If isStudent is false, do not send to backend.
          isStudent: activeFilters.isStudent ? true : undefined,
          familyId: activeFilters.familyId,
          status: activeFilters.status,
          country: activeFilters.country,
          accommodationNeeded: activeFilters.accommodationNeeded
        },
        sortValue: '',
        eventId
      });
      if (response.error) {
        throw response.error;
      }
      return response;
    } catch (e) {
      return rejectWithValue('rejected');
    }
  }
);

export const updateRegistration = createAsyncThunk(
  'yogAdmin/updateRegistration',
  async (updatedRegistration: IRegistration, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const registrationFields = {
        accommodationNeeded: updatedRegistration.accommodationNeeded,
        hasMysteryGift: updatedRegistration.hasMysteryGift,
        isStudent: updatedRegistration.isStudent,
        status: updatedRegistration.status
      };

      const response = await updateRegistrationsAPI(
        registrationFields,
        updatedRegistration.registrationId
      );
      if (response.error) {
        dispatch({
          type: commonActions.SHOW_HIDE_TOASTER,
          value: {
            displayToaster: true,
            message: 'Error updating registration. Please try again.',
            type: commonActions.ERROR_TOASTER
          }
        });
        throw response.error;
      }
      dispatch({
        type: commonActions.SHOW_HIDE_TOASTER,
        value: {
          displayToaster: true,
          message: 'Updated registration',
          type: commonActions.SUCCESS_TOASTER
        }
      });
      return fulfillWithValue(response);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

interface IYogAdminRedux {
  registrations: IRegistration[];
  getRegistrationsApiState: RespState;
  paginationMeta: IPaginationMeta;
  updateRegistrationApiState: RespState;
  updateRegistrationError: string;
  selectedRegistration: IRegistration | null;
  activeFilters: IFilters;
  events: any[];
  eventsApiState: RespState;
  eventsError: string;
}

export const InitialActiveFilterValues = {
  name: '',
  state: '',
  mandal: '',
  isStudent: false,
  gender: '',
  familyId: '',
  status: '',
  country: '',
  accommodationNeeded: ''
};

const initialState: IYogAdminRedux = {
  registrations: [],
  getRegistrationsApiState: RespState.IDLE,
  paginationMeta: {
    maxPageNumber: null,
    total: 0
  },
  updateRegistrationApiState: RespState.IDLE,
  updateRegistrationError: '',
  selectedRegistration: null,
  activeFilters: InitialActiveFilterValues,
  events: [],
  eventsApiState: RespState.IDLE,
  eventsError: ''
};

// Then, handle actions in your reducers:
const yogAdminSlice = createSlice({
  name: 'yogAdmin',
  initialState,
  reducers: {
    setActiveFilters: (state, { payload: data }) => {
      state.activeFilters = data;
    },
    setSelectedRegistration: (state, { payload: registration }) => {
      state.selectedRegistration = registration;
    },
    updateRegistrationsState: (state, { payload }) => {
      const registration = payload as IRegistration;
      const updatedData = state.registrations.map((reg) => {
        if (reg.registrationId === registration.registrationId) {
          return {
            ...reg,
            ...registration
          };
        }
        return reg;
      });
      state.registrations = updatedData;
    },
    updateSelectedRegistration: (state, { payload: registration }) => {
      state.selectedRegistration = {
        ...state.selectedRegistration,
        ...registration
      };
    },
    resetSelectedRegistration: (state) => {
      state.selectedRegistration = null;
    }
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(searchRegistrations.pending, (state) => {
      state.paginationMeta.maxPageNumber = null;
      state.getRegistrationsApiState = RespState.PENDING;
    });
    builder.addCase(
      searchRegistrations.fulfilled,
      (state, action: PayloadAction<ISearchRegistrationsResp>) => {
        // Add user to the state array
        // when page is 1, we will replace the registrations
        // other we will append the value.
        const { registrations, metadata } = action.payload;
        if (metadata.page == 1) {
          state.registrations = registrations;
        } else {
          registrations.forEach((member) => {
            state.registrations.push(member);
          });
        }
        state.paginationMeta.total = metadata.total;
        state.paginationMeta.maxPageNumber = metadata.pages;
        state.getRegistrationsApiState = RespState.SUCCEEDED;
      }
    );
    builder.addCase(searchRegistrations.rejected, (state) => {
      state.paginationMeta.maxPageNumber = null;
      state.getRegistrationsApiState = RespState.FAILED;
      state.registrations = [];
      state.paginationMeta.total = 0;
    });

    builder.addCase(updateRegistration.pending, (state) => {
      state.updateRegistrationApiState = RespState.PENDING;
    });
    builder.addCase(
      updateRegistration.fulfilled,
      (state, action: PayloadAction<IUpdateRegistrationResp>) => {
        state.updateRegistrationApiState = RespState.SUCCEEDED;
        const { registration } = action.payload;
        const { registrationId } = registration;

        const foundRegistrationIndex = state.registrations.findIndex(
          (reg) => reg.registrationId === registrationId
        );
        state.registrations[foundRegistrationIndex] = {
          ...state.registrations[foundRegistrationIndex],
          ...registration
        };
        if (state.selectedRegistration) {
          state.selectedRegistration = {
            ...state.registrations[foundRegistrationIndex],
            ...registration
          };
        }
      }
    );
    builder.addCase(updateRegistration.rejected, (state, err) => {
      state.updateRegistrationApiState = RespState.FAILED;
      if (err.error?.message) {
        state.updateRegistrationError = err.error.message;
      } else {
        state.updateRegistrationError = 'An error occured';
      }
    });
    builder.addCase(fetchYogEvents.pending, (state) => {
      state.eventsApiState = RespState.PENDING;
    });
    builder.addCase(fetchYogEvents.fulfilled, (state, action) => {
      state.events = action.payload;
      state.eventsApiState = RespState.SUCCEEDED;
    });
    builder.addCase(fetchYogEvents.rejected, (state, action) => {
      if (action.error?.message) {
        state.eventsError = action.error.message;
      } else {
        state.eventsError = 'An error occured fetching events';
      }
      state.eventsApiState = RespState.FAILED;
    });
  }
});

export const {
  setActiveFilters,
  setSelectedRegistration,
  resetSelectedRegistration,
  updateSelectedRegistration,
  updateRegistrationsState
} = yogAdminSlice.actions;

export default yogAdminSlice.reducer;
