import Avatar from '@mui/material/Avatar';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as CameraIcon } from '../../assets/icons/icon_camera.svg';
import { IMAGES_URL } from '../../config/config.constants';
import { MembersType } from '../../types';
import { AvatarBadge } from './AvatarBadge';

interface IAvatarWrapperProps {
  contact?: MembersType;
  displayCameraIcon?: boolean;
  classes?: string[];
  hideBadge?: boolean;
  size?: string;
}

export const AvatarWrapper = (props: IAvatarWrapperProps) => {
  const {
    contact,
    displayCameraIcon = false,
    classes: classesList = [],
    size = '64',
    hideBadge = false
  } = props;

  const { classes, cx } = useStyles({ badgeSize: 0.39 * parseInt(size), size });

  const src =
    contact && contact.profileImageUrl && contact.profileImageUrl !== 'undefined'
      ? IMAGES_URL + '/' + contact.profileImageUrl
      : undefined;

  return (
    <div className={cx('avatar-wrapper-parent', ...classesList)}>
      <Avatar className={cx('avatar-wrapper', classes.avatar)} src={src}>
        {src ? null : displayCameraIcon ? (
          <CameraIcon />
        ) : contact && contact.firstName && contact.lastName ? (
          `${contact.firstName.charAt(0)}${contact.lastName.charAt(0)}`
        ) : (
          'AA'
        )}
      </Avatar>
      {contact && !hideBadge ? (
        <AvatarBadge badgeClasses={[classes.badge]} contact={contact} />
      ) : null}
    </div>
  );
};

const useStyles = makeStyles<{ badgeSize: number; size: string }>()((__, { badgeSize, size }) => ({
  avatar: {
    height: `${size}px`,
    width: `${size}px`,
    fontSize: parseInt(size) * 0.45
  },
  badge: {
    top: parseInt(size) - badgeSize,
    left: -badgeSize,
    height: badgeSize,
    width: badgeSize
  }
}));
