import React, { ChangeEvent, FC, Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { STATES } from '../../../../assets/data/us_states';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/icon_close_small.svg';
import { ReactComponent as SearchActiveIcon } from '../../../../assets/icons/icon_search_active.svg';
import { ReactComponent as SearchInactiveIcon } from '../../../../assets/icons/icon_search_inactive.svg';
import { useAppSelector } from '../../../../helpers/store';
import { RootState } from '../../../../reducers/index.types';
import { SelectField } from '../../../../v2/components/SelectorField/index';
import { CityFilterInput } from './CityFilterInput';
import { contactActions } from '../../contacts.ducks';
import { useMembers } from '../../useMembers';

export const SearchCityStateFilter: FC = () => {
  const dispatch = useDispatch();
  const actions = bindActionCreators(contactActions, dispatch);

  const [isCityFilterDisabled, setIsCityFilterDisabled] = useState<boolean>(false);

  const { filteredCity, filteredState, searchValue, filterCitySuggestions } = useAppSelector(
    (state: RootState) => state.contactsData
  );

  const { getMembers } = useMembers();

  const handleOnSearch = ({
    search,
    city,
    state
  }: {
    search: string;
    city: string;
    state: string;
  }) => {
    const [filteredCity] = city ? city.split(', ') : [city];
    getMembers({
      pageNumber: 1,
      search,
      filter: {
        city: filteredCity?.toLowerCase(),
        state: state?.toLowerCase()
      }
    });
  };

  const largeWidthScreen = window ? window.innerWidth > 600 : false;

  const handleSearchIconClick = () => {
    if (searchValue && (searchValue.length >= 3 || searchValue.length === 0)) {
      handleOnSearch({
        search: searchValue,
        state: filteredState,
        city: filteredCity
      });
    }
  };
  const updateSearchValue = (event?: ChangeEvent<HTMLInputElement>) => {
    actions.updateSearchState(event?.target.value ?? '');
  };

  const handleSearchClearClick = () => {
    updateSearchValue();
    handleOnSearch({
      search: '',
      state: filteredState,
      city: filteredCity
    });
  };

  const handleSearchSubmit = (search: string) => {
    handleOnSearch({
      search,
      state: filteredState,
      city: filteredCity
    });
  };

  const handleSearchKeyUp = (event: React.KeyboardEvent) => {
    // If the user presses enter, fire search handler
    if (event.which === 13) {
      // We've nested this so this logic isn't run until enter key is pressed
      if (searchValue.length >= 3 || searchValue.length === 0) {
        handleSearchSubmit(searchValue);
      }
    }
  };
  const handleCityChange = (value: string) => {
    actions.updateFilteredCity(value);
  };

  const handleCityFilterClear = () => {
    handleCityChange('');
    setIsCityFilterDisabled(false);
    handleOnSearch({
      search: searchValue,
      state: filteredState,
      city: ''
    });
  };

  const handleSelectState = (name: string, state: string) => {
    actions.updateFilteredState(state);
    handleCityFilterClear();
    handleOnSearch({
      search: searchValue,
      state: state,
      city: ''
    });
  };

  const handleSelectCitySuggestion = (city: string) => {
    setIsCityFilterDisabled(true);
    handleOnSearch({
      search: searchValue,
      state: filteredState,
      city
    });
  };

  const SearchIcon = searchValue && searchValue.length >= 3 ? SearchActiveIcon : SearchInactiveIcon;

  return (
    <Fragment>
      <div className="contact-search-icon-container">
        <SearchIcon className="icon-search" onClick={handleSearchIconClick} />
        <input
          id="contact-search-field"
          className={[
            'contact-filter-search',
            searchValue && searchValue.length >= 3 ? 'active' : null
          ].join(' ')}
          type="text"
          placeholder="Search contacts"
          value={searchValue}
          onChange={updateSearchValue}
          onKeyUp={handleSearchKeyUp}
        />
        {searchValue ? <CloseIcon className="icon-close" onClick={handleSearchClearClick} /> : null}
      </div>
      {largeWidthScreen ||
      (searchValue && searchValue.length >= 0) ||
      filteredCity ||
      filteredState ? (
        <Fragment>
          <SelectField
            containerClassName="contact-filter-state-input-container"
            className="contact-filter-state-input"
            placeholder={'State'}
            options={STATES}
            onChange={handleSelectState}
            inputValue={filteredState}
          />
          <div className="contact-filter-city-input-container">
            <CityFilterInput
              className="contact-filter-city-input"
              value={filteredCity}
              isDisabled={isCityFilterDisabled}
              filterCitySuggestions={filterCitySuggestions}
              onChange={handleCityChange}
              onClear={handleCityFilterClear}
              onSelectSuggestion={handleSelectCitySuggestion}
            />
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  );
};
