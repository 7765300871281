import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  containerEdit: {
    border: '1px solid hsla(208, 30%, 82%, 1)',
    height: '75vh',
    overflowY: 'hidden',
    borderRadius: '20px',
    backgroundColor: 'white',
    flex: 4,
    padding: '24px 16px',
    position: 'relative',
    zIndex: 0
  },
  containerMain: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
    gap: '24px',
    height: '100%'
  }
}));
