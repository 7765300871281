import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// MATERIAL UI
import { Button, AppBar, Toolbar } from '@material-ui/core';
// PROJECT FILES
import * as FORMS from '../../constants/forms';
import * as MODAL_COMPONENTS from '../../constants/modals';
import { openModal, closeDrawer } from '../../actions/common.actions';
import { submitContactForm } from '../../actions/contactForm.actions';
import { setPendingSambandhUserStatus } from '../../actions/sambandhApproval.actions';
import { getSambandhApprovalsData } from '../../actions/admin.actions';
import { APPROVED } from '../../constants/status';
import { contactActions } from '../../views/Contacts/contacts.ducks';


const SambandhApprovalFooter = (props) => {
  return (
    <AppBar
      position='sticky'
      className='panel-footer'>
      <Toolbar>
        <div
          className='contact-form-button-container padding-sm'>
          {SecondaryButton(props)}
          {PrimaryButton(props)}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default connect(null, {
  uploadContactAvatar: contactActions.uploadContactAvatar,
  closeDrawer,
  submitContactForm,
  openModal,
  setPendingSambandhUserStatus,
  getSambandhApprovalsData
})(SambandhApprovalFooter);

SambandhApprovalFooter.propTypes = {
};

function SecondaryButton (props) {

  let buttonLabel = '';
  let buttonClasses = [];
  let labelClasses = [];
  buttonLabel = 'Decline';
  buttonClasses.push('button-red');
  buttonClasses.push('margin-xsm-right');
  labelClasses.push('label-white');
  const handleClick = () => {
    return props.openModal(MODAL_COMPONENTS.REJECT_SAMBANDH_MODAL);
  };

  return (
    <Button
      className={['button-primary', ...buttonClasses].join(' ')}
      onClick={handleClick}
      disableRipple
      color='primary'>
      <span className={['button-primary-label', ...labelClasses].join(' ')}>{buttonLabel}</span>
    </Button>
  );
}

function PrimaryButton (props) {
  let buttonLabel = '';
  let classes = [];
  buttonLabel = props.isFormDirty ? 'Approve & Save' : 'Approve';
  classes.push('button-green');
  const handleClick = async () => {
    let error = false;
    const approvalResults = await props.setPendingSambandhUserStatus(APPROVED.status);
    if (approvalResults.error) error = true;
    if (props.isFormDirty) {
      const submitContactFormResults = await props.submitContactForm(FORMS.SAMBANDH_APPROVAL_FORM);
      if (submitContactFormResults.error) error = true;
      if (props.uploadingImage) {
        if (submitContactFormResults && submitContactFormResults.id) {
          const uploadAvatarResults = await props.uploadContactAvatar(FORMS.SAMBANDH_APPROVAL_FORM, submitContactFormResults.id);
          if (uploadAvatarResults.error) error = true;
        } else {
          const uploadAvatarResults = await props.uploadContactAvatar(FORMS.SAMBANDH_APPROVAL_FORM);
          if (uploadAvatarResults.error) error = true;
        }
      }
    }
    if (!error) {
      props.closeDrawer();
      return props.getSambandhApprovalsData();
    }
  };
  return (
    <Button
      disableRipple
      disabled={props.isFormDirty && !props.isFormValid}
      onClick={handleClick}
      className={['button-primary', ...classes].join(' ')}
      color='primary'>
      <span className='button-primary-label label-white'>{buttonLabel}</span>
    </Button>
  );
}

PrimaryButton.propTypes = {
  submitContactForm: PropTypes.func.isRequired,
  uploadContactAvatar: PropTypes.func.isRequired,
  uploadingImage: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  isFormDirty: PropTypes.bool.isRequired,
  isFormValid: PropTypes.bool.isRequired,
  setPendingSambandhUserStatus: PropTypes.func.isRequired,
  getSambandhApprovalsData: PropTypes.func.isRequired
};

SecondaryButton.propTypes = {
  openModal: PropTypes.func.isRequired
};
