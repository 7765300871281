import React, { ChangeEvent } from 'react';
import ImageUploader from 'react-images-upload';
import { Button } from '@material-ui/core';
import { uploadImagesAPI } from '../../apis/commonAPIs';

import { ThakorjiSmrutiesViewProps, ThakorjiSmrutiesViewState } from './thakorjiSmrutiesView.types';

class ThakorjiSmrutiesView extends React.Component<
  ThakorjiSmrutiesViewProps,
  ThakorjiSmrutiesViewState
> {
  constructor(props: ThakorjiSmrutiesViewProps) {
    super(props);

    this.state = {
      imageType: 'thakorji',
      images: [],
      imageUrls: [],
      uploadSuccess: false,
      uploadMessage: null
    };
  }

  handleRadioSelect = (event: ChangeEvent<any>) => {
    this.setState({
      imageType: event.target.value
    });
  };

  handleImageSelect = (pictureFiles: File[], pictureDataURLs: string[]) => {
    this.setState({
      images: pictureFiles,
      imageUrls: pictureDataURLs
    });
  };

  uploadImagesAPI = async () => {
    if (this.state.images && this.state.images.length) {
      const imageFormData = new FormData();
      this.state.images.forEach((image) => {
        imageFormData.append(this.state.imageType, image, image.name);
      });
      const uploadImagesAPIResponse = await uploadImagesAPI(imageFormData);
      if (uploadImagesAPIResponse.error) {
        this.setState({
          uploadMessage: 'Error trying to upload images'
        });
      } else {
        this.setState({
          images: [],
          imageUrls: [],
          uploadMessage: 'Images uploaded successfully',
          uploadSuccess: true
        });
      }
    }
  };

  render() {
    const { uploadMessage, uploadSuccess } = this.state;

    return (
      <div className="thakorji-smruties-view-container">
        <header className="thakorji-smruties-header">
          <h2 className="thakorji-smruties-title">Thakorji & Smruti Darshan Uploader</h2>
          <h5>Please upload a max of 6 images at a time</h5>
          {uploadMessage && uploadSuccess ? <h3>{uploadMessage}</h3> : null}
        </header>
        <div className="thakorji-smruties-content">
          <div className="radio-button-container">
            <div className="radio-button">
              <input
                type="radio"
                id="thakorji"
                name="images-type"
                value="thakorji"
                checked={this.state.imageType === 'thakorji'}
                onChange={this.handleRadioSelect}
              />
              <label htmlFor="thakorji">Thakorji</label>
            </div>
            <div className="radio-button">
              <input
                type="radio"
                id="smruti"
                name="images-type"
                value="smruti"
                checked={this.state.imageType === 'smruti'}
                onChange={this.handleRadioSelect}
              />
              <label htmlFor="smruti">Smruti</label>
            </div>
          </div>
          <ImageUploader
            imgExtension={['.jpg', 'jpeg', '.png', '.gif']}
            onChange={this.handleImageSelect}
            withPreview
          />
          <Button
            disableRipple
            onClick={this.uploadImagesAPI}
            className="button-primary button-orange upload-image-button"
            color="primary"
          >
            <span className="button-primary-label label-white">Upload Images</span>
          </Button>
        </div>
      </div>
    );
  }
}

export default ThakorjiSmrutiesView;
