import * as React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { Icon } from '../svg-icons';

export const FieldLabel = ({
  label,
  required,
  tooltip
}) => {
  return (
    <div className={'access-container__field-label'}>
      {required && <span className={'access-container__required-symbol'}>*</span>}
      <label>{label}</label>
      {tooltip && <Tooltip placement='top' title={tooltip}>
        <Icon name={'HelpInfo'} className={'access-container__field-info'} width='12' height='12' fill='#899EB0' />
      </Tooltip>}
    </div>
  );
};

FieldLabel.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  tooltip: PropTypes.string
};
