import * as STATUS from '../../constants/messageStatus';
import { EBroadcastViewTabs, IBroadcastViewTab } from './BroadcastView.types';

export const ACTIVE_STATUS = [STATUS.APPROVED, STATUS.PENDING, STATUS.SENT];
export const INACTIVE_STATUS = [STATUS.CANCELLED, STATUS.DECLINED, STATUS.EXPIRED];

export const BroadcastViewTabs: IBroadcastViewTab[] = [
  {
    label: 'My Messages',
    value: EBroadcastViewTabs.MY_MESSAGES
  },
  {
    label: 'Messages to Approve',
    value: EBroadcastViewTabs.APPROVAL_MESSAGES
  }
];

export const BroadcastViewDocumentTitle = 'Sampark | Broadcast';
