import { adminViewActions, commonActions } from '../constants/actions';
import { getUsersByStatusAPI } from '../apis/adminAPIs';
import { getPendingSambandhUsers } from '../apis/sambandhAPIs';
import { GET_USERS_BY_STATUS_ERROR, SAMBANDH_APPROVAL_DATA_ERROR } from '../constants/toasterMessages';
import * as USER_STATUSES from '../constants/status';
import { checkUserPermission, ISSUPERADMIN, CANUPDATECONTACTS } from '../util/userPermission';

export function getApprovedUsers () {
  return async (dispatch, getState) => {
    const getApprovedUsersResponse = await getUsersByStatusAPI(USER_STATUSES.APPROVED.status);
    if (!getApprovedUsersResponse || getApprovedUsersResponse.error) {
      return dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: GET_USERS_BY_STATUS_ERROR, type: commonActions.ERROR_TOASTER } });
    } else {
      return dispatch({ type: adminViewActions.GET_APPROVED_USERS, value: getApprovedUsersResponse });
    }
  };
};

export function getSambandhApprovalsData () {
  return async (dispatch) => {
    dispatch({ type: adminViewActions.SHOW_HIDE_ADMIN_LOADER, value: true });
    const members = await getPendingSambandhUsers();
    if (members && members instanceof Array) {
      dispatch({ type: adminViewActions.SHOW_HIDE_ADMIN_LOADER, value: false });
      return dispatch({ type: adminViewActions.GET_SAMBANDH_APPROVALS, value: members });
    } else {
      dispatch({ type: adminViewActions.SHOW_HIDE_ADMIN_LOADER, value: false });
      return dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: SAMBANDH_APPROVAL_DATA_ERROR, type: commonActions.ERROR_TOASTER } });
    }
  };
}

export function getAdminData (tab) {
  return async (dispatch, getState) => {
    const canApproveSambandhUsers = checkUserPermission(getState().userAccess, CANUPDATECONTACTS);

    switch (tab) {
      case 'users': {
        dispatch(getApprovedUsers());
        break;
      }
      case 'sambandh': {
        if (canApproveSambandhUsers) {
          dispatch(getSambandhApprovalsData());
        }
        break;
      }
      default: {
        dispatch(getApprovedUsers()); // call getApprovedUsers() action // TODO: Need to fix this
        if (canApproveSambandhUsers) {
          dispatch(getSambandhApprovalsData());
        }
        break;
      }
    }
  };
}
