import { MessageListItem } from './MessageListItem';
import { IMessageListProps } from './MessageList.types';

// Renders a list of broadcast messages.
export const MessageList = ({ messages, onClickViewMessage }: IMessageListProps) => {
  return (
    <div className={'broadcast-cards-list-view'}>
      {messages.map((message) => {
        return (
          <MessageListItem
            key={message._id}
            message={message}
            onClickViewMessage={onClickViewMessage}
          />
        );
      })}
    </div>
  );
};
