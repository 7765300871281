import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { normalizePhone } from '../util/normalizePhone';
import { commonActions, contactFormActions } from '../constants/actions';
import { addContactAPI, updateContactAPI, verifyContactAPI } from '../apis/contactAPIs';
import { CONTACT_UPDATED_SUCCESS, NEW_CONTACT_ADDED_SUCCESS, CONTACT_UPDATED_ERROR, CONTACT_VERIFIED_SUCCESS, CONTACT_VERIFIED_ERROR } from '../constants/toasterMessages';
import { getAccessObj, GENDER } from '../util/userPermission';
import * as PERMISSIONS from '../constants/permissions';
import { contactActions } from '../views/Contacts/contacts.ducks'
import { bindActionCreators } from 'redux';

dayjs.extend(utc);

function convertAttributesForApi(attributes) {
  if (!attributes) {
    return [];
  };
  return [...attributes].map(attr => attr.value);
}

function convertAttributesForForm(attributes, attributesMap, attributeName) {
  if (!attributes || !attributesMap) {
    return [];
  }

  return [...attributes].map(attr => {
    let reference;
    attributesMap.forEach((attributeRef) => {
      if (attributeRef.id === attr) {
        reference = attributeRef;
      }
    });
    if (!reference) {
      reference = { id: attr, name: attr, value: attr };
    }
    return {
      name: reference.name,
      value: attr,
      description: reference.description,
      type: 'option',
      group: attributeName
    };
  });
}


// use to map audience value to name, for ex ALL-US-BHAI ==> All USA Bhaio
function getUserAudiences(audiences, audiencesList) {
  let contactAudiences = [];
  if (audiencesList && audiencesList.contacts && audiences) {
    const mapAudiences = getMapAudiences(audiencesList);
    audiences.forEach((audience) => {
      if (mapAudiences.has(audience)) {
        contactAudiences.push(mapAudiences.get(audience));
      }
    });
  }
  return contactAudiences;
}
function getMapAudiences(audiencesList) {
  const mapAudiences = new Map();
  audiencesList.contacts.forEach(audience => mapAudiences.set(audience.value, audience.name));
  return mapAudiences;
}

function mapUserToForm(userFromState, contactOptions) {
  const { attributes: attributesMap, adminAttributes: adminAttributesMap } = contactOptions;
  let clickedUser = {};
  if (!userFromState.id) return clickedUser;
  clickedUser.id = userFromState.id;
  clickedUser.firstName = userFromState.firstName;
  clickedUser.lastName = userFromState.lastName;
  clickedUser.nickName = userFromState.nickName;
  clickedUser.audiences = getUserAudiences(userFromState.audiences, contactOptions.audiencesList);
  clickedUser.tags = { ...userFromState.tags };
  if (userFromState.tags && userFromState.tags?.attributes && typeof userFromState.tags?.attributes?.[0] === 'string') {
    clickedUser.tags.attributes = convertAttributesForForm(userFromState.tags.attributes, attributesMap, 'Attributes');
  }
  if (userFromState.tags && userFromState.tags?.adminAttributes && typeof userFromState.tags.adminAttributes?.[0] === 'string') {
    clickedUser.tags.adminAttributes = convertAttributesForForm(userFromState.tags.adminAttributes, adminAttributesMap, 'Admin Attributes');
  }
  clickedUser.birthDate = userFromState.birthDate ? dayjs(userFromState.birthDate).utc().format('YYYY-MM-DD') : '';
  clickedUser.gender = userFromState.gender;
  clickedUser.maritalStatus = userFromState.maritalStatus;
  if (userFromState.muktType && Array.isArray(userFromState.muktType)) {
    const muktType = userFromState.muktType.map(mukt => getOptionForMuktType(mukt));
    clickedUser.muktType = muktType;
  } else {
    clickedUser.muktType = [];
  }

  clickedUser.contactDetails = {
    email: '',
    additionalEmails: [],
    cell: '',
    home: '',
    phones: userFromState.contactDetails.phones
  };
  clickedUser.contactDetails.email = userFromState.contactDetails.email;
  clickedUser.contactDetails.additionalEmails = userFromState.contactDetails.additionalEmails;

  if (userFromState.contactDetails.phones && userFromState.contactDetails.phones.length > 0) {
    userFromState.contactDetails.phones.forEach(phone => {
      switch (phone.type) {
        case 'Cell':
          clickedUser.contactDetails.cell = normalizePhone(phone.value);
          break;
        case 'Home':
          clickedUser.contactDetails.home = normalizePhone(phone.value);
          break;
        default:
          throw new Error(`Phone of type ${phone.type} is not valid`);
      }
    });
  } else {
    clickedUser.contactDetails.cell = '';
    clickedUser.contactDetails.home = '';
  }

  clickedUser.addressDetails = userFromState.addressDetails ? userFromState.addressDetails : {
    city: '',
    state: ''
  };
  clickedUser.reference = userFromState.reference ? userFromState.reference : {
    notes: ''
  };
  clickedUser.noReference = userFromState.noReference;
  clickedUser.profession = userFromState.profession ? userFromState.profession : {
    company: '',
    jobTitle: ''
  };
  clickedUser.interests = userFromState.interests || [];
  clickedUser.profileImageUrl = userFromState.profileImageUrl;
  clickedUser.updatedAt = userFromState.updatedAt ? dayjs(userFromState.updatedAt).format('MM/DD/YYYY') : '';
  clickedUser.isNewContact = false;
  clickedUser.invitationStatus = userFromState.invitationStatus;
  clickedUser.userID = userFromState.userID;
  clickedUser.yogId = userFromState.yogId;
  clickedUser.mandal = userFromState.mandal;
  clickedUser.workshopInvitationStatus = userFromState.workshopInvitationStatus;
  clickedUser.contactStatus = userFromState.contactStatus;
  return clickedUser;
}

function getOptionForMuktType(mukt) {
  return { name: mukt, value: mukt, type: 'option', group: 'Mukt Type' };
}

function createNewUser(contactOptions, userAccessGender) {
  let clickedUser = {};
  clickedUser.firstName = '';
  clickedUser.lastName = '';
  clickedUser.nickName = '';
  clickedUser.gender = userAccessGender || '';
  clickedUser.birthDate = '';
  clickedUser.maritalStatus = '';
  clickedUser.muktType = [];
  clickedUser.noReference = false;
  clickedUser.contactDetails = {
    email: '',
    additionalEmails: [],
    home: '',
    cell: '',
    phones: []
  };
  clickedUser.addressDetails = {
    address1: '',
    address2: '',
    city: '',
    state: '',
    country: 'US',
    zip: '',
    county: ''
  };
  clickedUser.reference = {
    notes: ''
  };
  clickedUser.noReference = false;
  clickedUser.profession = {
    company: '',
    jobTitle: ''
  };
  clickedUser.interests = [];
  clickedUser.attributes = [];
  clickedUser.adminAttributes = [];
  clickedUser.muktType = [];
  clickedUser.coordinates = {
    lat: '',
    lon: ''
  };
  clickedUser.isNewContact = true;
  clickedUser.referredBy = {
    name: '',
    id: ''
  };
  clickedUser.tags = {};
  clickedUser.workshopInvitationStatus = ''
  return clickedUser;
}

export function mapFormToUser(formUser) {
  let userToSubmit = {};
  if (formUser.id) {
    userToSubmit.id = formUser.id;
  }
  if (formUser._id) {
    userToSubmit._id = formUser._id;
  }
  userToSubmit.tags = {...formUser.tags};
  if (formUser.tags?.attributes) {
    userToSubmit.tags.attributes = convertAttributesForApi([...formUser.tags.attributes]);
  };
  if (formUser.tags?.adminAttributes) {
    userToSubmit.tags.adminAttributes = convertAttributesForApi([...formUser.tags.adminAttributes]);
  };
  userToSubmit.birthDate = formUser.birthDate ? dayjs(formUser.birthDate).toISOString() : '';
  userToSubmit.firstName = formUser.firstName;
  userToSubmit.lastName = formUser.lastName;
  userToSubmit.nickName = formUser.nickName;
  userToSubmit.gender = formUser.gender;
  userToSubmit.addressDetails = formUser.addressDetails;
  userToSubmit.contactDetails = {
    email: formUser.contactDetails.email,
    additionalEmails: formUser.contactDetails.additionalEmails,
    phones: [
      {
        type: 'Home',
        value: formUser.contactDetails.home
      },
      {
        type: 'Cell',
        value: formUser.contactDetails.cell
      }
    ]
  };
  userToSubmit.muktType = formUser.muktType.map(mukt => mukt.value? mukt.value : mukt);
  userToSubmit.maritalStatus = formUser.maritalStatus !== '' ? formUser.maritalStatus : null;
  userToSubmit.profession = formUser.profession;
  userToSubmit.reference = formUser.reference;
  userToSubmit.noReference = formUser.noReference;
  userToSubmit.interests = formUser.interests;
  userToSubmit.attendingSabha = [];
  if (formUser.followUp?.id) {
    userToSubmit.followUp = formUser.followUp.id;
  }
  userToSubmit.mandal = formUser.mandal;
  userToSubmit.workshopInvitationStatus = formUser.workshopInvitationStatus;
  return userToSubmit;
}

export function initializeContactForm(clickedUser) {
  return (dispatch, getState) => {
    const { contactOptions, searchValue } = getState().contactsData;
    const userAccessGender = getAccessObj(getState().userAccess, GENDER, PERMISSIONS.CONTACTS);
    const contactFormValues = !clickedUser ? createNewUser(contactOptions, userAccessGender) : mapUserToForm({ ...clickedUser }, contactOptions);
    return dispatch({ type: contactFormActions.INITIALIZE_CONTACT_FORM, value: contactFormValues });
  };
}

export function initializeReadOnlyContactForm(contact) {
  return (dispatch) => {
    return dispatch({ type: contactFormActions.INITIALIZE_READ_ONLY_CONTACT_FORM, value: contact });
  }
}

export function verifyAndSubmitContactForm(form, uploadingImage) {
  return async (dispatch, getState) => {
    const { values } = getState().form[form];
    if (values.id) { // UPDATE CONTACT
      const verifyContactResponse = await verifyContactAPI(values.id);
      if (!verifyContactResponse.error) {
        return submitContact(form, uploadingImage, values, dispatch, getState, CONTACT_VERIFIED_SUCCESS);
      }
      if (verifyContactResponse.error) {
        const error = verifyContactResponse.error;
        let message;
        if (error && error.response && error.response.data && error.response.data.message) {
          message = error.response.data.message;
        } else {
          message = CONTACT_VERIFIED_ERROR;
        }
        dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message, type: commonActions.ERROR_TOASTER } });
      }
      return verifyContanctResponse;
    }
  };
}

export function submitContactForm(form, uploadingImage) {
  return async (dispatch, getState) => {
    const { values } = getState().form[form];
    return submitContact(form, uploadingImage, values, dispatch, getState, CONTACT_UPDATED_SUCCESS);
  };
}

export function storeAvatarImage(avatarFile) {
  return (dispatch) => {
    return dispatch({ type: contactFormActions.STORE_AVATAR_IMAGE, value: avatarFile });
  };
}

export const submitContact = async (form, uploadingImage, values, dispatch, getState, successMsg) => {
  if (values.id) { // UPDATE CONTACT
    const contactData = mapFormToUser(values);
    const updateContactResponse = await updateContactAPI(contactData);
    if (!updateContactResponse.error) {
      if (uploadingImage) {
        await contactActions.uploadContactAvatar(form, values.id)(dispatch, getState);
      }
      dispatch({ type: contactFormActions.SUBMIT_CONTACT_FORM });
      dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: successMsg, type: commonActions.SUCCESS_TOASTER } });
    }

    if (updateContactResponse.error) {
      const error = updateContactResponse.error;
      let message;
      if (error && error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message;
      } else {
        message = CONTACT_UPDATED_ERROR;
      }
      dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message, type: commonActions.ERROR_TOASTER } });
    }
    return updateContactResponse;
  } else { // NEW CONTACT
    const contactData = mapFormToUser(values);
    const addContactResponse = await addContactAPI(contactData);
    if (!addContactResponse.error) {
      if (uploadingImage && addContactResponse.id) {
        await contactActions.uploadContactAvatar(form, addContactResponse.id)(dispatch, getState);
      }
      dispatch({ type: contactFormActions.SUBMIT_CONTACT_FORM });
      dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: NEW_CONTACT_ADDED_SUCCESS, type: commonActions.SUCCESS_TOASTER } });
    } else {
      const { error } = addContactResponse;
      let message;
      if (error && error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message;
      } else {
        message = CONTACT_UPDATED_ERROR;
      }
      dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message, type: commonActions.ERROR_TOASTER } });
    }
    return addContactResponse;
  }
}
