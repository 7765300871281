import * as TEMPLATE from '../../constants/readingTemplates';
import * as TYPES from '../../constants/contentTypes';

export function getReadingTemplate(contentType: string) {
  let template;
  switch (contentType) {
    case TYPES.WORDOFTHEWEEK.value:
      template = TEMPLATE.WordOfTheDayTemplate;
      break;
    case TYPES.SUTRA.value:
      template = TEMPLATE.SutraTemplate;
      break;
    case TYPES.PRASANG.value:
      template = TEMPLATE.PrasangTemplate;
      break;
    case TYPES.STORY.value:
      template = TEMPLATE.InspiringStoryTemplate;
      break;
    default:
      template = '';
  }

  return template;
}
