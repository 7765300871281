import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FieldLabel } from '../access-controls/FieldLabel';

export const SelectField = ({
  inputValue,
  label = '',
  onChange,
  multiple = false,
  lockSelected = false,
  options = [],
  maxSelect = 1,
  entireOptionObjectAsValue = false,
  tooltip = '',
  placeholder = '',
  required = false,
  readOnly = false,
  name = '',
  containerClassName = '',
  className = '',
  ...custom
}) => {
  const errorClassName = required && !inputValue ? 'error-field' : '';
  let classes = ['form-field-text', 'padding-xsm-left', className, errorClassName];
  if (readOnly) classes.push('disabled-text-field');
  const handleChange = (e, value) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(name, e.target.value);  
  };
  return (
    <Fragment>
      {label && <FieldLabel label={label} required={required} tooltip={tooltip} />}
      <div className={['form-field-text-container', containerClassName].join(' ')}>
        <Select
          displayEmpty
          multiple={multiple}
          value={inputValue ? inputValue : ''}
          SelectDisplayProps={
            placeholder && inputValue === ''
              ? {
                style: {
                  color: '#c4d2df'
                }
              } : null
          }
          MenuProps={{
            style: {
              marginTop: '0.5rem'
            },
            MenuListProps: {
              disablePadding: true
            },
          }}
          className={classes.join(' ')}
          disabled={readOnly}
          variant='standard'
          disableUnderline
          onChange={handleChange}>
          {placeholder ? <MenuItem key={0} value={''}>{placeholder}</MenuItem> : null}
          {options.map((option, index) => {
            let disabled = false;
            if (multiple & maxSelect) {
              const isSelected = inputValue.value.indexOf(option.value) !== -1;
              const maxLimitReached = inputValue.value.length === maxSelect;
              disabled = (multiple && maxLimitReached && !isSelected);
            }
            return (
              <MenuItem
                disabled={disabled}
                key={index}
                value={option.value || option.name || option.label}
                style={{
                  textTransform: 'capitalize'
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                >{option.name || option.label}</MenuItem>
            );
          })}
        </Select>
      </div>
    </Fragment>
  );
};

SelectField.propTypes = {
  inputValue: PropTypes.any,
  meta: PropTypes.any,
  label: PropTypes.string,
  options: PropTypes.any,
  placeholder: PropTypes.string,
  tooltip: PropTypes.string,
  entireOptionObjectAsValue: PropTypes.bool,
  multiple: PropTypes.bool,
  maxSelect: PropTypes.number,
  lockSelected: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  containerClassName: PropTypes.string,
  className: PropTypes.string
};
