import React, { Component } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
// material
import { Menu, MenuItem } from '@material-ui/core';
// project files
import { CANCEL_BROADCAST, COPY_BROADCAST } from '../../constants/menuOptions';
import getMenuOptions from '../../util/getMenuOptions';
import { BROADCAST_MENU } from '../../constants/menus';
import { HoverImage } from '../hover-image/HoverImage';
import icon_overflow from '../../assets/icons/icon_overflow.svg';
import icon_overflow_active from '../../assets/icons/icon_overflow_active.svg';
import { openModal } from '../../actions/common.actions';
import * as MODAL_COMPONENTS from '../../constants/modals';

class BroadcastMessageMenu extends Component {
  constructor (props) {
    super(props);
    this.state = {
      anchorEl: null
    };
  }

  handleCancelBroadcast () {
    this.props.openModal(MODAL_COMPONENTS.CANCEL_BROADCAST_MODAL, { message: this.props.message });
  }

  // open menu
  handleMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  // handle menu options click
  handleMenuClose = value => {
    const { onCreateCopy } = this.props;
    this.setState(
      { anchorEl: null },
      () => {
        switch (value) {
          case CANCEL_BROADCAST:
            this.handleCancelBroadcast();
            break;
          case COPY_BROADCAST:
            onCreateCopy();
            break;
          default:
        }
      }
    );
  }

  render () {
    const { anchorEl } = this.state;
    const menuOptions = getMenuOptions(BROADCAST_MENU, { status: this.props.message.status, id: this.props.message.id });
    let classes = this.props.classes ? this.props.classes.join(' ') : '';
    return (
      <div className={classes}>
        <HoverImage
          style={{display: 'flex'}}
          src={icon_overflow}
          hoverSrc={icon_overflow_active}
          onClick={this.handleMenuOpen} />
        <Menu
          MenuListProps={{
            disablePadding: true
          }}
          style={{marginTop: '0.5rem'}}
          anchorEl={anchorEl}
          anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
          getContentAnchorEl={null}
          open={Boolean(anchorEl)}
          onClose={() => {
            this.handleMenuClose();
          }}>
          {menuOptions.map((option, index) => {
            return (
              <MenuItem
                key={index}
                disabled={option.disabled}
                onClick={() => {
                  this.handleMenuClose(option);
                }}>
                {option.name}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  }
};

export default connect(
  null, { openModal }
)(BroadcastMessageMenu);

BroadcastMessageMenu.propTypes = {
  openModal: PropType.func.isRequired,
  onCreateCopy: PropType.func.isRequired,
  classes: PropType.arrayOf(PropType.string),
  message: PropType.object.isRequired
};
