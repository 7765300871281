import Stage from '../../../util/stage';
import http from '../../../helpers/http';
import { generateTypes, makeAction } from '../../../util/actions';
import { API } from '../../../config/config.constants';
import { commonActions } from '../../../constants/actions';

const TYPES = generateTypes(
  'GET_FOLLOWUP_KK_LIST', 'SET_FOLLOWUP_KK_LIST', 'RESET_FOLLOWUP_KK_LIST', 'ERROR_FOLLOWUP_KK_LIST',
  'GET_FOLLOW_UP_CONTACTS_BY_KK', 'SET_FOLLOW_UP_CONTACTS_BY_KK', 'RESET_FOLLOW_UP_CONTACTS_BY_KK', 'ERROR_FOLLOW_UP_CONTACTS_BY_KK'
);

const Actions = Object.freeze({
  get: () => makeAction(TYPES.GET_FOLLOWUP_KK_LIST),
  set: (data) => makeAction(TYPES.SET_FOLLOWUP_KK_LIST, { data }),
  reset: () => makeAction(TYPES.RESET_FOLLOWUP_KK_LIST),
  error: (err) => makeAction(TYPES.ERROR_FOLLOWUP_KK_LIST, { err }),
  getContactsList: () => makeAction(TYPES.GET_FOLLOW_UP_CONTACTS_BY_KK),
  setContactsList: (data) => makeAction(TYPES.SET_FOLLOW_UP_CONTACTS_BY_KK, { data }),
  resetContactsList: () => makeAction(TYPES.RESET_FOLLOW_UP_CONTACTS_BY_KK),
  errorContactsList: (err) => makeAction(TYPES.ERROR_FOLLOW_UP_CONTACTS_BY_KK, { err })
});

export const ActionCreators = Object.freeze({
  fetchFollowUpKKList: () => (dispatch) => {
    dispatch(Actions.get());

    http
      .get(API.FOLLOW_UP_ADMIN.GET_FOLLOW_UP_KKS)
      .then(response => {
        const { data } = response;
        dispatch(Actions.set(data));
      })
      .catch(error => {
        console.log(error);
        dispatch(Actions.error(error));
        dispatch({
          type: commonActions.SHOW_HIDE_TOASTER,
          value: {
            displayToaster: true,
            message: 'Error loading follow up karyakarta list',
            type: commonActions.ERROR_TOASTER
          }
        });
      });
  },
  resetFollowUpKKList: () => (dispatch) => dispatch(Actions.reset()),
  fetchFollowUpContactListForKK: (id) => (dispatch) => {
    dispatch(Actions.getContactsList());

    http
      .get(API.FOLLOW_UP_ADMIN.GET_FOLLOW_UP_CONTACTS_FOR_KK(id))
      .then(response => {
        const { data } = response;
        dispatch(Actions.setContactsList(data));
      })
      .catch(error => {
        console.log(error);
        dispatch(Actions.errorContactsList(error));
        dispatch({
          type: commonActions.SHOW_HIDE_TOASTER,
          value: {
            displayToaster: true,
            message: 'Error loading contacts for selected karyakarta',
            type: commonActions.ERROR_TOASTER
          }
        });
      });
  },
  resetFollowUpContactList: () => (dispatch) => dispatch(Actions.resetContactsList())
});

const initialState = Object.freeze({
  stage: Stage.READY,
  followUpKKAdmins: [],
  contactsStage: Stage.READY,
  followUpContacts: []
});

const reducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case TYPES.GET_FOLLOWUP_KK_LIST:
      return {
        ...state,
        stage: Stage.LOADING
      };
    case TYPES.SET_FOLLOWUP_KK_LIST:
      return {
        ...state,
        stage: Stage.LOADED,
        followUpKKAdmins: payload.data
      };
    case TYPES.RESET_FOLLOWUP_KK_LIST:
      return initialState;
    case TYPES.ERROR_SAMPARK_ADMIN_DATA:
      return {
        ...state,
        stage: Stage.LOAD_ERROR
      };
    case TYPES.GET_FOLLOW_UP_CONTACTS_BY_KK:
      return {
        ...state,
        contactsStage: Stage.LOADING
      };
    case TYPES.SET_FOLLOW_UP_CONTACTS_BY_KK:
      return {
        ...state,
        contactsStage: Stage.LOADED,
        followUpContacts: payload.data
      };
    case TYPES.RESET_FOLLOW_UP_CONTACTS_BY_KK:
      return initialState;
    case TYPES.ERROR_FOLLOW_UP_CONTACTS_BY_KK:
      return {
        ...state,
        contactsStage: Stage.LOAD_ERROR
      };
    default:
      return state;
  }
};

export default reducer;
