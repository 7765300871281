import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ days: number | undefined; fontSize: number | undefined }>()(
  (theme, { days, fontSize }) => ({
    holdStatusIndicator: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        fontSize: getFontSize(fontSize, true)
      },
      textAlign: 'center',
      padding: '0px 1px',
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      color: '#E8EBF4',
      ...{ fontSize: getFontSize(fontSize) },
      ...{ backgroundColor: getBackgroundColor(days) }
    }
  })
);
const getFontSize = (fontSize: number | undefined, mobile?: boolean) => {
  if (fontSize) {
    return fontSize;
  }
  if (mobile) {
    return 8;
  }
  return 10;
};

const getBackgroundColor = (days: number | undefined) => {
  if (days && days > 1) {
    return '#df942d';
  }
  return '#C84240';
};
