import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import http from '../../../../helpers/http';
import { API } from '../../../../config/config.constants';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import SelectedKKRow from './SelectedKK';
import AssignKK from './AssignKK';

const useStyles = withStyles({
  alert: {
    color: '#A02D1C',
    padding: '1rem',
    borderRadius: '3px',
    backgroundColor: '#FFEAE7',
    marginBottom: '1.5rem'
  },
  submitButton: {
    backgroundColor: '#FF5A52',
    borderRadius: '3px',
    padding: '14px 16px',
    minWidth: '147px',
    color: 'white',
    fontSize: '16px',
    textTransform: 'none',
    fontFamily: 'Rubik',
    '&:hover': {
      backgroundColor: '#ff2e24'
    }
  },
  submitButtonDisabled: {
    backgroundColor: '#EBF0F5',
    color: '#899EB0',
    cursor: 'not-allowed'
  }
});

function FollowUpModal (props) {
  const {
    classes,
    onClose,
    selectedContacts
  } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [selectedKK1, setSelectedKK1] = useState(null);
  const [selectedKK2, setSelectedKK2] = useState(null);
  // from API
  const [listOfKaryakartas, setListOfKaryakartas] = useState([]);

  useEffect(() => {
    const singleContactSelected = selectedContacts.length === 1;

    if (singleContactSelected) {
      const contact = selectedContacts[0];
      const { followUp } = contact;
      if (followUp.length > 0) {
        const [ followUp1, followUp2 ] = followUp;

        if (followUp1) {
          setSelectedKK1({ ...followUp1, memberID: followUp1._id });
        }
        if (followUp2) {
          setSelectedKK2({ ...followUp2, memberID: followUp2._id });
        }
      }
    }

    // Fire API to get the list of karyakartas to select
    getActiveKaryakartasAPI();

    return () => setListOfKaryakartas([]);
  }, []);

  async function getActiveKaryakartasAPI () {
    return http
      .get(API.CONTACTS_VIEW.GET_ACTIVE_KARYAKARTAS)
      .then((response) => {
        const data = response.data;
        if (data) {
          setListOfKaryakartas(data);
          setIsLoading(false);
        } else {
          // Handle error
          setIsLoading(false);
          setListOfKaryakartas([]);
        }
      })
      .catch(({ error }) => {
        // Handle error
        setIsLoading(false);
        setListOfKaryakartas([]);
        throw error;
      });
  }

  function isKKSelected (kk) {
    return (
      (selectedKK1 && selectedKK1._id === kk._id) ||
     (selectedKK2 && selectedKK2._id === kk._id)
    );
  }

  return (
    <Fragment>
      <DialogTitle>Assign Follow-up Karyakarta</DialogTitle>
      <DialogContent>
        <DialogContentText style={{marginBottom: '1rem'}}>
          Pick two follow-up karyakarta for selected contacts. These follow-up
          karyakarta will be assigned to all the selected contacts below.
        </DialogContentText>
        <div className={classes.alert}>
          Any changes may overwrite previous selections on confirmation.
        </div>
        {/* Search Lists */}
        {
          isLoading
            ? <LoadingSpinner />
            : (<Fragment>
              {selectedKK1
                ? <SelectedKKRow
                  selectedKK={selectedKK1}
                  onClear={() => {
                    setSelectedKK1(null);
                  }}
                />
                : <AssignKK
                  isKKSelected={isKKSelected}
                  listOfKaryakartas={listOfKaryakartas}
                  setSelectedKK={setSelectedKK1}
                  kkNumber={'1'}
                />
              }
              {selectedKK2
                ? <SelectedKKRow
                  selectedKK={selectedKK2}
                  onClear={() => {
                    setSelectedKK2(null);
                  }}
                />
                : <AssignKK
                  isKKSelected={isKKSelected}
                  listOfKaryakartas={listOfKaryakartas}
                  setSelectedKK={setSelectedKK2}
                  kkNumber={'2'}
                />
              }
            </Fragment>)
        }
      </DialogContent>
      <DialogActions>
        <Button
          disableRipple
          className='button-primary cancel-button'
          onClick={() => onClose()}
        >
          <span className='button-primary-label'>Cancel</span>
        </Button>
        <Button
          disableRipple
          classes={{
            root: classes.submitButton,
            disabled: classes.submitButtonDisabled
          }}
          disabled={isLoading || (!selectedKK1 && !selectedKK2)}
          onClick={() => onClose([selectedKK1, selectedKK2].filter(kk => kk))}
        >
          Confirm
        </Button>
      </DialogActions>
    </Fragment>
  );
}
FollowUpModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  selectedContacts: PropTypes.arrayOf(PropTypes.object).isRequired
};
export default useStyles(FollowUpModal);
