import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, FieldArray, formValueSelector, change } from 'redux-form';
import { Radio, FormControlLabel, Chip } from '@material-ui/core';
import * as FORMS from '../../../constants/forms';
import icon_radio_selected_small from '../../../assets/icons/icon_radio_selected_small.svg';
import icon_radio_unselected_small from '../../../assets/icons/icon_radio_unselected_small.svg';
import { calculateAge } from '../../../util/calculateAge';
import { normalizePhone } from '../../../util/normalizePhone';
import {
  renderTextField,
  renderRadioGroup,
  renderInterests,
  renderDatePicker,
  renderSelectField,
  renderAddressField,
  renderTextAreaField,
  renderMaskedTextField,
  CheckboxSelectField,
  renderCheckboxField
} from '../../form-fields';
import validate from '../validate';
import { STATES } from '../../../assets/data/us_states';
import { buildNameValueArray, mapObjectToOptionValueArray } from '../../../util/contactUtils';
import { checkUserPermission, CANEDITADMINATTRIBUTES, ISSUPERADMIN } from '../../../util/userPermission';
import ChipList from '../../ChipList';
import { renderAdditionalEmail } from '../../form-fields/renderAdditionalEmail';

const buildAttributeOptions = (tags, attributeName) => {
  if (!tags || tags.length === 0) {
    return {
      name: attributeName,
      value: []
    };
  }
  return {
    name: attributeName,
    value: tags.map(attr => ({
      name: attr.name,
      value: attr.id,
      description: attr.description
    }))
  };
};

let PersonalInfoSection = (props) => {
  let { muktTypes, maritalStatus, attributes, mandals, adminAttributes } = props.contactOptions;
  const { isSuperAdmin, initialValues, selectedValues, canEditAdminAttributes } = props;
  const { birthDate, gender, tags } = selectedValues;
  const { audiences, mandal } = initialValues;
  const adminAttributesArray = initialValues.tags.adminAttributes;

  const clearMuktType = () => {
    props.change('muktType', []);
  };
  const clearMandalType = () => {
    props.change('mandal', '')
  }

  const genderOptions = ['Male', 'Female'];

  const muktTypeCategory = useMemo(() => {
    if (genderOptions.includes(gender)) {
      return buildNameValueArray(muktTypes[gender.toLowerCase()]);
    }
    // when gender is not selected by superadmin, we need return mukt type null
    return [];
  }, [isSuperAdmin, gender, muktTypes]);

  const mandalOptions = useMemo(() => {
    if (genderOptions.includes(gender)) {
      return mapObjectToOptionValueArray(mandals[gender.toLowerCase()])
    }
    // when gender is not selected by superadmin, we need return mukt type null
    return [];
  }, [isSuperAdmin, gender, mandals]);

  const isNewContact = initialValues.isNewContact;
  const disableGender = !isNewContact && !isSuperAdmin;
  const tagOptions = buildAttributeOptions(attributes, "Attributes");
  const adminTagOptions = buildAttributeOptions(adminAttributes, "Admin Attributes");
  let contactAge;
  let isChild = false;
  const kishorAgeLimit = [6, 14];
  if (dayjs(birthDate).isValid()) {
    contactAge = calculateAge(birthDate);
    if (contactAge > kishorAgeLimit[0] && contactAge < kishorAgeLimit[1]) {
      isChild = true;
    }
  }
  let childOption;
  if (contactAge > kishorAgeLimit[0] && contactAge < kishorAgeLimit[1] && muktTypeCategory && isChild) {
    childOption = (gender === 'Male') ? [{ name: 'Kishore', value: 'Kishore' }] : [{ name: 'Kishori', value: 'Kishori' }];
  }
  return (
    <Fragment>
      <div
        className='personal-info-section'>
          <div className='field-reference'>
          <Field
            label='No Reference'
            name='noReference'
            component={renderCheckboxField}>
          </Field>
          </div>
        <div
          className='field-birthday'>
          <Field
            label='Birthday'
            name='birthDate'
            maxDate={dayjs().format('YYYY-MM-DD')}
            component={renderDatePicker}
            placeholder='mm/dd/yyyy'
          />
        </div>
        <div
          className='field-gender'>
          <Field
            required
            label='Gender'
            name='gender'
            component={renderRadioGroup}>
            {genderOptions.map((option, index) => {
              return (
                <FormControlLabel
                  key={index}
                  value={option}
                  disabled={disableGender || props.myUserAccount}
                  onChange={clearMuktType}
                  control={<Radio
                    disableRipple
                    checkedIcon={<img src={icon_radio_selected_small} />}
                    icon={<img src={icon_radio_unselected_small} />} />}
                  label={option} />
              );
            })}
          </Field>
        </div>
        <div
          className='field-email'>
          <Field
            required
            label='Email Address'
            name='contactDetails.email'
            component={renderTextField}
            placeholder='Enter email address'
            readOnly={props.myUserAccount}
          />
        </div>
        <div
          className='field-email'>
          <FieldArray
            label='Additional Email(s)'
            name='contactDetails.additionalEmails'
            component={renderAdditionalEmail}
            placeholder='Enter additional email addresses'
          />
        </div>
      
        <div
          className='field-phone-cell'>
          <Field
            required
            label='Cell Number'
            name='contactDetails.cell'
            placeholder='Enter cell number'
            mask='(999) 999 9999'
            normalize={normalizePhone}
            component={renderMaskedTextField} />
        </div>
        <div
          className='field-phone-home'>
          <Field
            label='Home Number'
            name='contactDetails.home'
            mask='(999) 999 9999'
            component={renderMaskedTextField}
            normalize={normalizePhone}
            placeholder='Enter home number'
          />
        </div>
        <div
          className='field-address-1'>
          <Field
            label='Address 1'
            name='addressDetails.address1'
            component={renderAddressField}
            placeholder='Address 1'
          />
        </div>
        <div
          className='field-address-2'>
          <Field
            label='Address 2'
            name='addressDetails.address2'
            component={renderTextField}
            placeholder='Address 2'
          />
        </div>
        <div
          className='field-address-city'>
          <Field
            required
            label='City'
            name='addressDetails.city'
            component={renderTextField}
            placeholder='Enter Address'
          />
        </div>
        <div className='field-address-state'>
          <Field
            required
            label='State'
            name='addressDetails.state'
            component={renderSelectField}
            placeholder='State'
            options={STATES}
          />
        </div>
        <div
          className='field-address-zip'>
          <Field
            label='Zip Code'
            name='addressDetails.zip'
            component={renderTextField}
            placeholder='Zip Code'
          />
        </div>
        <div
          className='field-marriage'>
          <Field
            label='Marital Status'
            name='maritalStatus'
            component={renderRadioGroup}>
            {maritalStatus.map((option, index) => {
              return (
                <FormControlLabel
                  key={index}
                  value={option}
                  control={<Radio
                    disableRipple
                    checkedIcon={<img src={icon_radio_selected_small} />}
                    icon={<img src={icon_radio_unselected_small} />} />}
                  label={option} />
              );
            })}
          </Field>
        </div>
        <div className='field-mandal'>
          <Field
            label='Mandal'
            name='mandal'
            component={renderSelectField}
            placeholder='Select Mandal'
            options={mandalOptions}
            onClearClicked={clearMandalType}
            value={mandal}
            readOnly={(!isSuperAdmin && props.myUserAccount) || !mandalOptions}
          />
        </div>
        <div
          className='field-mukt-type'>
          <Field
            label='Mukt Type (max 5)'
            name='muktType'
            component={CheckboxSelectField}
            lockSelected={isChild}
            displaySelectedCount
            renderSelectedChipsBelow
            placeholder='Mukt Type'
            buttonLabel={`Mykt Type(s) selected`}
            options={[getMuktTypeOptions(childOption, muktTypeCategory)]}
            maxSelect={5}
            multiple
            readOnly={(!isSuperAdmin && props.myUserAccount) || !muktTypeCategory}
          />
        </div>
        <div
          className='field-attributes-selector'>
          <Field // checkbox
            label='Attributes'
            options={[tagOptions]}
            name='tags.attributes'
            maxSelect
            displaySelectedCount
            buttonId='field-attribute-selector'
            dropdownMaxHeight={465}
            buttonLabel={`attribute(s) selected`}
            component={CheckboxSelectField}
            renderSelectedChipsBelow
          />
        </div>
        <div
          className='field-admin-attributes-selector'>
          {canEditAdminAttributes ? <Field // checkbox
            label='Admin Attributes'
            options={[adminTagOptions]}
            name='tags.adminAttributes'
            displaySelectedCount
            buttonId='field-attribute-selector'
            dropdownMaxHeight={465}
            maxSelect
            buttonLabel={`admin attribute(s) selected`}
            component={CheckboxSelectField}
            renderSelectedChipsBelow
          /> : null}
          {/* HOT fix admin attributes need hidden */}
          {/* <ChipList label={'Admin Attributes'} list={getAdminAtrributes(adminAttributesArray)} /> */}
        </div>
        {(isSuperAdmin && audiences) ? (<div className='field-audiences-display'>
          <ChipList label={'Audiences'} list={audiences} />
        </div>) : null}
        {!props.myUserAccount ? <div
          className='field-notes'>
          <Field
            label='Note'
            name='reference.notes'
            component={renderTextAreaField}
            placeholder='Eg: who referred them or what is their connection'
          />
        </div> : null
        }
        <div
          className='field-interests padding-sm-bottom'>
          <FieldArray
            label='Interests (Max 5 seperated by comma)'
            name='interests'
            component={renderInterests}
            placeholder='Ex: music, singing etc.'
          />
        </div>
      </div>
    </Fragment>
  );
};

function getMuktTypeOptions(childOption, muktTypeCategory) {
  const value = childOption || muktTypeCategory;
  return { name: 'Mukt Type', value, type: 'option', group: 'Mukt Type' };
}

function getAdminAtrributes(adminTagOptions) {
  if (adminTagOptions && Array.isArray(adminTagOptions)) {
    return adminTagOptions.map((option) => option.name);
  }
  return [];
}

function renderAttributeValues(attributeValues) {
  if (attributeValues && attributeValues.length) {
    return attributeValues.map((tag, index) => {
      return (
        <Chip
          key={index}
          className='chip chip-attributes'
          label={<span className='chip-label'>{tag.name}</span>}
        />
      );
    });
  }
}

function mapStateToProps(state, props) {
  return {
    contactOptions: state.contactsData.contactOptions,
    form: props.myUserAccount ? FORMS.USER_PROFILE_FORM : FORMS.CONTACT_INFO,
    selectedValues: formValueSelector(props.myUserAccount ? FORMS.USER_PROFILE_FORM : FORMS.CONTACT_INFO)(state, 'birthDate', 'addressDetails', 'gender', 'tags'),
    isSuperAdmin: checkUserPermission(state.userAccess, ISSUPERADMIN),
    canEditAdminAttributes: checkUserPermission(state.userAccess, CANEDITADMINATTRIBUTES)
  };
}

export default compose(
  connect(mapStateToProps, { change }),
  reduxForm({
    validate,
    destroyOnUnmount: false
  })
)(PersonalInfoSection);

PersonalInfoSection.propTypes = {
  change: PropTypes.func.isRequired,
  contactOptions: PropTypes.object,
  initialValues: PropTypes.object.isRequired,
  myUserAccount: PropTypes.bool,
  selectedValues: PropTypes.object,
  isSuperAdmin: PropTypes.bool.isRequired,
  canEditAdminAttributes: PropTypes.bool.isRequired
};
