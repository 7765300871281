import { registrationActions } from '../constants/actions';

export default function registrationReducer (state = { submitted: false, storedInvitedUser: {} }, action) {
  switch (action.type) {
    case registrationActions.SIGNUP_USER: // if user submits form successfully, set submitted to true
      return { ...state, submitted: true };
    case registrationActions.STORE_INVITED_USER:
      return { ...state, storedInvitedUser: action.payload };
    default:
      return state;
  }
}
