import { readingViewActions } from '../constants/actions';

export default function readingViewReducer (
  state = { all: { data: [], columns: [] }, prasangs: { data: [], columns: [] }, words: { data: [], columns: [] }, stories: { data: [], columns: [] }, SUTRA: { data: [], columns: [] }, showLoader: true },
  action) {
  switch (action.type) {
    case readingViewActions.GET_READING_DATA_ALL:
      return { ...state, all: action.value };
    case readingViewActions.GET_READING_DATA_PRASANG:
      return { ...state, prasangs: action.value };
    case readingViewActions.GET_READING_DATA_WORDS:
      return { ...state, words: action.value };
    case readingViewActions.GET_READING_DATA_SUTRO:
      return { ...state, SUTRA: action.value };
    case readingViewActions.GET_READING_DATA_STORIES:
      return { ...state, stories: action.value };
    case readingViewActions.SHOW_READING_LOADER:
      return { ...state, showLoader: true };
    case readingViewActions.HIDE_READING_LOADER:
      return { ...state, showLoader: false };
    default:
      return state;
  }
};
