import { Button, styled } from '@mui/material';
import Color from 'color';

export const StyledPrimaryButton = styled(Button, { name: 'StyledButton' })(({ theme }) => ({
  background: '#FF5A52',
  borderRadius: '7px',
  color: 'white',
  fontSize: '16px',

  [theme.breakpoints.up('xs')]: {
    padding: '12px 22px'
  },

  '&:hover': {
    background: Color('#FF5A52').darken(0.2).hex()
  }
}));
