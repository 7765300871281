import { getEvents, uploadEventImagesAPI, addImageGalleryToEventAPI } from '../apis/eventAPIs';
import { commonActions, eventActions } from '../constants/actions';
import { EVENTS_DATA_ERROR, EVENTS_IMAGES_UPLOAD_ERROR, EVENTS_IMAGES_UPLOAD_SUCCESS } from '../constants/toasterMessages';

export function getEventsData () {
  return async (dispatch) => {
    dispatch({ type: eventActions.SHOW_HIDE_EVENTS_LOADER, value: true });

    const eventsData = await getEvents();
    if (eventsData && eventsData instanceof Array) {
      dispatch({ type: eventActions.SHOW_HIDE_EVENTS_LOADER, value: false });
      return dispatch({ type: eventActions.GET_EVENTS_DATA, value: eventsData });
    } else {
      dispatch({ type: eventActions.SHOW_HIDE_EVENTS_LOADER, value: false });
      return dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: EVENTS_DATA_ERROR, type: commonActions.ERROR_TOASTER } });
    }
  };
}

export function uploadEventImages (eventID, eventTitle, eventImages) {
  return async (dispatch) => {
    if (eventImages && eventImages.length) {
      dispatch({ type: commonActions.DISPLAY_GLOBAL_LOADER });
      const imagesUploadedSuccessfully = new Array(eventImages.length).fill(false);
      const eventImageGalleryObject = {
        title: eventTitle,
        images: []
      };
      for (let i = 0; i < eventImages.length; i++) {
        const imageFormData = new FormData();
        imageFormData.append('files', eventImages[i]);

        const uploadImageResults = await uploadEventImagesAPI(eventID, imageFormData);
        if (uploadImageResults) {
          if (!uploadImageResults.error && uploadImageResults.files && uploadImageResults.files.length) {
            imagesUploadedSuccessfully[i] = true;
            eventImageGalleryObject.images.push(uploadImageResults.files[0]);
            const allImagesUploadedSuccessfully = imagesUploadedSuccessfully.every(item => item === true);
            if (allImagesUploadedSuccessfully === true) {
              const updateEventWithGalleryResults = await addImageGalleryToEventAPI(eventID, eventImageGalleryObject);
              if (!updateEventWithGalleryResults.error) {
                const eventsData = await getEvents();
                if (eventsData && eventsData instanceof Array) {
                  dispatch({ type: eventActions.GET_EVENTS_DATA, value: eventsData });
                  dispatch({ type: commonActions.HIDE_GLOBAL_LOADER });
                  dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: EVENTS_IMAGES_UPLOAD_SUCCESS, type: commonActions.SUCCESS_TOASTER } });
                  dispatch({ type: commonActions.CLOSE_MODAL });
                } else {
                  dispatch({ type: commonActions.HIDE_GLOBAL_LOADER });
                  return dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: EVENTS_DATA_ERROR, type: commonActions.ERROR_TOASTER } });
                }
              } else {
                dispatch({ type: commonActions.HIDE_GLOBAL_LOADER });
                dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: EVENTS_IMAGES_UPLOAD_ERROR, type: commonActions.ERROR_TOASTER } });
              }
            }
          } else {
            dispatch({ type: commonActions.HIDE_GLOBAL_LOADER });
            dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: EVENTS_IMAGES_UPLOAD_ERROR, type: commonActions.ERROR_TOASTER } });
          }
        }
      }
    }
  };
}
