import { ChangeEvent, FC, useState } from 'react';
import { Button, Tabs, Tab } from '@material-ui/core';
import { MessageList } from './MessageList';
import { EBroadcastViewTabs, IBroadcastMessage, ITabLabelProps } from './BroadcastView.types';
import { BroadcastViewTabs } from './constants';

const TabLabel: React.FC<ITabLabelProps> = ({ isActive, label }: ITabLabelProps) => (
  <div
    style={{
      color: isActive ? '#067bc2' : '#2a3d4c',
      fontWeight: '500'
    }}
  >
    {label}
  </div>
);

export const BroadcastMessagesView: FC<{
  allMessages: IBroadcastMessage[];
  getBroadcastApprovalData: () => (dispatch: any) => Promise<any>;
  getBroadcastData: () => (dispatch: any) => Promise<any>;
  pendingMessages: IBroadcastMessage[];
  handleClickedNewMessage: () => void;
  onClickViewMessage: (message: any) => void;
}> = ({
  allMessages,
  getBroadcastApprovalData,
  getBroadcastData,
  pendingMessages,
  handleClickedNewMessage,
  onClickViewMessage
}) => {
  const [activeTab, setActiveTab] = useState<EBroadcastViewTabs>(EBroadcastViewTabs.MY_MESSAGES);

  const handleTabChange = (_: ChangeEvent<any>, activeTab: number): void => {
    if (activeTab === EBroadcastViewTabs.MY_MESSAGES) {
      getBroadcastData();
    } else if (activeTab === EBroadcastViewTabs.APPROVAL_MESSAGES) {
      getBroadcastApprovalData();
    }
    setActiveTab(activeTab);
  };

  return (
    <div className="broadcast-view-container">
      <header className="broadcast-header">
        <div className="broadcast-header-row1">
          <h2 className="broadcast-title">Broadcast to Sambandh</h2>
          <div>
            <Button
              disableRipple
              onClick={handleClickedNewMessage}
              className="button-primary button-orange"
              color="primary"
            >
              <span className="button-primary-label label-white">New Message</span>
            </Button>
          </div>
        </div>
        <Tabs className="broadcast-tabs" value={activeTab} onChange={handleTabChange}>
          {BroadcastViewTabs.map((tab) => {
            return (
              <Tab
                key={tab.value}
                value={tab.value}
                disableRipple
                label={<TabLabel isActive={activeTab === tab.value} label={tab.label} />}
              />
            );
          })}
        </Tabs>
      </header>
      <section className="broadcast-data-container">
        {activeTab === EBroadcastViewTabs.MY_MESSAGES && (
          <div className="active-messages-container">
            <MessageList messages={allMessages} onClickViewMessage={onClickViewMessage} />
          </div>
        )}
        {activeTab === EBroadcastViewTabs.APPROVAL_MESSAGES && (
          <MessageList messages={pendingMessages} onClickViewMessage={onClickViewMessage} />
        )}
      </section>
    </div>
  );
};
