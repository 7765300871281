import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  bindActionCreators,
  compose
} from 'redux';
import { Redirect } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  Dialog
} from '@material-ui/core';
import AddSabhaModal from './AddSabhaModal';
import { ArrowLeft } from '../../v2/components/svg-icons';
import { AvatarWrapper } from '../../components/Avatar/AvatarWrapper';
import { FOLLOW_UP } from '../../constants/routes';
import { commonActions } from '../../constants/actions';
import { ActionCreators } from './followUpDetails.ducks';
import { oneOf, isDone, isUpdateError } from '../../util/stage';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { Toaster } from '../../components/Toaster/Toaster';
import formatPhoneNumber from '../../util/formatPhoneNumber';
import { ProfileBadge } from '../../v2/components/profileBadge';
import { isMobileDevice } from '../../util/windowUtils';

const useStyles = withStyles({
  container: {
    margin: '0rem auto',
    maxWidth: '650px'
  },
  view: {
    margin: '1.5rem'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.5rem 0'
  },
  title: {
    fontSize: '24px',
    color: '#2A3D4C',
    fontWeight: '500'
  },
  controls: {
    margin: 'auto 0'
  },
  listItemText: {
    textTransform: 'capitalize'
  },
  avatar: {
    marginRight: '19px'
  },
  name: {
    textTransform: 'capitalize',
    fontSize: '18px',
    fontWeight: '500'
  },
  nickName: {
    textTransform: 'capitalize'
  },
  nameContainer: {
    marginBottom: '1rem'
  },
  sectionTitle: {
    fontSize: '14px',
    color: '#899EB0'
  },
  contactDetail: {
    fontSize: '18px',
    color: '#067BC2'
  },
  infoText: {
    fontSize: '18px',
    textTransform: 'capitalize'
  },
  muktType: {
    backgroundColor: '#FFF3D2',
    borderRadius: '3px',
    padding: '4px 8px',
    color: '#9E7726'
  },
  section: {
    borderBottom: '1px solid #EBF0F5',
    marginBottom: '24px',
    paddingBottom: '16px'
  },
  userInfo: {
    marginBottom: '30px',
    display: 'flex'
  },
  addSabhaButton: {
    textTransform: 'capitalize',
    color: '#067BC2',
    border: '1px solid #067BC2',
    height: '36px',
    fontFamily: 'Rubik'
  },
  addSabhaButtonMini: {
    color: '#899EB0',
    border: '1px solid #C3D2DF',
    backgroundColor: 'white',
    height: '36px',
    fontWeight: 'bold',
    minWidth: '36px',
    fontFamily: 'Rubik'
  },
  tagRow: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px'
  },
  dialogRoot: {
    padding: '2rem'
  },
  sabhaTag: {
    color: '#2256BF',
    backgroundColor: '#DDE9FD',
    height: '36px',
    padding: '8px',
    fontSize: '16px',
    borderRadius: '3px',
    width: 'fit-content',
    marginRight: '8px',
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  errorBanner: {
    backgroundColor: '#D63230',
    color: 'white',
    padding: '1rem 1.5rem'
  },
  ellipse: {
    width: '3px',
    height: '3px',
    borderRadius: '50%',
    background: '#899EB0',
    marginRight: '2px'
  },
  back: {
    cursor: 'pointer',
    '&:hover': {
      fill: '#067BC2'
    }
  },
  errorParent: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

dayjs.extend(utc);

function FollowUpDetails (props) {
  const {
    classes,
    history,
    actions,
    stage,
    contact,
    updateStage,
    followUpIdProps = '',
    useRoutes = true
  } = props;

  const {
    firstName,
    lastName,
    nickName,
    contactDetails,
    addressDetails,
    muktType,
    birthDate,
    followUpKaryakartas = [],
    followUp = [],
    tags,
  } = contact;
  const followUpKKs = followUpKaryakartas.length > 0 ? followUpKaryakartas : followUp;

  const followUpTags = (tags && tags.followUp) ? tags.followUp : [];
  const attributeTags = (tags && tags.attributes) ? tags.attributes : [];
  let followUpId = null;
  if (useRoutes) {
    if (!history.location.state || !history.location.state.followUpId) {
      return <Redirect to='/sampark/followup' />;
    }
    followUpId = history.location.state.followUpId;
  } else {
    followUpId = followUpIdProps;
  }

  const [ openSabhaDialog, setOpenSabhaDialog ] = useState(false);
  const [ showToaster, setShowToaster ] = useState(false);
  const [ toasterData, setToasterData ] = useState({});

  const isMobile = isMobileDevice();

  const hideToaster = () => {
    setShowToaster(false);
  };

  useEffect(() => {
    actions.fetchFollowUpDetails(followUpId);
    return () => actions.resetFollowUpDetails();
  }, []);

  useEffect(() => {
    if (isDone(updateStage)) {
      setOpenSabhaDialog(false);
      actions.fetchFollowUpDetails(followUpId);
      setToasterData({
        toasterMessageType: commonActions.SUCCESS_TOASTER,
        toasterMessage: 'Successfully updated sabha tags'
      });
      setShowToaster(true);
    };
    if (isUpdateError(updateStage)) {
      setToasterData({
        toasterMessageType: commonActions.ERROR_TOASTER,
        toasterMessage: 'Error updating sabha tags'
      });
      setShowToaster(true);
    }
  }, [updateStage]);

  const handleSubmit = (data) => {
    actions.updateSabhaTags(data, followUpId);
  };

  const formattedBirthday = dayjs(birthDate).utc().format('MMMM DD, YYYY');
  if (updateStage === 'LOAD_ERROR' && classes) {
    return (<div className={classes.errorParent}><div>You are unauthorized to view this Karyakarta</div></div>);
  }
  return (
    <Fragment>
      {
        (isDone(stage) && (!followUpTags || !followUpTags.length)) ? <div className={classes.errorBanner}>
          A sabha tag is required for all contacts.
        </div> : null
      }
      <div className={classes.container}>
        <div className={classes.view}>
          <div className={classes.header}>
            <div className={classes.controls}>
              {useRoutes ? (<ArrowLeft
                className={classes.back}
                onClick={() => history.push(FOLLOW_UP.value)}
              />) : (null)
              }
            </div>
          </div>
          {
            oneOf('READY', 'LOADING')(stage)
              ? (
                <LoadingSpinner />
              ) : (
                <Grid container spacing={0}>
                  <div className={classes.userInfo}>
                    <AvatarWrapper classes={[classes.avatar]} size={'100'} contact={contact} />
                    <div>
                      <div className={classes.nameContainer}>
                        <span className={classes.name}>
                          {firstName} {lastName}
                        </span>
                        <br />
                        <span className={classes.nickName}>
                          {nickName}
                        </span>
                      </div>
                      <div>
                        {
                          followUpTags && followUpTags.length
                            ? (
                              <div className={classes.tagRow}>
                                {
                                  followUpTags.map(tag => (<div
                                    className={classes.sabhaTag}
                                    key={tag}
                                  >
                                    {tag}
                                  </div>))
                                }
                                <Button
                                  variant='outlined'
                                  className={classes.addSabhaButtonMini}
                                  disabled={!useRoutes}
                                  onClick={() => setOpenSabhaDialog(true)}
                                >
                                  <div className={classes.ellipse}></div>
                                  <div className={classes.ellipse}></div>
                                  <div className={classes.ellipse}></div>
                                </Button>
                              </div>
                            ) : (
                              <Button
                                variant='outlined'
                                disabled={!useRoutes}
                                className={classes.addSabhaButton}
                                onClick={() => setOpenSabhaDialog(true)}
                              >+Add a sabha tag
                              </Button>
                            )
                        }
                      </div>
                    </div>
                  </div>
                  <Grid item container spacing={1} className={classes.section}>
                    <Grid item xs={12} className={classes.sectionTitle}>
                      Contact Info
                    </Grid>
                    <Grid item xs={12} container spacing={1}>
                      {contactDetails.phones
                        .filter(phone => phone.value)
                        .map(phone => {
                          return (
                            <Grid item container key={phone.value}>
                              <Grid item xs={4} className={classes.infoText}>{phone.type}</Grid>
                              <Grid item xs={8} className={classes.contactDetail}>
                                <a href={`tel:${phone.value}`}>{formatPhoneNumber(phone.value)}</a>
                              </Grid>
                            </Grid>
                          );
                        })}
                    </Grid>
                    <Grid item container>
                      <Grid item xs={4} className={classes.infoText}>Email</Grid>
                      <Grid item xs={8} className={classes.contactDetail}>
                        <a href={`mailto:${contactDetails.email}`}>{contactDetails.email}</a>
                      </Grid>
                    </Grid>
                    {/* We will only show additional emails if they exist */}
            {contactDetails.additionalEmails && (
              <Grid item container>
                <Grid item xs={4} className={classes.infoText}>
                  Additional Emails
                </Grid>
                <div>
                  {contactDetails.additionalEmails?.map((email: string, index: number) => (
                    <Grid key={index} item xs={8} className={classes.contactDetail}>
                      <a href={`mailto:${email}`}>{email}</a>
                    </Grid>
                  ))}
                </div>
              </Grid>
            )}
                  </Grid>
                  <Grid item container spacing={1} className={classes.section}>
                    <Grid item xs={12} className={classes.sectionTitle}>
                      Address
                    </Grid>
                    <Grid item xs={12} className={classes.infoText}>
                      {addressDetails.address1}
                      <br />
                      {addressDetails.city}, <span style={{textTransform: 'uppercase'}}>{addressDetails.state}</span> {addressDetails.zip}
                      <br />
                      <span style={{textTransform: 'uppercase'}}>{addressDetails.country}</span>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={1} className={classes.section}>
                    <Grid item xs={12} className={classes.sectionTitle}>
                      Follow-up Karyakarta(s)
                    </Grid>
                    {
                      followUpKKs.map(followUpMember => {
                        if (!followUpMember) {
                          return null;
                        }
                        const { firstName, lastName, addressDetails } = followUpMember;
                        const name = `${firstName} ${lastName}`;
                        let location;
                        if (addressDetails && addressDetails.city && addressDetails.state) {
                          location = `${addressDetails.city}, ${addressDetails.state.toUpperCase()}`;
                        }
                        return (
                          <Grid container item xs={12} className={classes.infoText} key={followUpMember._id}>
                            <ProfileBadge
                              primaryText={name}
                              secondaryText={location}
                              contact={followUpMember}
                            />
                          </Grid>
                        );
                      })
                    }
                  </Grid>
                  <Grid item container spacing={1} className={classes.section}>
                    <Grid item xs={12} className={classes.sectionTitle}>
                      Mukt Type
                    </Grid>
                    <Grid item xs={12}>
                      {muktType.map(type => {
                        return (
                          <span className={classes.muktType} key={type}>
                            {type}
                          </span>
                        );
                      })}
                    </Grid>
                  </Grid>
                  <Grid item container spacing={1} className={classes.section}>
                    <Grid item xs={12} className={classes.sectionTitle}>
                      Attributes
                    </Grid>
                    <Grid item xs={12} className={classes.tagRow}>
                      {attributeTags.map(type => {
                        return (
                          <span className={classes.muktType} key={type}>
                            {type}
                          </span>
                        );
                      })}
                    </Grid>
                  </Grid>
                  <Grid item container spacing={1} className={classes.section}>
                    <Grid item xs={12} className={classes.sectionTitle}>
                      Birthdate
                    </Grid>
                    <Grid item xs={12} className={classes.infoText}>
                      {formattedBirthday}
                    </Grid>
                  </Grid>
                </Grid>
              )
          }
          <Dialog
            fullScreen={isMobile}
            open={openSabhaDialog}
            aria-labelledby='responsive-dialog-title'>
            <AddSabhaModal
              onClose={() => setOpenSabhaDialog(false)}
              onCancel={() => setOpenSabhaDialog(false)}
              onSubmit={handleSubmit}
              addressDetails={addressDetails}
              contactTags={followUpTags}
            />
          </Dialog>
          <Toaster
            displayToaster={showToaster}
            toasterMessage={toasterData.toasterMessage}
            toasterMessageType={toasterData.toasterMessageType}
            hideToaster={hideToaster}
          />
        </div>
      </div>
    </Fragment>
  );
}

FollowUpDetails.propTypes = {
  contact: PropTypes.object.isRequired,
  stage: PropTypes.symbol.isRequired,
  classes: PropTypes.object.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  history: PropTypes.object,
  updateStage: PropTypes.symbol.isRequired,
  useRoutes: PropTypes.bool,
  followUpIdProps: PropTypes.string
};

const mapStateToProps = ({followUpDetails}) => ({
  ...followUpDetails
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch)
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  useStyles
)(FollowUpDetails);
