import { styled } from '@mui/material';

export const StyledPageHeaderContainer = styled('section', { name: 'StyledPageHeaderContainer' })({
  alignItems: 'flex-end',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '1rem'
});

export const StyledContactsCount = styled('span', { name: 'StyledContactsCount' })(({ theme }) => ({
  color: '#899EB0',
  fontSize: '14px',
  [theme.breakpoints.up('sm')]: {
    fontSize: '20px'
  }
}));

export const StyledHeading = styled('h2', { name: 'StyledHeading' })({
  fontSize: '24px',
  color: '#2A3D4C',
  fontWeight: 500
});
