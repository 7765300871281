import { useState } from 'react';
import { ReactComponent as SearchIcon } from '../../../../../assets/icons/icon_search_grey.svg';
import CloseIcon from '@mui/icons-material/Close';
import { CreateAttributeModal } from '../Modal/CreateModalAttribute/CreateAttributeModal';
import { useAppDispatch, useAppSelector } from '../../../../../helpers/store';
import { resetAttribute } from '../../attributeAdmin.ducks';
import { useKeyPress } from '../../../../../v2/hooks/useKeyPress';
import { useStyles } from './attributeCompose.styles';
import { IAttributeCompose } from '../../attributes.types';

export const AttributeCompose = ({
  handleAttributeFilter,
  clearHandler,
  value
}: IAttributeCompose) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useAppDispatch();

  const { classes } = useStyles();
  const { allFields, allAttributeGroups } = useAppSelector((state) => state.admin.attribute);
  const groupList: any = [];
  allAttributeGroups?.forEach((groupName: any) => {
    groupList.push({ value: groupName, label: groupName });
  });

  const openModalHandler = () => {
    setIsModalOpen(true);
  };

  const closeModalHandler = () => {
    setIsModalOpen(false);
    dispatch(resetAttribute());
  };

  useKeyPress('Escape', clearHandler);

  return (
    <div className={classes.container}>
      <div className={classes.header}>My Attributes</div>
      <div className={classes.wrapper}>
        <label className={classes.fieldInput}>
          <SearchIcon className={classes.iconSearch} />
          <input
            className={classes.searchInput}
            placeholder="Search"
            value={value}
            onChange={(event) => handleAttributeFilter(event.target.value)}
          />
          <CloseIcon className={classes.iconClose} onClick={clearHandler} />
        </label>
        <button className={classes.button} onClick={openModalHandler}>
          New Attribute
        </button>
        {isModalOpen && (
          <CreateAttributeModal
            closeModalHandler={closeModalHandler}
            isModalOpen={isModalOpen}
            allFields={allFields}
            allAttributeGroups={groupList}
          />
        )}
      </div>
    </div>
  );
};
