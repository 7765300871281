import React from 'react';
import PropType from 'prop-types';

export default class Tag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      value
    } = this.props;

    return (
      <div className={'tag-container'}>
        <p className={'tag-value'}>{value}</p>
      </div>
    );
  }
};

Tag.propTypes = {
  value: PropType.string
};
