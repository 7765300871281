import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash/lang';
// Material UI
import { Menu, Checkbox, Button, ListItem } from '@material-ui/core';
// Project Files
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
// icons
import icon_select_arrow from '../../../assets/icons/icon_select_arrow.svg';
import icon_checked_small from '../../../assets/icons/icon_checked_small.svg';
import icon_unchecked_small from '../../../assets/icons/icon_unchecked_small.svg';
import { FieldLabel } from '../access-controls/FieldLabel';

/*
 * Needs a prop called 'options' of structure:
 * [
 *  {
 *    name: <NAME OF LIST>
 *    value: [
 *      {
 *        name: <option 1 label>,
 *        value: <option 1 value>
 *      },
 *      {
 *        name: <option 1 label>,
 *        value: <option 1 value>
 *      },
 *    ]
 *  }
 * ]
 */

export class MultiSelectorField extends Component {

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      checked: [],
      searchValue: '',
      selectAllSelected: false,
      isLoading: false,
      componentMounted: false,
      touched: false
    };
  }

  componentDidMount() {
    // if the checked values in state do not reflect the actual checked values
    if (!isEqual(this.props.input.value, this.state.checked)) {
      this.setState({
        checked: this.props.input.value,
        componentMounted: true
      });
    }
  }

  resetAllCheckedValues(form, name) {
    this.props.onChange(this.props.name, []);
  }

  componentDidUpdate(prevProps) {
    /* eslint-disable */

    // if it was not disabled and now it is disabled - uncheck all boxes
    if (!prevProps.disabled && this.props.disabled) {
      this.setState({
        checked: []
      }, this.resetAllCheckedValues);
    }
    // if the list of options changes, unselect all options
    if (prevProps.options && prevProps.options.length && this.props.options && this.props.options.length) {
      if ((prevProps.options.length !== this.props.options.length) && !isEqual(prevProps.options, this.props.options)) {

        const prevGroups = prevProps.options.map(group => group.name);
        const currGroups = this.props.options.map(group => group.name);

        const uniqGroups = currGroups.filter((name, i) => {
          if (prevGroups.indexOf(name) !== -1) return false;
          return true;
        });

        if (uniqGroups.length === 0) {
          this.setState({
            checked: []
          }, this.resetAllCheckedValues);
        }

      }
    }

    // if input value changes, change checked values
    if (!isEqual(this.props.input.value, prevProps.input.value)) {
      this.setState({
        checked: this.props.input.value
      });
    }
    /* eslint-enable */
  }

  handleOpen = event => {
    this.setState({
      anchorEl: event.currentTarget,
      searchValue: ''
    }, () => {
      if (!this.state.touched) {
        this.setState({ touched: true });
      }
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  indexOfObject = (array, object) => {
    let index = -1;
    if (array && array instanceof Array && array.length) {
      array.forEach((element, i) => {
        if (element.name === object.name) {
          index = i;
        }
      });
    }
    return index;
  }

  handleSelectOption = (option) => {
    const { checked } = this.state;
    const { onChange } = this.props;
    let currentIndex;
    if (!option.name) {
      currentIndex = -2;
    } else {
      currentIndex = this.indexOfObject(checked, option);
    }
    const newChecked = [...checked];

    if (currentIndex === -1) { // if checking a new item
      newChecked.push(option);
      this.setState({
        checked: newChecked
      }, () => {
        onChange(this.props.name, newChecked);
      });
    } else if (currentIndex === -2) { // if no item was clicked
      this.handleClose();
    } else { // if unchecking item
      newChecked.splice(currentIndex, 1);
      this.setState({
        checked: newChecked
      }, () => {
        onChange(this.props.name, newChecked);
      });
    }
  }

  handleSelectAll = (value) => {
    const { onChange } = this.props;
    const options = this.filterOptions('').filter((option, index) => {
      if (option.type === 'option') return true;
      return false;
    });

    if (value) {
      this.setState({
        checked: options,
        selectAllSelected: true
      }, () => {
        onChange(this.props.name, options);
      });
    } else {
      this.setState({
        checked: [],
        selectAllSelected: false
      }, () => {
        onChange(this.props.name, []);
      });
    }
  }

  updateSearchState = (value) => {
    this.setState({
      searchValue: value
    });
  }

  filterOptions = (searchValue) => {
    const concatOptions = [];
    this.props.options.map(list => {
      concatOptions.push({
        name: list.name,
        value: list.name,
        type: 'title'
      });
      list.value.map(option => {
        option.type = 'option';
        option.group = list.name;
        concatOptions.push(option);
      });
    });

    return concatOptions.filter((option, index) => {
      const optionParts = option.name.split(' ');
      const searchLength = searchValue.length;
      let keep = false;

      for (const part of optionParts) {
        const parsedPart = part.trim().toLowerCase();
        keep = parsedPart.slice(0, searchLength) === searchValue.toLowerCase();
        if (keep) return keep;
      }
      keep = option.name.trim().toLowerCase().slice(0, searchLength) === searchValue.toLowerCase();
      if (option.type === 'title') keep = true;
      return keep;
    });
  }

  isChecked = (option) => {
    const { checked } = this.state;
    let isChecked = false;
    if (checked.length > 0) {
      const { name, group } = option;
      checked.forEach(item => {
        if (item.name === name && item.group === group) {
          isChecked = true;
        }
      });
    }
    return isChecked;
  }

  renderMenuItems() {
    const { maxSelect } = this.props;
    const { checked, searchValue, isLoading } = this.state;
    const limitReached = checked.length === maxSelect;
    const filteredOptions = this.filterOptions(searchValue);
    if (isLoading) {
      return (
        <ListItem
          className='checkbox-menu-item'
          style={{ display: 'flex', justifyContent: 'center' }}
          disabled>
          <span style={{ fontWeight: '400' }}><LoadingSpinner /></span>
        </ListItem>
      );
    }
    if (filteredOptions.length > 0) {
      return filteredOptions.map((option, index) => {
        const isChecked = this.isChecked(option);
        const optionType = option.type === 'option';
        return (
          <ListItem
            key={index}
            value={option.value}
            className='checkbox-menu-item'
            disabled={limitReached && !isChecked}
            onClick={() => {
              if (optionType) return this.handleSelectOption(option);
            }}>
            {optionType &&
              <Checkbox
                disableRipple
                checkedIcon={<img src={icon_checked_small} />}
                icon={<img src={icon_unchecked_small} />}
                checked={isChecked}
              />}
            <span style={{ fontWeight: optionType ? '400' : 'bold' }}>{option.name}</span>
          </ListItem>
        );
      });
    } else {
      // if no search results found
      return (
        <ListItem
          className='checkbox-menu-item'
          disabled>
          No search results found
        </ListItem>
      );
    }

  }

  render() {
    const { label, buttonLabel, buttonId, maxSelect, disabled, input, required, tooltip } = this.props;
    const { anchorEl, searchValue, isLoading } = this.state;
    const buttonDisabled = disabled || isLoading;
    const errorClassName = required && this.props.options[0].value.length > 0 && input.value.length === 0 ? 'error-field' : '';
    const buttonClassNames = 'form-field-text padding-xsm-left ' +
      (buttonDisabled ? 'button-disabled' : '') +
      errorClassName;
    return (
      <Fragment>
        {label && <FieldLabel label={label} required={required} tooltip={tooltip} />}
        <div className='form-field-text-container'>
          <Button
            disableRipple
            disabled={buttonDisabled}
            aria-owns={anchorEl ? { buttonId } : null}
            aria-haspopup='true'
            onClick={this.handleOpen}
            // onBlur={this.handleBlur}
            className={buttonClassNames}
            color='primary'>
            {isLoading ? <div style={{ 'position': 'absolute' }}><LoadingSpinner /> </div> : null}
            <span className='form-field-button-label'>{input.value.length + ' ' + buttonLabel} <img src={icon_select_arrow} /></span>
          </Button>
        </div>
        <Menu
          MenuListProps={{
            disablePadding: true,
            className: 'checkbox-field-list'
          }}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          getContentAnchorEl={null}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}>
            {!isLoading ? (
                <ListItem className='checkbox-header-search padding-sm-top'>
                  <input
                    placeholder='Search'
                    value={searchValue}
                    onChange={(event) => this.updateSearchState(event.target.value)}
                    className='form-field-text padding-xsm-left'
                    type='text' />
                </ListItem>
            ) : null}
            {this.renderMenuItems()}
        </Menu>
      </Fragment>
    );
  }
}

MultiSelectorField.propTypes = {
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  buttonId: PropTypes.string,
  buttonLabel: PropTypes.string.isRequired,
  maxSelect: PropTypes.number,
  onChange: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.string
};
