import React from 'react';
import PropTypes from 'prop-types';
import { AvatarWrapper } from '../../../components/Avatar/AvatarWrapper';

import './profileBadge.scss';

/**
 * @param {string} primaryText: primary text to display
 * @param {string} secondaryText: sub-text to display below primary text
 * @param {object} contact: contact object
 */
export const ProfileBadge = (props) => {
  const { contact, primaryText, secondaryText } = props;

  const avatarContact = {
    userID: contact.userID,
    firstName: contact.firstName,
    lastName: contact.lastName,
    profileImageUrl: contact.profileImageUrl,
    invitationStatus: contact.invitationStatus
  };

  return (
    <div className='profileBadge-container'>
      <AvatarWrapper size={'44'} classes={['profileBadge-avatar']} contact={avatarContact} />
      <div>
        <div className='profileBadge-primaryText'>{primaryText}</div>
        {secondaryText && <div className='profileBadge-secondaryText'>{secondaryText}</div>}
      </div>
    </div>
  );
};

ProfileBadge.propTypes = {
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
  contact: PropTypes.object
};
