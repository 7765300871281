export enum REGISTRATION_STATUS {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  AUTO_APPROVED = 'AUTO_APPROVED',
  AUTO_REJECTED = 'AUTO_REJECTED',
  INVITED = 'INVITED',
  HOLD = 'HOLD',
  PAID = 'PAID',
  PAID_OVERRIDE = 'PAID_OVERRIDE'
}

export const showApprovedBatch = [
  REGISTRATION_STATUS.APPROVED,
  REGISTRATION_STATUS.PAID_OVERRIDE,
  REGISTRATION_STATUS.AUTO_APPROVED
];

export const hideApproveRejectButton = [
  REGISTRATION_STATUS.HOLD,
  REGISTRATION_STATUS.PAID_OVERRIDE
];
