import { authActions } from '../constants/actions';

export default function authenticationReducer(
  state = { googleAuthenticated: false, samparkAuthenticated: false, access: {}, error: '', redirect401: false },
  action) {
  switch (action.type) {
    case authActions.AUTH_USER_GOOGLE: // sign user in with google
      return { ...state, error: '', googleAuthenticated: true };
    case authActions.AUTH_USER_SAMPARK: // sign user in with sampark
      return { ...state, error: '', samparkAuthenticated: true };
    case authActions.AUTH_USER_ACCESS: // add the user access object to state
      return { ...state, error: '', access: action.payload };
    case authActions.UNAUTH_USER: // unautherize user to access private routes
      return { ...state, samparkAuthenticated: false, googleAuthenticated: false, error: '' };
    case authActions.AUTH_ERROR: // sampark auth error
      return { ...state, error: action.payload };
    case authActions.REDIRECT_401:
      return { ...state, redirect401: true };
    default:
      return state;
  }
}
/*
* We should try to use checkUserPermission function instead of below function
* Their was refactor of sampark where we accumulated entire permission modal to checkUserPermission.
* We can add below permission if its missing, or we can discuss a new way to simplify checkUserPermission, 
* if we find its not optimum 
*/
export const selectIsReadOnlyAccess = (state) => {
  const { userAccess: { userAccessObject: { access, isSuperAdmin } } } = state;

  if (isSuperAdmin) return false;

  const contactActions = access?.contacts?.actions;
  if (contactActions?.create || contactActions?.update) {
    return false;
  }

  return true;
}
/*
* We should try to use checkUserPermission function instead of below function
* Their was refactor of sampark where we accumulated entire permission modal to checkUserPermission.
* We can add below permission if its missing, or we can discuss a new way to simplify checkUserPermission, 
* if we find its not optimum 
*/
export const selectCanAddMember = (state) => {
  const { userAccess: { userAccessObject: { access, isSuperAdmin } } } = state;

  if (isSuperAdmin) return true;

  const contactActions = access?.contacts?.actions;
  return !!contactActions?.create;
}