import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import * as FORMS from '../../../constants/forms';

import {
  renderTextAreaField,
  renderSelectField
} from '../../form-fields';

let ContactUsForm = (props) => {
  const issueSelectOptions = [
    {
      name: 'Report an issue',
      value: 'Report an issue'
    },
    {
      name: 'Request a feature',
      value: 'Request a feature'
    },
    {
      name: 'General question',
      value: 'General question'
    }
  ];
  return (
    <div className='contact-us-fields'>
      {/* <div
        className='field-issue-name'>
        <Field
          name='name'
          component={renderTextField}
          placeholder='Name'
        />
      </div>
      <div
        className='field-issue-email'>
        <Field
          name='email'
          component={renderTextField}
          placeholder='Email'
        />
      </div> */}
      <div
        className='field-issue-select'>
        <Field
          name='issueSelect'
          component={renderSelectField}
          options={issueSelectOptions}
          placeholder='Select One'
        />
      </div>
      <div
        className='field-issue-details'>
        <Field
          name='issueDetails'
          component={renderTextAreaField}
          placeholder='Explain your issue in detail'
        />
      </div>
    </div>
  );
};

const validate = (values) => {
  const errors = {};

  if (!values.issueSelect) {
    errors.issueSelect = 'Please select an issue from the dropdown';
  }

  if (!values.issueDetails) {
    errors.issueDetails = 'Please provide a detailed explanation of your issue';
  }

  return errors;
};

ContactUsForm = reduxForm({
  form: FORMS.CONTACT_US_FORM,
  validate
})(ContactUsForm);

export default connect(null, {})(ContactUsForm);
