import { sambandhApprovalActions } from '../constants/actions';

export default function sambandhApprovalReducer (
  state = {
    submitted: false,
    storedSambandhUser: {},
    error: ''
  },
  action) {

  switch (action.type) {
    case sambandhApprovalActions.INITIALIZE_SAMBANDH_FORM:
      return { ...state, storedSambandhUser: action.payload, submitted: false };
    case sambandhApprovalActions.SUBMIT_SAMBANDH_APPROVAL:
      return { ...state, submitted: true };
    default:
      return { ...state };
  }

};
