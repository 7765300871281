import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { updateEventImage } from '../actions/eventForm.actions';
import { IMAGES_URL } from '../config/config.constants';
import icon_image from '../assets/icons/icon_image.svg';
import icon_image_delete from '../assets/icons/icon_image_delete.svg';

class ImageUploader extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      avatar: (this.props.input.value && this.props.input.value != null)
        ? IMAGES_URL + '/' + this.props.input.value
        : null,
      fileSelected: (this.props.input.value && this.props.input.value != null)
        ? this.props.input.value : null
    };
  }

  clearImage = () => {
    this.setState({
      avatar: null,
      fileSelected: null
    });
  }

  fileSelectedHandler (selectedFile) {
    if (selectedFile && (selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/png')) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.setState({
          avatar: event.target.result
        });
      };
      this.props.change(this.props.meta.form, this.props.input.name, this.state.fileSelected);
      this.props.updateEventImage(this.state.fileSelected);
      reader.readAsDataURL(this.state.fileSelected);
    }
  }

  render () {

    const {
      avatar
    } = this.state;

    return (
      <div className='image-uploader-container'>
        <div className='image-uploader-box'>
          <img className={avatar != null ? 'image-max' : 'image'} src={avatar != null ? avatar : icon_image}
            onClick={() => { if (avatar === null) { this.fileInput.click(); } }} />
          {avatar ? <img className='image-icon-delete' src={icon_image_delete} onClick={this.clearImage} /> : null}
        </div>
        <input
          ref={(fileInput) => { this.fileInput = fileInput; }}
          type='file'
          className='image-file-input'
          onChange={(event) => {
            this.setState({
              fileSelected: event.target.files[0]
            }, () => {
              this.fileSelectedHandler(this.state.fileSelected);
            });
          }}
        />
      </div>
    );
  }
}

ImageUploader.propTypes = {
  change: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  updateEventImage: PropTypes.func.isRequired
  // removeEventImage: PropTypes.func.isRequired
};

export default connect(null, { updateEventImage, change })(ImageUploader);
