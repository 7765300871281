export const CONTACT_DATA_ERROR = 'Error getting contacts data';
export const CONTACT_DELETED_ERROR = 'Error Trying to Delete Contact(s)';
export const CONTACT_DELETED_CONTACTS_ERROR =
  'Contact(s) Deleted Successfully! However; error getting updated contacts list';
export const CONTACT_OPTIONS_ERROR = 'Error getting contact options data';
export const CONTACT_PAGINATION_ERROR = 'Error getting pagination contacts data';
export const CONTACT_DELETED_SUCCESS = 'Contact(s) deleted successfully!';
export const DASHBOARD_DATA_ERROR = 'Error getting dashboard data';
export const EVENTS_DATA_ERROR = 'Error getting events data';
export const EVENTS_CREATE_ERROR = 'Error creating event';
export const EVENTS_CREATE_SUCCESS = 'Event created successfully!';
export const EVENTS_UPDATE_ERROR = 'Error updating event';
export const EVENTS_UPDATE_SUCCESS = 'Event updated successfully!';
export const EVENTS_IMAGES_UPLOAD_SUCCESS = 'Event images uploaded successfully!';
export const EVENTS_IMAGES_UPLOAD_ERROR = 'Error uploading event images';
export const INVITE_CONTACT_SUCCESS = 'Invite sent successfully!';
export const INVITE_CONTACT_ERROR = 'Error trying to invite contact';
export const CANCEL_INVITE_ERROR = 'Error trying to cancel invite for contact';
export const SAMBANDH_APPROVAL_DATA_ERROR = 'Error getting sambandh approvals data';
export const BROADCAST_MESSAGES_DATA_ERROR = 'Error getting broadcast messages';
export const CONTACT_US_SUBMIT_SUCCESS = 'Your message was sent successfully!';
export const CONTACT_US_SUBMIT_ERROR = 'There was an error sending your email.';
export const GET_USERS_BY_STATUS_ERROR = 'Error getting managable users';
export const BROADCAST_MESSAGE_CANCELLED_SUCCESS = 'Broadcast message cancelled successfully!';
export const BROADCAST_MESSAGE_CANCELLED_ERROR = 'Error cancelling broadcast message';
export const CONTACT_UPDATED_SUCCESS = 'Contact updated successfully!';
export const CONTACT_VERIFIED_SUCCESS = 'Contact verified successfully!';
export const CONTACT_UPDATED_ERROR = 'Error updating contact';
export const CONTACT_VERIFIED_ERROR = 'Error verifying contact';
export const NEW_CONTACT_ADDED_SUCCESS = 'New contact added successfully!';
export const NEW_CONTACT_ADDED_ERROR = 'Error adding new contact';
export const SAMPARK_INVITATION_CANCELLED_SUCCESS = 'Contact invitation cancelled successfully!';
export const CONTACT_AVATAR_UPLOAD_SUCCESS = 'Contact photo uploaded successfully!';
export const CONTACT_AVATAR_UPLOAD_ERROR = 'Error trying to upload contact photo';
export const EVENT_PHOTO_UPLOAD_ERROR = 'Error trying to upload event photo';
export const EVENT_PHOTO_UPLOAD_NOT_FOUND = 'No event image found to upload';
export const ADDRESS_DATA_ERROR = 'Error getting address information';
export const GET_COUNTIES_IN_STATE_ERROR = 'Error getting counties in state';
export const GET_ZIP_IN_COUNTY_ERROR = 'Error getting zip codes in county';
export const SUBMIT_SAMPARK_FORM_ERROR = 'Error submitting access form';
export const APPROVE_SAMPARK_FORM_ERROR = 'Error approving access form';
export const DECLINE_SAMPARK_FORM_ERROR = 'Error declining access form';
export const BROADCAST_SUBMIT_SUCCESS = 'Message submitted for approval';
export const BROADCAST_SUBMIT_ERROR = 'Error submitting message for approval';
export const BROADCAST_APPROVE_SUCCESS = 'Message approved successfully';
export const BROADCAST_APPROVE_ERROR = 'Error approving message';
export const BROADCAST_DECLINE_SUCCESS = 'Message declined successfully';
export const BROADCAST_DECLINE_ERROR = 'Error declining message';
export const READING_DATA_GET_ERROR = 'Error getting reading data';
export const READING_SUBMIT_SUCCESS = 'Reading content submitted successfully!';
export const READING_SUBMIT_ERROR = 'Error submitting reading content';
export const READING_APPROVE_SUCCESS = 'Reading approved successfully';
export const READING_APPROVE_ERROR = 'Error approving reading content';
export const READING_DECLINE_SUCCESS = 'Reading declined successfully';
export const READING_DECLINE_ERROR = 'Error declining reading content';
export const VERIFICATION_FORM_SUBMIT_SUCCESS = 'Verification form submitted successfully';
export const VERIFICATION_FORM_SUBMIT_ERROR = 'Error submitting verification form';
export const GET_MY_ACCOUNT_DATA_ERROR = 'Error getting my user account data';
export const GET_ALL_ALBUMS_DATA_ERROR = 'Error getting albums data';
export const DELETE_ALBUM_ERROR = 'Error deleting album';
export const DELETE_ALBUM_SUCCESS = 'Album deleted successfully';
export const CREATE_ALBUM_ERROR = 'Error creating album';
export const CREATE_ALBUM_SUCCESS = 'Album created successfully';
export const UPDATE_ALBUM_ERROR = 'Error updating album images';
export const UPDATE_ALBUM_SUCCESS = 'Album updated successfully';
export const KARYAKARTA_ASSIGNED_SUCCESS = 'Follow-up karyakarta have been assigned successfully';
export const KARYAKARTA_ASSIGNED_ERROR = 'There was an error assigning follow-up karyakarta';
export const REQUIRED_FIELDS_ERROR = 'Please fill all the required fields.';
export const WORKSHOP_INVITATION_SUCCESS = (email: string) =>
  `Invitation sent to ${email} successfully`;
export const WORKSHOP_INVITATION_ERROR = (email: string) =>
  `Failed to send to invitation to ${email}`;
