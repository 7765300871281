import { GRAPHQL_ENDPOINT } from '../config/config.constants';
import { SAMPARKTOKEN } from '../config/config.tokens';
import http from '../helpers/http';

const GET_SIGNED_URL_ENDPOINT = `${GRAPHQL_ENDPOINT}/aws/signinKey/`;
const token = localStorage.getItem(SAMPARKTOKEN);

const useSignInUrl = async (file: File, id: string, prefix: string) => {
  const config = {
    headers: { Authorization: `Bearer ${token}`, sourceapp: 'sampark' }
  };
  const bodyParameters = { key: `${id}-${file.name}`, prefix: prefix };

  const response = await http.post(GET_SIGNED_URL_ENDPOINT, bodyParameters, config);
  if (!response || !response.data) {
    throw new Error('Failed to get a valid signed URL from the server.');
  }

  const signedURL = response.data;
  //UploadImageToServer
  await uploadFile(signedURL.signedUrl, file);
  return signedURL?.assetUrl;
};

const uploadFile = async (signedUrl: string, file: File) => {
  const blob = new Blob([file], { type: file.type });
  const config = {
    headers: { 'Content-Type': blob.type }
  };
  const uploadResponse = await http.put(signedUrl, blob, config);

  if (!uploadResponse) {
    throw new Error('Error uploading image.');
  }
};

export default useSignInUrl;
