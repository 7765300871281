import { iconContracts } from '../../../assets/icons/icon-contacts';
import { iconFollowup } from '../../../assets/icons/icon-followup';
import { iconBroadcast } from '../../../assets/icons/icon-broadcasts';
import { iconEvents } from '../../../assets/icons/icon-events';
import { iconSwadhyay } from '../../../assets/icons/icon-swadhyay';
import { iconMedia } from '../../../assets/icons/icon-media';

export const accessConfig = {
  contacts: {
    icon: iconContracts,
    displayLabel: 'Contacts',
    description: 'Allow this user to view or manage contacts in Sampark',
    audiences: {
      displayLabel: 'Audiences',
      description: 'Select the audiences you want this person to have access to.',
      tooltip: 'Select the age range for karyakarta access',
      required: false,
      key: 'audiences',
      placeholder: 'Audiences',
    },
    hasAudiences: true,
    gender: true,
    actions: [
      {
        name: 'Add',
        default: true,
        key: 'create'
      },
      {
        name: 'view',
        default: true,
        key: 'read'
      },
      {
        name: 'update',
        default: false,
        key: 'update'
      },
      {
        name: 'delete',
        default: false,
        key: 'delete'
      },
      {
        name: 'export',
        default: false,
        key: 'export'
      },
      {
        name: 'Update Admin Attributes',
        default: false,
        key: 'updateAdminAttributes'
      }
    ],
    requiredFields: ['actions']
  },
  followup: {
    icon: iconFollowup,
    displayLabel: 'Follow-up',
    description: 'Assign this contact as a follow-up karyakarta',
    gender: false,
    hasActions: true,
    actions: [
      {
        name: 'Add',
        key: 'create',
        default: true
      },
      {
        name: 'update',
        key: 'update',
        default: true
      },
      {
        name: 'view',
        default: true,
        key: 'read'
      }
    ],
    requiredFields: ['actions']
  },
  events: {
    icon: iconEvents,
    displayLabel: 'Events',
    description: 'Allow this user to view or manage events in Sampark',
    audiences: {
      displayLabel: 'Audiences',
      description: 'Select the audiences you want this person to have access to.',
      tooltip: 'Select the audiences you want this person to have access to.',
      required: true,
      key: 'audiences',
      placeholder: 'Audiences',
    },
    hasAudiences: true,
    actions: [
      {
        name: 'create',
        key: 'create',
        default: true
      },
      {
        name: 'view',
        key: 'read',
        default: true
      },
      {
        name: 'update',
        key: 'update',
        default: true
      }
    ],
    requiredFields: ['audiences', 'actions']
  },
  broadcasts: {
    icon: iconBroadcast,
    displayLabel: 'Broadcast',
    description: 'Allow this user to view or manage broadcast in Sampark',
    audiences: {
      displayLabel: 'Audiences',
      description: 'Select the audiences you want this person to have access to.',
      required: true,
      key: 'audiences',
      placeholder: 'Audiences',

    },
    hasAudiences: true,
    actions: [
      {
        name: 'create',
        key: 'create',
        default: true
      },
      {
        name: 'read',
        key: 'read',
        default: true
      },
      {
        name: 'update',
        key: 'update',
        default: true
      }
    ],
    requiredFields: ['audiences']
  },
  smrutis: {
    icon: iconMedia,
    displayLabel: 'Smruti',
    description: 'Allow this user to view or manage smrutis in Sampark',
    gender: false,
    actions: [
      {
        name: 'create',
        key: 'create',
        default: true
      },
      {
        name: 'read',
        key: 'read',
        default: true
      },
      {
        name: 'update',
        key: 'update',
        default: true
      }
    ],
    requiredFields: ['actions']
  },
  swadhyay: {
    icon: iconSwadhyay,
    displayLabel: 'Swadhay',
    description: 'Allow this user to view or manage swadhyay in Sampark',
    gender: false,
    actions: [
      {
        name: 'create',
        key: 'create',
        default: true
      },
      {
        name: 'read',
        key: 'read',
        default: true
      },
      {
        name: 'update',
        key: 'update',
        default: true
      }
    ],
    requiredFields: ['actions']
  }
};
