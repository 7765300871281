import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// material
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// Project Files
import { closeModal } from '../../actions/common.actions';
import { doesListContainUser } from '../../util/UserUtils';
import { contactActions } from '../../views/Contacts/contacts.ducks';

const useStyles = withStyles({
  alert: {
    color: '#A02D1C',
    padding: '1rem',
    margin: '0 1rem',
    borderRadius: '3px',
    backgroundColor: '#FFEAE7',
    marginBottom: '1.5rem'
  }
});

class DeleteContactModal extends React.Component {
  constructor (props) {
    super(props);
  }

  handleClick = () => {
    const {
      deleteSelectedContacts,
      contactsToDelete
    } = this.props;
    const contactIds = contactsToDelete.map(contact => contact.id);
    deleteSelectedContacts(contactIds);
    return this.props.closeModal();
  }

  render () {
    const {
      classes,
      contactsToDelete
    } = this.props;

    return (
      <Fragment>
        <DialogTitle>Delete Contact</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete the selected contact(s)?</DialogContentText>
        </DialogContent>
        { doesListContainUser(contactsToDelete) ? (
          <div className={classes.alert}>
            You are about to delete a Sampark or Sambandh User.
          </div>
        ) : null
        }
        <DialogActions>
          <Button
            disableRipple
            className='button-primary cancel-button'
            onClick={this.props.closeModal}>
            <span className='button-primary-label'>Cancel</span>
          </Button>
          <Button
            disableRipple
            className='button-primary button-orange'
            onClick={this.handleClick}>
            <span className='button-primary-label label-white'>Confirm</span>
          </Button>
        </DialogActions>
      </Fragment>
    );
  }
}

export default connect(null, {
  closeModal,
  deleteSelectedContacts: contactActions.deleteSelectedContacts
})(useStyles(DeleteContactModal));

DeleteContactModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  deleteSelectedContacts: PropTypes.func.isRequired,
  contactsToDelete: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired
};
