import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

const SecondaryButton = (props) => {
  const { style, className, handleClick, label, ...rest } = props;
  return (
    <div className='secondary-button-container'>
      <Button
        style={style}
        className={['secondary-button', className || null].join(' ')}
        disableRipple
        onClick={handleClick}
        {...rest}
      >{label}</Button>
    </div>
  );
};

SecondaryButton.propTypes = {
  label: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.object
};

export default SecondaryButton;
