import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../helpers/store';
import { resetAttribute, setAttribute } from '../../attributeAdmin.ducks';
import { CreateAttributeModal } from '../Modal/CreateModalAttribute/CreateAttributeModal';
import { AttributeList } from './attributeList';
import { IAttributes } from '../../attributes.types';

export const AttributeModify = ({ attributes }: { attributes: IAttributes[] }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  //getting all the attributes
  const { allFields, allAttributeGroups } = useAppSelector((state) => state.admin.attribute);
  const groupList: { value: string; label: string }[] = allAttributeGroups.map(
    (groupName: string) => {
      return { value: groupName, label: groupName };
    }
  );

  const updatedAttributes: Record<string, IAttributes[]> =
    attributes.length > 0
      ? attributes.reduce((prev: Record<string, IAttributes[]>, curr: any) => {
          prev[curr.group] = (prev[curr.group] || []).concat(curr);
          return prev;
        }, {})
      : {};

  const dispatch = useAppDispatch();

  const openModalHandler = (item: IAttributes) => {
    setIsModalOpen(true);
    dispatch(setAttribute(item));
  };

  const closeModalHandler = () => {
    setIsModalOpen(false);
    dispatch(resetAttribute());
  };

  return (
    <>
      <AttributeList updatedAttributes={updatedAttributes} openModalHandler={openModalHandler} />
      {isModalOpen && (
        <CreateAttributeModal
          closeModalHandler={closeModalHandler}
          isModalOpen={isModalOpen}
          allFields={allFields}
          allAttributeGroups={groupList}
        />
      )}
    </>
  );
};
