import { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { getDashboardData } from '../../actions/dashboard.actions';
import { AvatarWrapper } from '../../components/Avatar/AvatarWrapper';
import { useAppDispatch, useAppSelector } from '../../helpers/store';
import { DataCard } from './DataCard';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';

const DashboardView = () => {
  const dispatch = useAppDispatch();
  const actions = bindActionCreators({ getDashboardData }, dispatch);
  const dashboardData = useAppSelector((state) => state.dashboardData);
  const { userAccount } = useAppSelector((state) => state.userAccess);

  useSetDocumentTitle('Sampark | Home');
  useEffect(() => {
    actions.getDashboardData();
  }, []);

  if (!userAccount) {
    return null;
  }

  return (
    <div className="dashboad-view-container">
      <header className="dashboard-header">
        <section className="welcome-container">
          <AvatarWrapper size={'84'} classes={['avatar-big']} contact={userAccount} hideBadge />
          <div className="welcome-text-container">
            <p className="small-title">Dasna Das & Jai Swaminarayan</p>
            <h3 className="username">{userAccount.firstName}</h3>
          </div>
        </section>
      </header>
      <section className="dashboard-content">
        <section className="weekly-birthday">
          <DataCard headerTitle="Weekly Birthdays" items={dashboardData.birthdays} />
        </section>
        <section className="mukto-and-subscriptions">
          <DataCard headerTitle="Recently Updated" items={dashboardData.recentlyUpdated} />
        </section>
      </section>
    </div>
  );
};

export default DashboardView;
