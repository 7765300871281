import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { renderTextField } from '../../form-fields';
import { AvatarUploader } from '../../Avatar/AvatarUploader';
import validate from '../validate';
import * as FORMS from '../../../constants/forms';

let BasicInfoSection = (props) => {
  return (
    <div className='contact-form-top-info'>
      <div
        className='contact-form-profile-image'>
        <Field
          name='profileImageUrl'
          component={AvatarUploader}
          contact={props.initialValues}
          height={84}
          width={84}
        />
      </div>
      <div
        className='field-first-name padding-sm-right'>
        <Field
          name='firstName'
          component={renderTextField}
          placeholder='First Name'
        />
      </div>
      <div
        className='field-last-name padding-sm-right'>
        <Field
          name='lastName'
          component={renderTextField}
          placeholder='Last Name'
        />
      </div>
      <div
        className='field-nickname padding-sm-right'>
        <Field
          name='nickName'
          component={renderTextField}
          placeholder='Nickname'
        />
      </div>
    </div>
  );
};

function mapStateToProps (state, props) {
  return {
    form: props.myUserAccount ? FORMS.USER_PROFILE_FORM : FORMS.CONTACT_INFO
  };
}

export default compose(
  connect(mapStateToProps),
  reduxForm({
    validate,
    destroyOnUnmount: false
  })
)(BasicInfoSection);

BasicInfoSection.propTypes = {
  initialValues: PropTypes.object
};
