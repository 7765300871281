import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import icon_unauthorized from '../assets/icons/icon_unauthorized.svg';
import { Logo } from '../components/samparkLogo';

export const ErrorView = () => (
  <Fragment>
    <Logo isNewAccount={false} />
    <div className="error-message-main-container">
      <div className="error-message-container">
        <img className="error-message-image" src={icon_unauthorized} />
        <div className="error-message-title">404</div>
        <div className="error-message-content">Page not found !!!</div>
        <div className="error-message-submessage">
          <GoBackPage />
        </div>
      </div>
    </div>
  </Fragment>
);

const GoBackPage = () => {
  return (
    <div className="error-message-submessage">
      <Link
        to={{
          pathname: '/auth/home'
        }}
      >
        Go back to home
      </Link>
    </div>
  );
};

export const SamparkErrorView = () => {
  return (
    <div className="error-message-main-container">
      <div className="error-message-container">
        <img className="error-message-image" src={icon_unauthorized} />
        <div className="error-message-title">Page Not Found</div>
        <div className="error-message-content">
          The page you are looking for does not exist, or you do not have access to it.
        </div>
        <div className="error-message-submessage">
          <GoBackPage />
        </div>
      </div>
    </div>
  );
};
