import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  iconDelete: {
    color: 'hsla(220, 70%, 44%, 1)',
    marginRight: '35px',
    right: '14px',
    top: '14px',
    '&:hover': {
      filter: 'brightness(150%)'
    }
  },
  button: {
    border: 'none',
    cursor: 'pointer',
    fontSize: '1rem',
    backgroundColor: 'rgb(231, 234, 243)',
    '&:first-child': {
      borderRadius: '5px 0 0 5px'
    },
    '&:last-child': {
      borderRadius: '0 5px 5px 0'
    },
    '&:hover': {
      backgroundColor: 'hsl(209, 100%, 55%)',
      borderRadius: '5px',
      color: 'white',
      '&:first-child': {
        borderRadius: '5px 0 0 5px'
      },
      '&:last-child': {
        borderRadius: '0 5px 5px 0'
      }
    }
  },
  selectedButton: {
    border: 'none',
    cursor: 'pointer',
    fontSize: '1rem',
    backgroundColor: 'hsl(209, 100%, 55%)',
    borderRadius: '5px',
    color: 'white',
    '&:first-child': {
      borderRadius: '5px 0 0 5px'
    },
    '&:last-child': {
      borderRadius: '0 5px 5px 0'
    }
  },
  formatter: {
    display: 'flex',
    height: '28px'
  },
  containerHeader: {
    display: 'flex',
    borderBottom: '1px solid hsla(225, 35%, 93%, 1)',
    position: 'relative',
    paddingTop: '5px',
    paddingBottom: '23px',
    paddingLeft: '14px',
    backgroundColor: 'white',
    justifyContent: 'space-between'
  },
  containerOptions: {
    gap: '35px',
    display: 'flex',
    alignItems: 'center'
  }
}));
