import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';

import renderTextField from './renderTextField';
import renderSelectField from './renderSelectField';
import renderTimeField from './renderTimeField';
import icon_close_small_black from '../../assets/icons/icon_close_small_black.svg';
import { formatTime } from '../../util/formatTime';

const renderScheduleFields = ({
  eventDates,
  fields = [],
  meta: { touched, error, warning }
}) => (
  <div className='event-schedule-container'>
    {eventDates && eventDates.map((eventDate, index) => {
      return (
        <div className='schedule-container' key={index}>
          <div className='field-event-schedule-day'>
            <Field
              name={`${fields.name}[${index}].title`}
              label={'Day'}
              component={renderTextField}
              placeholder={'eg: Aug 10, 2018'}
            />
            {touched && ((error && <div className='form-field-error'>{error}</div>) ||
              (warning && <div className='form-field-error'>{warning}</div>))}
          </div>
          <FieldArray
            name={`${fields.name}[${index}].sessions`}
            component={renderSessionField}
          />
        </div>
      );
    })}
  </div>
);

renderScheduleFields.propTypes = {
  eventDates: PropTypes.array,
  fields: PropTypes.object,
  meta: PropTypes.any
};

const renderSessionField = ({
  fields = [],
  meta: { touched, error, warning }
}) => {
  return (
    <div className='session-container'>
      {fields && fields.map((field, index) => {
        return (
          <div className='session-row' key={index}>
            <div className='field-event-session-name'>
              <Field
                name={`${fields.name}[${index}].name`}
                label={'Session Name'}
                component={renderTextField}
              />
            </div>
            <div className='field-event-session-start-time'>
              <Field
                name={`${fields.name}[${index}].startTime`}
                label='Start'
                placeholder={'00:00'}
                format={(value) => formatTime(value)}
                component={renderTimeField}
              />
            </div>
            <div className='field-event-session-start-time-period'>
              <Field
                name={`${fields.name}[${index}].startTimePeriod`}
                options={[
                  {
                    name: 'AM',
                    value: 'am'
                  },
                  {
                    name: 'PM',
                    value: 'pm'
                  }
                ]}
                component={renderSelectField}
              />
            </div>
            <div className='field-event-session-end-time'>
              <Field
                name={`${fields.name}[${index}].endTime`}
                label='End (optional)'
                placeholder={'00:00'}
                format={(value) => formatTime(value)}
                component={renderTimeField}
              />
            </div>
            <div className='field-event-session-end-time-period'>
              <Field
                name={`${fields.name}[${index}].endTimePeriod`}
                options={[
                  {
                    name: 'AM',
                    value: 'am'
                  },
                  {
                    name: 'PM',
                    value: 'pm'
                  }
                ]}
                component={renderSelectField}
              />
              <div className='field-event-session-delete'>
                {index !== 0 ? <img className='icon-close' src={icon_close_small_black} onClick={() => {
                  fields.remove(index);
                }} /> : null}
              </div>
            </div>
          </div>
        );
      })}
      <a className='link' onClick={() => { fields.push({name: '', startTime: '', startTimePeriod: '', endTime: '', endTimePeriod: ''}); }}>+ Add Session</a>
    </div>
  );
};

renderSessionField.propTypes = {
  fields: PropTypes.object,
  meta: PropTypes.any
};

export default renderScheduleFields;
