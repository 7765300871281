import { iconProps } from './icons-types';

export const YogShield = ({ stroke }: iconProps) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.4548 18.1818V16.8523C12.4548 14.4272 9.88575 12.4545 6.72757 12.4545C3.56939 12.4545 1.0003 14.4272 1.0003 16.8523V18.1818"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.72757 9.18182C8.75748 9.18182 10.4094 7.52991 10.4094 5.5C10.4094 3.47009 8.75748 1.81818 6.72757 1.81818C4.69766 1.81818 3.04575 3.47009 3.04575 5.5C3.04575 7.52991 4.69766 9.18182 6.72757 9.18182Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0003 8.36364L16.1367 12.6591L14.0912 11.2273"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
