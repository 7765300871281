import { makeStyles } from 'tss-react/mui';

export const userenderSelectFieldStyle = makeStyles()(() => ({
  formFieldLabelRow: {
    display: 'flex',
    marginBottom: '0.5rem'
  },
  formFieldRequired: {
    fontSize: '16px',
    color: '$sunset-orange' // Assuming $sunset-orange is a variable
  },
  formFieldTooltipIcon: {
    width: '11px',
    height: '11px',
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '100%',
    alignSelf: 'center',
    fontSize: '10px',
    display: 'flex',
    justifyContent: 'center'
  },
  tagClearLink: {
    cursor: 'pointer',
    right: '10px',
    top: '0px'
  },
  formFieldClear: {
    justifyContent: 'space-between'
  }
}));
