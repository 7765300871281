import { iconProps } from './icons-types';

export const iconShield = ({ stroke }: iconProps) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.45459 9.72727L8.63641 11.1818L11.5455 6.81818"
        stroke={stroke}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.00004 1L2.45459 4.63636V9C2.54041 12.184 3.62914 15.2436 9.00004 17C14.371 15.2436 15.4597 12.184 15.5455 9V4.63636L9.00004 1Z"
        stroke={stroke}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
