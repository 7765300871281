import { ALL, WORDOFTHEWEEK, STORY, SUTRA, PRASANG } from '../constants/contentTypes';
import { readingViewActions, commonActions, readingPanelActions } from '../constants/actions';
import { READING_DATA_GET_ERROR } from '../constants/toasterMessages';
import { getReadingDataAPI } from '../apis/ReadingAPIs';

export function getReadingData (dataType, by) {
  return async (dispatch) => {
    dispatch({ type: readingViewActions.SHOW_READING_LOADER });
    let readingData = [];
    let columns = [];
    let action;
    let contentBy = '';
    switch (dataType.value) {
      case ALL.value:
        action = readingViewActions.GET_READING_DATA_ALL;
        break;
      case WORDOFTHEWEEK.value:
        action = readingViewActions.GET_READING_DATA_WORDS;
        break;
      case SUTRA.value:
        action = readingViewActions.GET_READING_DATA_SUTRO;
        break;
      case PRASANG.value:
        action = readingViewActions.GET_READING_DATA_PRASANG;
        break;
      case STORY.value:
        action = readingViewActions.GET_READING_DATA_STORIES;
        break;
      default:
    }
    if (by === 1) { contentBy = 'others'; }
    columns = [
      {
        name: 'Type',
        value: 'type',
        classes: ['capitalize']
      },
      {
        name: 'Content',
        value: 'englishTitle'
      },
      {
        name: 'Publish Date',
        value: 'publishDate',
        date: 'MMM DD, YYYY'
      },
      {
        name: 'Last Edited By',
        value: 'updatedByName',
        classes: ['capitalize']
      },
      {
        name: 'Status',
        value: 'status',
        classes: ['status']
      }
    ];
    readingData = await getReadingDataAPI(dataType.value, contentBy);
    if (readingData && readingData instanceof Array) {
      dispatch({ type: readingPanelActions.CLEAR_INITIAL_VALUES_READING });
      dispatch({ type: readingViewActions.HIDE_READING_LOADER });
      return dispatch({ type: action, value: { data: transformReadingData(readingData), columns } });
    } else if (readingData && readingData === {}) {
      dispatch({ type: readingViewActions.HIDE_READING_LOADER });
    } else {
      dispatch({ type: readingViewActions.HIDE_READING_LOADER });
      return dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: `${READING_DATA_GET_ERROR}: ${dataType.value}`, type: commonActions.ERROR_TOASTER } });
    }
  };

}

function transformReadingData (data) {
  return data.map(content => {
    return {
      ...content,
      englishContent: content.content ? content.content.english : null,
      gujaratiContent: content.content ? content.content.gujarati : null,
      englishTitle: content.title ? content.title.english : null,
      gujaratiTitle: content.title ? content.title.gujarati : null,
      createdByName: content.createdBy ? `${content.createdBy.firstName} ${content.createdBy.lastName}` : 'User not found',
      updatedByName: content.updateBy ? `${content.updateBy.firstName} ${content.updateBy.lastName}` : 'User not found'
    };
  });

}
