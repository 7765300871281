import React, { Component } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
// material
import { Menu, MenuItem, Avatar } from '@mui/material';
// project files
import { MY_ACCOUNT, SETTINGS, LOGOUT } from '../../constants/menuOptions';
import getMenuOptions from '../../util/getMenuOptions';
import { USER_MENU } from '../../constants/menus';
import { openDrawer, openModal } from '../../actions/common.actions';
import { initializeContactForm } from '../../actions/contactForm.actions';
import { DRAWER_COMPONENTS, IMAGES_URL } from '../../config/config.constants';
import { SIGNOUT, HOME, SMRUTI_DARSHAN } from '../../constants/routes';
import { checkUserPermission, ISSUPERADMIN } from '../../util/userPermission';
import * as MODAL_COMPONENTS from '../../constants/modals';
class UserMenu extends Component {
  constructor (props) {
    super(props);
    this.state = {
      anchorEl: null
    };
  }

  handleLogoutUser () {
    this.props.history.push(SIGNOUT.value);
  }

  // open menu
  handleMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  // handle menu options click
  handleMenuClose = option => {
    this.setState(
      { anchorEl: null },
      () => {
        if (option) {
          switch (option.value) {
            case MY_ACCOUNT.value:
              this.props.initializeContactForm(this.props.userAccount);
              this.props.openModal(MODAL_COMPONENTS.CONTACT_INFO_READ_ONLY);
              break;
            case SETTINGS.value:
              console.log(SETTINGS.name, 'option disabled');
              break;
            case LOGOUT.value:
              this.handleLogoutUser();
              break;
            default:
          }
        }
      }
    );
  }

  render () {

    const {
      history,
      userAccessObject,
      userAccount
    } = this.props;

    const { anchorEl } = this.state;

    const menuOptions = getMenuOptions(USER_MENU, {
      pathname: history.location.pathname,
      userAccessObject
    });

    const src = (userAccount.profileImageUrl && userAccount.profileImageUrl !== 'undefined')
      ? IMAGES_URL + '/' + userAccount.profileImageUrl
      : null;

    const classes = this.props.classes ? this.props.classes.join(' ') : '';

    let avatarInitial;
    if (!userAccount.profileImageUrl && userAccount.firstName != null) {
      avatarInitial = userAccount.firstName[0];
    }

    return (
      <div className={classes}>
        <Avatar
          onClick={this.handleMenuOpen}
          className='header-avatar'
          src={src}>
          {avatarInitial}
        </Avatar>
        <Menu
          MenuListProps={{
            disablePadding: true
          }}
          style={{marginTop: '0.5rem'}}
          anchorEl={anchorEl}
          anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
          getContentAnchorEl={null}
          open={Boolean(anchorEl)}
          onClose={() => {
            this.handleMenuClose();
          }}>
          {menuOptions.map((option, index) => {
            return (
              <MenuItem
                key={index}
                disabled={option.disabled}
                onClick={() => {
                  this.handleMenuClose(option);
                }}>
                {option.name}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  }
};

function mapStateToProps (state) {
  return {
    userAccessObject: state.userAccess.userAccessObject,
    isSuperAdmin: checkUserPermission(state.userAccess, ISSUPERADMIN),
    userAccount: state.userAccess.userAccount
  };
}

export default connect(
  mapStateToProps, { openDrawer, openModal, initializeContactForm }
)(UserMenu);

UserMenu.propTypes = {
  classes: PropType.arrayOf(PropType.string),
  history: PropType.object.isRequired,
  initializeContactForm: PropType.func.isRequired,
  openDrawer: PropType.func.isRequired,
  userAccessObject: PropType.object.isRequired,
  userAccount: PropType.object.isRequired
};
