import CardContent from '@mui/material/CardContent';

interface IDataCardItem {
  label: string;
  location: string;
  state: string;
  value?: string;
  valueSecondary?: string;
}
interface IDataCardProps {
  headerTitle: string;
  items: IDataCardItem[];
}

export const DataCard = (props: IDataCardProps) => {
  const { headerTitle, items = [] } = props;

  const renderItems = () => {
    let list = [];

    if (items && items instanceof Array && items.length) {
      list = items.map((obj, index) => (
        <li key={index}>
          <div className="item-label">
            <div className="item-name">{obj.label}</div>
            <div className="item-location-container">
              {obj.location && typeof obj.location === 'string' && obj.location.length ? (
                <span className="item-location">{obj.location}</span>
              ) : null}
              <span className="item-state">{obj.state}</span>
            </div>
          </div>
          <div className="item-value">
            <div className="item-value">{obj.value}</div>
            <div className="item-value-secondary">{obj.valueSecondary}</div>
          </div>
        </li>
      ));
    } else {
      return <p>No Data</p>;
    }

    return <ul>{list}</ul>;
  };

  return (
    <div className="card">
      <div className="card-header">
        <div className="card-header-title">{headerTitle}</div>
      </div>
      <CardContent className="card-content-container">{renderItems()}</CardContent>
    </div>
  );
};
