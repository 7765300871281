import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useStyles } from './autocomplete.styles';

const filter = createFilterOptions();

export const AutoComplete = ({ value, handleAttributeGroup, options }: any) => {
  const { classes } = useStyles();
  return (
    <Autocomplete
      className={classes.focused}
      value={value}
      onChange={(__, newValue) => {
        if (typeof newValue === 'string') {
          handleAttributeGroup(newValue);
        } else if (newValue && newValue.inputValue) {
          handleAttributeGroup({ value: newValue.inputValue, label: newValue.inputValue });
        } else {
          if (newValue) {
            handleAttributeGroup(newValue);
          }
        }
      }}
      options={options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.label;
      }}
      renderOption={(props, option) => <li {...props}>{option.label}</li>}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.label);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            label: `Add "${inputValue}"`
          });
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      renderInput={(params) => <TextField {...params} />}
      size="small"
    />
  );
};
