import { FC, useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';

import { MembersType } from '../../../../../types';
import { MembersListItem } from './MembersListItem';
import { FamilyMemberType } from '../../familyTree.duck';
import { useIntersectionObserver } from '../../../../../v2/hooks/useIntersectionObserver';
import { Address } from '../../../../../gql/generated';

export type OptionType = {
  value: string | NonNullable<Address>;
  name: string;
};

type MembersListProps = {
  className?: string;
  contentClassName?: string;
  members: MembersType[] | FamilyMemberType[];
  familyMembers?: MembersType[] | FamilyMemberType[];
  addressOptions?: OptionType[];
  isEditMode?: boolean;
  onAddressChange?: (member: FamilyMemberType) => void;
  showAddButton?: boolean;
  showFamilyAssigned?: boolean;
  showRemoveButton?: boolean;
  showAssignButton?: boolean;
  showAddDisabled?: boolean;
  isFamilyTreeContent?: boolean;
  selectedFamilyId?: string;
  total?: number;
  onClick?: (id: string) => void;
  onAdd?: (member: MembersType) => void;
  onRemove?: (memberId: string) => void;
  fetchMore?: () => void;
  onAssign?: (memberId: string) => void;
};

export const MembersList: FC<MembersListProps> = ({
  className,
  contentClassName = '',
  members,
  familyMembers = [],
  onAdd,
  onRemove,
  onAssign,
  onClick,
  addressOptions,
  isEditMode = false,
  onAddressChange,
  showAddButton = false,
  showFamilyAssigned = false,
  showRemoveButton = false,
  showAddDisabled = false,
  selectedFamilyId = '',
  fetchMore,
  showAssignButton = false,
  isFamilyTreeContent = false,
  total = 0
}) => {
  const { classes, cx } = useStyles();
  const callBack = useCallback(
    (entries) => {
      // only if intersecting meaning, its coming into view, we fetch more
      if (entries && entries[0].isIntersecting) {
        if (fetchMore) {
          fetchMore();
        }
      }
    },
    [fetchMore]
  );

  useIntersectionObserver({
    root: !isFamilyTreeContent ? document.getElementById('rootMemberListId') : null,
    target: !isFamilyTreeContent ? document.getElementById('loadingMoreId') : null,
    intersectedCallBack: callBack,
    rootMargin: '0px 0px 0px 0px',
    threshold: 0,
    subscribe: !isFamilyTreeContent
  });

  const checkIsSelected = useCallback(
    (member) => {
      const findIndex = familyMembers.findIndex((m) => m._id === member._id);
      return findIndex > -1;
    },
    [selectedFamilyId, familyMembers]
  );
  return (
    <div
      id={isFamilyTreeContent ? 'familyContentId' : 'rootMemberListId'}
      className={!isFamilyTreeContent ? cx(classes.listContainer, className) : className}
    >
      {members.length > 0
        ? members
            .filter((m) => m)
            .map((member) => {
              if (!member?._id) return null;
              return (
                <MembersListItem
                  className={contentClassName}
                  key={member._id}
                  id={member._id}
                  member={member}
                  addressOptions={addressOptions}
                  isEditMode={isEditMode}
                  onAddressChange={onAddressChange}
                  onAdd={onAdd}
                  onRemove={onRemove}
                  onAssign={onAssign}
                  onClick={onClick}
                  isSelected={checkIsSelected(member)}
                  showAddButton={showAddButton}
                  showFamilyAssigned={showFamilyAssigned && !!member.familyId}
                  showRemoveButton={showRemoveButton && members.length > 1}
                  showAssignButton={showAssignButton}
                  showAddDisabled={showAddDisabled}
                  showShareIcon={!selectedFamilyId && familyMembers.length === 0}
                />
              );
            })
        : null}
      {total > members.length ? (
        <div id={isFamilyTreeContent ? 'noshowId' : 'loadingMoreId'}>Loading ....</div>
      ) : null}
    </div>
  );
};

const useStyles = makeStyles()({
  listContainer: {
    overflowY: 'auto'
  }
});
