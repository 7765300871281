import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// MATERIAL UI
// PROJECT FILES
import { closeDrawer, openModal } from '../../actions/common.actions';
import icon_close from '../../assets/icons/icon_cross.svg';
import sampark_logo from '../../assets/icons/sampark.svg';
import getTabOptions from '../../util/getTabOptions';
import { HEADER_TABS } from '../../constants/tabGroups';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import getMenuOptions from '../../util/getMenuOptions';
import { initializeContactForm } from '../../actions/contactForm.actions';
import { MY_ACCOUNT, SETTINGS, LOGOUT } from '../../constants/menuOptions';
import { USER_MENU } from '../../constants/menus';
import { SIGNOUT } from '../../constants/routes';
import * as MODAL_COMPONENTS from '../../constants/modals';
import { checkUserPermission, USERROUTES, ISSUPERADMIN } from '../../util/userPermission';
import { growthbook } from '../../helpers/growthbook';

class NavPanel extends Component {
  state = {
    tabValues: []
  };

  componentDidMount() {
    const { isSuperAdmin, userRoutes } = this.props;
    const tabValues = getTabOptions(isSuperAdmin, HEADER_TABS, userRoutes);

    this.setState({
      tabValues: tabValues
    });
  }

  handleLogoutUser() {
    const { history } = this.props;
    history.push(SIGNOUT.value);
  }

  handleMenuClose = option => {
    const { initializeContactForm, openDrawer, openModal, userAccount } = this.props;
    if (option) {
      switch (option.value) {
        case MY_ACCOUNT.value:
          initializeContactForm(userAccount);
          openModal(MODAL_COMPONENTS.CONTACT_INFO_READ_ONLY);
          break;
        case SETTINGS.value:
          break;
        case LOGOUT.value:
          this.handleLogoutUser();
          break;
        default:
      }
    }
  }

  topRow = () => {
    const { closeDrawer } = this.props;
    return (
      <div className='top-row'>
        <img src={sampark_logo} />
        <img
          className='event-form-dismiss'
          onClick={closeDrawer}
          src={icon_close} />
      </div>
    );
  }

  tabs = (isYogAdmin) => {
    const { tabValues } = this.state;

    return (
      <div className='nav-panel-tabs'>
        {tabValues.map((route, index) => {
          if (route.name === 'Yog Admin' && !isYogAdmin) return null;
          return (
            <NavLink
              key={index}
              className='navLink'
              activeClassName='activeNavLink'
              onClick={this.props.closeDrawer}
              to={route.value}>
              {route.name}
            </NavLink>
          );
        })}
      </div>
    );
  }

  bottomRow = () => {
    const { userAccessObject, history } = this.props;
    const menuOptions = getMenuOptions(USER_MENU, {
      pathname: history.location.pathname,
      userAccessObject
    });

    const nonDisabled = menuOptions.filter(option => !option.disabled);

    return (
      <div className='bottom-section'>
        {nonDisabled.map((option, index) => {
          return (
            <div
              key={index}
              className='panel-buttons'
              onClick={() => {
                this.handleMenuClose(option);
                this.props.closeDrawer();
              }}>
              {option.name}
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const isYogAdmin = growthbook.getFeatureValue('sampark.yog-admin-tab');

    return (
      <div className='nav-panel'>
        {this.topRow()}
        <div className='nav-panel-buttons'>
          {this.tabs(isYogAdmin)}
          {this.bottomRow()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isSuperAdmin: checkUserPermission(state.userAccess, ISSUPERADMIN),
    userRoutes: checkUserPermission(state.userAccess, USERROUTES),
    userAccessObject: state.userAccess.userAccessObject,
    userAccount: state.userAccess.userAccount
  };
}

export default withRouter(connect(mapStateToProps,
  {
    closeDrawer,
    initializeContactForm,
    openModal
  })(NavPanel));

NavPanel.propTypes = {
  isSuperAdmin: PropTypes.bool.isRequired,
  userRoutes: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  initializeContactForm: PropTypes.func.isRequired,
  openDrawer: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  userAccessObject: PropTypes.object.isRequired,
  userAccount: PropTypes.object.isRequired
};
