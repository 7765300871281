import { RootState } from '../../../reducers/index.types';
import { ISSUPERADMIN, checkUserPermission } from '../../../util/userPermission';

export const isSuperAdminSelector = (state: RootState) => {
  const userAccess = state.userAccess;
  return checkUserPermission(userAccess, ISSUPERADMIN);
};

export const getEvents = (state: RootState) => {
  return state?.admin?.yogAdmin?.events;
};
