import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { dashboardActions, commonActions } from '../constants/actions';
import { DASHBOARD_DATA_ERROR } from '../constants/toasterMessages';
import { getDashboard } from '../apis/dashboardAPIs';

dayjs.extend(utc);

export function getDashboardData () {
  return async (dispatch) => {
    dispatch({ type: dashboardActions.SHOW_HIDE_DASHBOARD_LOADER, value: true });

    const dashboardData = await getDashboard();
    if (dashboardData) {

      if (dashboardData.birthdays && dashboardData.birthdays instanceof Array && dashboardData.birthdays.length) {

        const birthdays = dashboardData.birthdays.map((item) => {
          let location;
          let state;
          if (item.location) {
            const locationSplit = item.location.split(', ');
            location = locationSplit[0];
            state = locationSplit[1];
          }
          const birthday = dayjs.utc(item.birthDate).set('year', dayjs().get('year'));

          return {
            label: item.name,
            value: birthday.format('ddd'),
            valueSecondary: birthday.format('MMM DD'),
            location: location || item.location,
            state: location && state ? state : null,
            date: birthday.toString()
          };
        });

        dashboardData.birthdays = birthdays;

      }

      if (dashboardData.mukto) {
        if (dashboardData.mukto.lastFiveUpdatedMukto && dashboardData.mukto.lastFiveUpdatedMukto.members && dashboardData.mukto.lastFiveUpdatedMukto.members instanceof Array && dashboardData.mukto.lastFiveUpdatedMukto.members.length) {
          const recentlyUpdated = dashboardData.mukto.lastFiveUpdatedMukto.members.map((member) => {
            return {
              label: member.firstName + ' ' + member.lastName,
              location: member.addressDetails.city,
              state: member.addressDetails.state,
              ...member
            };
          });
          dashboardData.recentlyUpdated = recentlyUpdated;
        }
      }

      return dispatch({ type: dashboardActions.GET_DASHBOARD_DATA, value: dashboardData });
    } else {
      dispatch({ type: dashboardActions.SHOW_HIDE_DASHBOARD_LOADER, value: false });
      dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: DASHBOARD_DATA_ERROR, type: commonActions.ERROR_TOASTER } });
    }
  };
}

const date_sort_asc = function (item1, item2) {
  if (item1.date > item2.date) return 1;
  if (item1.date < item2.date) return -1;
  return 0;
};
