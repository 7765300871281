import * as React from 'react';
import PropTypes from 'prop-types';
import { CheckboxField } from '../CheckboxField';
import { FieldLabel } from './FieldLabel';

export const PermissionSelectorField = ({
  accessConfig,
  manageUserPermissions,
  disabled,
  onChange,
  render,
  displayLabel,
  name,
  label,
  required,
  tooltip
}) => {
  if (render === false) {
    return null;
  }
  const _onChange = (e, value) => {
    const newManageUserPermissions = {...manageUserPermissions};
    newManageUserPermissions[e] = value;
    if (value === false) delete newManageUserPermissions[e];
    onChange(name, newManageUserPermissions);
  };
  if (!manageUserPermissions) {
    return null;
  }

  return (
    <div className={'access-container__panel-control'}>
      {label && <FieldLabel label={label} required={required} tooltip={tooltip} />}
      <div className={'access-container__panel-permissions-container'}>
        {
          accessConfig.map((item) => {
            const input = manageUserPermissions[item.key] ? true : false;
            return (
              <CheckboxField
                label={item.name + ' ' + displayLabel}
                input={input}
                name={item.key}
                key={item.key}
                disabled={item.default || disabled}
                inline
                onChange={_onChange}
              />
            );
          })}
      </div>
    </div>
  );
};

PermissionSelectorField.propTypes = {
  manageUserPermissions: PropTypes.object,
  accessConfig: PropTypes.array,
  disabled: PropTypes.bool,
  displayLabel: PropTypes.string,
  onChange: PropTypes.func,
  render: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  tooltip: PropTypes.string
};
