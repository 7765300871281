import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  fieldInput: {
    color: 'hsl(206, 29%, 23%)',
    fontSize: '1rem',
    height: '40px',
    border: '1px solid hsla(225, 19%, 60%, 1); ',
    borderRadius: '3px',
    paddingLeft: '16px',
    ':hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)'
    },
    '::placeholder': {
      fontFamily: 'inherit',
      Weight: 400,
      fontSize: '16px'
    }
  },
  numberInput: {
    fontSize: '1rem',
    color: 'hsl(206, 29%, 23%)',
    width: '74px',
    height: '40px',
    border: '1px solid hsla(225, 19%, 60%, 1) ',
    borderRadius: '3px',
    paddingLeft: '16px',
    ':invalid': {
      borderColor: 'red'
    }
  }
}));
