import { sambandhApprovalActions, commonActions } from '../constants/actions';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { normalizePhone } from '../util/normalizePhone';
import { updateSambandhUserStatus } from '../apis/sambandhAPIs';
import { DECLINED } from '../constants/status';
import { REJECT_SAMBANDH_FORM, SAMBANDH_APPROVAL_FORM } from '../constants/forms';
import {ActionCreators} from '../views/Admin/SambandhAdmin/sambandhAdmin.duck';

dayjs.extend(utc);

export function initializeSambandhApprovalForm (user) {
  return (dispatch) => {
    dispatch({ type: sambandhApprovalActions.INITIALIZE_SAMBANDH_FORM, payload: mapUserToForm(user) });
  };
}

function mapUserToForm (userFromState) {
  let clickedUser = { };
  clickedUser._id = userFromState._id;
  clickedUser.id = userFromState._id;
  clickedUser.firstName = userFromState.firstName;
  clickedUser.lastName = userFromState.lastName;
  clickedUser.nickName = userFromState.nickName;
  clickedUser.birthDate = userFromState.birthDate ? dayjs(userFromState.birthDate).utc().format('YYYY-MM-DD') : '';
  clickedUser.gender = userFromState.gender;
  clickedUser.maritalStatus = userFromState.maritalStatus;
  clickedUser.muktType = userFromState.muktType || [];
  clickedUser.contactDetails = {
    email: '',
    cell: '',
    home: ''
  };
  clickedUser.contactDetails.email = userFromState.contactDetails.email;

  if (userFromState.contactDetails.phones && userFromState.contactDetails.phones.length > 0) {
    userFromState.contactDetails.phones.forEach(phone => {
      switch (phone.type) {
        case 'Cell':
          clickedUser.contactDetails.cell = normalizePhone(phone.value);
          break;
        case 'Home':
          clickedUser.contactDetails.home = normalizePhone(phone.value);
          break;
        default:
          throw new Error(`Phone of type ${phone.type} is not valid`);
      }
    });
  } else {
    clickedUser.contactDetails.cell = '';
    clickedUser.contactDetails.home = '';
  }

  clickedUser.addressDetails = userFromState.addressDetails ? userFromState.addressDetails : {
    city: '',
    state: ''
  };
  clickedUser.reference = userFromState.reference ? userFromState.reference : {
    referredBy: '',
    notes: ''
  };
  clickedUser.profession = userFromState.profession ? userFromState.profession : {
    company: '',
    jobTitle: ''
  };
  clickedUser.interests = userFromState.interests || [];
  clickedUser.profileImageUrl = userFromState.profileImageUrl;
  clickedUser.updatedAt = userFromState.updatedAt ? dayjs(userFromState.updatedAt).format('MM/DD/YYYY') : '';
  clickedUser.isNewContact = false;
  clickedUser.invitationStatus = userFromState.invitationStatus;
  clickedUser.userID = userFromState.userID;

  return clickedUser;
}

export function setPendingSambandhUserStatus (status) {
  return async (dispatch, getState) => {
    const form = getState().form[SAMBANDH_APPROVAL_FORM];
    if (form) {
      const { values } = form;
      if (values) {
        const { _id } = values;
        let notes = null;
        if (status === DECLINED.status) {
          notes = getState().form[REJECT_SAMBANDH_FORM].values.notes;
        }
        const data = await updateSambandhUserStatus(_id, status, notes);

        if (data.error) {
          dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: `Error - could not ${status.toLowerCase()} user`, type: commonActions.ERROR_TOASTER } });
        } else {
          dispatch(ActionCreators.fetchSambandhApprovals());
          dispatch({ type: sambandhApprovalActions.SUBMIT_SAMBANDH_APPROVAL });
          dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: `Sambandh user successfully ${status.toLowerCase()}`, type: commonActions.SUCCESS_TOASTER } });
        }
        return data;
      }
    }
  };
}
