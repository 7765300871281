export enum RespState {
  READY,
  LOADING,
  LOADED,
  LOAD_ERROR,
  CREATING,
  CREATED,
  CREATE_ERROR,
  UPDATING,
  UPDATED,
  UPDATE_ERROR,
  DELETING,
  DELETED,
  DELETED_ERROR,
  DELETE_CONFIRM,
  ROLLING_BACK,
  ROLLED_BACK,
  ROLLBACK_ERROR
}

export interface IAttributes {
  _id?: string;
  key: string;
  description?: string;
  group?: string;
  definition: {
    fact: string;
    operator: string;
    value: string[] | string;
    path?: string;
    field?: string;
  };
  field?: any;
}

export interface ILabelValues {
  label: string;
  val: string;
}

export interface IAllFields {
  name: string;
  datatype: string;
  labelValues: ILabelValues[];
  multiSelect: boolean;
  path: string;
  label: string;
}

export interface IAttributeField {
  name: string;
  datatype: string;
  labelValues: [];
  path: string;
  multiSelect: boolean;
}

export interface IAttributeAdminRedux {
  attributes: IAttributes[];
  getFieldsApiState: RespState;
  attributesApiState: RespState;
  allFields: IAllFields[];
  attribute: IAttributes;
  allAttributeGroups: string[];
  previousAttribute: IAttributes[];
}

export interface IValueType {
  value: string;
  label: string;
}

export interface IAttributeGroupDropdown {
  attributeGroup: string;
  handleAttributeGroup: (groupObject: IValueType) => void;
  allAttributeGroups: { value: string; label: string }[];
}

export interface IDescriptionField {
  description: string;
  handleDescription: (value: string) => void;
}
export interface INameField {
  name: string;
  handleName: (value: string) => void;
}

export interface IValueDropdown {
  field: IAllFields | undefined;
  handleOperator: (operator: { val: string; label: string }) => void;
  operator: { val: string; label: string };
}

export interface ISearchSelect {
  inputValue?: any;
  options: ILabelValues[];
  multiple?: boolean;
  className?: string;
  placeholder?: string;
  onChange: (value: string[] | string) => void;
}

export interface ITextFieldProps {
  label: string;
  tooltipValue?: string;
  children: React.ReactNode;
  width?: string;
}

export interface IAttributeCompose {
  handleAttributeFilter: (value: string) => void;
  clearHandler: () => void;
  value: string;
}
