import { commonActions } from '../../constants/actions';

export function showToaster (message, status) {
  return async (dispatch, getState) => dispatch({
    type: commonActions.SHOW_HIDE_TOASTER,
    value: {
      displayToaster: true,
      message,
      type: status
    } });
}
