import Snackbar from '@mui/material/Snackbar';
import { ReactComponent as CloseIcon } from '../../assets/icons/icon_close_mini.svg';
import { ToastIcon } from './ToastIcon';
import { useToasterStyles } from './Toaster.styles';

interface IToasterProps {
  displayToaster: boolean;
  hideToaster: () => void;
  toasterMessage?: string;
  toasterMessageType?: string;
}

export const Toaster = ({
  displayToaster,
  hideToaster,
  toasterMessage,
  toasterMessageType
}: IToasterProps) => {
  const { classes, cx } = useToasterStyles();

  return (
    <Snackbar
      open={displayToaster}
      onClose={hideToaster}
      message={
        <div className={cx(classes.messageContainer)}>
          <ToastIcon toasterMessageType={toasterMessageType} />
          <span>{toasterMessage}</span>
        </div>
      }
      action={<CloseIcon className={classes.closeIcon} onClick={hideToaster} />}
    />
  );
};
