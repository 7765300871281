import { useDispatch } from 'react-redux';
import { commonActions } from '../../../../constants/actions';

interface IUseAPI {
  successMessage: string;
  errorMessage: string;
  apiFunction: (dataObject: any) => Promise<any>;
  dataObject: any;
}

const useAPI = () => {
  const dispatch = useDispatch();

  // This function is used to call the API and show the toaster message based on the response
  const callApi = async ({ successMessage, errorMessage, apiFunction, dataObject }: IUseAPI) => {
    const updateContactResponse = await apiFunction(dataObject);
    if (!updateContactResponse.error) {
      dispatch({
        type: commonActions.SHOW_HIDE_TOASTER,
        value: {
          displayToaster: true,
          message: successMessage,
          type: commonActions.SUCCESS_TOASTER
        }
      });
    } else {
      const { error } = updateContactResponse;
      let message;
      if (error && error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message;
      } else {
        message = errorMessage;
      }
      dispatch({
        type: commonActions.SHOW_HIDE_TOASTER,
        value: { displayToaster: true, message, type: commonActions.ERROR_TOASTER }
      });
    }
  };

  return { callApi };
};
export default useAPI;
