import dayjs from 'dayjs';

export function calculateAge(birthDate: string) {
  if (!birthDate || !dayjs(birthDate).isValid()) {
    return;
  }
  const diff = dayjs().diff(birthDate, 'year');
  if (diff < 0) {
    return 0;
  } else {
    return diff;
  }
}
