import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { eventFormActions, commonActions } from '../constants/actions';
import { uploadImagesAPI } from '../apis/commonAPIs';
import { submitEvent } from '../apis/eventAPIs';
import { EVENT_FORM } from '../constants/forms';
import {
  EVENTS_UPDATE_ERROR,
  EVENTS_UPDATE_SUCCESS,
  EVENTS_CREATE_ERROR,
  EVENTS_CREATE_SUCCESS,
  EVENT_PHOTO_UPLOAD_ERROR,
  EVENT_PHOTO_UPLOAD_NOT_FOUND
} from '../constants/toasterMessages';

dayjs.extend(utc);

export function initializeEventForm(event) {
  return async (dispatch, getState) => {
    dispatch({ type: eventFormActions.CLEAR_INITIAL_VALUES });
    // tell UI to show spinner
    dispatch({ type: eventFormActions.INITIALIZE_EVENT_START });
    // set initial values
    let eventToStore;

    if (!event) {
      eventToStore = Object.assign({}, createNewEvent());
    } else {
      eventToStore = Object.assign({}, await mapEventToForm(event));
    }

    if (event && event.images && event.images.length) {
      dispatch({ type: eventFormActions.UPDATE_EVENT_IMAGES, value: event.images });
    }
    dispatch({ type: eventFormActions.INITIALIZE_EVENT_FORM, value: eventToStore });

    // tell UI to show form
    dispatch({ type: eventFormActions.INITIALIZE_EVENT_DONE });
  };
};

function createNewEvent() {
  let newEvent = {
    type: 'one-time',
    images: [],
    duration: 'single day',
    start_date: '',
    end_date: '',
    contact: [
      {
        name: '',
        phone: ''
      }
    ],
    audiences: [],
    schedule: [{
      title: '',
      sessions: [
        {
          name: '',
          startTime: '',
          startTimePeriod: '',
          endTime: '',
          endTimePeriod: ''
        }
      ]
    }]
  };

  return newEvent;
}

async function mapEventToForm(eventFormData) {
  const formData = JSON.parse(JSON.stringify(eventFormData));

  if (formData._id) {
    formData.id = formData._id;
    delete formData._id;
  }

  formData.start_date = eventFormData.start_date ? dayjs(eventFormData.start_date).utc().format('YYYY-MM-DD') : '';
  formData.end_date = eventFormData.end_date ? dayjs(eventFormData.end_date).utc().format('YYYY-MM-DD') : '';

  if (typeof formData.checkinInstructions === 'string' && formData.checkinInstructions.length) {
    formData.enableCheckinInstructions = true;
  }

  if (typeof formData.travelArrangementInstruction === 'string' && formData.travelArrangementInstruction.length) {
    formData.enableTravelArrangements = true;
  }

  // If no end_date provided, set the end_date to start_date
  if (!formData.end_date) {
    formData.end_date = formData.start_date;
  }
  return formData;
}

function mapFormToAPI(values) {
  const valuesToSubmit = { ...values };

  valuesToSubmit.start_date = valuesToSubmit.start_date ? dayjs(valuesToSubmit.start_date).utc().format('YYYY-MM-DD') : '';

  const isSingleDayEvent = valuesToSubmit.duration === 'single day';

  if (isSingleDayEvent) {
    valuesToSubmit.end_date = dayjs(valuesToSubmit.start_date).utc().format('YYYY-MM-DD');
  } else {
    valuesToSubmit.end_date = valuesToSubmit.end_date ?
      dayjs(valuesToSubmit.end_date).utc().format('YYYY-MM-DD') :
      dayjs(valuesToSubmit.start_date).utc().format('YYYY-MM-DD');
  }

  return valuesToSubmit;
}

export function submitEventForm() {
  return async (dispatch, getState) => {
    // removing userAccessObject from here - it was not used anywhere in mapFormToAPI
    const eventFormData = mapFormToAPI(getState().form[EVENT_FORM].values);

    let errorMessage;
    let successMessage;
    // Update
    if (eventFormData.id) {
      errorMessage = EVENTS_UPDATE_ERROR;
      successMessage = EVENTS_UPDATE_SUCCESS;
    } else {
      // Insert new
      errorMessage = EVENTS_CREATE_ERROR;
      successMessage = EVENTS_CREATE_SUCCESS;
    }
    dispatch({ type: eventFormActions.SHOW_HIDE_EVENT_FORM_LOADER, value: true });

    // Delete images property only when new images are present
    // Don't delete images property when copying event and not replacing image
    if (eventFormData.images && eventFormData.images.length) {
      eventFormData.images.forEach((image, index) => {
        if (image instanceof File) {
          eventFormData.images.splice(index, 1);
        }
      });
      if (eventFormData.images.length === 0) {
        delete eventFormData.images;
      }
    }

    const eventData = await submitEvent(eventFormData);
    if (!eventData || eventData.error) {
      dispatch({ type: eventFormActions.SHOW_HIDE_EVENT_FORM_LOADER, value: false });
      dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: errorMessage, type: commonActions.ERROR_TOASTER } });
    } else {
      dispatch({ type: eventFormActions.SHOW_HIDE_EVENT_FORM_LOADER, value: false });
      dispatch({ type: eventFormActions.SUBMIT_EVENT_FORM, value: eventFormData });
      dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: successMessage, type: commonActions.SUCCESS_TOASTER } });
      return eventData;
    }
  };
}

export function uploadEventFormImage(eventIDFromEvent = null) {
  return async (dispatch, getState) => {

    const currentState = getState();
    const eventFormFromRedux = currentState[EVENT_FORM];
    const eventFormValues = currentState.form[EVENT_FORM].values;

    let eventID;
    if (eventIDFromEvent) {
      eventID = eventIDFromEvent;
    } else {
      eventID = eventFormValues.id;
    }
    const eventImages = eventFormFromRedux.eventImages;

    if (eventImages && eventImages.length) {

      const eventImagesFormData = new FormData();
      eventImages.forEach((eventImage, index) => {
        eventImagesFormData.append('eventId', eventID);
        eventImagesFormData.append(`event`, eventImage, eventImage.name);
      });

      const eventImageUploadResults = await uploadImagesAPI(eventImagesFormData);
      if (eventImageUploadResults.error) {
        dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: EVENT_PHOTO_UPLOAD_ERROR, type: commonActions.ERROR_TOASTER } });
      }
      return dispatch({ type: eventFormActions.CLEAR_EVENT_IMAGES });
    } else {
      dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: EVENT_PHOTO_UPLOAD_NOT_FOUND, type: commonActions.ERROR_TOASTER } });
      return dispatch({ type: eventFormActions.CLEAR_EVENT_IMAGES });
    }
  };
}

export function updateEventImage(eventImageFile) {
  return (dispatch, getState) => {

    const currentState = getState();
    const eventForm = currentState[EVENT_FORM];
    let eventImages = eventForm.eventImages;
    if (eventImages && eventImages instanceof Array) {
      eventImages = [eventImageFile];
    }

    return dispatch({ type: eventFormActions.UPDATE_EVENT_IMAGES, value: eventImages });
  };
}

export function removeEventImage(eventImageFile) {
  return (dispatch, getState) => {

    const currentState = getState();
    const eventForm = currentState[EVENT_FORM];
    let eventImages = eventForm.eventImages;
    if (eventImages && eventImages instanceof Array && eventImages.length) {
      const foundFileToRemove = eventImages.find((file) => file.name === eventImageFile.name);
      if (foundFileToRemove) {
        eventImages.splice(foundFileToRemove, 1);
      }
    }

    return dispatch({ type: eventFormActions.UPDATE_EVENT_IMAGES, value: eventImages });
  };
}

export function resetEventForm() {
  return async (dispatch) => {
    dispatch({ type: eventFormActions.RESET_EVENT_FORM })
  }
}