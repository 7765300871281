import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import YogView from './YogView';
import { getRegisteredMembers, downloadRegisteredMembers, gotoNextPage, gotoPrevPage } from '../../actions/yog.actions';

const PAGE_SIZE = 15;

class Container extends Component {
  componentDidMount = () => {
    const { registeredMembers } = this.props;

    if (!(registeredMembers && registeredMembers.length)) {
      this.props.fetchData(this.props.page);
    }
  };

  render () {
    return (
      <YogView {...this.props}
        onDownloadReport={this.props.downloadReport}
        onNextPage={this.nextPage}
        onPrevPage={this.prevPage}
        pageSize={PAGE_SIZE}
      />
    );
  }

  downloadReport = () => {
    if (this.props.downloadReport) this.props.downloadReport();
  }

  prevPage = () => {
    if (this.props.page > 1) {
      this.props.gotoPrevPage();
      this.props.fetchData(this.props.page - 1);
    }
  };

  nextPage = () => {
    if (this.props.page < this.props.pages) {
      this.props.gotoNextPage();
      this.props.fetchData(this.props.page + 1);
    }
  };
}

Container.propTypes = {
  registeredMembers: PropTypes.any,
  fetchData: PropTypes.func,
  downloadReport: PropTypes.func,
  page: PropTypes.number,
  pages: PropTypes.number,
  gotoNextPage: PropTypes.func,
  gotoPrevPage: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    registeredMembers: state.yogReport.registeredMembers || [],
    page: state.yogReport.currentPage || 1,
    pages: Math.ceil(state.yogReport.count / PAGE_SIZE),
    totalCount: state.yogReport.count || 1
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: (pageNo) => {
      getRegisteredMembers(pageNo, PAGE_SIZE, dispatch);
    },
    downloadReport: () => {
      downloadRegisteredMembers(dispatch);
    },
    gotoNextPage: () => gotoNextPage(dispatch),
    gotoPrevPage: () => gotoPrevPage(dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
