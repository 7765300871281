import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const validate = (values, props) => {
  let errors = {};

  if (!values.title) {
    errors.title = 'Please enter a title';
  }

  if (!values.message) {
    errors.message = 'Please enter message content';
  }

  if (!values.schedule) {
    errors.schedule = 'Please schedule the message';
  }

  if (!values.audiences || values.audiences.length === 0) {
    errors.audiences = 'Please select audiences';
  }

  if (values.schedule) {
    const schedule = values.schedule;
    if (!schedule.when) {
      errors.schedule = {
        when: 'Please select when this message is to be sent'
      };
    }
    if (schedule.when === 'later') {
      const date = schedule.date;
      const time = schedule.time;
      const lowerLimit = dayjs().add(10, 'm');
      const upperLimit = dayjs().add(7, 'd');
      let whenLimit = [lowerLimit, upperLimit];

      if (!date) {
        errors.schedule = {
          date: 'Please select a date for the message to be sent'
        };
      }
      if (!time) {
        errors.schedule = {
          time: 'Please select a time for the message to be sent'
        };
      }
      if (time) {
        if (!time.value) {
          errors.schedule = {
            time: {
              value: 'Please select a time for the message to be sent'
            }
          };
        }
        if (!time.meridian) {
          errors.schedule = {
            time: {
              meridian: 'Please select AM or PM'
            }
          };
        }
      }

      if (date && time) {
        // if valid date
        const selectedTime = dayjs(`${schedule.date} ${schedule.time.value}${schedule.time.meridian}`, 'YYYY-MM-DD hh:mma');
        if (dayjs(selectedTime).isValid()) {
          // date cannot be before lower limit
          if (selectedTime.isBefore(whenLimit[0])) {
            errors.schedule = {
              date: 'Selected date & time cannot be within 10 minutes of current time',
              time: {
                value: 'Selected date & time cannot be within 10 minutes of current time',
                meridian: 'Selected date & time cannot be within 10 minutes of current time'
              }
            };
          }
          // date cannot be after upper limit
          if (selectedTime.isAfter(whenLimit[1])) {
            errors.schedule = {
              date: 'Selected date & time must be within 7 days of current time',
              time: {
                value: 'Selected date & time must be within 7 days of current time',
                meridian: 'Selected date & time must be within 7 days of current time'
              }
            };
          }
        }
      }
    }
  }
  return errors;
};

export default validate;
