import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LOGGEDINUSERINFOTOKEN } from '../config/config.tokens';
import UserMenu from './menus/UserMenu';
import ContactUsMenu from './menus/ContactUsMenu';
import ResponsiveHeaderMenu from './menus/ResponsiveHeaderMenu';

function Header(props: any) {
  const { history } = props;
  const [loggedInUser, setLoggedInUser] = useState<string | null>(null);

  useEffect(() => {
    if (localStorage.getItem(LOGGEDINUSERINFOTOKEN)) {
      setLoggedInUser(JSON.parse(localStorage.getItem(LOGGEDINUSERINFOTOKEN) ?? ''));
    }
  }, []);

  if (loggedInUser == null) {
    return null;
  }

  return (
    <div className="header-container">
      <div className="utility-row">
        {/*@ts-ignore */}
        <ResponsiveHeaderMenu classes={['responsive-header-menu']} history={history} />
        <div className="header-right-buttons">
          {/*@ts-ignore */}
          <ContactUsMenu classes={['header-contact-us-menu']} loggedInUser={loggedInUser} />
          <UserMenu classes={['header-user-menu']} history={history} />
        </div>
      </div>
    </div>
  );
}

Header.propTypes = {
  history: PropTypes.object.isRequired,
  tabName: PropTypes.string
};

export default Header;
