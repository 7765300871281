import { useEffect, useState } from 'react';

interface IIntersectionObserver {
  intersectedCallBack: IntersectionObserverCallback;
  root: Element | Document | null;
  target: Element | null;
  // should like margin,
  // positive to expand the view and negative to shrik the view.
  rootMargin?: string;
  threshold?: number | number[];
  subscribe?: boolean;
}
/*
 * Wrapper for intersection observer used so that is more react comtable.
 */
export const useIntersectionObserver = ({
  root,
  target,
  intersectedCallBack,
  rootMargin,
  threshold,
  subscribe = true
}: IIntersectionObserver) => {
  const options: IntersectionObserverInit = {
    rootMargin,
    threshold,
    root
  };
  const [intersectedObserver, setIntersectionObserver] = useState<IntersectionObserver>(
    new IntersectionObserver(intersectedCallBack, options)
  );
  useEffect(() => {
    if (subscribe) {
      const options: IntersectionObserverInit = {
        rootMargin,
        threshold,
        root: null
      };
      if (root != null) {
        options.root = root;
      }
      intersectedObserver.disconnect();
      const newIntersectionObserver = new IntersectionObserver(intersectedCallBack, options);
      if (newIntersectionObserver !== null) {
        if (target) {
          newIntersectionObserver?.observe(target);
        }
      }
      setIntersectionObserver(newIntersectionObserver);
    }
  }, [root, target, intersectedCallBack, subscribe]);
};
