import { Maybe } from '../../../../gql/generated';
import { capitalizeString } from '../../../../util/capitalizeString';

export const formatAddress = (
  address1: Maybe<string>,
  city: Maybe<string>,
  state: Maybe<string>,
  country: Maybe<string>
) => {
  return `${capitalizeString(
    `${address1 ? address1 + ',' : ''} ${city ? city + ',' : ''}`
  )} ${state?.toUpperCase()} ${country?.toUpperCase()}`;
};
