import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  containerFooter: {
    borderTop: '1px solid hsla(208, 30%, 82%, 1)',
    padding: '32px',
    marginLeft: '-16px',
    position: 'absolute',
    bottom: '0',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px'
  },

  footer: {
    width: '148px',
    height: '50px',
    padding: '14px 16px',
    gap: '10px',
    border: 'none',
    borderRadius: '3px',
    fontSize: '18px',
    fontWeight: '400',
    fontFamily: 'Rubik',
    cursor: 'pointer',
    '&.cancel': {
      backgroundColor: 'transparent',
      color: 'hsla(203, 94%, 39%, 1)',
      '&:hover': {
        backgroundColor: 'hsla(208, 30%, 82%, 0.2)'
      }
    },
    '&.submit': {
      backgroundColor: 'hsla(3, 100%, 66%, 1)',
      color: 'white',
      '&:hover': {
        backgroundColor: 'hsla(3, 100%, 50%, 1)'
      }
    }
  }
}));
