import React, { useState } from 'react';

interface IHoverImageProps {
  disabled?: boolean;
  hoverSrc: string;
  onClick?: (e: React.MouseEvent) => void;
  onMouseEnter?: () => void;
  onMouseExit?: () => void;
  src: string;
  style?: React.CSSProperties;
}

export const HoverImage = ({
  disabled,
  hoverSrc,
  onClick,
  onMouseEnter,
  onMouseExit,
  src,
  style
}: IHoverImageProps) => {
  const [imgSource, setImgSource] = useState(src);

  const handleClick = (e: React.MouseEvent) => {
    if (!disabled && onClick) {
      onClick(e);
    }
  };

  const mouseOver = () => {
    if (onMouseEnter) {
      onMouseEnter();
    }
    setImgSource(hoverSrc);
  };

  const mouseOut = () => {
    if (onMouseExit) {
      onMouseExit();
    }
    setImgSource(src);
  };

  return (
    <div
      className="hover-image-container"
      onMouseOver={mouseOver}
      onMouseOut={mouseOut}
      onClick={handleClick}
    >
      <img src={imgSource} style={style} />
    </div>
  );
};
