export const WordOfTheDayTemplate = {
  content: {
    english: '',
    gujarati: ''
  },
  title: {
    english: '',
    gujarati: ''
  }
};

export const SutraTemplate = {
  content: {
    english: '',
    gujarati: ''
  },
  title: {
    english: '',
    gujarati: ''
  }
};

export const PrasangTemplate = {
  content: {
    english: '',
    gujarati: ''
  },
  title: {
    english: '',
    gujarati: ''
  }
};

export const InspiringStoryTemplate = {
  content: {
    english: '',
    gujarati: ''
  },
  title: {
    english: '',
    gujarati: ''
  }
};
