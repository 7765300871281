import { UserByType } from '../../types';

// Tab enum
export enum EBroadcastViewTabs {
  'MY_MESSAGES',
  'APPROVAL_MESSAGES'
}

// Type of the Tab rendering Broadcast messages.
export interface IBroadcastViewTab {
  label: string;
  value: EBroadcastViewTabs;
}
interface ISchedule {
  at: string;
  when: string;
}

// Type of a Broadcast message.
// TODO: This should be used in the BroadcastListCard
// component, reducer & actiion.
export interface IBroadcastMessage {
  _id?: string;
  audiences: string[];
  createdAt?: string;
  createdBy?: UserByType;
  declinedReason?: string;
  message: string;
  schedule?: ISchedule;
  status?: string;
  title: string;
  updatedAt?: string;
  updatedBy?: UserByType;
}

// Props for the Tab label displayed above the list.
export interface ITabLabelProps {
  isActive: boolean;
  label: string;
}
