export interface ICountry {
  abbreviation: string;
  name: string;
  supportsAddressValidation?: boolean;
  symbol: string;
  value: string;
}
export const COUNTRIES: ICountry[] = [
  {
    abbreviation: 'us',
    value: 'us',
    name: 'United States',
    supportsAddressValidation: true,
    symbol: '🇺🇸'
  },
  // TODO: Uncomment all countries when international registrations are supported.
  {
    abbreviation: 'au',
    value: 'au',
    name: 'Australia',
    supportsAddressValidation: false,
    symbol: '🇦🇺'
  },
  {
    abbreviation: 'ca',
    value: 'ca',
    name: 'Canada',
    supportsAddressValidation: false,
    symbol: '🇨🇦'
  },
  {
    abbreviation: 'fr',
    value: 'fr',
    name: 'France',
    supportsAddressValidation: false,
    symbol: '🇫🇷'
  },
  {
    abbreviation: 'de',
    value: 'de',
    name: 'Germany',
    supportsAddressValidation: false,
    symbol: '🇩🇪'
  },
  {
    abbreviation: 'in',
    value: 'in',
    name: 'India',
    symbol: '🇮🇳'
  },
  {
    abbreviation: 'ky',
    value: 'ky',
    name: 'Kenya',
    symbol: '🇰🇪'
  },
  {
    abbreviation: 'mx',
    value: 'mx',
    name: 'Mexico',
    supportsAddressValidation: false,
    symbol: '🇲🇽'
  },
  {
    abbreviation: 'nz',
    value: 'nz',
    name: 'New Zealand',
    supportsAddressValidation: false,
    symbol: '🇳🇿'
  },
  {
    abbreviation: 'pa',
    value: 'pa',
    name: 'Panama',
    symbol: '🇵🇦'
  },
  {
    abbreviation: 'pl',
    value: 'pl',
    name: 'Poland',
    supportsAddressValidation: false,
    symbol: '🇵🇱'
  },
  {
    abbreviation: 'sl',
    value: 'sl',
    name: 'Scotland',
    symbol: '🏴󠁧󠁢󠁳󠁣󠁴󠁿'
  },
  {
    abbreviation: 'za',
    value: 'za',
    name: 'South Africa',
    symbol: '🇿🇦'
  },
  {
    abbreviation: 'ch',
    value: 'ch',
    name: 'Switzerland',
    supportsAddressValidation: false,
    symbol: '🇨🇭'
  },
  {
    abbreviation: 'tz',
    value: 'tz',
    name: 'Tanzania',
    symbol: '🇹🇿'
  },
  {
    abbreviation: 'ug',
    value: 'ug',
    name: 'Uganda',

    symbol: '🇺🇬'
  },
  {
    abbreviation: 'uae',
    value: 'uae',
    name: 'United Arab Emirates',
    symbol: '🇦🇪'
  },
  {
    abbreviation: 'gb',
    value: 'gb',
    name: 'United Kingdom',
    supportsAddressValidation: false,
    symbol: '🇬🇧'
  }
];

export const getCountryNameFromAbbreviation = (countryAbbreviation: string | undefined) => {
  return COUNTRIES.find((country) => country.abbreviation === countryAbbreviation)?.name;
};
