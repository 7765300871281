export type SortOption = {
  label: string;
  value: string;
};

export const sortOptions: SortOption[] = [
  {
    label: 'Last Updated',
    value: '-updatedAt'
  },
  {
    label: 'First Name (A-Z)',
    value: 'firstName'
  },
  {
    label: 'First Name (Z-A)',
    value: '-firstName'
  },
  {
    label: 'Last Name (A-Z)',
    value: 'lastName'
  },
  {
    label: 'Last Name (Z-A)',
    value: '-lastName'
  },
  {
    label: 'City (A-Z)',
    value: 'city'
  },
  {
    label: 'City (Z-A)',
    value: '-city'
  },
  {
    label: 'State (A-Z)',
    value: 'state'
  },
  {
    label: 'State (Z-A)',
    value: '-state'
  },
  {
    label: 'Email (A-Z)',
    value: 'email'
  },
  {
    label: 'Email (Z-A)',
    value: '-email'
  }
];

export type SortHeader = {
  name: string;
  value: string;
  className: string;
  sort?: boolean;
  sortDirection?: number;
};

export const sortHeaders: SortHeader[] = [
  {
    name: '',
    value: '',
    className: 'checkbox'
  },
  {
    name: '',
    value: '',
    className: 'contact-card-avatar'
  },
  {
    name: 'First Name',
    value: 'firstName',
    className: 'contact-card-first-name'
  },
  {
    name: 'Last Name',
    value: 'lastName',
    className: 'contact-card-last-name'
  },
  {
    name: 'Nick Name',
    value: 'nickName',
    className: 'contact-card-list-nick-name'
  },
  {
    name: 'City',
    value: 'city',
    className: 'contact-card-city'
  },
  {
    name: 'State',
    value: 'state',
    className: 'contact-card-state'
  },
  {
    name: 'Email',
    value: 'email',
    className: 'contact-card-email'
  },
  {
    name: 'Phone',
    value: 'phoneNumber',
    className: 'contact-card-phoneNumber'
  }
];
