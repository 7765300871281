import Button from '@material-ui/core/Button';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Dialog } from '@mui/material';
import dayjs from 'dayjs';
import { FC, useCallback, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { initializeEventForm } from '../../actions/eventForm.actions';
import { getEventsData } from '../../actions/events.actions';
import EventsPanel from '../../components/events-panel/EventsPanel';
import { useAppDispatch, useAppSelector } from '../../helpers/store';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { EventList } from './EventList';
import { EventViewProps, EventsType } from './eventsView.types';

const TABS = ['Upcoming Events', 'Past Events'];

export const EventsView: FC<EventViewProps> = () => {
  useSetDocumentTitle('Sampark | Events');

  const [activeTab, setActiveTab] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const eventsData = useAppSelector((state) => state.eventsData.events);
  const dispatch = useAppDispatch();
  const actions = bindActionCreators({ getEventsData, initializeEventForm }, dispatch);

  useEffect(() => {
    actions.getEventsData();
  }, []);

  const handleClickAddEvent = useCallback(() => {
    actions.initializeEventForm(null);
    setShowModal(true);
  }, []);

  const handleOpenEventModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleCloseEventModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const todayDate = dayjs();
  const filteredEventData = eventsData.filter((data: EventsType) => {
    const eventEndDate = dayjs(data.end_date);
    if (activeTab === 0) {
      return todayDate.isSameOrBefore(eventEndDate, 'day');
    }

    return todayDate.isAfter(eventEndDate, 'day');
  });

  // At mobile screen size, the modal should be full screen.
  const fullScreen = window.innerWidth < 768;

  return (
    <>
      <Dialog disableEscapeKeyDown fullScreen={fullScreen} maxWidth="md" open={showModal}>
        <EventsPanel onClose={handleCloseEventModal} />
      </Dialog>
      <div className="events-view-container">
        <header className="events-header">
          <div className="events-header-row1">
            <h2 className="events-title">Events</h2>
            <Button
              disableRipple
              id="addNewEventButton"
              onClick={handleClickAddEvent}
              className="button-primary button-orange"
              color="primary"
            >
              <span className="button-primary-label label-white">Add Event</span>
            </Button>
          </div>
          <Tabs
            className="events-tabs"
            value={activeTab}
            onChange={(__, activeTab) => setActiveTab(activeTab)}
          >
            {TABS.map((tab, index) => {
              return (
                <Tab
                  key={index}
                  value={index}
                  disableRipple
                  label={
                    <div
                      style={{
                        color: activeTab === index ? '#067bc2' : '#2a3d4c',
                        fontWeight: '500'
                      }}
                    >
                      {tab}
                    </div>
                  }
                />
              );
            })}
          </Tabs>
        </header>
        <section className="events-data-container">
          <EventList events={filteredEventData} onClickDetails={handleOpenEventModal} />
        </section>
      </div>
    </>
  );
};

export default EventsView;
