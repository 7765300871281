import { ReactComponent as DeleteIcon } from '../../../../../assets/icons/icon_delete_outline.svg';
import { IHeader } from '../../rules.types';
import { useStyles } from './header.styles';

export const Header = ({
  ruleSelected,
  handleOpenModal,
  isJsonType,
  handleFormatType,
  name
}: IHeader) => {
  const { classes } = useStyles();

  return (
    <div className={classes.containerHeader}>
      <h3>Edit Rules</h3>
      <div className={classes.containerOptions}>
        <div className={classes.formatter}>
          <button
            className={isJsonType ? classes.button : classes.selectedButton}
            onClick={(e) => handleFormatType(e, false)}
            formNoValidate
          >
            HTML Form
          </button>
          <button
            className={isJsonType ? classes.selectedButton : classes.button}
            onClick={(e) => handleFormatType(e, true)}
            formNoValidate
          >
            JSON Form
          </button>
        </div>
        {ruleSelected instanceof Array && ruleSelected.length > 0 && (
          <DeleteIcon className={classes.iconDelete} onClick={() => handleOpenModal(name)} />
        )}
      </div>
    </div>
  );
};
