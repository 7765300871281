import isEmpty from './isEmpty';
import { BROADCAST, EVENTS } from '../constants/permissions';
import { allPermissionRoutes } from '../constants/routes';
import { hasPermission } from './hasPermission';
import { growthbook } from '../helpers/growthbook';

export const ISSUPERADMIN = 'isSuperAdmin';
export const CANEXPORT = 'CONTACTS.EXPORT';
export const CANUPDATECONTACTS = 'CANUPDATECONTACTS';
export const CANVIEWCONTACTS = 'CANVIEWCONTACTS';
export const FECTCHUSERPERMISSIONS = 'FECTCHUSERPERMISSIONS';
export const FECTCHUSERACCESS = 'FECTCHUSERACCESS';
export const DISABLEGENDER = 'DISABLEGENDER';
export const GENDER = 'GENDER';
export const USERID = 'userAccount.id';
export const GETBROADCASTLOCATIONPERMISSIONS = 'GETBROADCASTLOCATIONPERMISSIONS';
export const GETEVENTSLOCATIONPERMISSIONS = 'GETEVENTSLOCATIONPERMISSIONS';
export const GETPERMISSIONS = 'userAccessObject.access';
export const USERROUTES = 'USERROUTES';
export const ADMINVIEWROUTES = 'ADMINVIEWROUTES';
export const ADMINTABS = 'ADMINTABS';
export const GETHOMEURL = 'GETHOMEURL';
export const CANDELETE = 'CANDELETE';
export const HASFOLLOWUP = 'HASFOLLOWUP';
export const CANEDITADMINATTRIBUTES = 'CANEDITADMINATTRIBUTES';

export function checkUserPermission(userAccess, permission) {
  if (!userAccess) return undefined;
  const isSuperAdmin = !!userAccess.isSuperAdmin;
  const userPermissions = userAccess.userAccessObject && userAccess.userAccessObject.access;
  const userAccount = userAccess.userAccount;
  switch (permission) {
    case ISSUPERADMIN:
      return isSuperAdmin;
    case CANEXPORT:
      return isSuperAdmin || !!(userPermissions && userPermissions.contacts && userPermissions.contacts.actions.export);
    case CANDELETE:
      return isSuperAdmin || !!(userPermissions && userPermissions.contacts && userPermissions.contacts.actions.delete);
    case CANEDITADMINATTRIBUTES:
      return isSuperAdmin || !!(userPermissions && userPermissions.contacts && userPermissions.contacts.actions.updateAdminAttributes);
    case CANUPDATECONTACTS:
      return !!(isSuperAdmin || (userPermissions && userPermissions.contacts?.actions?.update))
    case CANVIEWCONTACTS:
      return !!(isSuperAdmin || (userPermissions && userPermissions.contacts?.actions?.read));
    case FECTCHUSERACCESS:
      return isEmpty(userAccount);
    case DISABLEGENDER:
      return !isSuperAdmin;
    case USERROUTES: {
      const routes = allPermissionRoutes.map((r, i) => {
        const canUpdateContacts = checkUserPermission(userAccess, CANUPDATECONTACTS);
        if (userAccess && userAccess.userAccessObject) {
          const { access = {} } = userAccess.userAccessObject;
          const canAccess = hasPermission(r.permission, userAccess.userAccessObject);
          if (r.name === 'Admin') {
            return canUpdateContacts ? r : undefined;
          }
          if (r.name === 'Yog Admin') {
            return canUpdateContacts && growthbook.getFeatureValue('sampark.yog-admin-tab') ? r : undefined;
          }
          if (canAccess) {
            return r;
          }
        }
      }) || [];
      return routes.filter(route => route);
    }
    case ADMINTABS: {
      const adminTabs = [];

      const canUpdateContacts = checkUserPermission(userAccess, CANUPDATECONTACTS);
      if (canUpdateContacts) {
        adminTabs.push({ name: 'Sambandh Approvals', value: '/sampark/admin/sambandhApprovals' });
        adminTabs.push({ name: 'Sampark Sevako', value: '/sampark/admin/followupKK' });
      }

      if (isSuperAdmin) {
        adminTabs.push({ name: 'Sampark Admins', value: '/sampark/admin/samparkAdmin' });
        // TODO: Check which levels should have access to this
        adminTabs.push({ name: 'Family Tree', value: '/sampark/admin/familyTree' });
        adminTabs.push({ name: 'Attributes', value: '/sampark/admin/attributes' });
        adminTabs.push({ name: 'Rules', value: '/sampark/admin/rules' });
      }

      return adminTabs;
    }
    case GETHOMEURL: {
      const route = allPermissionRoutes
        .find(r => Object.keys(userAccess).find(key => key === r.permission));

      return route && route.value;
    }
    case HASFOLLOWUP: {
      return isSuperAdmin || (userPermissions && userPermissions.followup && userPermissions.followup.actions);
    }
    default:
      break;
  }
};

export function getAccessObj(userAccess, FIELD, permissionKey) {
  const isSuperAdmin = getPermissionObject(userAccess, ISSUPERADMIN);
  switch (FIELD) {
    case ISSUPERADMIN:
      return isSuperAdmin;
    case GENDER:
      if (isSuperAdmin) return undefined;
      // When user's access object does not have gender - return user's gender
      return userAccess && userAccess.userAccount ? userAccess.userAccount.gender : undefined;
    case USERID:
      return getPermissionObject(userAccess, USERID);
    case GETBROADCASTLOCATIONPERMISSIONS: {
      if (isSuperAdmin) return undefined;
      const permissions = getPermissionObject(userAccess, GETPERMISSIONS);
      const broadcastPermissions = permissions && permissions[BROADCAST];

      return {
        isSuperAdmin,
        gender: getAccessObj(userAccess, GENDER, BROADCAST),
        permissions: broadcastPermissions
      };
    }
    case GETEVENTSLOCATIONPERMISSIONS: {
      if (isSuperAdmin) return undefined;
      const permissions = getPermissionObject(userAccess, GETPERMISSIONS);
      const eventsPermissions = permissions && permissions[EVENTS];
      return {
        isSuperAdmin,
        permissions: eventsPermissions
      };
    }
    case GETPERMISSIONS: {
      return !isSuperAdmin ? getPermissionObject(userAccess, GETPERMISSIONS) : undefined;
    }

  }
};

const getPermissionObject = (userAccessObject, field) => {
  if (!field || !userAccessObject || userAccessObject === {}) return undefined;
  if (field.indexOf('.') > -1) {
    let nestedAccessObject = userAccessObject;
    const accessArray = field.split('.');
    if (nestedAccessObject && accessArray.length > 0) {
      accessArray.forEach(path => { nestedAccessObject = nestedAccessObject[path]; });
    }
    return nestedAccessObject;
  }
  return userAccessObject[field];
};
