import { eventFormActions } from '../constants/actions';

const initialState = {
  event: null,
  formSubmitted: false,
  eventImages: [],
  target: { audiences: [] },
  initializing: false
};

export default function eventsFormReducer(
  state = { ...initialState },
  action) {
  switch (action.type) {
    case eventFormActions.CLEAR_INITIAL_VALUES:
      return {
        ...initialState
      };
    case eventFormActions.INITIALIZE_EVENT_START:
      return { ...state, initializing: true };
    case eventFormActions.INITIALIZE_EVENT_DONE:
      return { ...state, initializing: false };
    case eventFormActions.INITIALIZE_EVENT_FORM:
      return { ...state, event: action.value, formSubmitted: false };
    case eventFormActions.SUBMIT_EVENT_FORM:
      return { ...state, formSubmitted: true };
    case eventFormActions.UPDATE_EVENT_IMAGES:
      return { ...state, eventImages: action.value };
    case eventFormActions.CLEAR_EVENT_IMAGES:
      return { ...state, eventImages: [] };
    case eventFormActions.RESET_EVENT_FORM:
      return { ...state, event: null, formSubmitted: false }
    default:
      return state;
  }
};
