import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '24px'
  }
}));
