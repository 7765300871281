import { ListItemText, MenuItem, Select } from '@mui/material';
import { ILabelValues, ISearchSelect } from '../../attributes.types';
import { useStyles } from './searchSelect.styles';
import { MenuProps } from './MultiSelect';

export const SingleSelect = ({
  inputValue,
  onChange,
  options,
  placeholder,
  ...delegated
}: ISearchSelect) => {
  const { classes } = useStyles();
  const clickHandler = (val: string) => {
    onChange(val);
  };
  return (
    <Select
      {...delegated}
      placeholder={placeholder}
      value={inputValue}
      MenuProps={MenuProps}
      classes={{
        root: classes.rootSelect,
        outlined: classes.outlineSelect
      }}
      required
    >
      {options?.map((option: ILabelValues, index: number) => {
        const { label, val } = option;
        return (
          <MenuItem
            key={index}
            value={val}
            classes={{
              selected: classes.selected,
              root: classes.rootSelected
            }}
            onClick={() => clickHandler(val)}
          >
            <ListItemText primary={label[0].toUpperCase() + label.slice(1)} />
          </MenuItem>
        );
      })}
    </Select>
  );
};
