import type { IBroadcastMessage } from './BroadcastView.types';

// Returns a list of `messages` whose status appears in an array `status` or is equal to `status` (if string)
export const getMessagesOfStatus = (
  messages: IBroadcastMessage[],
  status: string | string[]
): IBroadcastMessage[] => {
  return messages.filter((message) => {
    if (typeof status === 'string') {
      return message.status === status;
    } else {
      return status.includes(message.status ?? '');
    }
  });
};
