import dayjs from 'dayjs';
import queryString from 'query-string';
import http from '../helpers/http';
import { API } from '../config/config.constants';
import { saveCSV } from '../util/saveCSV';

export function getContactsAPI(searchValue, currentPageNumber, contactFilterValues, sortValue, emailType) {
  return http
    .get(getMembersAPIURL(searchValue, currentPageNumber, contactFilterValues, sortValue, emailType))
    .then((response) => {
      let members = response.data.members;
      if (members) {
        return members;
      } else {
        return { error: response };
      }
    })
    .catch((error) => {
      return { error };
    });
}

export async function getContactByIdAPI(id) {
  const response = await http.get(API.CONTACTS_VIEW.GET_CONTACT_BY_ID(id));
  if (response && response.data) {
    return response.data;
  } else {
    return { error: response };
  }
}

export function addContactAPI(contact) {
  return http
    .post(API.CONTACTS_VIEW.MEMBERS_API, contact)
    .then((response) => {
      const data = response.data;
      if (data) {
        return response.data;
      } else {
        return { error: response };
      }
    })
    .catch((error) => {
      return { error: error };
    });
}

export function updateContactAPI(contact) {
  return http
    .put(API.CONTACTS_VIEW.MEMBERS_API, contact)
    .then((response) => {
      const data = response.data;
      if (data) {
        return data;
      } else {
        return { error: response };
      }
    })
    .catch((error) => {
      return { error: error };
    });
}

export const updateFollowUpContactAPI = (contact) => {
  return http.put(API.CONTACTS_VIEW.SET_FOLLOW_UP_KARYAKARTAS, contact)
        .then((data) => {
          if (data.status === 200) {
            return data
          } else {
            return { error: response };
          }
        })
        .catch((error) => {
          return { error: error };
        })
}

export function deleteContactsAPI(selectedContact) {
  return http
    .delete(API.CONTACTS_VIEW.DELETE_MEMBERS_API(selectedContact[0]))
    .then((response) => {
      const responseStatus = response.status;
      if (responseStatus === 200) {
        return responseStatus;
      } else {
        return { error: response };
      }
    })
    .catch((error) => {
      return { error };
    });
}

export function uploadContactAvatarAPI(formDataObject, id) {
  return http
    .post(API.CONTACTS_VIEW.UPLOAD_AVATAR(id), formDataObject)
    .then((response) => {
      const data = response.data;
      if (data) {
        return data;
      } else {
        return { error: response };
      }
    })
    .catch(({ error }) => {
      return { error: error };
    });
}

export function getContactOptionsAPI() {
  return http
    .get(API.CONTACTS_VIEW.GET_CONTACT_OPTIONS)
    .then((response) => {
      let data = response.data;
      if (data) {
        return data;
      } else {
        return { error: response };
      }
    })
    .catch(({ error }) => {
      return { error: error };
    });
}

export function getAudiencesListAPI() {
  return http
    .get(API.ACCESS.AUDIENCES)
    .then((response) => {
      let data = response.data;
      if (data) {
        return data;
      } else {
        return { error: response };
      }
    })
    .catch(({ error }) => {
      return { error: error };
    });
}

export function getExportDataAPI(exportFilterValues) {
  return http
    .get(getExportDataAPIURL(exportFilterValues))
    .then((response) => {
      if (response.status === 200 && response.data) {
        const filename = `report-${dayjs().format('MM-DD-YYYY')}.csv`;
        saveCSV(response.data, filename);
      }
    })
    .catch(({ error }) => {
      return { error: error };
    });
}

export function inviteContactToSamparkAPI(memberId) {
  return http
    .post(API.CONTACTS_VIEW.INVITE_MEMBER, { memberId })
    .then((response) => {
      const data = response.data;
      if (data) {
        return data;
      } else {
        return { error: response };
      }
    })
    .catch((failedResponse) => {
      const response = failedResponse.response;
      if (response) {
        const data = response.data;
        if (data) {
          const error = data.error;
          if (error) {
            return { error: error };
          } else {
            return { error: 'Error trying to invite contact' };
          }
        } else {
          return { error: 'Error trying to invite contact' };
        }
      } else {
        return { error: 'Error trying to invite contact' };
      }
    });
}

export function cancelInvitationAPI(contact) {
  return http
    .put(API.CONTACTS_VIEW.CANCEL_INVITE, { id: contact.id })
    .then((response) => {
      const data = response.data;
      if (data) {
        return data;
      } else {
        return { error: response };
      }
    })
    .catch(({ error }) => {
      return { error: error };
    });
}

export function getFilterCitySuggestionAPI(city) {
  return http
    .get(`${API.CONTACT_PANEL.GET_FILTER_CITY_SUGGESTIONS_URL}${city}`)
    .then((response) => {
      const data = response.data;
      return (data) || { error: response };
    })
    .catch(({ error }) => {
      return { error: error };
    });
}

function getMembersAPIURL(searchValue = '', pageNumber, contactFilterValues, sortValue, emailType) {
  let API_URL = API.CONTACTS_VIEW.MEMBERS_API;
  let query = {};

  if (typeof searchValue === 'string' && searchValue !== '') {
    query.q = searchValue;
  }

  query.pageNo = pageNumber;
  query.size = 50;

  query = getQuery(contactFilterValues, query);

  API_URL = queryString.stringifyUrl({
    url: API_URL,
    query
  });

  if (sortValue && typeof sortValue === 'string' && sortValue.length) {
    API_URL += `&sort=${sortValue}`;
  }

  return API_URL;
};

function getQuery(contactFilterValues, query) {
  if (contactFilterValues && typeof contactFilterValues === 'object' && Object.keys(contactFilterValues).length > 0) {
    const { city: cityState, state, maritalStatus, followUp, unverified, ...otherFilterValues } = contactFilterValues;

    query = {
      ...query,
      ...otherFilterValues
    };

    if (state && !cityState) {
      query.state = state;
    }
    if (cityState) {
      const [city, state] = cityState.split(', ');
      query.city = city.toLowerCase();
      query.state = state.toLowerCase();
    }

    if (maritalStatus) {
      Object.keys(maritalStatus).forEach(status => {
        if (maritalStatus[status] === true) {
          query.maritalStatus = query.maritalStatus ? [...query.maritalStatus, status] : [status];
        }
      });

    }

    if (followUp) {
      Object.keys(followUp).forEach(type => {
        if (followUp[type] === true) {
          query.followUp = type.toLowerCase();
        }
      });
    }

    if (unverified) {
      query.contactStatus = 'yog_pending';
    }
  }
  return query;
}

function getExportDataAPIURL(contactFilterValues) {
  let API_URL = API.CONTACTS_VIEW.GET_EXPORT_DATA;
  let query = {};
  let formatedContactFilter = { ...contactFilterValues };
  formatedContactFilter.muktType = contactFilterValues?.muktType?.map((muktType) => muktType.value);
  formatedContactFilter.adminAttributes = contactFilterValues?.adminAttributes?.map((adminAttribute) => adminAttribute.value);
  formatedContactFilter.attributes = contactFilterValues?.attributes?.map((attribute) => attribute.value);
  query = getQuery(formatedContactFilter, query);
  API_URL = queryString.stringifyUrl({
    url: API_URL,
    query
  });
  return API_URL;
}

export function verifyContactAPI(id) {
  return http
    .put(API.CONTACTS_VIEW.VERIFY_MEMBERS_API(id))
    .then((response) => {
      const data = response.data;
      if (data) {
        return data;
      } else {
        return { error: response };
      }
    })
    .catch((error) => {
      return { error: error };
    });
}
