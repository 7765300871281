import { makeStyles } from 'tss-react/mui';
import FilterButton from '../FilterButton/FilterButton';

interface FilterButtonGroupProps {
  buttons: { text: string; value: string }[];
  onClickHandler?: (value: string) => void;
  value: string;
}

const FilterButtonGroup = ({ buttons, onClickHandler, value }: FilterButtonGroupProps) => {
  const { classes } = useStyles();

  const handleFilterButtonClicked = (option: string, isSelected: boolean) => {
    if (!onClickHandler) {
      return;
    }
    if (onClickHandler) {
      onClickHandler(isSelected ? option : '');
    }
  };

  return (
    <div className={classes.container}>
      {buttons?.map(({ text, value: val }) => {
        return (
          <div key={`${text}-${val}`}>
            <FilterButton
              text={text}
              onClick={handleFilterButtonClicked}
              selected={value === val}
              value={val}
            />
          </div>
        );
      })}
    </div>
  );
};

const useStyles = makeStyles()({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
    '> div': {
      marginRight: '0.5rem'
    },
    '> div:last-child': {
      marginRight: '0'
    }
  }
});

export default FilterButtonGroup;
