import { useState, useEffect } from 'react';

/**
 *
 * @param value string value to debounce
 * @param delay delay (default to 500ms)
 * @returns value after debounce
 */
export const useDebounce = (value: string, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
};
