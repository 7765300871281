import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// material
import { Button } from '@material-ui/core';
// project files
import { openDrawer } from '../actions/common.actions';
import { initializeSambandhApprovalForm } from '../actions/sambandhApproval.actions';
import { DRAWER_COMPONENTS } from '../config/config.constants';
import { AvatarWrapper } from './Avatar/AvatarWrapper';
import * as USER_AUTH_STATUS from '../constants/status';

class SambandhApprovalCard extends React.Component {

  constructor (props) {
    super(props);
  }

  renderApprovalItems = (items) => {
    let list = [];

    if (items && items instanceof Array && items.length) {
      list = items.map((obj, index) => {
        const approvalStatus = obj.status;
        const disableButton = approvalStatus === USER_AUTH_STATUS.INVITED.status.toLowerCase();
        const { addressDetails } = obj;
        return (
          <div className='manage-data-approval-list-item' key={index}>
            <AvatarWrapper size={'44'} classes={['manage-data-avatar']} contact={obj} />
            <div className='manage-data-avatar-name-location'>
              <div className='manage-data-name'>{obj.firstName + ' ' + obj.lastName}</div>
              <div className='manage-data-location'>{`${addressDetails.city}, `}<span className='manage-data-location-state'>{`${addressDetails.state}`}</span></div> {/* ` */}
              <div className={['manage-data-user-status', approvalStatus].join(' ')}>{approvalStatus}</div>
            </div>
            <Button
              className={['manage-data-button', disableButton ? 'disable-button' : null].join(' ')}
              disabled={disableButton}
              onClick={() => {
                this.props.initializeSambandhApprovalForm(obj);
                this.props.openDrawer(DRAWER_COMPONENTS.SAMBANDH_APPROVAL);
              }}>
              View
            </Button>
          </div>
        );
      });
    }

    return list;
  }

  render () {

    const {
      items,
      title
    } = this.props;

    return (
      <div className='manage-data-card-container'>
        <header className='manage-data-card-header'>
          <h3 className='manage-data-card-title'>{title}</h3>
        </header>
        <div className='manage-data-items'>
          {this.renderApprovalItems(items)}
        </div>
      </div>
    );
  }
}

SambandhApprovalCard.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  openDrawer: PropTypes.func.isRequired,
  initializeSambandhApprovalForm: PropTypes.func.isRequired
};

export default connect(null, {openDrawer, initializeSambandhApprovalForm})(SambandhApprovalCard);
