import { keyframes } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

const show_toast = keyframes`
    0% { transform: translateX(0%)};
    80% {transform: translateX(-115%)};
    100% { transform: translateX(-110%)};`;

const hide_toast = keyframes`
    0% { transform: translateX(-110%)};
    40% {transform: translateX(-115%)};
    100% { transform: translateX(0%)};`;

const progress = keyframes`
  0% { width: 100%};
  100% { width: 0%};`;

const colorChange = keyframes`
  0% { background-position: 94% 0%};
  50% { background-position: 7% 100%};
  100% { background-position: 94% 0%};`;

export const useStyles = makeStyles<{
  textColor: string;
  backgroundColor: string;
  toast: string | undefined;
}>()((__, { textColor, backgroundColor, toast }) => ({
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      filter: 'brightness(50%)'
    }
  },
  actionHandlers: {
    gap: '7px',
    display: 'flex',
    alignItems: 'center'
  },
  undoButton: {
    border: 'none',
    fontSize: '18px',
    backgroundColor: `hsla(${backgroundColor}, 0.1)`,
    color: `hsl(${textColor})`,
    borderRadius: '7px',
    padding: '4px 14px',
    '&:hover': {
      background: `hsla(${backgroundColor}, 0.2)`
    },
    '&:focus': {
      background: `hsla(${backgroundColor}, 0.4)`,
      color: 'white'
    }
  },
  messageText: {
    color: `inherit`,
    textAlign: 'left'
  },
  message: {
    color: 'inherit',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    gap: '7px',
    fontSize: `${28 / 16}rem`
  },

  wrapper: {
    color: `hsl(${textColor})`,
    height: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    alignItems: 'center',
    backgroundColor: `hsl(${backgroundColor})`,
    '&::before': {
      height: '3px',
      width: '0%',
      backgroundImage: `linear-gradient(220deg, hsl(${backgroundColor}), hsl(${textColor}))`,
      content: "''",
      position: 'absolute',
      bottom: 0,
      left: 0,
      backgroundSize: '400% 400%',
      animation: `${
        toast === 'showToast'
          ? `${colorChange} 2000ms ease infinite, ${progress} 10000ms linear both`
          : undefined
      }`
    }
  },
  container: {
    isolation: 'isolate',
    zIndex: 2000,
    borderRadius: '10px',
    position: 'relative',
    overflow: 'hidden',
    filter: 'drop-shadow(0px 0px 16px hsla(0deg, 0%, 0%, 0.25))',
    animation: `${
      toast === 'showToast'
        ? `
        ${show_toast} 1000ms ease both`
        : toast === 'hideToast'
        ? `${hide_toast} 1000ms ease both`
        : undefined
    }`
  }
}));
