import dayjs from 'dayjs';
import * as APIs from '../apis/broadcastAPIs';
import { APPROVED, DECLINED } from '../constants/messageStatus';
import { broadcastFormActions, commonActions } from '../constants/actions';
import {
  BROADCAST_SUBMIT_ERROR,
  BROADCAST_SUBMIT_SUCCESS,
  BROADCAST_APPROVE_ERROR,
  BROADCAST_APPROVE_SUCCESS,
  BROADCAST_DECLINE_ERROR,
  BROADCAST_DECLINE_SUCCESS
} from '../constants/toasterMessages';
import { getAccessObj, GETBROADCASTLOCATIONPERMISSIONS, ISSUPERADMIN } from '../util/userPermission';

export function initializeBroadcastForm(message) {
  return async (dispatch, getState) => {
    dispatch({ type: broadcastFormActions.CLEAR_INITIAL_VALUES });
    // tell UI to show spinner
    dispatch({ type: broadcastFormActions.BROADCAST_FORM_INITIALIZING });

    // set initial values
    let messageToStore;
    if (message) {
      const value = await mapMessageToForm(message);
      messageToStore = Object.assign({}, value);
    } else {
      messageToStore = Object.assign({}, createNewMessage());
    }
    dispatch({
      type: broadcastFormActions.INITIALIZE_BROADCAST_FORM,
      value: messageToStore
    });

    // tell UI to show form
    dispatch({ type: broadcastFormActions.BROADCAST_FORM_INITIALIZED });
  };
};

function createNewMessage() {
  let newMessage = {
    title: '',
    message: '',
    schedule: {
      when: 'now'
    },
    audiences: [],
  };
  return newMessage;
}

async function mapMessageToForm(messageFromState) {
  let mappedMessage = {};

  mappedMessage._id = messageFromState._id;
  mappedMessage.title = messageFromState.title;
  mappedMessage.message = messageFromState.message;

  const messageSchedule = messageFromState?.schedule;

  // When creating a copy, we do not copy the schedule. So this value could be undefined.
  if (messageSchedule) {
    const dayjsSchedule = dayjs(messageSchedule?.at);
  
    const mappedSchedule = {
      when: messageSchedule?.when,
      date: dayjsSchedule.format('YYYY-MM-DD'),
      time: {
        value: dayjsSchedule.format('hh:mm'),
        meridian: dayjsSchedule.format('a')
      }
    };

    mappedMessage.schedule = mappedSchedule;
  }

  mappedMessage.status = messageFromState.status;
  mappedMessage.createdBy = messageFromState.createdBy;
  mappedMessage.declinedReason = messageFromState.declinedReason;
  mappedMessage.audiences = messageFromState.audiences;
  return mappedMessage;
}

export function submitBroadcastForm() {
  return async (dispatch, getState) => {
    const broadcastFormValues = getState().form.broadcastForm.values;
    const userAccessObject = getAccessObj(getState().userAccess, GETBROADCASTLOCATIONPERMISSIONS);
    const isSuperAdmin = getAccessObj(getState().userAccess, ISSUPERADMIN);
    // it consists of isSuperAdmin, gender, permissions

    if (!broadcastFormValues) {
      throw new Error('cannot submit broadcast form with no values');
    }
    const valuesToSubmit = mapFormToAPI(broadcastFormValues, userAccessObject, isSuperAdmin);
    const broadcastMessage = await APIs.createBroadcastMessage(valuesToSubmit);
    if (broadcastMessage && broadcastMessage._id) {
      dispatch({
        type: broadcastFormActions.SUBMIT_BROADCAST_FORM
      });
      dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: BROADCAST_SUBMIT_SUCCESS, type: commonActions.SUCCESS_TOASTER } });
    } else {
      // dispatch({
      //   type: broadcastFormActions.SUBMIT_BROADCAST_FORM_ERROR
      // });
      dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: BROADCAST_SUBMIT_ERROR, type: commonActions.ERROR_TOASTER } });
    }
  };
}

function mapFormToAPI(values, userAccessObject, isSuperAdmin) {
  const { schedule } = values;

  let valuesToSubmit = Object.assign({}, values, {
    schedule: {
      at: buildISOTimeFromSchedule(schedule),
      when: schedule.when
    },
  }
  );
  return valuesToSubmit;
}

function buildISOTimeFromSchedule(schedule) {
  if (schedule.when === 'later') {
    return dayjs(`${schedule.date} ${schedule.time.value}${schedule.time.meridian}`, 'YYYY-MM-DD hh:mma').format('YYYY-MM-DDTHH:mm');
  }
  return '';
}

export function approveBroadcastForm() {
  return async (dispatch, getState) => {
    const broadcastFormValues = getState().form.broadcastForm.values;
    const approveBroadcastMessage = await APIs.changeBroadcastMessageStatus(broadcastFormValues._id, APPROVED);
    if (approveBroadcastMessage && approveBroadcastMessage._id) {
      dispatch({
        type: broadcastFormActions.SUBMIT_BROADCAST_FORM
      });
      dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: BROADCAST_APPROVE_SUCCESS, type: commonActions.SUCCESS_TOASTER } });
    } else {
      dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: BROADCAST_APPROVE_ERROR, type: commonActions.ERROR_TOASTER } });
    }
  };
}

export function declineBroadcastForm() {
  return async (dispatch, getState) => {
    const broadcastFormValues = getState().form.broadcastForm.values;
    const declineBroadcastMessage = await APIs.changeBroadcastMessageStatus(broadcastFormValues._id, DECLINED, "");
    if (declineBroadcastMessage && declineBroadcastMessage._id) {
      dispatch({
        type: broadcastFormActions.SUBMIT_BROADCAST_FORM
      });
      dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: BROADCAST_DECLINE_SUCCESS, type: commonActions.SUCCESS_TOASTER } });
    } else {
      dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: BROADCAST_DECLINE_ERROR, type: commonActions.ERROR_TOASTER } });
    }
  };
}

export function resetBroadcastForm() {
  return async (dispatch) => {
    dispatch({ type: broadcastFormActions.RESET_BROADCAST_FORM })
  }
}
