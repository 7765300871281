import { useStyles } from './footer.styles';

export interface IFooter {
  closeRuleHandler: () => void;
  submitHandler: () => void;
}

export const Footer = ({ closeRuleHandler, submitHandler }: IFooter) => {
  const { classes } = useStyles();

  return (
    <div className={classes.containerFooter}>
      <button className={`${classes.footer} cancel`} onClick={closeRuleHandler}>
        Cancel
      </button>
      <button className={`${classes.footer} submit`} onClick={submitHandler}>
        Submit
      </button>
    </div>
  );
};
