import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// v1 files
import * as FORMS from '../../../constants/forms';
import * as USER_AUTH_STATUS from '../../../constants/status';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';

import { initFormSubmission } from '../../../actions/approvalForm.actions';
import { REQUIRED_FIELDS_ERROR } from '../../../constants/toasterMessages';
import { commonActions } from '../../../constants/actions';

// v2 files
import { AccessPanel } from '../../components/access-panel';
import { Header } from '../../components';
import { accessConfig } from './accessConfig';
import { getAccessObj, GETPERMISSIONS, checkUserPermission, ISSUPERADMIN } from '../../../util/userPermission';
import { showToaster } from '../../actions/Toaster.action';
import { initForm, changeAccess, changeActiveTab, resetForm } from './accessForm.ducks';
import { formatPermissionsToAPI } from '../../utils';
import AccessContainerFooter from './AccessContainerFooter';

const AccessContainerImpl = (props) => {

  useEffect(() => {
    // When the form unmounts, reset the form
    return () => {
      props.resetForm();
    }
  }, [])

  if (props.formStatus === undefined || (props.approval && props.manageUserId && props.approval._id !== props.manageUserId)) {
    props.initFormSubmission(); // approvalForm action
    props.initForm(props.approval); // accessForm action
    return null;
  }

  const _onChange = (key, value) => {
    props.changeAccess(value, props.approval);
  };

  const _onChangeTab = (activeTab) => {
    const { isFormValid } = props;
    if (!isFormValid) {
      _showToaster();
    }
    props.changeActiveTab(activeTab);
  };

  const _showToaster = () => {
    props.showToaster(REQUIRED_FIELDS_ERROR, commonActions.ERROR_TOASTER);
    return undefined;
  };
  // const _isManaging = (status) => (status === USER_AUTH_STATUS.APPROVED.status);

  const _isApproving = (status) => (status === USER_AUTH_STATUS.PENDING.status);

  const onSubmit = () => {
    const { manageUserId, manageUserPermissions, isFormDirty, isFormValid, isSuperAdmin } = props;
    if (!isFormDirty) return undefined;
    if (!isFormValid) return _showToaster();
    const formatPermissions = formatPermissionsToAPI(manageUserPermissions);

    return {
      isSuperAdmin,
      manageUserId,
      manageUserPermissions: formatPermissions || manageUserPermissions
    };
  };

  const onClose = () => {
    props.onClose(); // close AccessContainer Modal on submit
  };

  const _getFormNameFromStatus = (status) => {
    switch (status) {
      case USER_AUTH_STATUS.VERIFIED.status:
      case USER_AUTH_STATUS.APPROVED.status:
        return FORMS.APPROVAL_FORM;
      case USER_AUTH_STATUS.PENDING.status:
        return FORMS.APPROVAL_VIEW;
      default:
        throw new Error(`Cannot open access panel for ${status} status`);
    }
  };

  const {
    isSuperAdmin,
    userPermissions,
    approvedInviteeOptionsLoading,
    manageUserPermissions,
    activeTab,
    isFormDirty,
    isFormValid,
    formStatus,
    manageUserName,
    accessConfig,
    audiencesList,
    originalPermissions } = props;
  const disclaimer = 'All new account approvals need to be confirmed by your admin. You will be notified upon confirmation.';
  const form = formStatus && _getFormNameFromStatus(formStatus);

  return (
    <div className={'access-container'}>
      <Header user={manageUserName} />
      <div className='access-container__separator' />
      {!isSuperAdmin && <div className='approval-disclaimer maring-md-bottom'>{disclaimer}</div>}
      {
        approvedInviteeOptionsLoading ? (
          <LoadingSpinner />
        ) : (
          <AccessPanel
            accessConfig={accessConfig}
            isSuperAdmin={isSuperAdmin}
            activeTab={activeTab}
            name={'accessPanel'}
            manageUserPermissions={manageUserPermissions}
            userPermissions={userPermissions}
            onChange={_onChange}
            onChangeTab={_onChangeTab}
            disabled={_isApproving(formStatus)}
            originalPermissions={originalPermissions}
            audiencesList={audiencesList}
          />
        )
      }
      <AccessContainerFooter
        isFormDirty={isFormDirty}
        isFormValid={isFormValid}
        position={'fixed'}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const { APPROVAL_FORM, ACCESS_FORM } = FORMS;
  const userPermissions = getAccessObj(state.userAccess, GETPERMISSIONS) || {};
  const isSuperAdmin = checkUserPermission(state.userAccess, ISSUPERADMIN);
  const manageUserPermissions = state[ACCESS_FORM].manageUserPermissions;
  let activeTab = state[ACCESS_FORM].activeTab;

  if (!activeTab) {
    activeTab = Object.keys(accessConfig)[0];
  }
  return {
    approval: props.approval,
    isSuperAdmin,
    userPermissions,
    approvedInviteeOptionsLoading: state[APPROVAL_FORM].approvedInviteeOptionsLoading,
    manageUserPermissions,
    manageUserId: state[ACCESS_FORM].manageUserId,
    activeTab,
    isFormDirty: state[ACCESS_FORM].isFormDirty,
    isFormValid: state[ACCESS_FORM].isFormValid,
    formStatus: state[ACCESS_FORM].formStatus,
    manageUserName: state[ACCESS_FORM].manageUserName,
    originalPermissions: state[ACCESS_FORM].originalPermissions,
    accessConfig: accessConfig,
    audiencesList: state.contactsData.contactOptions.audiencesList,
  };
};

AccessContainerImpl.propTypes = {
  approval: PropTypes.object.isRequired,
  isSuperAdmin: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  userPermissions: PropTypes.object.isRequired,
  approvedInviteeOptionsLoading: PropTypes.bool.isRequired,
  formStatus: PropTypes.string,
  manageUserName: PropTypes.string,
  manageUserId: PropTypes.string,
  manageUserPermissions: PropTypes.object,
  originalPermissions: PropTypes.object,
  activeTab: PropTypes.string,
  isFormDirty: PropTypes.bool,
  isFormValid: PropTypes.bool,
  initFormSubmission: PropTypes.func.isRequired,
  showToaster: PropTypes.func,
  initForm: PropTypes.func,
  changeAccess: PropTypes.func,
  changeActiveTab: PropTypes.func,
  resetForm: PropTypes.func,
  accessConfig: PropTypes.object,
  audiencesList: PropTypes.object,
};

export const AccessContainer = connect(mapStateToProps, {
  initFormSubmission,
  showToaster,
  initForm,
  changeAccess,
  changeActiveTab,
  resetForm,
})(AccessContainerImpl);
