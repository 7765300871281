import { FC } from 'react';
import { EventsType } from './eventsView.types';
import { EventListCard } from './EventListCard';

export const EventList: FC<{ events: EventsType[]; onClickDetails: () => void }> = ({
  events,
  onClickDetails
}) => {
  return (
    <div className="events-cards-list-view">
      {events.map((event_data, index) => (
        <EventListCard key={index} event={event_data} onClickDetails={onClickDetails} />
      ))}
    </div>
  );
};
