import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as FamilyIcon } from '../../../../../assets/icons/family.svg';

const FamilyAssignedTag = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.tag}>
      <FamilyIcon />
      <h5 className={classes.text}>Family Assigned</h5>
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  tag: {
    background: '#E8EBF4',
    borderRadius: '100px',
    cursor: 'default',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0, 0, 1, 0)
  },
  text: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '14px',
    color: '#5F698B',
    margin: theme.spacing(0, 0, 0, 1)
  }
}));

export default FamilyAssignedTag;
