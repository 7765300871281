import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import FilterButton from '../FilterButton/FilterButton';

interface FilterButtonGroupProps {
  buttons: { text: string; value: string }[];
  onClickHandler?: (value: string[]) => void;
}

const FilterButtonGroup = ({ buttons, onClickHandler }: FilterButtonGroupProps) => {
  const { classes } = useStyles();
  const [filterValues, setFilterValues] = useState<string[]>([]);

  function handleButtonClick(enabled: boolean, value: string) {
    if (enabled) {
      const doesValueExist = filterValues.includes(value);
      if (!doesValueExist) {
        const updatedFilterValues = [...filterValues, value];
        setFilterValues(updatedFilterValues);
        if (onClickHandler) {
          onClickHandler(updatedFilterValues);
        }
      }
    } else {
      const updatedFilterValues = filterValues.filter((val) => val !== value);
      setFilterValues(updatedFilterValues);
      if (onClickHandler) {
        onClickHandler(updatedFilterValues);
      }
    }
  }

  return (
    <div className={classes.container}>
      {buttons?.map(({ text, value }) => {
        return (
          <div key={`${text}-${value}`}>
            <FilterButton
              text={text}
              onClick={(enabled: boolean) => handleButtonClick(enabled, value)}
              selected={filterValues.includes(value)}
            />
          </div>
        );
      })}
    </div>
  );
};

const useStyles = makeStyles()({
  container: {
    display: 'flex',
    '> div': {
      marginRight: '0.5rem'
    },
    '> div:last-child': {
      marginRight: '0'
    }
  }
});

export default FilterButtonGroup;
