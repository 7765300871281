import { ALL, PRASANG, STORY, SUTRA, WORDOFTHEWEEK } from '../../constants/contentTypes';

export const documentTitle = 'Sampark | Swadhyay';

export const tabValues: Array<{
  name: string;
  value: string;
}> = [
  {
    name: 'All Content',
    value: 'allContent'
  },
  {
    name: 'Approvals',
    value: 'approvals'
  }
];

export const CONTENT_FILTER_OPTIONS = [ALL, WORDOFTHEWEEK, SUTRA, PRASANG, STORY];
