import { IDescriptionField } from '../../../../attributes.types';
import { TextField } from '../../../TextField/TextField';
import { useStyles } from './field.styles';

export const DescriptionField = ({ description, handleDescription }: IDescriptionField) => {
  const { classes } = useStyles();

  return (
    <TextField
      label="Description"
      tooltipValue="This field can help in understanding what the attribute is about."
      width="large"
    >
      <input
        className={classes.fieldInput}
        placeholder="Description"
        value={description}
        type="text"
        onChange={(event) => handleDescription(event.target.value)}
        required
      />
    </TextField>
  );
};
