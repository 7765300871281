import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// MATERIAL UI
import { Button, AppBar, Toolbar } from '@material-ui/core';
import { approveReadingForm, declineReadingForm } from '../../actions/reading.panel.actions';

const ReadingManageFooter = (props) => {
  return (
    <AppBar
      position='sticky'
      className='panel-footer'>
      <Toolbar>
        <div
          className='contact-form-button-container padding-sm'>
          {SecondaryButton(props)}
          {PrimaryButton(props)}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default connect(null, {
  approveReadingForm,
  declineReadingForm,
})(ReadingManageFooter);

function SecondaryButton (props) {

  const buttonLabel = 'Decline';

  return (
    <Button
      className={['button-primary', 'button-red', 'margin-xsm-right'].join(' ')}
      onClick={props.declineReadingForm}
      disableRipple
      color='primary'>
      <span className={['button-primary-label', 'label-white'].join(' ')}>{buttonLabel}</span>
    </Button>
  );
}

function PrimaryButton (props) {
  const buttonLabel = 'Approve';
  let classes = [];
  classes.push('button-green');

  return (
    <Button
      disableRipple
      onClick={props.approveReadingForm}
      className={['button-primary', ...classes].join(' ')}
      color='primary'>
      <span className='button-primary-label label-white'>{buttonLabel}</span>
    </Button>
  );
}

PrimaryButton.propTypes = {
  approveReadingForm: PropTypes.func.isRequired
};

SecondaryButton.propTypes = {
  openModal: PropTypes.func.isRequired
};
