import jwtDecode from 'jwt-decode';
import { getMyUserAccountDataAPI } from '../apis/commonAPIs';
import { SAMPARKTOKEN } from '../config/config.tokens';
import { commonActions, userAccessActions } from '../constants/actions';
import { SAMPARK } from '../constants/applications';
import { GET_MY_ACCOUNT_DATA_ERROR } from '../constants/toasterMessages';
import { AppDispatch } from '../helpers/store';
import { IDecodedToken } from '../util/getLoggedInRedirectLink';

export function getUserAccessObject() {
  return (dispatch: AppDispatch) => {
    const RESPONSE = jwtDecode<IDecodedToken>(localStorage.getItem(SAMPARKTOKEN) || '').uType.find(
      (app) => app.name === SAMPARK
    );

    if (RESPONSE?.permissions) {
      const { permissions } = RESPONSE;
      if (permissions.isSuperAdmin) {
        dispatch({ type: userAccessActions.GET_IS_SUPER_ADMIN, value: permissions.isSuperAdmin });
      }
      dispatch({ type: userAccessActions.GET_USER_ACCESS_OBJECT, value: permissions });
    }
  };
}

export function getMyUserAccountData() {
  return async (dispatch: AppDispatch) => {
    const userAccountData = await getMyUserAccountDataAPI();
    if (userAccountData) {
      return dispatch({
        type: userAccessActions.GET_MY_USER_ACCOUNT,
        value: userAccountData
      });
    } else {
      dispatch({
        type: commonActions.SHOW_HIDE_TOASTER,
        value: {
          displayToaster: true,
          message: GET_MY_ACCOUNT_DATA_ERROR,
          type: commonActions.ERROR_TOASTER
        }
      });
    }
  };
}
