import { IVariant } from './toaster.types';

export const COLOR_BY_VARIANT: IVariant = {
  success: { 'background-color': '120, 90%, 96%', color: '120, 80%, 35%' },
  error: { 'background-color': '345, 90%, 96%', color: '345, 100%, 50%' },
  warning: { 'background-color': '40, 100%, 94%', color: '35, 100%, 46%' },
  notice: { 'background-color': '235,0%, 100%', color: '235, 100%, 50%' }
};

export const TOAST_VARIANTS = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning'
};
