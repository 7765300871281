import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Any: { input: any; output: any };
};

export type Address = {
  __typename: 'Address';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  lat?: Maybe<Scalars['String']['output']>;
  lon?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  lat?: InputMaybe<Scalars['String']['input']>;
  lon?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type Album = {
  __typename: 'Album';
  assets?: Maybe<Array<Asset>>;
  campaign?: Maybe<Campaign>;
  campaignId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy: Member;
  description?: Maybe<Scalars['String']['output']>;
  hasPublishedAssets?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  onBehalfOf?: Maybe<Scalars['String']['output']>;
  status: AlbumStatusType;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Member>;
};

export type AlbumFilterInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
};

export type AlbumInput = {
  assets?: InputMaybe<Array<InputMaybe<AssetInput>>>;
  campaignId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  hasPublishedAssets?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  onBehalfOf?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AlbumStatusType>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Album */
export enum AlbumStatusType {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type Asset = {
  __typename: 'Asset';
  albumId?: Maybe<Scalars['ID']['output']>;
  approverId?: Maybe<Member>;
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['ID']['output'];
  filename?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  metadata?: Maybe<Scalars['Any']['output']>;
  rawURL: Scalars['String']['output'];
  s3URL?: Maybe<Scalars['String']['output']>;
  sizeInMB?: Maybe<Scalars['Int']['output']>;
  status: AssetStatus;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  thumbnailURL?: Maybe<Scalars['String']['output']>;
  type: MediaType;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['ID']['output']>;
  videoOutput?: Maybe<VideoOutput>;
};

export type AssetConnection = {
  __typename: 'AssetConnection';
  assets: Array<Maybe<Asset>>;
  pageInfo: PageInfo;
};

export type AssetFilterInput = {
  albumName?: InputMaybe<Scalars['String']['input']>;
  approvalStatus?: InputMaybe<AssetStatus>;
  campaignName?: InputMaybe<Scalars['String']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<MediaType>;
};

export type AssetInput = {
  albumId?: InputMaybe<Scalars['ID']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['Any']['input']>;
  rawURL: Scalars['String']['input'];
  sizeInMB?: InputMaybe<Scalars['Int']['input']>;
  status?: AssetStatus;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  thumbnailURL?: InputMaybe<Scalars['String']['input']>;
  type: MediaType;
  videoOutput?: InputMaybe<VideoOutputInput>;
};

export enum AssetStatus {
  Approved = 'APPROVED',
  Autoapproved = 'AUTOAPPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

/** Asset approval */
export type AssetUpdateStatusInput = {
  assetID?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<AssetStatus>;
};

export type Campaign = {
  __typename: 'Campaign';
  approvers?: Maybe<Array<Scalars['String']['output']>>;
  campaignImage?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['ID']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageAssetRules?: Maybe<ImageAssetRules>;
  isAutoApprove?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  startDate?: Maybe<Scalars['String']['output']>;
  status: CampaignStatusType;
  updatedAt?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['ID']['output']>;
  uploaders?: Maybe<Array<Scalars['String']['output']>>;
  videoAssetRules?: Maybe<VideoAssetRules>;
  viewers?: Maybe<Array<Scalars['String']['output']>>;
};

export type CampaignFilterInput = {
  approvers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  status: Array<CampaignStatusType>;
  uploaders?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  viewers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CampaignInput = {
  approvers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  campaignImage?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  imageAssetRules?: InputMaybe<ImageAssetRulesInput>;
  isAutoApprove?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<CampaignStatusType>;
  uploaders?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  videoAssetRules?: InputMaybe<VideoAssetRulesInput>;
  viewers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/**
 * Campaign
 * Campaign lifecycle is defined by the status of the campaign
 * New campaign will be in PENDING status
 * Once campaign is started it will be in RUNNING status
 * Once campaign is ended it will be in DONE status
 * If campaign is rejected then it will be in REJECTED status
 * if campaign is not active then it will be in OFFLINE status
 * viewers are Audience roles who can view the assets
 * uploaders are Audience roles who can upload the assets
 * approvers are Audience roles who can approve the assets
 */
export enum CampaignStatusType {
  Done = 'DONE',
  Offline = 'OFFLINE',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Running = 'RUNNING'
}

export type ContactDetail = {
  __typename: 'ContactDetail';
  email?: Maybe<Scalars['String']['output']>;
  phones?: Maybe<Array<Maybe<Phone>>>;
};

export type ContactDetailInput = {
  additionalEmails?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email?: InputMaybe<Scalars['String']['input']>;
  phones?: InputMaybe<Array<InputMaybe<PhoneInput>>>;
};

export type CreateMemberInput = {
  addressDetails?: InputMaybe<AddressInput>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  birthDate?: InputMaybe<Scalars['String']['input']>;
  contactDetails?: InputMaybe<ContactDetailInput>;
  contactStatus?: InputMaybe<Scalars['String']['input']>;
  family?: InputMaybe<Scalars['String']['input']>;
  familyId?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  followUp?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  gender: Scalars['String']['input'];
  interests?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isActive: Scalars['Boolean']['input'];
  isSambandhUser?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  mandal?: InputMaybe<Scalars['String']['input']>;
  maritalStatus?: InputMaybe<Scalars['String']['input']>;
  muktType?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nickName?: InputMaybe<Scalars['String']['input']>;
  noReference?: InputMaybe<Scalars['Boolean']['input']>;
  profession?: InputMaybe<ProfessionInput>;
  profileImageUrl?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<ReferenceInput>;
  tags?: InputMaybe<TagsInput>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  userID?: InputMaybe<Scalars['ID']['input']>;
};

export type DeleteCampaignResponse = {
  __typename: 'DeleteCampaignResponse';
  message: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
};

export type DeleteMemberResponse = {
  __typename: 'DeleteMemberResponse';
  message: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
};

export type FollowUp = {
  __typename: 'FollowUp';
  _id?: Maybe<Scalars['String']['output']>;
  addressDetails?: Maybe<Address>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  profileImageUrl?: Maybe<Scalars['String']['output']>;
};

export type FollowUpInput = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  addressDetails?: InputMaybe<AddressInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  profileImageUrl?: InputMaybe<Scalars['String']['input']>;
};

export type ImageAssetRules = {
  __typename: 'ImageAssetRules';
  format: Array<Scalars['String']['output']>;
  sizeInMB?: Maybe<Scalars['Int']['output']>;
};

export type ImageAssetRulesInput = {
  format: Array<Scalars['String']['input']>;
  sizeInMB?: InputMaybe<Scalars['Int']['input']>;
};

export type InvitationStatus = {
  __typename: 'InvitationStatus';
  appname?: Maybe<Scalars['String']['output']>;
  invitedBy?: Maybe<Scalars['String']['output']>;
  sent?: Maybe<Scalars['Boolean']['output']>;
};

export enum MediaType {
  Audio = 'AUDIO',
  Document = 'DOCUMENT',
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export type Member = {
  __typename: 'Member';
  _id?: Maybe<Scalars['String']['output']>;
  addressDetails?: Maybe<Address>;
  audiences?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  birthDate?: Maybe<Scalars['String']['output']>;
  contactDetails?: Maybe<ContactDetail>;
  contactStatus?: Maybe<Scalars['String']['output']>;
  family?: Maybe<Scalars['String']['output']>;
  familyId?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  followUp?: Maybe<Array<Maybe<Member>>>;
  gender?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  interests?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  invitationStatus?: Maybe<Array<Maybe<InvitationStatus>>>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isSambandhUser?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  mandal?: Maybe<Scalars['String']['output']>;
  maritalStatus?: Maybe<Scalars['String']['output']>;
  muktType?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  nickName?: Maybe<Scalars['String']['output']>;
  noReference?: Maybe<Scalars['Boolean']['output']>;
  profession?: Maybe<Profession>;
  profileImageUrl?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Reference>;
  tags?: Maybe<Tags>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  userID?: Maybe<Scalars['String']['output']>;
};

export type MemberConnection = {
  __typename: 'MemberConnection';
  members?: Maybe<Array<Maybe<Member>>>;
  pageInfo: PageInfo;
};

export type MemberFilterInput = {
  adminAttributes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  attributes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  city?: InputMaybe<Scalars['String']['input']>;
  contactStatus?: InputMaybe<Scalars['String']['input']>;
  emailType?: InputMaybe<Scalars['String']['input']>;
  followUp?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  interests?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  mandal?: InputMaybe<Scalars['String']['input']>;
  maritalStatus?: InputMaybe<Scalars['String']['input']>;
  muktType?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  noReference?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type Metadata = {
  __typename: 'Metadata';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type MetadataInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type Mutation = {
  __typename: 'Mutation';
  addAlbum?: Maybe<Album>;
  createAsset?: Maybe<Asset>;
  createCampaign: Campaign;
  createMember: Member;
  deleteAlbum?: Maybe<DeleteAlbumResponse>;
  deleteAsset?: Maybe<DeleteAssetResponse>;
  deleteCampaign: DeleteCampaignResponse;
  deleteMember: DeleteMemberResponse;
  updateAlbum?: Maybe<Album>;
  updateAsset?: Maybe<Asset>;
  updateAssetStatus?: Maybe<Asset>;
  updateCampaign: Campaign;
  updateMember: Member;
};

export type MutationAddAlbumArgs = {
  input: AlbumInput;
};

export type MutationCreateAssetArgs = {
  input: AssetInput;
};

export type MutationCreateCampaignArgs = {
  input: CampaignInput;
};

export type MutationCreateMemberArgs = {
  input: CreateMemberInput;
};

export type MutationDeleteAlbumArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteAssetArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteCampaignArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteMemberArgs = {
  id: Scalars['String']['input'];
};

export type MutationUpdateAlbumArgs = {
  id: Scalars['ID']['input'];
  input: AlbumInput;
};

export type MutationUpdateAssetArgs = {
  id: Scalars['ID']['input'];
  input: AssetInput;
};

export type MutationUpdateAssetStatusArgs = {
  input: AssetUpdateStatusInput;
};

export type MutationUpdateCampaignArgs = {
  id: Scalars['ID']['input'];
  input: CampaignInput;
};

export type MutationUpdateMemberArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMemberInput;
};

export type PageInfo = {
  __typename: 'PageInfo';
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Phone = {
  __typename: 'Phone';
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type PhoneInput = {
  type?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type Profession = {
  __typename: 'Profession';
  company?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
};

export type ProfessionInput = {
  company?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
  __typename: 'Query';
  album?: Maybe<Album>;
  albums?: Maybe<AlbumConnection>;
  asset?: Maybe<Asset>;
  assets?: Maybe<AssetConnection>;
  campaign?: Maybe<Campaign>;
  campaigns?: Maybe<Array<Maybe<Campaign>>>;
  getMemberById?: Maybe<Member>;
  getMembers?: Maybe<MemberConnection>;
  publishedAlbums?: Maybe<AlbumConnection>;
  reviewAlbums?: Maybe<AlbumConnection>;
};

export type QueryAlbumArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAlbumsArgs = {
  filter?: InputMaybe<AlbumFilterInput>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryAssetArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAssetsArgs = {
  filter?: InputMaybe<AssetFilterInput>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCampaignArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCampaignsArgs = {
  filter?: InputMaybe<CampaignFilterInput>;
};

export type QueryGetMemberByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetMembersArgs = {
  filter?: InputMaybe<MemberFilterInput>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPublishedAlbumsArgs = {
  filter?: InputMaybe<PublishedAlbumsFilter>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortInput?: InputMaybe<SortInput>;
};

export type QueryReviewAlbumsArgs = {
  filter?: InputMaybe<ReviewAlbumsFilter>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortInput?: InputMaybe<SortInput>;
};

export type Reference = {
  __typename: 'Reference';
  notes?: Maybe<Scalars['String']['output']>;
  referredBy?: Maybe<Member>;
};

export type Tags = {
  __typename: 'Tags';
  adminAttributes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  attributes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  followUp?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type TagsInput = {
  adminAttributes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  attributes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  followUp?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateMemberInput = {
  addressDetails?: InputMaybe<AddressInput>;
  audiences?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  birthDate?: InputMaybe<Scalars['String']['input']>;
  contactDetails?: InputMaybe<ContactDetailInput>;
  contactStatus?: InputMaybe<Scalars['String']['input']>;
  family?: InputMaybe<Scalars['String']['input']>;
  familyId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  followUp?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  gender?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  interests?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isSambandhUser?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mandal?: InputMaybe<Scalars['String']['input']>;
  maritalStatus?: InputMaybe<Scalars['String']['input']>;
  muktType?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nickName?: InputMaybe<Scalars['String']['input']>;
  noReference?: InputMaybe<Scalars['Boolean']['input']>;
  profession?: InputMaybe<ProfessionInput>;
  profileImageUrl?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<ReferenceInput>;
  tags?: InputMaybe<TagsInput>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  userID?: InputMaybe<Scalars['ID']['input']>;
};

export type VideoAssetRules = {
  __typename: 'VideoAssetRules';
  duration?: Maybe<Scalars['Int']['output']>;
  format?: Maybe<Array<Scalars['String']['output']>>;
};

export type VideoAssetRulesInput = {
  duration?: InputMaybe<Scalars['Int']['input']>;
  format?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type VideoOutput = {
  __typename: 'VideoOutput';
  res480p?: Maybe<Scalars['String']['output']>;
  res720p?: Maybe<Scalars['String']['output']>;
  res1080p?: Maybe<Scalars['String']['output']>;
};

export type VideoOutputInput = {
  res480p?: InputMaybe<Scalars['String']['input']>;
  res720p?: InputMaybe<Scalars['String']['input']>;
  res1080p?: InputMaybe<Scalars['String']['input']>;
};

export type AlbumConnection = {
  __typename: 'albumConnection';
  albums: Array<Maybe<Album>>;
  pageInfo: PageInfo;
};

export type DeleteAlbumResponse = {
  __typename: 'deleteAlbumResponse';
  message?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
};

export type DeleteAssetResponse = {
  __typename: 'deleteAssetResponse';
  message?: Maybe<Scalars['String']['output']>;
  status: Scalars['Boolean']['output'];
};

export type PublishedAlbumsFilter = {
  campaignId?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ReferenceInput = {
  notes?: InputMaybe<Scalars['String']['input']>;
  referredBy?: InputMaybe<Scalars['ID']['input']>;
};

export type ReviewAlbumsFilter = {
  AssetStatus?: InputMaybe<Array<InputMaybe<AssetStatus>>>;
  campaignId?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SortInput = {
  createdAt?: InputMaybe<SortType>;
  name?: InputMaybe<SortType>;
  updatedAt?: InputMaybe<SortType>;
};

export enum SortType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type GetMembersQueryVariables = Exact<{
  filter?: InputMaybe<MemberFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetMembersQuery = {
  __typename: 'Query';
  getMembers?: {
    __typename: 'MemberConnection';
    members?: Array<{
      __typename: 'Member';
      _id?: string | null;
      gender?: string | null;
      nickName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      updatedAt?: string | null;
      profileImageUrl?: string | null;
      isActive?: boolean | null;
      maritalStatus?: string | null;
      muktType?: Array<string | null> | null;
      userID?: string | null;
      interests?: Array<string | null> | null;
      birthDate?: string | null;
      isSambandhUser?: boolean | null;
      audiences?: Array<string | null> | null;
      contactStatus?: string | null;
      family?: string | null;
      id?: string | null;
      mandal?: string | null;
      familyId?: string | null;
      noReference?: boolean | null;
      reference?: {
        __typename: 'Reference';
        notes?: string | null;
        referredBy?: {
          __typename: 'Member';
          _id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          profileImageUrl?: string | null;
          contactDetails?: {
            __typename: 'ContactDetail';
            email?: string | null;
            phones?: Array<{
              __typename: 'Phone';
              type?: string | null;
              value?: string | null;
            } | null> | null;
          } | null;
        } | null;
      } | null;
      profession?: {
        __typename: 'Profession';
        jobTitle?: string | null;
        company?: string | null;
      } | null;
      addressDetails?: {
        __typename: 'Address';
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        county?: string | null;
        state?: string | null;
        country?: string | null;
        zip?: string | null;
        lon?: string | null;
        lat?: string | null;
      } | null;
      contactDetails?: {
        __typename: 'ContactDetail';
        email?: string | null;
        phones?: Array<{
          __typename: 'Phone';
          type?: string | null;
          value?: string | null;
        } | null> | null;
      } | null;
      followUp?: Array<{ __typename: 'Member'; _id?: string | null } | null> | null;
      tags?: {
        __typename: 'Tags';
        adminAttributes?: Array<string | null> | null;
        followUp?: Array<string | null> | null;
        attributes?: Array<string | null> | null;
      } | null;
      invitationStatus?: Array<{
        __typename: 'InvitationStatus';
        invitedBy?: string | null;
        sent?: boolean | null;
        appname?: string | null;
      } | null> | null;
    } | null> | null;
    pageInfo: { __typename: 'PageInfo'; limit: number; page: number; pages: number; total: number };
  } | null;
};

export const GetMembersDocument = gql`
  query GetMembers(
    $filter: MemberFilterInput
    $pageSize: Int
    $pageNumber: Int
    $search: String
    $sort: String
  ) {
    getMembers(
      filter: $filter
      pageSize: $pageSize
      pageNumber: $pageNumber
      search: $search
      sort: $sort
    ) {
      members {
        _id
        gender
        nickName
        firstName
        lastName
        updatedAt
        profileImageUrl
        isActive
        maritalStatus
        muktType
        userID
        interests
        reference {
          referredBy {
            _id
            firstName
            lastName
            contactDetails {
              email
              phones {
                type
                value
              }
            }
            profileImageUrl
          }
          notes
        }
        profession {
          jobTitle
          company
        }
        addressDetails {
          address1
          address2
          city
          county
          state
          country
          zip
          lon
          lat
        }
        contactDetails {
          email
          phones {
            type
            value
          }
        }
        birthDate
        isSambandhUser
        followUp {
          _id
        }
        tags {
          adminAttributes
          followUp
          attributes
        }
        audiences
        contactStatus
        family
        id
        mandal
        familyId
        noReference
        invitationStatus {
          invitedBy
          sent
          appname
        }
      }
      pageInfo {
        limit
        page
        pages
        total
      }
    }
  }
`;

/**
 * __useGetMembersQuery__
 *
 * To run a query within a React component, call `useGetMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pageSize: // value for 'pageSize'
 *      pageNumber: // value for 'pageNumber'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetMembersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMembersQuery, GetMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMembersQuery, GetMembersQueryVariables>(GetMembersDocument, options);
}
export function useGetMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMembersQuery, GetMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMembersQuery, GetMembersQueryVariables>(
    GetMembersDocument,
    options
  );
}
export function useGetMembersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetMembersQuery, GetMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetMembersQuery, GetMembersQueryVariables>(
    GetMembersDocument,
    options
  );
}
export type GetMembersQueryHookResult = ReturnType<typeof useGetMembersQuery>;
export type GetMembersLazyQueryHookResult = ReturnType<typeof useGetMembersLazyQuery>;
export type GetMembersSuspenseQueryHookResult = ReturnType<typeof useGetMembersSuspenseQuery>;
export type GetMembersQueryResult = Apollo.QueryResult<GetMembersQuery, GetMembersQueryVariables>;
