import { API } from '../config/config.constants';
import http from '../helpers/http';
import { IAttribute } from '../views/Admin/Attributes/attributeAdmin.ducks';

export const getAllAttributes = () => {
  return http
    .get(API.ATTRIBUTE.GET_ALL_ATTRIBUTES)
    .then((response) => {
      const data = response.data;
      if (data) {
        return data;
      } else {
        return { error: response };
      }
    })
    .catch(({ error }) => {
      return { error: error };
    });
};

export const getAllFields = () => {
  return http
    .get(API.ATTRIBUTE.GET_ALL_FIELDS)
    .then((response) => {
      const data = response.data;
      if (data) {
        return data;
      } else {
        return { error: response };
      }
    })
    .catch(({ error }) => {
      console.error('Something went wrong while getting attribute fields', error);
    });
};

export const updateAttribute = async (attribute: IAttribute) => {
  const response = await http.put(
    API.ATTRIBUTE.UPDATE_ATTRIBUTE(attribute.oldKey || ''),
    attribute.value
  );
  if (response && response.data) {
    return response.data;
  } else {
    return { error: response };
  }
};

export const createAttribute = async (attribute: IAttribute) => {
  const response = await http.post(API.ATTRIBUTE.CREATE_ATTRIBUTE, attribute.value);
  if (response && response.data) {
    return response.data;
  } else {
    return { error: response };
  }
};

export const removeAttribute = async (id: string) => {
  return http
    .delete(API.ATTRIBUTE.REMOVE_ATTRIBUTE(id))
    .then((response) => {
      const data = response.data;
      if (data) {
        return data;
      } else {
        return { error: response };
      }
    })
    .catch(({ error }) => {
      console.error('Something went wrong while getting deleting an attribute', error);
    });
};
