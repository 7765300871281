import React, { Component } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
// material
import { Menu, MenuItem } from '@material-ui/core';
// project files
import { CONTACT_US, FAQ } from '../../constants/menuOptions';
import getMenuOptions from '../../util/getMenuOptions';
import { CONTACT_US_MENU } from '../../constants/menus';
import { HoverImage } from '../hover-image/HoverImage';
import icon_help_active from '../../assets/icons/icon_help_active.svg';
import icon_help_inactive from '../../assets/icons/icon_help_inactive.svg';
import { openModal } from '../../actions/common.actions';
import * as MODAL_COMPONENTS from '../../constants/modals';

class ContactUsMenu extends Component {
  constructor (props) {
    super(props);
    this.state = {
      anchorEl: null
    };
  }

  handleContactUs () {
    this.props.openModal(MODAL_COMPONENTS.CONTACT_US_MODAL, { loggedInUser: this.props.loggedInUser });
  }

  // open menu
  handleMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  // handle menu options click
  handleMenuClose = value => {
    this.setState(
      { anchorEl: null },
      () => {
        switch (value) {
          case CONTACT_US:
            this.handleContactUs();
            break;
          case FAQ:
            console.log(value.name, 'option disabled');
            break;
          default:
        }
      }
    );
  }

  render () {
    const { anchorEl } = this.state;
    const menuOptions = getMenuOptions(CONTACT_US_MENU);
    let classes = this.props.classes ? this.props.classes.join(' ') : '';
    return (
      <div className={classes}>
        <HoverImage
          style={{display: 'flex', marginRight: '2rem'}}
          src={icon_help_inactive}
          hoverSrc={icon_help_active}
          onClick={this.handleMenuOpen} />
        <Menu
          MenuListProps={{
            disablePadding: true
          }}
          style={{marginTop: '0.5rem'}}
          anchorEl={anchorEl}
          anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
          getContentAnchorEl={null}
          open={Boolean(anchorEl)}
          onClose={() => {
            this.handleMenuClose();
          }}>
          {menuOptions.map((option, index) => {
            return (
              <MenuItem
                key={index}
                disabled={option.disabled}
                onClick={() => {
                  this.handleMenuClose(option);
                }}>
                {option.name}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  }
};

export default connect(
  null, { openModal }
)(ContactUsMenu);

ContactUsMenu.propTypes = {
  openModal: PropType.func.isRequired,
  loggedInUser: PropType.object.isRequired,
  classes: PropType.arrayOf(PropType.string)
};
