import { broadcastViewActions, commonActions } from '../constants/actions';
import { getBroadcastMessages, changeBroadcastMessageStatus } from '../apis/broadcastAPIs';
import * as STATUS from '../constants/messageStatus';
import { LOGGEDINUSERINFOTOKEN } from '../config/config.tokens';
import { BROADCAST_MESSAGES_DATA_ERROR, BROADCAST_MESSAGE_CANCELLED_SUCCESS, BROADCAST_MESSAGE_CANCELLED_ERROR } from '../constants/toasterMessages';

export function getBroadcastData () {
  return async (dispatch) => {
    dispatch({ type: broadcastViewActions.SHOW_HIDE_BROADCAST_LOADER, value: true });
    const broadcasts = await getBroadcastMessages();
    if (broadcasts && broadcasts instanceof Array) {
      dispatch({ type: broadcastViewActions.SHOW_HIDE_BROADCAST_LOADER, value: false });
      return dispatch({ type: broadcastViewActions.GET_BROADCAST_MESSAGES, value: broadcasts });
    } else {
      dispatch({ type: broadcastViewActions.SHOW_HIDE_BROADCAST_LOADER, value: false });
      return dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: BROADCAST_MESSAGES_DATA_ERROR, type: commonActions.ERROR_TOASTER } });
    }
  };
};

export function getBroadcastApprovalData () {
  return async (dispatch) => {
    dispatch({ type: broadcastViewActions.SHOW_HIDE_BROADCAST_LOADER, value: true });
    const skipId = JSON.parse(localStorage.getItem(LOGGEDINUSERINFOTOKEN))._id;
    if (skipId) {
      const broadcasts = await getBroadcastMessages(STATUS.PENDING, skipId);
      if (broadcasts && broadcasts instanceof Array) {
        dispatch({ type: broadcastViewActions.SHOW_HIDE_BROADCAST_LOADER, value: false });
        return dispatch({ type: broadcastViewActions.GET_BROADCAST_APPROVAL_MESSAGES, value: broadcasts });
      } else {
        dispatch({ type: broadcastViewActions.SHOW_HIDE_BROADCAST_LOADER, value: false });
        return dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: BROADCAST_MESSAGES_DATA_ERROR, type: commonActions.ERROR_TOASTER } });
      }
    } else {
      return dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: BROADCAST_MESSAGES_DATA_ERROR, type: commonActions.ERROR_TOASTER } });
    }
  };
};

export function cancelBroadcast (id) {
  return async (dispatch) => {
    const approveBroadcastMessage = await changeBroadcastMessageStatus(id, STATUS.CANCELLED);
    if (approveBroadcastMessage && approveBroadcastMessage._id) {
      dispatch({ type: broadcastViewActions.CANCEL_BROADCAST_MESSAGE, value: approveBroadcastMessage });
      return dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: BROADCAST_MESSAGE_CANCELLED_SUCCESS, type: commonActions.SUCCESS_TOASTER } });
    } else {
      return dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: BROADCAST_MESSAGE_CANCELLED_ERROR, type: commonActions.ERROR_TOASTER } });
    }
  };
}
