import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// project files
import icon_unauthorized from '../../assets/icons/icon_unauthorized.svg';
import icon_pending from '../../assets/icons/icon_pending.svg';
import * as USER_AUTH_STATUS from '../../constants/status';
import { Logo } from '../../components/samparkLogo';

class AuthStatus extends Component {
  constructor (props) {
    super(props);
  }

  render () {
    // if previous route did not pass any parameters in state, redirect to /auth
    if (!this.props.location.state) {
      return (<Redirect to={{
        pathname: '/auth'
      }} />);
    }
    const { location } = this.props;
    const { title, message, status } = location.state;
    const USER_IS_UNAUTHORIZED = (status === USER_AUTH_STATUS.UNAUTHORIZED.status || status === USER_AUTH_STATUS.ERROR.status);
    const USER_IS_DECLINED = status === USER_AUTH_STATUS.DECLINED.status;
    return (
      <Fragment>
        <Logo isNewAccount />
        <div className='message-container'>
          {/* if user is not unauthorized, they are either pending, or verified */}
          <img className='message-image' src={USER_IS_UNAUTHORIZED || USER_IS_DECLINED ? icon_unauthorized : icon_pending} />
          <div className='message-title'>
            {title}
          </div>
          <div className='message-content'>
            {message}
          </div>
          {USER_IS_UNAUTHORIZED && <div className='message-submessage'>
            <br /><LoginWithNewAccount />
          </div>}
        </div>
        <Footer />
      </Fragment>
    );
  }
}

AuthStatus.propTypes = {
  location: PropTypes.object.isRequired
};

export default AuthStatus;

const Footer = () => {
  return (
    <div style={{justifySelf: 'center', alignSelf: 'center', display: 'none'}}>
      Have questions? { <Link to='/auth/contact'>Contact us</Link> }
    </div>
  );
};

const LoginWithNewAccount = () => {
  return (
    <div className='message-submessage'>
      <Link to={{
        pathname: '/auth',
        state: {
          loginWithNewAccount: true
        }
      }}>Login with another account</Link>
    </div>
  );
};
