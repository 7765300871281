import { iconProps } from './icons-types';

export const iconBroadcast = ({ stroke }: iconProps) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2005_1519)">
        <path
          d="M14.25 6H15C16.2427 6 17.25 7.00725 17.25 8.25C17.25 9.49275 16.2427 10.5 15 10.5H14.25"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.75 6V10.5C0.75 11.3288 1.42125 12 2.25 12H9.75L14.25 15V1.5L9.75 4.5H2.25C1.42125 4.5 0.75 5.17125 0.75 6Z"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.25 12L7.5 17.25"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2005_1519">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
