// generate a map from list of keys
export const symbolMap = (...keys) => keys.reduce((types, key) => {
  types[ key ] = key;

  return types;
}, {});

// a symbol map of all "stages" for async actions
const Stage = symbolMap(
  'READY',
  'LOADING', 'LOADED', 'LOAD_ERROR',
  'CREATING', 'CREATED', 'CREATE_ERROR',
  'UPDATING', 'UPDATED', 'UPDATE_ERROR',
  'DELETE_CONFIRM', 'DELETING', 'DELETED', 'DELETE_ERROR'
);

// generate a checker for a set of stages
export const oneOf = (...stages) => (stage) => stages.some((key) => Stage[key] === stage);

export const isLoading = oneOf('LOADING', 'CREATING', 'UPDATING', 'DELETING');

export const isDone = oneOf('LOADED', 'CREATED', 'UPDATED', 'DELETED');

// action is complete
export const isFinished = oneOf('CREATED', 'UPDATED', 'DELETED');

export const isError = oneOf(
  'LOAD_ERROR',
  'CREATE_ERROR',
  'UPDATE_ERROR',
  'DELETE_ERROR'
);
export const isUpdateError = oneOf(
  'CREATE_ERROR',
  'UPDATE_ERROR',
  'DELETE_ERROR'
);
export const isLoadError = oneOf(
  'LOAD_ERROR'
);
export const isDeleting = oneOf('DELETE_CONFIRM', 'DELETING', 'DELETE_ERROR');

export default Stage;
