import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const renderTextAreaField = ({
  label = null,
  input,
  placeholder,
  meta: { touched, error, warning, dirty },
  readOnly = false,
  required = false,
  maxChars
}) => {
  let classes = ['form-field-text-area', 'padding-xsm-left'];
  let charCountClasses = ['form-field-textarea-character-counter'];
  readOnly && classes.push('disabled-text-field');
  if (touched && error) {
    classes.push('form-field-text-error-state');
    charCountClasses.push('padding-bottom');
  } else {
    charCountClasses.push('padding-xsm-bottom');
  }
  return (
    <Fragment>
      {label && <div className='form-field-label-row'>
        <label>{label} {required && <span className='form-field-required'>*</span>}</label>
      </div>}
      <div className='form-field-text-container'>
        {
          maxChars && (<div className={charCountClasses.join(' ')}>
            {maxChars - input.value.length}
          </div>)
        }
        <textarea
          maxLength={maxChars || 9999}
          rows='5'
          className={classes.join(' ')}
          {...input}
          disabled={readOnly}
          placeholder={placeholder} />
        {touched &&
          ((error && <div className='form-field-error'>{error}</div>) ||
            (warning && <div className='form-field-error'>{warning}</div>))}
      </div>
    </Fragment>
  );
};

export default renderTextAreaField;

renderTextAreaField.propTypes = {
  input: PropTypes.any,
  placeholder: PropTypes.string,
  meta: PropTypes.any,
  label: PropTypes.string,
  addNew: PropTypes.string,
  readOnly: PropTypes.bool,
  maxChars: PropTypes.number,
  required: PropTypes.bool
};
