import { filterFormActions } from '../constants/actions';

export default function filterFormReducer (
  state = { contactFilterValues: [] },
  action) {
  switch (action.type) {
    case filterFormActions.SELECT_CONTACT_FILTER_OPTION:
      return { ...state, contactFilterValues: [...state.contactFilterValues, action.value.filterOptionName] };
    case filterFormActions.UNSELECT_CONTACT_FILTER_OPTION:
      return { ...state, contactFilterValues: action.value.updatedSelectedFilterValues };
    default:
      return { ...state };
  }
}
