import { bindActionCreators } from 'redux';
import { openModal } from '../../../../actions/common.actions';
import { initializeReadOnlyContactForm } from '../../../../actions/contactForm.actions';
import { getContactByIdAPI } from '../../../../apis/contactAPIs';
import { CONTACT_INFO_READ_ONLY } from '../../../../constants/modals';
import { useAppDispatch } from '../../../../helpers/store';
import { useState } from 'react';
import { MembersType } from '../../../../types';

export const useMemberDetailsModal = () => {
  const dispatch = useAppDispatch();
  const actions = bindActionCreators({ openModal, initializeReadOnlyContactForm }, dispatch);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchAndOpenMemberDetails = async (memberId: string) => {
    try {
      setLoading(true);
      const contact: MembersType = await getContactByIdAPI(memberId);
      if (contact?._id) {
        actions.initializeReadOnlyContactForm(contact);
        actions.openModal(CONTACT_INFO_READ_ONLY, {
          maxWidth: 'sm'
        });
      }
      setLoading(false);
    } catch (e) {
      console.warn(e);
      setLoading(false);
    }
  };

  return {
    loading,
    fetchAndOpenMemberDetails
  };
};
