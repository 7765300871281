export const STRING_OPERATORS = [
  {
    val: 'equal',
    label: 'Must equals value'
  },
  {
    val: 'notEqual',
    label: 'Must not equals value'
  },
  {
    val: 'in',
    label: 'Includes'
  },
  {
    val: 'notIn',
    label: 'Does not include'
  }
];

export const NUMERICI_OPERATORS = [
  {
    val: 'equal',
    label: 'Must equals value'
  },
  {
    val: 'notEqual',
    label: 'Must not equals value'
  },
  {
    val: 'lessThan',
    label: 'Must be less than value'
  },
  {
    val: 'lessThanInclusive',
    label: 'Must be less than or equals to value'
  },
  {
    val: 'greaterThan',
    label: 'Must be greater than value'
  },
  {
    val: 'greaterThanInclusive',
    label: 'Must be greater than or equals to value'
  }
];

export const ARRAY_OPERATORS = [
  {
    val: 'in',
    label: 'Includes'
  },
  {
    val: 'notIn',
    label: 'Does not include'
  },
  {
    val: 'contains',
    label: 'Contains'
  },
  {
    val: 'doesNotContain',
    label: 'Does not contain'
  }
];

export const ALL_OPERATORS = [
  {
    val: 'contains',
    label: 'Contains'
  },
  {
    val: 'doesNotContain',
    label: 'Does not contain'
  },
  {
    val: 'in',
    label: 'Includes'
  },
  {
    val: 'notIn',
    label: 'Does not include'
  },
  {
    val: 'equal',
    label: 'Must equals value'
  },
  {
    val: 'notEqual',
    label: 'Must not equals value'
  },
  {
    val: 'lessThan',
    label: 'Must be less than value'
  },
  {
    val: 'lessThanInclusive',
    label: 'Must be less than or equals to value'
  },
  {
    val: 'greaterThan',
    label: 'Must be greater than value'
  },
  {
    val: 'greaterThanInclusive',
    label: 'Must be greater than or equals to value'
  }
];
