import { useDroppable } from '@dnd-kit/core';
import { ReactChild, ReactFragment, ReactPortal } from 'react';
import { makeStyles } from 'tss-react/mui';

export const Droppable = (props: {
  index: string | number;
  data?: string;
  children: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined;
}) => {
  const newData: Record<string, any> = { id: props.data };
  const { isOver, setNodeRef } = useDroppable({
    id: props.index,
    data: newData.id
  });

  const { classes } = useStyles({ isOver });

  return (
    <div ref={setNodeRef} className={classes.root}>
      {props.children}
    </div>
  );
};

const useStyles = makeStyles<{ isOver: boolean }>()((__, { isOver }) => ({
  root: {
    color: isOver ? 'green' : undefined,
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
    flexWrap: 'wrap',
    width: '100%'
  }
}));
