import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';

interface FilterButtonProps {
  className?: string;
  text: string;
  onClick?: (value: string, selected: boolean) => void;
  selected?: boolean;
  value: string;
}

const FilterButton = ({ className, text, onClick, selected, value }: FilterButtonProps) => {
  const { classes } = useStyles();

  function handleButtonClick() {
    if (onClick) {
      onClick(value, !selected);
    }
  }

  return (
    <button
      className={classNames(
        classes.button,
        {
          [classes.selected]: selected
        },
        className
      )}
      onClick={handleButtonClick}
    >
      {text}
    </button>
  );
};

const useStyles = makeStyles()({
  button: {
    backgroundColor: '#E8EBF4',
    borderRadius: '8px',
    color: '#5F698B',
    cursor: 'pointer',
    border: '1px solid transparent',

    display: 'flex',
    justifyContent: 'center',
    padding: '8px 10px',

    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '20px',

    '&:focus': {
      border: '1px solid #ff5a52'
    }
  },
  selected: {
    backgroundColor: '#ff5a52',
    color: 'white',

    '&:focus': {
      border: '1px solid #a4332e'
    }
  }
});

export default FilterButton;
