import { GrowthBookProvider, IfFeatureEnabled } from '@growthbook/growthbook-react';
import { useCallback, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { hideToaster, triggerDevFlag } from '../../actions/common.actions';
import { getMyUserAccountData, getUserAccessObject } from '../../actions/userAccess.actions';
import DrawerController from '../../components/DrawerController';
import Header from '../../components/Header';
import { Toaster } from '../../components/Toaster/Toaster';
import ModalController from '../../components/modals/ModalController';
import { SideNav } from '../../components/side-panel/SideNav';
import { useAppDispatch, useAppSelector } from '../../helpers/store';
import isEmpty from '../../util/isEmpty';
import { FECTCHUSERACCESS, USERROUTES, checkUserPermission } from '../../util/userPermission';
import { contactActions } from '../../views/Contacts/contacts.ducks';
import { SamparkErrorView } from '../../views/ErrorView';
import { useSamparkLayoutStyles } from './SamparkLayout.styles';
import { ISamparkLayoutProps } from './SamparkLayout.types';
import { UnderMaintenance } from './UnderMaintenance';
import { growthbook, initializeGrowthBook } from '../../helpers/growthbook';

export const SamparkLayout = ({ history, location }: ISamparkLayoutProps) => {
  const dispatch = useAppDispatch();
  const actions = bindActionCreators(
    {
      ...contactActions,
      getMyUserAccountData,
      getUserAccessObject,
      hideToaster,
      triggerDevFlag
    },
    dispatch
  );
  const {
    contactOptions,
    isDevMode,
    displayLoader,
    displayToaster,
    isUserAccessEmpty,
    redirect401,
    toasterMessage,
    toasterMessageType,
    fetchUserAccount,
    userRoutes,
    userAccessObject,
    memberId,
    isSuperAdmin = false
  } = useAppSelector((state) => ({
    contactOptions: state.contactsData.contactOptions,
    isDevMode: state.commonData.isDevMode,
    displayLoader: state.commonData.globalLoader,
    displayToaster: state.commonData.displayToaster,
    isUserAccessEmpty: isEmpty(state.userAccess),
    redirect401: state.auth.redirect401,
    toasterMessage: state.commonData.message,
    toasterMessageType: state.commonData.type,
    fetchUserAccount: checkUserPermission(state.userAccess, FECTCHUSERACCESS),
    userRoutes: checkUserPermission(state.userAccess, USERROUTES),
    userAccessObject: state.userAccess.userAccessObject,
    memberId: state.userAccess.userAccount.id,
    isSuperAdmin: state.userAccess?.isSuperAdmin || false
  }));

  const [isSideNavExpanded, setIsSideNavExpanded] = useState(true);
  const { classes } = useSamparkLayoutStyles({ isSideNavExpanded });

  const checkQueryPerameter = () => {
    const searchValue = history.location.search;
    const flagValue = searchValue && searchValue === '?dev' ? 'true' : 'false';
    if (isDevMode !== flagValue) {
      actions.triggerDevFlag(flagValue);
    }
  };

  const handleToggleSideNav = () => {
    setIsSideNavExpanded((open) => !open);
  };

  const getData = useCallback(() => {
    if (fetchUserAccount) {
      actions.getMyUserAccountData();
    }
    if (contactOptions.maritalStatus.length === 0) {
      actions.getContactOptions();
    }
  }, [contactOptions.maritalStatus]);

  useEffect(() => {
    document.body.setAttribute('class', 'sampark-body');
    actions.getUserAccessObject();
    getData();
  }, [contactOptions?.maritalStatus]);

  useEffect(() => {
    if (checkQueryPerameter) {
      checkQueryPerameter();
    }

    if (redirect401 === true) {
      history.push('/auth/signout');
    }

    if (displayLoader === true) {
      document.body.classList.add('loading-mask');
    } else {
      document.body.classList.remove('loading-mask');
    }
  }, [displayLoader, checkQueryPerameter, redirect401]);

  const url = location.pathname;

  const followUpDetailsRegex = /(\/sampark\/followup\/)\d+/i;
  const hideHeader = followUpDetailsRegex.test(url);

  useEffect(() => {
    if (isSuperAdmin || (userAccessObject && userAccessObject.access && memberId)) {
      initializeGrowthBook(isSuperAdmin, memberId);
    }
  }, [userAccessObject, memberId]);

  //if user is not a SuperAdmin and has no userAccess
  if (!isSuperAdmin && isUserAccessEmpty) {
    return <UnderMaintenance />;
  }

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <IfFeatureEnabled feature="samparkui">
        <div className={classes.samparkUi}>
          <div className={classes.samparkLayout}>
            <header className="header">{!hideHeader && <Header history={history} />}</header>
            <SideNav toggleExpanded={handleToggleSideNav} isExpanded={isSideNavExpanded} />
            <div className="content-sampark">
              <Switch>
                {/* This removes trailing slash.
                 * This makes it easier to figure out sub paths (/sampark/admin/sampark/, etc)
                 */}
                <Redirect from="/:url*(/+)" to={url.slice(0, -1)} />
                {userRoutes
                  .filter((r: any) => r.permission === undefined || r.permission)
                  .map((r: any, i: number) => (
                    <Route path={r.value} component={r.component} key={i} />
                  ))}
                <Route path="/sampark/error" component={SamparkErrorView} />
                {/* <Redirect to='/sampark/error' /> */}
              </Switch>
            </div>
            <Toaster
              displayToaster={displayToaster}
              toasterMessage={toasterMessage}
              toasterMessageType={toasterMessageType}
              hideToaster={actions.hideToaster}
            />
            <ModalController />
            <DrawerController />
          </div>
        </div>
      </IfFeatureEnabled>
    </GrowthBookProvider>
  );
};
