/* eslint-disable */
import { makeStyles } from 'tss-react/mui';
import { useFeature } from '@growthbook/growthbook-react';

import { MembersList } from '../components/MembersList/MembersList';
import { IRegistration, IFilters } from '../yogAdmin.duck';

import ActiveFilterChipGroup from './ActiveFilterChipGroup';
import { ReactComponent as FilterIcon } from '../../../../assets/icons/icon_search_grey.svg';

interface YogAdminResultsProps {
  registrations: IRegistration[];
  totalResults: number;
  fetchMore: () => void;
  onClickRegistration: (registration: IRegistration) => void;
  approveRegistration: (registration: IRegistration) => void;
  openConfirmRejectModal: (registration: IRegistration) => void;
  rejectRegistration: (registration: IRegistration) => void;
  onClickFamilyId: (familyId: string) => void;
  handleClearFilterByField: (field: keyof IFilters) => void;
  activeFilters: IFilters;
  isUpdatingRegistration: boolean;
  isMobile?: boolean;
  toggleFilterModal: () => void;
  openConfirmRemoveHoldModal: (registration: IRegistration) => void;
  eventId: string;
}

const YogAdminResults = ({
  registrations,
  totalResults,
  fetchMore,
  onClickRegistration,
  approveRegistration,
  openConfirmRejectModal,
  onClickFamilyId,
  activeFilters,
  handleClearFilterByField,
  isMobile,
  toggleFilterModal,
  openConfirmRemoveHoldModal
}: YogAdminResultsProps) => {
  const { classes, theme } = useStyles();

  const yogAdminActions = useFeature('sampark.yog-admin-actions').on;

  const NoContentContainer = () => {
    const { classes } = useStyles();
    return (
      <div className={classes.noContentContainer}>
        <p>No registrations found</p>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.stickyHeader}>
        <header className={classes.header}>
          <h4 className={classes.title}>Results{totalResults ? ` (${totalResults})` : null}</h4>
          {!isMobile ? (
            <ActiveFilterChipGroup
              activeFilters={activeFilters}
              handleClearFilterByField={handleClearFilterByField}
            />
          ) : (
            <button onClick={toggleFilterModal} className={classes.actionButton}>
              <FilterIcon />
            </button>
          )}
        </header>
      </div>
      {isMobile && activeFilters ? (
        <section className={classes.mobileFilterChipContainer}>
          <ActiveFilterChipGroup
            activeFilters={activeFilters}
            handleClearFilterByField={handleClearFilterByField}
          />
        </section>
      ) : null}
      <section className={classes.contentContainer}>
        {registrations?.length > 0 ? (
          <MembersList
            className={classes.contentListContainer}
            registrations={registrations}
            onClick={onClickRegistration}
            total={totalResults}
            fetchMore={fetchMore}
            hideQuickActions={!yogAdminActions}
            isMobile={isMobile}
            approveRegistration={approveRegistration}
            rejectRegistration={openConfirmRejectModal}
            openConfirmRemoveHoldModal={openConfirmRemoveHoldModal}
            onClickFamilyId={onClickFamilyId}
          />
        ) : (
          <NoContentContainer />
        )}
      </section>
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    background: '#fff'
  },
  stickyHeader: {
    zIndex: 1,
    position: 'sticky',
    top: 0,
    background: '#fff'
  },
  actionButton: {
    backgroundColor: '#E8EBF4',
    borderRadius: '8px',
    color: '#5F698B',
    fontSize: '18px',
    fontWeight: 'bold',
    border: '2px solid transparent',
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    cursor: 'pointer',

    '&:focus,&:hover': {
      border: '2px solid #3A4463'
    }
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    overflowY: 'auto'
  },
  addressUpdateBanner: {
    background: '#DDE9FD',
    padding: theme.spacing(2),
    color: '#2256BF',
    fontFamily: 'Rubik',
    fontSize: '0.8rem',
    fontWeight: 400
  },
  addressUpdateBtn: {
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    fontFamily: 'Rubik',
    fontSize: '0.8rem',
    fontWeight: 600,
    color: '#2256BF',
    textDecoration: 'underline',
    padding: theme.spacing(0, 0, 0, 0.5)
  },
  header: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1)
    }
  },
  recommendedContact: {
    opacity: 0.5
  },
  filter: {},
  contentListContainer: {
    marginBottom: theme.spacing(4),
    paddingBottom: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',

    '&::-webkit-scrollbar': {
      borderRadius: '9px',
      width: '4px'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '9px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '9px',
      backgroundColor: 'darkgrey'
    }
  },
  contentListItem: {
    borderRadius: '16px'
  },
  loading: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: 'rgba(0, 0, 0, 0.4)',
    zIndex: 2
  },
  mobileFilterChipContainer: {
    marginTop: '8px',
    overflowX: 'auto',
    minHeight: '28px'
  },
  noContentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#5F698B',
    height: '100%',
    '& p': {
      opacity: 0.5
    }
  },
  subTitle: {
    color: '#A5ADC6',
    fontSize: '12px',
    marginTop: 0,
    marginBottom: theme.spacing(2),
    '& > span': {
      fontWeight: 'bold',
      color: '#848EAC'
    }
  },
  title: {
    fontSize: '1rem',
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: '500',
    margin: theme.spacing(1, 0, 1)
  },
  addressUpdateButtons: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(12),
    display: 'flex'
  },
  cancelButton: {
    cursor: 'pointer',
    background: '#D63230',
    borderRadius: '9px',
    border: 'none',
    fontFamily: 'Rubik',
    fontSize: '1rem',
    fontWeight: 400,
    color: '#FFFFFF',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: theme.spacing(1)
    }
  },
  updateButton: {
    background: '#26C972',
    '&:disabled': {
      cursor: 'default',
      opacity: 0.5
    }
  }
}));

export default YogAdminResults;
