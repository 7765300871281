import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Avatar from '@mui/material/Avatar';

import { IMAGES_URL } from '../../../../../config/config.constants';
import { OptionType } from './MembersList';

import { IRegistration } from '../../yogAdmin.duck';
import studentCap from '../../../../../assets/icons/student-cap.svg';
import { ReactComponent as CheckIcon } from '../../../../../assets/icons/icon_check_yog_admin.svg';
import { ReactComponent as CrossIcon } from '../../../../../assets/icons/icon_cross_yog_admin.svg';
import { ReactComponent as RemoveHoldIcon } from '../../../../../assets/icons/icon-remove-hold_yog_admin.svg';
import { ReactComponent as HoldIcon } from '../../../../../assets/icons/icon-hold_yog_admin.svg';
import { ReactComponent as EllipsesIcon } from '../../../../../assets/icons/icon_ellipses.svg';
import classNames from 'classnames';
import {
  REGISTRATION_STATUS,
  hideApproveRejectButton,
  showApprovedBatch
} from '../../constants/RegistrationStatus';
import { isSuperAdminSelector } from '../../selector';
import HoldStatusBanner from '../HoldStatusBanner/index';
import { useAppSelector } from '../../../../../helpers/store';
import { getCountryNameFromAbbreviation } from '../../../../../util/countries';

type MembersListItemProps = {
  className?: string;
  id: string;
  registration: IRegistration;
  addressOptions?: OptionType[];
  isSelected?: boolean;
  isMobile?: boolean;
  onClick?: (registration: IRegistration) => void;
  approveRegistration: (registration: IRegistration) => void;
  rejectRegistration: (registration: IRegistration) => void;
  onClickFamilyId: (familyId: string) => void;
  hideQuickActions?: boolean;
  openConfirmRemoveHoldModal: (registration: IRegistration) => void;
};

export const MembersListItem = ({
  className,
  id,
  registration,
  isMobile,
  onClick,
  approveRegistration,
  rejectRegistration,
  onClickFamilyId,
  hideQuickActions,
  openConfirmRemoveHoldModal
}: MembersListItemProps) => {
  const { member, isStudent, isEditable = false, status, onHoldStartDate } = registration;
  const { firstName, lastName, city = '', state = '', country = '', familyId } = member;
  const { classes, cx } = useStyles();
  const isSuperAdmin = useAppSelector(isSuperAdminSelector);
  const profileImageUrl =
    member && member.profileImageUrl && member.profileImageUrl !== 'undefined'
      ? IMAGES_URL + '/' + member.profileImageUrl
      : '';
  // This should open the registration modal
  const handleViewRegistation = () => {
    if (onClick) {
      onClick(registration);
    }
  };

  // This should trigger a filter based on familyId
  const handleClickFamilyId = () => {
    onClickFamilyId(familyId);
  };

  const handleClickApprove = () => {
    approveRegistration(registration);
  };
  const handleClickReject = () => {
    rejectRegistration(registration);
  };

  return (
    <div className={classes.container}>
      <div id={id} className={cx(classes.listItemContainer, className)}>
        <div className={classes.listContent}>
          <div className={classes.avatarWrapper}>
            <Avatar className={classes.avatar} src={profileImageUrl} variant={'rounded'} />
            {status != REGISTRATION_STATUS.PENDING ? (
              <div
                className={classNames(classes.statusBadge, {
                  [classes.approvedStatusBadge]: showApprovedBatch.includes(status),
                  [classes.holdStatusBadge]: status === REGISTRATION_STATUS.HOLD,
                  [classes.rejectedStatusBadge]: status === REGISTRATION_STATUS.REJECTED
                })}
              >
                {showApprovedBatch.includes(status) ? <CheckIcon /> : null}
                {status === REGISTRATION_STATUS.REJECTED ? <CrossIcon /> : null}
                {status === REGISTRATION_STATUS.HOLD ? <HoldIcon /> : null}
              </div>
            ) : null}
            <HoldStatusBanner status={status} targetDate={onHoldStartDate} />
          </div>
          <div className={classes.listItemDetails}>
            <div className={classes.metadataRow}>
              {familyId ? (
                <p
                  role="button"
                  tabIndex={0}
                  onClick={handleClickFamilyId}
                  className={classes.familyId}
                >
                  FAMILY-{familyId.slice(-5)}
                </p>
              ) : null}
              {isStudent ? <img src={studentCap} /> : null}
            </div>
            <div
              role="button"
              tabIndex={0}
              onClick={handleViewRegistation}
              className={classes.memberInfo}
            >
              <h4 className={classes.name}>{`${firstName} ${lastName}`}</h4>
              <p className={classes.address}>
                {city}, <span style={{ textTransform: 'uppercase' }}>{state}</span>,{' '}
                <span>{getCountryNameFromAbbreviation(country)}</span>
              </p>
            </div>
          </div>
        </div>

        <div className={classes.actions}>
          {!hideQuickActions &&
          isEditable &&
          !isMobile &&
          !hideApproveRejectButton.includes(status) ? (
            <>
              <button
                onClick={handleClickApprove}
                disabled={status === REGISTRATION_STATUS.APPROVED}
                className={classNames(classes.actionButton, {
                  [classes.approveButton]: status === REGISTRATION_STATUS.APPROVED
                })}
              >
                <CheckIcon />
              </button>
              <button
                onClick={handleClickReject}
                className={classNames(classes.actionButton, {
                  [classes.rejectButton]: status === REGISTRATION_STATUS.REJECTED
                })}
              >
                <CrossIcon />
              </button>
            </>
          ) : null}
          {!hideQuickActions && !isMobile && status === REGISTRATION_STATUS.HOLD && isSuperAdmin ? (
            <button
              onClick={() => openConfirmRemoveHoldModal(registration)}
              className={classNames(classes.actionButton, {
                [classes.rejectButton]: true
              })}
            >
              <RemoveHoldIcon />
            </button>
          ) : null}
          <button onClick={handleViewRegistation} className={classes.actionButton}>
            <EllipsesIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  avatorContainer: {
    display: 'flex',
    gap: 16,
    alignItems: 'center'
  },
  address: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '17px',
    color: '#5F698B',
    textTransform: 'capitalize'
  },
  actions: {
    display: 'flex',
    gap: 16
  },
  avatar: {
    height: 60,
    width: 60,
    background: '#E8EBF4',
    [theme.breakpoints.up('sm')]: {
      height: 72,
      width: 72
    }
  },
  avatarWrapper: {
    position: 'relative',
    backgroundColor: '#E8EBF4',
    borderRadius: 4,
    height: 'fit-content'
  },
  statusBadge: {
    position: 'absolute',
    top: '-1px',
    right: '-5px',
    height: '20px',
    width: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid white',
    borderRadius: '100%',
    '> svg': {
      filter: 'brightness(100)',
      height: '12px',
      width: '12px'
    }
  },
  approvedStatusBadge: {
    backgroundColor: '#38A772'
  },
  rejectedStatusBadge: {
    backgroundColor: '#C84240'
  },
  holdStatusBadge: {
    background: '#C84240'
  },
  actionButton: {
    backgroundColor: '#E8EBF4',
    borderRadius: '8px',
    color: '#5F698B',
    fontSize: '18px',
    padding: '8px 16px',
    fontWeight: 'bold',
    border: '2px solid transparent',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    cursor: 'pointer',

    '&:focus,&:hover': {
      border: '2px solid #3A4463'
    },
    [theme.breakpoints.up('sm')]: {
      padding: '16px 32px'
    }
  },
  rejectButton: {
    backgroundColor: '#C84240',
    color: '#FFFFFF',
    '> svg': {
      filter: 'brightness(100)'
    }
  },
  approveButton: {
    backgroundColor: '#38A772',
    color: '#FFFFFF',
    '> svg': {
      filter: 'brightness(100)'
    }
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: 0,
    minWidth: 'calc(100% - 50px)',
    backgroundColor: 'white',
    borderRadius: '4px',
    [theme.breakpoints.up('sm')]: {
      minWidth: 'calc(100% - 70px)',
      padding: theme.spacing(0, 1)
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: 'calc(100% - 120px)'
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: 'calc(100% - 200px)'
    },
    '&:hover': {
      backgroundColor: '#f8f8f8',
      transition: 'background-color 0.1s ease-in-out'
    }
  },
  familyId: {
    fontSize: '12px',
    color: '#5F698B',
    textTransform: 'uppercase',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  listItemContainer: {
    alignItems: 'center',
    display: 'flex',
    border: 'none',
    borderRadius: '9px',
    justifyContent: 'space-between',
    margin: '8px 0',
    width: '100%',
    textAlign: 'left',
    boxShadow: 'none',
    '&:disabled': {
      cursor: 'default'
    }
  },
  listContent: {
    alignItems: 'stretch',
    display: 'flex',
    gap: 4
  },
  listItemDetails: {
    alignItems: 'flex-start',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '& p': {
      margin: 0
    },
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      padding: '4px 0px'
    }
  },
  metadataRow: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,

    '>img': {
      height: '16px'
    }
  },
  memberInfo: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  name: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '21px',
    color: '#3A4463',
    fontSize: '16px',
    textTransform: 'capitalize',
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px'
    }
  }
}));
