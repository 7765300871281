import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// project files
import SignUpForm from './SignUp_Form';
import * as USER_AUTH_STATUS from '../../constants/status';
import { Logo } from '../samparkLogo';

class SignUp extends Component {

  render () {
    // if user is not signed in with google, redirect them to /auth
    if (!this.props.googleAuthenticated) {
      return <Redirect to='/auth' />;
    }
    return (this.props.submitted)
      // if user submits form, redirect to /auth/signup_status
      ? <Redirect to={{
        pathname: '/auth/signup_status',
        state: {
          from: this.props.location,
          title: USER_AUTH_STATUS.VERIFIED.title,
          message: USER_AUTH_STATUS.VERIFIED.message,
          status: USER_AUTH_STATUS.VERIFIED.status
        }
      }} />
      // if user hasn't submitted yet, display sign up form
      : (
        <Fragment>
          <Logo isNewAccount={false} />
          <InfoBox />
          <SignUpForm
            initialValues={this.props.storedInvitedUser} />
          <Footer />
        </Fragment>
      );
  }
}

function mapStateToProps (state) {
  return {
    googleAuthenticated: state.auth.googleAuthenticated,
    submitted: state.registration.submitted,
    storedInvitedUser: state.registration.storedInvitedUser
  };
}

export default connect(mapStateToProps, {})(SignUp);

SignUp.propTypes = {
  googleAuthenticated: PropTypes.bool.isRequired,
  submitted: PropTypes.bool.isRequired,
  storedInvitedUser: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};
/*
 * SignUp props:
 * - whether user is already signed in with google or not
 * - whether user submitted form or not
 * - sign up errors - if any exist
 * - google user object
 */

const Footer = (props) => {
  return (
    <div style={{justifySelf: 'center', alignSelf: 'center', display: 'none'}}>
      Have questions? { <Link to='/auth/contact'>Contact us</Link> }
    </div>
  );
};

const InfoBox = () => {
  return (
    <div
      className='signup-infobox'
      style={{justifySelf: 'center', alignSelf: 'center'}}>
      <span style={{fontWeight: '500'}}>Thanks for signing up!</span> <br />
      We’ve started setting up your account. Please upload a clear picture of yourself and confirm the information below.
    </div>
  );
};
