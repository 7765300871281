import { Button } from '@mui/material';
import { IUserAccess } from './SamparkAdminTab.types';

interface IManageAccessButtonProps {
  disabled?: boolean;
  label?: string;
  obj: IUserAccess;
  onClick: (user: IUserAccess) => void;
}

export const ManageAccessButton = (props: IManageAccessButtonProps) => {
  const { disabled = false, label = 'Manage', obj, onClick } = props;

  const handleOnClick = () => {
    onClick(obj);
  };

  return (
    <Button
      className={['manage-data-button', disabled ? 'disable-button' : null].join(' ')}
      disabled={disabled}
      onClick={handleOnClick}
    >
      {label}
    </Button>
  );
};
