// react imports
import React, { Component, Fragment, useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// material ui imports
import Button from '@material-ui/core/Button';
// project files
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { signinUser } from '../../actions/authentication.actions';
// constants / icons
import { LOGGEDINUSERINFOTOKEN, SAMPARKTOKEN } from '../../config/config.tokens';
import * as USER_AUTH_STATUS from '../../constants/status';
import GoogleLogo from '../../assets/google-logo.svg';
import sampark from '../../assets/icons/sampark.svg';
import icon_men from '../../assets/icons/icon_men_landing.svg';
import { getRedirectResult, signInWithPopup } from 'firebase/auth';
import axios from 'axios';
import { API } from '../../config/config.constants';
import { useAppDispatch, useAppSelector } from '../../helpers/store';
import { authActions } from '../../constants/actions';
import { getLoggedInRedirectLink } from '../../util/getLoggedInRedirectLink';
import { firebaseAuth, googleProvider } from '../../config/config.firebase';

const SignIn = (props) => {
  // These are the results from the Google Firebase auth redirect.
  // This would contain the user token and other google account info.
  const [redirectResults, setRedirectResults] = useState(null);
  /**
   * Initially isLoading is true. It gets set to false if there was no redirect
   * (initial page load, ie user has not clicked Log In button yet) or once
   * authn (google) & authz (sampark) are complete.
   */
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const { samparkAuthenticated } = useAppSelector((state) => state.auth);

  const saveRedirectResult = useCallback((result) => {
    if (result && result.user) {
      setRedirectResults(result.user)
    } else {
      setRedirectResults(null)
      setIsLoading(false)
    }
  }, [setRedirectResults, setIsLoading]);
  const onErrorResult = useCallback((error) => {
    // Can catch google auth errors here if needed
    console.error(error)
    setRedirectResults(null)
    setIsLoading(false)
  }, [setRedirectResults, setIsLoading]);
  const onClickGoogleSignIn = useCallback(() => {
    setIsLoading(true);
    if (window.origin.includes('localhost')) {
      signInWithPopup(firebaseAuth, googleProvider)
        .then(result => {
          saveRedirectResult(result);
        })
        .catch((error) => {
          onErrorResult(error);
        })
    } else {
      dispatch(signinUser());
    }
  }, [saveRedirectResult, onErrorResult, dispatch, signinUser]);

  useEffect(() => {
    if (!window.origin.includes('localhost')) {
      getRedirectResult(firebaseAuth)
        .then(result => {
          saveRedirectResult(result);
        })
        .catch((error) => {
          onErrorResult(error);
        });
    }
  }, [onErrorResult, saveRedirectResult]);


  useEffect(() => {
    /**
     * Once we get get authn results from Google, we must
     * authz the user in Sampark using the /signin API.
     * - If it succeeds, we set the user as authorized,
     *   save their data and redirect them. 
     * - If it fails, we leave the user on the Sign In page.
     * 
     */
    if (redirectResults) {
      setIsLoading(true)
      axios
        .post(API.AUTHENTICATION.SIGN_IN, redirectResults.toJSON(),
          {
            headers: {
              'sourceapp': 'sampark'
            }
          })
        .then(resp => {
          const { data } = resp;
          if (data?.token) {
            localStorage.setItem(SAMPARKTOKEN, data?.token);
            localStorage.setItem(
              LOGGEDINUSERINFOTOKEN,
              JSON.stringify({
                firstName: data.firstName,
                lastName: data.lastName,
                profileImageUrl: data.profileImageUrl,
                status: data.status,
                _id: data._id
              })
            );
            dispatch({ type: authActions.AUTH_USER_SAMPARK });
          } else {
            // Some unhandled error
            dispatch({
              type: authActions.AUTH_ERROR,
              payload: USER_AUTH_STATUS.ERROR.status
            });
          }
        })
        .catch(e => {
          console.error(e)
          dispatch({
            type: authActions.AUTH_ERROR,
            payload: USER_AUTH_STATUS.ERROR.status
          });
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [redirectResults])

  // Redirect user to appropriate page when they log in successfully
  useEffect(() => {
    if (samparkAuthenticated) {
      props.history.push(getLoggedInRedirectLink())
    }
  }, [samparkAuthenticated])

  return (
    <div className='landing-page'>
      <div className='landing-page-left'>
        <img className='landing-page-men' src={icon_men} />
        <img className='landing-page-logo' src={sampark} />
      </div>
      <div
        className='landing-page-right'>
        <img
          className='landing-page-logo-responsive'
          src={sampark} />
        <div
          className='landing-page-sign-in'>
          {
            isLoading ? <LoadingSpinner />
              : <Fragment>
                <span className='landing-text'>Log in with</span>
                <Button
                  onClick={() => onClickGoogleSignIn()}
                  disableRipple
                  className='signin-button'>
                  <img alt='Google' src={GoogleLogo} />
                </Button>
                <span
                  style={{ display: 'none' }}
                  className='landing-footer'>Have questions? <Link to='/auth/contact'>Contact Us</Link>
                </span>
              </Fragment>
          }
        </div>
        <div className='landing-page-disclaimer'>
          <div style={{ fontWeight: 500, lineHeight: 1.7 }}>Disclaimer</div>
          <div>Your login information is only accessed by your ‘Log In’ client. Your <span style={{ fontWeight: 500 }}>password</span> will never be stored on our servers.</div>
        </div>
      </div>
    </div>
  )
}

SignIn.propTypes = {
  history: PropTypes.object.isRequired,
};

export default SignIn;
