import { createTheme } from '@mui/material';
import Slide from '@mui/material/Slide';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1440
    }
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          color: '#c3d2df'
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        // Disables ripple effect on all buttons
        disableRipple: true
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // Removes uppercase text transform on buttons
          textTransform: 'none'
        }
      }
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        autoHideDuration: 5000,
        TransitionComponent: Slide
      },
      styleOverrides: {
        root: {
          marginTop: '2rem'
        }
      }
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          border: '1px solid #c3d2df',
          borderRadius: '4px',
          boxShadow: '0 0 12px 0 rgba(42, 61, 76, 0.05)'
        }
      }
    }
  },
  typography: {
    fontFamily: 'Rubik'
  }
});
