import http from '../helpers/http';
import { API } from '../config/config.constants';

export const getFamilyByIdAPI = async (id: string) => {
  const response = await http.get(API.FAMILY_TREE.FAMILY_BY_ID(id));
  if (response && response.data) {
    return response.data;
  } else {
    return { error: response };
  }
};

export const getFamilyRecommendationsAPI = async (memberIds: string[]) => {
  const response = await http.get(API.FAMILY_TREE.FAMILIES, {
    params: { memberId: [...memberIds], recommendation: true }
  });
  if (response && response.data) {
    return response.data;
  } else {
    return { error: response };
  }
};

export const createOrUpdateFamilyAPI = async (memberIds: string[], familyId: null | string) => {
  let response;
  if (familyId) {
    response = await http.put(API.FAMILY_TREE.UPDATE_FAMILY(familyId), { memberIds });
  } else {
    response = await http.post(API.FAMILY_TREE.CREATE_FAMILY, { memberIds });
  }
  if (response && response.data) {
    return response.data;
  } else {
    return { error: response };
  }
};
