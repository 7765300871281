import { combineReducers } from 'redux';

import followUpAdmin from './FollowUpAdmin/followUpAdmin.duck';
import samparkAdmin from './SamparkAdmin/samparkAdmin.duck';
import sambandhAdmin from './SambandhAdmin/sambandhAdmin.duck';
import familyTree from './FamilyTree/familyTree.duck';
import yogAdmin from './YogAdmin/yogAdmin.duck';
import attribute from './Attributes/attributeAdmin.ducks'
import rule from './Rules/ruleAdmin.ducks'
import toaster from './Attributes/components/Toaster/toaster.ducks'

export default combineReducers({
  followUpAdmin,
  samparkAdmin,
  sambandhAdmin,
  familyTree,
  yogAdmin,
  attribute,
  rule,
  toaster
});
