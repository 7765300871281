import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ textColor: string; backgroundColor: string }>()(
  (__, { textColor, backgroundColor }) => ({
    iconWrapper: {
      position: 'absolute',
      top: '-42px'
    },
    message: {
      padding: '14px',
      color: 'inherit'
    },
    messageHeader: {
      fontSize: '24px',
      fontWeight: 500,
      padding: '7px'
    },
    wrapper: {
      textAlign: 'center',
      fontSize: '1.5rem',
      fontWeight: 400,
      color: 'hsl(0, 0%, 55%)',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '49px'
    },
    createButton: {
      minWidth: '120px',
      height: '40px',
      padding: '8px 12px',
      gap: '10px',
      borderRadius: '7px',
      backgroundColor: `hsla(${backgroundColor}, 0.8)`,
      border: 'none',
      color: `hsl(${textColor})`,
      fontSize: '1rem',
      fontWeight: 400,
      position: 'absolute',
      cursor: 'pointer',
      right: '20px',
      '&:hover': {
        backgroundColor: `hsl(${textColor})`,
        color: 'white'
      }
    },
    cancelButton: {
      fontSize: '1rem',
      fontWeight: 400,
      minWidth: '120px',
      height: '40px',
      padding: '8px 12px',
      gap: '10px',
      borderRadius: '7px',
      backgroundColor: 'transparent',
      border: `2px solid hsla(${textColor}, 0.8)`,
      color: `hsl(${textColor})`,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: `hsl(${textColor})`,
        color: `hsl(${backgroundColor})`
      }
    }
  })
);
