import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { reduxForm, Field, FieldArray, formValueSelector, change } from 'redux-form';
// MATERIAL UI
import { Radio, FormControlLabel } from '@material-ui/core';
// project files
import * as FORMS from '../../constants/forms';
import icon_radio_selected_small from '../../assets/icons/icon_radio_selected_small.svg';
import icon_radio_unselected_small from '../../assets/icons/icon_radio_unselected_small.svg';
import { calculateAge } from '../../util/calculateAge';
import { normalizePhone } from '../../util/normalizePhone';
import { checkUserPermission, ISSUPERADMIN } from '../../util/userPermission';

// PROJECT FILES
import {
  renderTextField,
  renderRadioGroup,
  renderInterests,
  renderDatePicker,
  renderSelectField,
  renderAddressField,
  renderTextAreaField,
  renderMaskedTextField
} from '../form-fields';
import validate from '../contact-panel/validate';
import { STATES } from '../../assets/data/us_states';
import { buildNameValueArray } from '../../util/contactUtils';

let PersonalInfoSection = (props) => {

  let { muktTypes, maritalStatus } = props.contactOptions;
  const { isSuperAdmin, initialValues, selectedValues } = props;
  const {
    birthDate,
    gender
    // addressDetails
  } = selectedValues;

  const updateMuktType = () => {
    props.change('muktType', []);
  };

  // Rendering the muktTypes dropdown based on gender value
  if (gender) {
    muktTypes = buildNameValueArray(muktTypes[gender.toLowerCase()]);
  } else {
    // if we don't have a gender value, we will not provide a list of muktTypes
    muktTypes = [];
  }

  const isNewContact = initialValues.isNewContact;
  const genderOptions = ['Male', 'Female'];
  const disableGender = !isNewContact && !isSuperAdmin;
  let contactAge;
  let isChild = false;
  const kishorAgeLimit = [6, 14];
  if (dayjs(birthDate).isValid()) {
    contactAge = calculateAge(birthDate);
    if (contactAge > kishorAgeLimit[0] && contactAge < kishorAgeLimit[1]) {
      isChild = true;
    }
  }
  // const enableFollowUp = addressDetails && (addressDetails.city !== '' && addressDetails.state !== '');
  return (
    <Fragment>
      <div
        className='personal-info-section'>
        <div
          className='field-birthday'>
          <Field
            label='Birthday'
            name='birthDate'
            onBlur={function (event, newValue, prevValue, name) {
              if (dayjs(newValue).isValid()) {
                const age = calculateAge(newValue);
                if (age > kishorAgeLimit[0] && age < kishorAgeLimit[1]) {
                  props.change('muktType', ['Kishore/Kishori']);
                }
              }
            }}
            maxDate={dayjs().format('YYYY-MM-DD')}
            component={renderDatePicker}
            placeholder='mm/dd/yyyy'
          />
        </div>
        <div
          className='field-gender'>
          <Field
            required
            label='Gender'
            name='gender'
            component={renderRadioGroup}>
            {genderOptions.map((option, index) => {
              return (
                <FormControlLabel
                  key={index}
                  value={option}
                  disabled={disableGender}
                  onChange={updateMuktType}
                  control={<Radio
                    disableRipple
                    checkedIcon={<img src={icon_radio_selected_small} />}
                    icon={<img src={icon_radio_unselected_small} />} />}
                  label={option} />
              );
            })}
          </Field>
        </div>
        <div
          className='field-phone-cell'>
          <Field
            required
            label='Cell Number'
            name='contactDetails.cell'
            placeholder='Enter cell number'
            mask='(999) 999 9999'
            normalize={normalizePhone}
            component={renderMaskedTextField} />
        </div>
        <div
          className='field-phone-home'>
          <Field
            label='Home Number'
            name='contactDetails.home'
            mask='(999) 999 9999'
            component={renderMaskedTextField}
            normalize={normalizePhone}
            placeholder='Enter home number'
          />
        </div>
        <div
          className='field-address-1'>
          <Field
            label='Address 1'
            name='addressDetails.address1'
            component={renderAddressField}
            placeholder='Address 1'
          />
        </div>
        <div
          className='field-address-2'>
          <Field
            label='Address 2'
            name='addressDetails.address2'
            component={renderTextField}
            placeholder='Address 2'
          />
        </div>
        <div
          className='field-address-city'>
          <Field
            required
            label='City'
            name='addressDetails.city'
            component={renderTextField}
            placeholder='Enter Address'
          />
        </div>
        <div className='field-address-state-zip'>
          <div
            className='field-address-state'>
            <Field
              required
              label='State'
              name='addressDetails.state'
              component={renderSelectField}
              placeholder='State'
              options={STATES}
            />
          </div>
          <div
            className='field-address-zip'>
            <Field
              label='Zip Code'
              name='addressDetails.zip'
              component={renderTextField}
              placeholder='Zip Code'
            />
          </div>
        </div>
        <div
          className='field-marriage'>
          <Field
            label='Marital Status'
            name='maritalStatus'
            component={renderRadioGroup}>
            {maritalStatus.map((option, index) => {
              return (
                <FormControlLabel
                  key={index}
                  value={option}
                  control={<Radio
                    disableRipple
                    checkedIcon={<img src={icon_radio_selected_small} />}
                    icon={<img src={icon_radio_unselected_small} />} />}
                  label={option} />
              );
            })}
          </Field>
        </div>
        <div
          className='field-mukt-type'>
          <Field
            label='Mukt Type (max 2)'
            name='muktType'
            component={renderSelectField}
            lockSelected={isChild}
            placeholder='Mukt Type'
            options={muktTypes}
            maxSelect={2}
            multiple
            readOnly={muktTypes && muktTypes.length ? false : true}
          />
        </div>
        <div
          className='field-notes'>
          <Field
            label='Note'
            name='reference.notes'
            component={renderTextAreaField}
            placeholder='Eg: who referred them or what is their connection'
          />
        </div>
        <div
          className='field-interests padding-sm-bottom'>
          <FieldArray
            label='Interests (Max 5 seperated by comma)'
            name='interests'
            component={renderInterests}
            placeholder='Ex: music, singing etc.'
          />
        </div>
      </div>
    </Fragment>
  );
};

PersonalInfoSection = reduxForm({
  form: FORMS.SAMBANDH_APPROVAL_FORM,
  validate,
  destroyOnUnmount: false
})(PersonalInfoSection);

const selector = formValueSelector(FORMS.SAMBANDH_APPROVAL_FORM);
function mapStateToProps(state) {
  return {
    selectedValues: selector(state, 'birthDate', 'addressDetails', 'gender'),
    contactOptions: state.contactsData.contactOptions,
    isSuperAdmin: checkUserPermission(state.userAccess, ISSUPERADMIN),
  };
}

export default connect(mapStateToProps, { change })(PersonalInfoSection);

PersonalInfoSection.propTypes = {
  contactOptions: PropTypes.object,
  isSuperrAdmin: PropTypes.bool,
  initialValues: PropTypes.object.isRequired,
  selectedValues: PropTypes.object,
  change: PropTypes.func.isRequired
};
