import { iconProps } from './icons-types';

export const iconHome = ({ stroke }: iconProps) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2005_1504)">
        <path
          d="M6 17.25V12.75C6 11.0932 7.34325 9.75 9 9.75C10.6568 9.75 12 11.0932 12 12.75V17.25"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.75 15.75V7.5L9 0.75L17.25 7.5V15.75C17.25 16.5788 16.5788 17.25 15.75 17.25H2.25C1.42125 17.25 0.75 16.5788 0.75 15.75Z"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2005_1504">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
