export function formatTime (timeValue) {
  if (timeValue) {
    const currentTimeValue = timeValue.toString().replace(':', '');
    const regex = /\d{2,4}/;
    const match = currentTimeValue.match(regex);
    if (match) {
      const splitDigits = currentTimeValue.split('');
      if (splitDigits.length === 2) {
        return splitDigits.join('');
      } else if (splitDigits.length === 3) {
        const minutes = `${splitDigits[1]}${splitDigits[2]}`;
        if (minutes > 59) {
          return `${splitDigits[0]}${splitDigits[1]}`;
        } else {
          return `${splitDigits[0]}:${splitDigits[1]}${splitDigits[2]}`;
        }
      } else if (splitDigits.length === 4) {
        const hours = `${splitDigits[0]}${splitDigits[1]}`;
        if (hours > 12) {
          return `${splitDigits[0]}:${splitDigits[1]}${splitDigits[2]}`;
        } else {
          return `${splitDigits[0]}${splitDigits[1]}:${splitDigits[2]}${
            splitDigits[3]
          }`;
        }
      }
    }
  }
}
