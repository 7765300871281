import { Button, Grid } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closeModal, openModal } from '../../../../actions/common.actions';
import { initializeContactForm } from '../../../../actions/contactForm.actions';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/icon_cross.svg';
import { AvatarWrapper } from '../../../../components/Avatar/AvatarWrapper';
import * as MODAL_COMPONENTS from '../../../../constants/modals';
import { RootState } from '../../../../reducers/index.types';
import formatPhoneNumber from '../../../../util/formatPhoneNumber';
import { ProfileBadge } from '../../../../v2/components/profileBadge';
import { useStyles } from './styles';
import { CopyIcon } from '../../../../components/CopyIcon';
import { useCallback } from 'react';
import {
  CANEDITADMINATTRIBUTES,
  CANUPDATECONTACTS,
  ISSUPERADMIN,
  USERID,
  checkUserPermission,
  getAccessObj
} from '../../../../util/userPermission';

interface IContactInfo {
  contact: any;
  classes: any;
  onClickEdit: () => void;
}

interface IAttribute {
  group: string;
  name: string;
  type: string;
  value: string;
}

dayjs.extend(utc);

const ContactInfo = (props: IContactInfo) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const actions = bindActionCreators({ initializeContactForm, closeModal, openModal }, dispatch);
  const { clickedUser: contact } = useSelector((state: RootState) => state.contactForm);
  const userAccess = useSelector((state: RootState) => state.userAccess);
  const userId = getAccessObj(userAccess, USERID);
  const isSuperAdmin = checkUserPermission(userAccess, ISSUPERADMIN);
  const canUpdateMember = checkUserPermission(userAccess, CANUPDATECONTACTS);
  const {
    audiences = [],
    addressDetails,
    birthDate,
    contactDetails,
    firstName,
    followUp = [],
    followUpKaryakartas = [],
    id,
    lastName,
    muktType,
    nickName,
    reference,
    tags,
    mandal,
    updatedAt
  } = contact;
  const isUsersOwnInfo = userId === id;
  const showEditButton = isSuperAdmin || (canUpdateMember && !isUsersOwnInfo);
  const followUpKKs = followUpKaryakartas.length > 0 ? followUpKaryakartas : followUp;
  const formattedBirthday = dayjs(birthDate).utc().format('MMMM DD, YYYY');
  const canEditAdminAttributes = checkUserPermission(userAccess, CANEDITADMINATTRIBUTES);

  const {
    firstName: refFirstName,
    lastName: refLastName,
    contactDetails: refContactDetails
  } = reference?.referredBy || {
    firstName: '',
    lastName: '',
    contactDetails: {
      phones: [],
      email: ''
    }
  };

  const handleClickEdit = useCallback(() => {
    actions.openModal(MODAL_COMPONENTS.CONTACT_FORM);
  }, [actions]);

  return (
    <div className={classes.container}>
      <div className={classes.view}>
        <div className={classes.header}>
          <div className={classes.headerRow}>
            <div className={classes.titleGroup}>
              <h4 className="title-text padding-xsm-right">Profile</h4>
              {id ? <CopyIcon content={contact.id} /> : null}
            </div>
            <div className={classes.controls}>
              {showEditButton ? <Button onClick={handleClickEdit}>Edit</Button> : null}
              <CloseIcon className={classes.close} onClick={actions.closeModal} />
            </div>
          </div>
          <div className={classes.headerRow}>
            <span className={classes.sectionTitle}>(Updated: {updatedAt})</span>
          </div>
        </div>
        <Grid container spacing={0}>
          <div className={classes.userInfo}>
            <AvatarWrapper classes={[classes.avatar]} size={'100'} contact={contact} />
            <div className={classes.nameContainer}>
              <span className={classes.name}>
                {firstName} {lastName}
              </span>
              <br />
              <span className={classes.nickName}>{nickName}</span>
            </div>
          </div>
          <Grid item container spacing={1} className={classes.section}>
            <Grid item xs={12} className={classes.sectionTitle}>
              Contact Info
            </Grid>
            <Grid item xs={12} container spacing={1}>
              {contactDetails.phones?.length > 0 &&
                contactDetails.phones
                  .filter((phone: any) => phone.value)
                  .map((phone: any) => {
                    return (
                      <Grid item container key={phone.value}>
                        <Grid item xs={4} className={classes.infoText}>
                          {phone.type}
                        </Grid>
                        <Grid item xs={8} className={classes.contactDetail}>
                          <a href={`tel:${phone.value}`}>{formatPhoneNumber(phone.value)}</a>
                        </Grid>
                      </Grid>
                    );
                  })}
            </Grid>
            <Grid item container>
              <Grid item xs={4} className={classes.infoText}>
                Email
              </Grid>
              <Grid item xs={8} className={classes.contactDetail}>
                <a href={`mailto:${contactDetails.email}`}>{contactDetails.email}</a>
              </Grid>
            </Grid>
            {/* We will only show additional emails if they exist */}
            {contactDetails.additionalEmails?.length > 0 && (
              <Grid item container>
                <Grid item xs={4} className={classes.infoText}>
                  Additional Emails
                </Grid>
                <div>
                  {contactDetails.additionalEmails?.map((email: string, index: number) => (
                    <Grid key={index} item xs={8} className={classes.contactDetail}>
                      <a href={`mailto:${email}`}>{email}</a>
                    </Grid>
                  ))}
                </div>
              </Grid>
            )}
          </Grid>
          {addressDetails ? (
            <Grid item container spacing={1} className={classes.section}>
              <Grid item xs={12} className={classes.sectionTitle}>
                Address
              </Grid>
              <Grid item xs={12} className={classes.infoText}>
                {addressDetails?.address1}
                <br />
                {addressDetails?.city},{' '}
                <span style={{ textTransform: 'uppercase' }}>{addressDetails?.state}</span>{' '}
                {addressDetails?.zip},{' '}
                <span style={{ textTransform: 'uppercase' }}>{addressDetails?.country}</span>
              </Grid>
            </Grid>
          ) : null}

          {reference?.referredBy ? (
            <Grid item container spacing={1} className={classes.section}>
              <Grid item xs={12} className={classes.sectionTitle}>
                Referred By
              </Grid>
              <Grid item xs={12} className={classes.infoText}>
                <div className={classes.userInfo}>
                  <AvatarWrapper
                    classes={[classes.avatar]}
                    size={'80'}
                    contact={reference?.referredBy}
                  />
                  <div className={classes.nameContainer}>
                    <span className={classes.name} style={{ fontSize: '1.25rem' }}>
                      {refFirstName} {refLastName}
                    </span>
                    <div style={{ marginTop: 8 }}>
                      {refContactDetails?.phones?.length > 0 &&
                        refContactDetails?.phones
                          .filter((phone: any) => phone.value)
                          .map((phone: any) => {
                            return (
                              <Grid
                                item
                                key={phone.value}
                                xs={8}
                                className={classes.contactDetail}
                                style={{ marginTop: 2, fontSize: '1rem' }}
                              >
                                <a href={`tel:${phone.value}`}>{formatPhoneNumber(phone.value)}</a>
                              </Grid>
                            );
                          })}
                      <Grid
                        item
                        xs={8}
                        className={classes.contactDetail}
                        style={{ marginTop: 2, fontSize: '1rem' }}
                      >
                        <a href={`mailto:${refContactDetails?.email}`}>
                          {refContactDetails?.email}
                        </a>
                      </Grid>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          ) : null}

          <Grid item container spacing={1} className={classes.section}>
            <Grid item xs={12} className={classes.sectionTitle}>
              Follow-up Karyakarta(s)
            </Grid>
            {followUpKKs.length ? (
              followUpKKs.map((followUpMember: any) => {
                if (!followUpMember) {
                  return null;
                }
                const { firstName, lastName, addressDetails } = followUpMember;
                const name = `${firstName} ${lastName}`;
                let location;
                if (addressDetails && addressDetails.city && addressDetails.state) {
                  location = `${addressDetails.city}, ${addressDetails.state.toUpperCase()}`;
                }
                return (
                  <Grid
                    container
                    item
                    xs={12}
                    className={classes.infoText}
                    key={followUpMember._id}
                  >
                    <ProfileBadge
                      primaryText={name}
                      secondaryText={location}
                      contact={followUpMember}
                    />
                  </Grid>
                );
              })
            ) : (
              <Grid container item xs={12} className={classes.sectionTitle}>
                No follow-up karyakartas have been assigned
              </Grid>
            )}
          </Grid>
          <Grid item container spacing={1} className={classes.section}>
            <Grid item xs={12} className={classes.sectionTitle}>
              Mukt Type
            </Grid>
            {muktType.length ? (
              <Grid item xs={12} className={classes.tagRow}>
                {muktType.map((type: IAttribute) => {
                  return (
                    <span className={classes.muktType} key={type.value}>
                      {type.name}
                    </span>
                  );
                })}
              </Grid>
            ) : (
              <Grid container item xs={12} className={classes.sectionTitle}>
                No mukt types have been assigned
              </Grid>
            )}
          </Grid>
          <Grid item container spacing={1} className={classes.section}>
            <Grid item xs={12} className={classes.sectionTitle}>
              Birthdate
            </Grid>
            <Grid item xs={12} className={classes.infoText}>
              {formattedBirthday}
            </Grid>
          </Grid>
          <Grid item container spacing={1} className={classes.section}>
            <Grid item xs={12} className={classes.sectionTitle}>
              Mandal
            </Grid>
            {mandal ? (
              <Grid item xs={12} className={classes.infoText}>
                {mandal}
              </Grid>
            ) : (
              <Grid container item xs={12} className={classes.sectionTitle}>
                No mandal have been assigned
              </Grid>
            )}
          </Grid>
          <Grid item container spacing={1} className={classes.section}>
            <Grid item xs={12} className={classes.sectionTitle}>
              Sabha
            </Grid>
            {tags.followUp?.length ? (
              <Grid item xs={12} className={classes.tagRow}>
                {tags.followUp.map((type: IAttribute) => {
                  return (
                    <span className={classes.muktType} key={type.value}>
                      {type}
                    </span>
                  );
                })}
              </Grid>
            ) : (
              <Grid container item xs={12} className={classes.sectionTitle}>
                No sabhas have been assigned
              </Grid>
            )}
          </Grid>
          {canEditAdminAttributes ? (
            <Grid item container spacing={1} className={classes.section}>
              <Grid item xs={12} className={classes.sectionTitle}>
                Admin Attributes
              </Grid>
              {tags.adminAttributes?.length > 0 ? (
                <Grid item xs={8} sm={12} className={classes.tagRow}>
                  {tags.adminAttributes.map((attribute: IAttribute) => {
                    return (
                      <span className={classes.muktType} key={attribute.value}>
                        {attribute.name}
                      </span>
                    );
                  })}
                </Grid>
              ) : (
                <Grid container item xs={12} className={classes.sectionTitle}>
                  No tags (admin attributes) have been assigned
                </Grid>
              )}
            </Grid>
          ) : null}
          <Grid item container spacing={1} className={classes.section}>
            <Grid item xs={12} className={classes.sectionTitle}>
              Attributes
            </Grid>
            {tags.attributes?.length ? (
              <Grid item xs={8} sm={12} className={classes.tagRow}>
                {tags.attributes.map((attribute: IAttribute) => {
                  return (
                    <span className={classes.muktType} key={attribute.value}>
                      {attribute.name}
                    </span>
                  );
                })}
              </Grid>
            ) : (
              <Grid container item xs={12} className={classes.sectionTitle}>
                No tags (attributes) have been assigned
              </Grid>
            )}
          </Grid>
          <Grid item container spacing={1} className={classes.section}>
            <Grid item xs={12} className={classes.sectionTitle}>
              Notes
            </Grid>
            {reference?.notes ? (
              <Grid item xs={12} className={classes.infoText}>
                {reference?.notes}
              </Grid>
            ) : (
              <Grid container item xs={12} className={classes.sectionTitle}>
                No notes for this contact
              </Grid>
            )}
          </Grid>
          <Grid item container spacing={1} className={classes.section}>
            <Grid item xs={12} className={classes.sectionTitle}>
              Audiences
            </Grid>
            {audiences?.length ? (
              <Grid item xs={8} sm={12} className={classes.tagRow}>
                {audiences.map((audience: string) => {
                  return (
                    <span className={classes.muktType} key={audience}>
                      {audience}
                    </span>
                  );
                })}
              </Grid>
            ) : (
              <Grid container item xs={12} className={classes.sectionTitle}>
                No audiences have been assigned
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export const StyledContactInfo = useStyles(ContactInfo);
