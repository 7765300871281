import MultiSelectDropdownWithChips, { IOption } from '../../../v2/components/MultiSelectDropDown';

export const AudienceSelectorField = ({
  onChange,
  disabled,
  audiencesConfig,
  manageUserPermissions,
  audiences
}: IAudienceSelectorField) => {
  const handleChange = (event: string, values: IOption[], formValid: boolean) => {
    const returnValues: string[] = [];
    values.forEach((value) => returnValues.push(value.value));
    onChange(audiencesConfig.key, returnValues, formValid);
  };
  return (
    <MultiSelectDropdownWithChips
      eventName={'e'}
      options={audiences || []}
      onChange={handleChange}
      disabled={disabled}
      displayConfig={audiencesConfig}
      value={getInputValue(manageUserPermissions || [], audiences || [])}
    />
  );
};

export interface IAudienceSelectorField {
  disabled: boolean;
  onChange: (event: string, value: string[], formValid: boolean) => void;
  isSuperAdmin: boolean;
  audiencesConfig: IAudiencesConfig;
  manageUserPermissions: string[];
  audiences: IOption[] | undefined;
}

interface IAudiencesConfig {
  displayLabel: string;
  description: string;
  tooltip: string;
  required: boolean;
  key: string;
  placeholder: string;
}

const getInputValue = (userPermission: string[], audiences: IOption[]) => {
  const set = new Set();
  userPermission.forEach((keyTab) => set.add(keyTab));
  return audiences.filter((audience) => set.has(audience.value));
};
