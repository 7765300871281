import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  bindActionCreators
} from 'redux';
import { withStyles } from '@material-ui/core/styles';

import { checkUserPermission, ISSUPERADMIN } from '../../../util/userPermission';
import { oneOf, isError } from '../../../util/stage';
import { ActionCreators } from './sambandhAdmin.duck';
import SambandApprovalCard from '../../../components/SambandApprovalCard';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';

const useStyles = withStyles({
  errorContainer: {
    margin: '0rem auto',
    maxWidth: '650px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  }
});

const SambandhAdmin = (props) => {
  const {
    sambandhApprovals = [],
    setAccessModalOpen,
    actions,
    stage,
    classes
  } = props;

  useEffect(() => {
    actions.fetchSambandhApprovals();

    return () => actions.resetSambandhApprovals();
  }, []);

  if (isError(stage)) {
    return (
      <div className={classes.errorContainer}>
        An error has occured while loading data.
        <br />
        If problem persists, please submit a ticket using the Contact Us feature.
      </div>
    );
  }

  if (oneOf('READY', 'LOADING')(stage)) {
    return <LoadingSpinner />;
  }

  return (
    <div className='admin-data-approvals-container'>
      <div className='admin-data-approval-section'>
        <SambandApprovalCard title='Sambandh Approvals' type='approvals' inviteOnly items={[...sambandhApprovals]} onModalOpen={setAccessModalOpen} />
      </div>
    </div>
  );
};

SambandhAdmin.propTypes = {
  sambandhApprovals: PropTypes.arrayOf(PropTypes.object).isRequired,
  setAccessModalOpen: PropTypes.func.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  stage: PropTypes.symbol.isRequired,
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ admin: { sambandhAdmin }, userAccess }) => ({
  ...sambandhAdmin,
  isSuperAdmin: checkUserPermission(userAccess, ISSUPERADMIN)
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(useStyles(SambandhAdmin));
