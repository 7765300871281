export const CREATE = 'CREATE';
export const NONE = 'NONE';
export const READ = 'READ';
export const DELETE = 'DELETE';
export const UPDATE = 'UPDATE';

export const BROADCAST = 'broadcasts';
export const CONTACTS = 'contacts';
export const EVENTS = 'events';
export const SWADHYAY = 'swadhyay';
export const SMRUTI = 'smrutis';
export const FOLLOWUP = 'followup';

export const allPermissions = [BROADCAST, CONTACTS, EVENTS, SWADHYAY, SMRUTI, FOLLOWUP];
