import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as MODAL_COMPONENTS from '../../constants/modals';

import {
  bindActionCreators,
  compose
} from 'redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Icon,
  List,
  ListItem,
  Divider,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Button
} from '@material-ui/core';

import icon_caret_right from '../../assets/icons/icon_caret_right.svg';
import icon_alert from '../../assets/icons/icon_alert.svg';
import { AvatarWrapper } from '../../components/Avatar/AvatarWrapper';
import { FOLLOW_UP } from '../../constants/routes';
import { ActionCreators } from './followUp.ducks';
import { oneOf } from '../../util/stage';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import Color from 'color';
import { useDispatch } from 'react-redux';
import { initializeContactForm } from '../../actions/contactForm.actions';
import { openModal } from '../../actions/common.actions';

const useStyles = withStyles({
  container: {
    margin: '0rem auto',
    maxWidth: '650px'
  },
  view: {
    margin: '0 0 2rem 0'
  },
  userButton: {
    padding: '1rem 0'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    fontSize: '24px',
    color: '#2A3D4C',
    fontWeight: '500'
  },
  controls: {
    margin: 'auto 0'
  },
  submitButton: {
    backgroundColor: '#FF5A52',
    borderRadius: '3px',
    padding: '12px',
    color: 'white',
    fontSize: '16px',
    textTransform: 'none',
    fontFamily: 'Rubik',
    borderRadius: 7,
    '&:hover': {
      background: Color('#FF5A52').darken(0.2).hex()
    }
  },
  submitLabel: {
    color: 'white',
    fontSize: '16px',
    textTransform: 'none',
    fontWeight: 'bold'
  },
  listItemText: {
    textTransform: 'capitalize',
    marginLeft: '18px'
  },
  listDivider: {
    backgroundColor: '#EBF0F5'
  },
  alertIcon: {
    marginRight: '2.5rem'
  },
  emptyMessage: {
    color: '#899EB0',
    textAlign: 'center',
    marginTop: '3.5rem'
  },
  loader: {
    marginTop: '3.5rem'
  }
});

function FollowUp(props) {
  const { classes, history, actions, stage, list } = props;

  const dispatch = useMemo(() => useDispatch());

  useEffect(() => {
    actions.fetchFollowUpList();
    return () => actions.resetFollowUpList();
  }, []);
  const handleAddNewClick = useCallback(() => {
    dispatch(initializeContactForm(null));
    dispatch(openModal(MODAL_COMPONENTS.CONTACT_FORM, { maxWidth: 'sm' }));
  }, [actions]);

  return (
    <div className={classes.container}>
      <div className={classes.view}>

        <div className={classes.header}>
          <div>
            <h2 className={classes.title}>Follow-up</h2>
          </div>
          <div className={classes.controls}>
            <Button
              onClick={handleAddNewClick}
              classes={{
                root: classes.submitButton,
                text: classes.submitLabel
              }}
              disableRipple
            >
              New Contact
            </Button>
          </div>
        </div>
        {
          oneOf('READY', 'LOADING')(stage) ? (
            <LoadingSpinner className={classes.loader} />
          ) : list.length ? (
            <List
              className={classes.userList}
              disablePadding
            >
              {list.map((user) => {
                const {
                  firstName,
                  lastName,
                  addressDetails,
                  tags
                } = user;

                const followUpTags = (tags && tags.followUp) ? tags.followUp : [];

                const city = (addressDetails && addressDetails.city) || '';
                const state = (addressDetails && addressDetails.state.toUpperCase()) || '';
                return (
                  <React.Fragment key={user._id}>
                    <ListItem
                      disableGutters
                      button
                      className={classes.userButton}
                      onClick={() => history.push({
                        pathname: `${FOLLOW_UP.value}/${user._id}`,
                        state: {
                          followUpId: user._id
                        }
                      })}
                    >
                      <ListItemAvatar>
                        <AvatarWrapper
                          size='56'
                          contact={user}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        classes={{
                          root: classes.listItemText
                        }}
                        primary={`${firstName} ${lastName}`}
                        secondary={`${city}${state ? `, ${state}` : ''}`}
                      />
                      {!followUpTags.length ? <Icon className={classes.alertIcon}>
                        <img src={icon_alert} />
                      </Icon> : null
                      }
                      <ListItemSecondaryAction>
                        <img src={icon_caret_right} />
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider className={classes.listDivider} />
                  </React.Fragment>
                );
              })}
            </List>
          ) : (
            <div className={classes.emptyMessage}>
              You haven't been assigned any users to follow-up with.
              <br />
              Please contact your karyakarta.
            </div>
          )
        }
      </div>
    </div>
  );
}

FollowUp.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  stage: PropTypes.symbol.isRequired,
  list: PropTypes.array.isRequired
};

const mapStateToProps = ({ followUp }) => ({
  ...followUp
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch)
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  useStyles
)(FollowUp);
