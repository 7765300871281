import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// MATERIAL UI
import { Button, AppBar, Toolbar } from '@material-ui/core';
// PROJECT FILES
import { closeDrawer } from '../../actions/common.actions';
import { submitBroadcastForm } from '../../actions/broadcastForm.actions';

const BroadcastCreateFooter = (props) => {
  const buttonDisabled = !props.isFormValid
  return (
    <AppBar
      position='sticky'
      className='panel-footer'>
      <Toolbar>
        <div
          className='contact-form-button-container padding-sm'>
          {SecondaryButton(props)}
          {PrimaryButton(buttonDisabled, props)}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default connect(null, {
  closeDrawer,
  submitBroadcastForm
})(BroadcastCreateFooter);

BroadcastCreateFooter.propTypes = {
  isFormValid: PropTypes.bool,
  isFormDirty: PropTypes.bool,
  onCancel: PropTypes.func
};

function SecondaryButton (props) {

  let buttonLabel = '';
  let buttonClasses = [];
  let labelClasses = [];
  buttonLabel = 'Cancel';
  buttonClasses.push('cancel-button');

  return (
    <Button
      className={['button-primary', ...buttonClasses].join(' ')}
      onClick={props.onCancel}
      disableRipple
      color='primary'>
      <span className={['button-primary-label', ...labelClasses].join(' ')}>{buttonLabel}</span>
    </Button>
  );
}

function PrimaryButton (buttonDisabled, props) {
  let buttonLabel = '';
  let classes = [];
  buttonLabel = 'Send for Approval';
  classes.push('button-orange');
  if (buttonDisabled) {
    classes.push('button-primary-disabled');
  }
  const handleClick = () => {
    return props.submitBroadcastForm();
  };

  return (
    <Button
      disableRipple
      onClick={handleClick}
      disabled={buttonDisabled}
      className={['button-primary', ...classes].join(' ')}
      color='primary'>
      <span className='button-primary-label label-white'>{buttonLabel}</span>
    </Button>
  );
}

PrimaryButton.propTypes = {
  submitBroadcastForm: PropTypes.func.isRequired
};

SecondaryButton.propTypes = {
  closeDrawer: PropTypes.func.isRequired
};
