import { FC, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from '../../../../reducers/index.types';

import {
  CANUPDATECONTACTS,
  ISSUPERADMIN,
  USERID,
  checkUserPermission,
  getAccessObj
} from '../../../../util/userPermission';
import ContactCard from '../ContactCard';
import { contactActions } from '../../contacts.ducks';
import {
  StyledCardsViewContainer,
  StyledListViewContainer,
  StyledMoreLoadingContainer
} from './CardsList.styles';
import { MembersType } from '../../../../types';

type CardsListProps = {
  cardView: boolean;
  openFollowUpModal: () => void;
  contacts: MembersType[];
  showLoading: boolean;
  loadMoreRef: (node: HTMLDivElement) => void;
};

export const CardsList: FC<CardsListProps> = ({
  contacts,
  cardView,
  openFollowUpModal,
  showLoading = false,
  loadMoreRef
}) => {
  const { selectedContacts } = useSelector((state: RootState) => state.contactsData);

  const userAccess = useSelector((state: RootState) => state.userAccess);
  const canUpdateMember = checkUserPermission(userAccess, CANUPDATECONTACTS);
  const userId = getAccessObj(userAccess, USERID);
  const isSuperAdmin = checkUserPermission(userAccess, ISSUPERADMIN);

  const dispatch = useDispatch();
  //TODO check alternative to bindActionCreators
  const actions = bindActionCreators(contactActions, dispatch);
  const StyledContainer = cardView ? StyledCardsViewContainer : StyledListViewContainer;

  return (
    <Fragment>
      <StyledContainer>
        {Array.isArray(contacts) && contacts?.length
          ? contacts.map((obj) => (
              <ContactCard
                key={obj._id}
                canUpdateMember={canUpdateMember}
                contact={obj}
                isListView={!cardView}
                isSuperAdmin={isSuperAdmin}
                openFollowUpModal={openFollowUpModal}
                selectedContacts={selectedContacts ?? []}
                userId={userId}
                onSelectContact={actions.selectContact}
                onUnselectContact={actions.unselectContact}
              />
            ))
          : null}
      </StyledContainer>
      {showLoading ? (
        <StyledMoreLoadingContainer id={'contact-card-scroll'} ref={loadMoreRef}>
          Loading contacts ....
        </StyledMoreLoadingContainer>
      ) : null}
    </Fragment>
  );
};
