import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  attribute: {
    borderRadius: '3px',
    padding: '8px',
    cursor: 'pointer',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    fontSize: '16px',
    fontFamily: 'Rubik',
    color: 'hsla(226, 19%, 46%, 1)',
    backgroundColor: 'hsla(216, 15%, 95%, 0.3)',
    border: '2px solid hsla(226, 19%, 46%, 0.1)',
    '&:hover': {
      color: 'hsla(226, 19%, 35%, 1)',
      backgroundColor: 'hsl(216, 15%, 95%)',
      border: '2px solid hsla(226, 19%, 46%, 0.8)'
    },
    '&:focus': {
      color: 'hsla(226, 19%, 35%, 1)',
      border: '2px solid hsla(226, 19%, 46%, 0.8)',
      backgroundColor: 'hsl(216, 15%, 95%)'
    }
  },
  attributeLabel: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    maxWidth: '200px'
  },
  containerList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '7px',
    borderRadius: '7px'
  },
  listHeader: {
    color: '#3a4463',
    fontFamily: 'Rubik',
    fontSize: '16px',
    fontWeight: 500
  },
  alertBanner: {
    backgroundColor: 'hsl(50, 81%, 94%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '7px',
    borderRadius: '7px',
    marginTop: '14px'
  },
  alertText: {
    fontSize: '14px',
    paddingLeft: '7px'
  },
  alertIcon: {
    width: '14px',
    height: '14px'
  },
  accordianWrapper: {
    overflowX: 'hidden',
    overflowY: 'visible'
  },
  fadeIn: {
    '@keyframes fadeIn': {
      '0%': {
        opacity: 0
      },
      '100%': {
        opacity: 1
      }
    },
    animation: '$fadeIn 400ms ease-in-out both'
  },
  containerAccordian: {
    height: '75vh',
    zIndex: 1,
    animation: '$fadeIn 400ms ease-in-out both',
    flex: 1,
    width: '200px',
    border: '1px solid hsla(208, 30%, 82%, 1)',
    borderRadius: '20px',
    backgroundColor: 'white',
    padding: '0px 16px',
    gap: '7px',
    display: 'flex',
    flexDirection: 'column'
  },
  containerHeaderList: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid hsla(225, 35%, 93%, 1)`,
    position: 'relative',
    zIndex: 5,
    backgroundColor: 'white',
    paddingTop: '24px'
  },
  containerSearch: {
    fontSize: '1rem',
    width: '80%',
    border: 'none',
    '&::placeholder': {
      color: 'hsla(229, 17%, 36%, 0.5)'
    },
    '&:focus': {
      borderBottom: '1px solid hsla(229, 17%, 36%, 0.5)'
    }
  },
  iconSearch: {
    position: 'absolute',
    right: '16px',
    cursor: 'pointer'
  },
  subHeader: {
    fontSize: '12px',
    color: 'hsla(225, 22%, 71%, 1)',
    padding: '8px 0',
    marginBottom: '1px'
  }
}));
