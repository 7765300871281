import * as PERMISSIONS from '../constants/permissions';

export function hasPermission (permission, { isSuperAdmin, access }) {
  if (isSuperAdmin) return true;

  return (permission && (
    access?.[permission]?.actions?.create ||
    access?.[permission]?.actions?.read
  ));
}
