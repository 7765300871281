import Stage from '../../../util/stage';
import http from '../../../helpers/http';
import { generateTypes, makeAction } from '../../../util/actions';
import { API } from '../../../config/config.constants';
import { commonActions } from '../../../constants/actions';

const TYPES = generateTypes(
  'GET_SAMPARK_ADMIN_DATA', 'SET_SAMPARK_ADMIN_DATA', 'RESET_SAMPARK_ADMIN_DATA', 'ERROR_SAMPARK_ADMIN_DATA'
);

const Actions = Object.freeze({
  get: () => makeAction(TYPES.GET_SAMPARK_ADMIN_DATA),
  set: (data) => makeAction(TYPES.SET_SAMPARK_ADMIN_DATA, { data }),
  reset: () => makeAction(TYPES.RESET_SAMPARK_ADMIN_DATA),
  error: (err) => makeAction(TYPES.ERROR_SAMPARK_ADMIN_DATA, { err })
});

export const ActionCreators = Object.freeze({
  fetchSamparkAdminData: () => (dispatch) => {
    dispatch(Actions.get());

    const requests = [
      API.ADMIN_VIEW.GET_SAMPARK_USERS_BY_STATUS('APPROVED')
    ].map(url => http.get(url));

    Promise.all(requests)
      .then(response => {
        const [ { data: approvedUsers } ] = response;
        dispatch(Actions.set({ approvedUsers }));
      })
      .catch(err => {
        dispatch(Actions.error(err));
        dispatch({
          type: commonActions.SHOW_HIDE_TOASTER,
          value: {
            displayToaster: true,
            message: 'Error loading sambandh approval data',
            type: commonActions.ERROR_TOASTER
          }
        });
      });
  },
  resetSamparkAdminData: () => (dispatch) => dispatch(Actions.reset())
});

const initialState = Object.freeze({
  stage: Stage.READY,
  data: {
    approvedUsers: []
  }
});

const reducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case TYPES.GET_SAMPARK_ADMIN_DATA:
      return {
        ...state,
        stage: Stage.LOADING
      };
    case TYPES.SET_SAMPARK_ADMIN_DATA:
      return {
        ...state,
        stage: Stage.LOADED,
        data: payload.data
      };
    case TYPES.RESET_SAMPARK_ADMIN_DATA:
      return initialState;
    case TYPES.ERROR_SAMPARK_ADMIN_DATA:
      return {
        ...state,
        stage: Stage.LOAD_ERROR
      };
    default:
      return state;
  }
};

export default reducer;
