import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import PropTypes from 'prop-types';

export const PrivateRoute = (ComposedComponent) => {

  const PrivateComponent = (props) => {
    const { samparkAuthenticated } = props;

    return (
      samparkAuthenticated
        ? <ComposedComponent {...props} />
        : <Redirect to='/auth' />
    );
  };

  PrivateComponent.propTypes = {
    samparkAuthenticated: PropTypes.bool,
    history: PropTypes.object.isRequired
  };

  function mapStateToProps (state) {
    return {
      samparkAuthenticated: state.auth.samparkAuthenticated
    };
  }

  return connect(mapStateToProps)(PrivateComponent);
  // return <AuthenticationContainer />;
};
