import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  createAttribute,
  getAllAttributes,
  getAllFields,
  removeAttribute,
  updateAttribute
} from '../../../apis/attributeAPIs';
import { showToast } from './components/Toaster/toaster.ducks';
import { IAttributeAdminRedux, IAttributes, RespState } from './attributes.types';
import { TOAST_VARIANTS } from './components/Toaster/toaster.constants';

export const getAttributes = createAsyncThunk(
  'attributeAdmin/attributes',
  async (query, { rejectWithValue }) => {
    try {
      const response = await getAllAttributes();
      if (response.error) {
        throw response.error;
      }
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getFields = createAsyncThunk(
  'attributeAdmin/fields',
  async (query, { dispatch, rejectWithValue }) => {
    try {
      const response = await getAllFields();

      if (response.error) {
        throw response.error;
      }
      return response;
    } catch (e: any) {
      dispatch(showToast({ type: TOAST_VARIANTS.ERROR, message: e.response.data }));
      return rejectWithValue(e);
    }
  }
);

export const rollBackAttribute = createAsyncThunk(
  'attributeAdmin/rollbackAttribute',
  async (attribute: IAttribute, { dispatch, rejectWithValue }) => {
    try {
      const response = await createAttribute(attribute);
      if (response.error) {
        throw response.error;
      }
      dispatch(resetPreviousAttribute());
      dispatch(showToast({ type: TOAST_VARIANTS.SUCCESS, message: 'Attribute Reverted' }));
      return response;
    } catch (e: any) {
      dispatch(showToast({ type: TOAST_VARIANTS.ERROR, message: e.response.data }));
      return rejectWithValue(e);
    }
  }
);

export interface IAttribute {
  value: IAttributes;
  oldKey?: string;
}

export const createAttributes = createAsyncThunk(
  'attributeAdmin/createAttribute',
  async (attribute: IAttribute, { dispatch, rejectWithValue }) => {
    try {
      const response = await createAttribute(attribute);
      if (response.error) {
        throw response.error;
      }
      dispatch(resetAttribute());
      dispatch(showToast({ type: TOAST_VARIANTS.SUCCESS, message: 'Attribute Created' }));
      return response;
    } catch (e: any) {
      dispatch(showToast({ type: TOAST_VARIANTS.ERROR, message: e.response.data }));
      return rejectWithValue(e);
    }
  }
);

export const updateAttributes = createAsyncThunk(
  'attributeAdmin/updateAttribute',
  async (attribute: IAttribute, { dispatch, rejectWithValue }) => {
    try {
      const response = await updateAttribute(attribute);
      if (response.error) {
        throw response.error;
      }
      dispatch(showToast({ type: TOAST_VARIANTS.SUCCESS, message: 'Attribute Updated' }));
      return response;
    } catch (e: any) {
      dispatch(showToast({ type: TOAST_VARIANTS.ERROR, message: e.response.data }));
      return rejectWithValue(e);
    }
  }
);

export const deleteAttribute = createAsyncThunk(
  'attributeAdmin/deleteAttribute',
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await removeAttribute(id);
      if (response.error) {
        throw response.error;
      }
      dispatch(getAttributes());
      dispatch(showToast({ type: TOAST_VARIANTS.WARNING, message: 'Attribute Deleted' }));
      return response;
    } catch (e: any) {
      dispatch(showToast({ type: TOAST_VARIANTS.ERROR, message: e.response.data }));
      return rejectWithValue(e);
    }
  }
);

const initialState: IAttributeAdminRedux = {
  attributes: [],
  attribute: {
    _id: '',
    key: '',
    description: '',
    group: '',
    field: {
      name: '',
      datatype: 'string',
      labelValues: [],
      path: '',
      multiSelect: false,
      label: ''
    },
    definition: {
      fact: '',
      operator: '',
      value: [],
      path: '',
      field: ''
    }
  },
  getFieldsApiState: RespState.READY,
  attributesApiState: RespState.READY,
  allFields: [],
  allAttributeGroups: [''],
  previousAttribute: []
};

// Then, handle actions in your reducers:
const attributeAdminSlice = createSlice({
  name: 'attributeAdmin',
  initialState,
  reducers: {
    setAttribute: (state, action) => {
      state.attribute = action.payload;
    },
    storeAttribute: (state, action) => {
      state.previousAttribute = state.attributes.filter(
        (attribute) => attribute.key === action.payload
      );
      delete state.previousAttribute[0]._id;
    },
    resetState: () => initialState,
    resetPreviousAttribute: (state) => {
      state.previousAttribute = [];
    },
    resetAttribute: (state) => {
      state.attribute = {
        _id: '',
        key: '',
        description: '',
        group: '',
        field: {
          name: '',
          datatype: 'labelValues',
          labelValues: [],
          path: '',
          multiSelect: false
        },
        definition: {
          fact: '',
          operator: '',
          value: [],
          path: '',
          field: ''
        }
      };
    }
  },
  extraReducers: (builder) => {
    //get all fields
    builder.addCase(getFields.pending, (state) => {
      state.getFieldsApiState = RespState.LOADING;
    });
    builder.addCase(getFields.fulfilled, (state, action) => {
      state.getFieldsApiState = RespState.LOADED;
      const { fields, groups } = action.payload;
      fields?.forEach((field: any) => {
        field['label'] = field.name;
      });
      state.allFields = fields;
      state.allAttributeGroups = groups;
    });
    builder.addCase(getFields.rejected, (state) => {
      state.getFieldsApiState = RespState.LOAD_ERROR;
      state.allFields = [];
    });

    //get all attributes
    builder.addCase(getAttributes.pending, (state) => {
      state.attributesApiState = RespState.LOADING;
    });
    builder.addCase(getAttributes.fulfilled, (state, action) => {
      state.attributesApiState = RespState.LOADED;
      state.attributes = action.payload;
    });
    builder.addCase(getAttributes.rejected, (state) => {
      state.attributesApiState = RespState.LOAD_ERROR;
      state.attributes = [];
    });

    //create an attribute
    builder.addCase(createAttributes.pending, (state) => {
      state.attributesApiState = RespState.CREATING;
    });
    builder.addCase(createAttributes.fulfilled, (state, action) => {
      state.attributesApiState = RespState.CREATED;
      state.attributes = [...state.attributes, action.payload];
    });
    builder.addCase(createAttributes.rejected, (state) => {
      state.attributesApiState = RespState.CREATE_ERROR;
    });

    //update an attribute
    builder.addCase(updateAttributes.pending, (state) => {
      state.attributesApiState = RespState.UPDATING;
    });
    builder.addCase(updateAttributes.fulfilled, (state, action) => {
      state.attributesApiState = RespState.UPDATED;
      state.attributes.forEach((__, index) => {
        state.attributes[index] = action.payload;
      });
    });
    builder.addCase(updateAttributes.rejected, (state) => {
      state.attributesApiState = RespState.UPDATE_ERROR;
    });

    //rollback an attribute
    builder.addCase(rollBackAttribute.pending, (state) => {
      state.attributesApiState = RespState.ROLLING_BACK;
    });
    builder.addCase(rollBackAttribute.fulfilled, (state, action) => {
      state.attributesApiState = RespState.ROLLED_BACK;
      let isExist = false;
      state.attributes.forEach((attribute, index) => {
        if (attribute._id === action.payload._id) {
          isExist = true;
          state.attributes[index] = action.payload;
        }
      });
      if (!isExist) {
        state.attributes = [...state.attributes, action.payload];
      }
    });
    builder.addCase(rollBackAttribute.rejected, (state) => {
      state.attributesApiState = RespState.ROLLBACK_ERROR;
    });

    //delete an attribute
    builder.addCase(deleteAttribute.pending, (state) => {
      state.attributesApiState = RespState.DELETING;
    });
    builder.addCase(deleteAttribute.fulfilled, (state) => {
      state.attributesApiState = RespState.DELETED;
    });
    builder.addCase(deleteAttribute.rejected, (state) => {
      state.attributesApiState = RespState.DELETED_ERROR;
    });
  }
});

export const { setAttribute, storeAttribute, resetAttribute, resetPreviousAttribute, resetState } =
  attributeAdminSlice.actions;

export default attributeAdminSlice.reducer;
