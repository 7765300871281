import { withStyles } from '@material-ui/core/styles';

export const useStyles = withStyles({
  container: {
    margin: '0rem auto',
    maxWidth: '650px',
    width: '100%'
  },
  view: {
    margin: '1.5rem'
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: '1.5rem'
  },
  headerRow: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px'
  },
  titleGroup: {
    display: 'flex'
  },
  avatar: {
    marginRight: '19px'
  },
  name: {
    textTransform: 'capitalize',
    fontSize: '1.5rem',
    'font-weight': '500'
  },
  nickName: {
    textTransform: 'capitalize',
    fontSize: '18px'
  },
  sectionTitle: {
    fontSize: '14px',
    color: '#899EB0'
  },
  contactDetail: {
    fontSize: '18px',
    color: '#067BC2'
  },
  infoText: {
    fontSize: '18px',
    textTransform: 'capitalize'
  },
  muktType: {
    backgroundColor: '#FFF3D2',
    borderRadius: '3px',
    padding: '4px 8px',
    color: '#9E7726'
  },
  section: {
    '&:not(:last-child)': {
      borderBottom: '1px solid #EBF0F5',
      marginBottom: '24px'
    },
    paddingBottom: '16px'
  },
  userInfo: {
    marginBottom: '30px',
    display: 'flex',
    alignItems: 'center'
  },
  tagRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '8px'
  },
  close: {
    cursor: 'pointer'
  }
});
