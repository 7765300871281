import React, { ChangeEvent, useState } from 'react';
import { ReactComponent as CloseIcon } from '../../assets/icons/icon_close_small.svg';
import { ReactComponent as SearchActiveIcon } from '../../assets/icons/icon_search_active.svg';
import { ReactComponent as SearchInactiveIcon } from '../../assets/icons/icon_search_inactive.svg';
import { useSearchStyles } from './Search.styles';
import {
  DEFAULT_SEARCH_MIN_LENGTH,
  DEFAULT_SEARCH_PLACEHOLDER,
  DEFAULT_VALID_REGEX
} from './Search.constants';

interface ISearchProps {
  minLength?: number;
  onInputChange: (val: string) => void;
  onSearchSubmit?: (val: string) => void;
  placeholder?: string;
  searchValue?: string;
}

export const Search = (props: ISearchProps) => {
  const {
    minLength = DEFAULT_SEARCH_MIN_LENGTH,
    onInputChange,
    onSearchSubmit,
    placeholder = DEFAULT_SEARCH_PLACEHOLDER,
    searchValue = ''
  } = props;

  const { classes, cx } = useSearchStyles();
  const [hasError, setHasError] = useState(false);

  const isActive = !!(searchValue && searchValue.length >= minLength);

  // If a submit function is provided use that or else do nothing.
  const handleSearchSubmit = () => {
    if (isActive && onSearchSubmit) {
      onSearchSubmit(searchValue);
    }
  };

  // If the user hits `Enter` we can run the submit function or reset the search if the input is empty.
  const handleKeyUp = (event: React.KeyboardEvent) => {
    // If the user presses enter, fire search handler
    if (event.key === 'Enter') {
      // We've nested this so this logic isn't run until enter key is pressed
      if (searchValue.length >= minLength || searchValue.length === 0) {
        handleSearchSubmit();
      }
    }
  };

  // Set the search term to '' and submit a search with the empty value to reset the data.
  const handleClearSearch = () => {
    onInputChange('');
    handleSearchSubmit();
  };

  // Set the input and test for bad characters.
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value }
    } = e;

    if (onInputChange) {
      onInputChange(value);
    }

    // If input has length & contains invalid string, set error. Otherwise reset error.
    if (value.length && !DEFAULT_VALID_REGEX.test(value)) {
      if (!hasError) {
        setHasError(true);
      }
    } else {
      if (hasError) {
        setHasError(false);
      }
    }
  };

  const SearchIcon = isActive ? SearchActiveIcon : SearchInactiveIcon;

  return (
    <div className={classes.container}>
      <SearchIcon
        className={cx(classes.icon, classes.searchIcon, { inactive: !isActive })}
        onClick={handleSearchSubmit}
      />
      <input
        className={cx(classes.searchInput, { active: isActive, error: hasError })}
        onChange={handleInputChange}
        onKeyUp={handleKeyUp}
        placeholder={placeholder}
        type="text"
        value={searchValue}
      />
      {searchValue ? (
        <CloseIcon className={cx(classes.icon, classes.clearIcon)} onClick={handleClearSearch} />
      ) : null}
    </div>
  );
};
