import React from 'react';
import PropTypes from 'prop-types';
// material ui
import { Button } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItemUI from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { ProfileBadge } from '../profileBadge';
import icon_alert from '../../../assets/icons/icon_alert.svg';

import './adminListItems.scss';

/**
 * @param {array} items
 * {
 *  name: name of contact,
 *  city: city of contact,
 *  state: state of contact,
 *  contact: contact object,
 *  tag: approvalStatus (invited|pending|verified|declined),
 *  badge: control item - display badge with label/text,
 *  showAlert: display alert icon based on value,
 *  getActionButton: render custom component for CTA,
 * }
 */
export const AdminListItems = (props) => {
  const { items } = props;
  if (items && items instanceof Array && items.length) {
    return (
      <List component='div'>
        {items.map((item, index) => {
          return <AdminListItem key={index} {...item} />;
        })}
      </List>
    );
  }
  return null;
};

AdminListItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired
};

/**
 * @param {string} name: name of contact
 * @param {string} city: city of contact
 * @param {string} state: state of contact
 * @param {object} contact: contact object
 * @param {string} tag: approvalStatus (invited|pending|verified|declined)
 * @param {string} badge: control item - display badge with label/text
 * @param {boolean} showAlert: display alert icon based on value
 * @param {component|func} getActionButton: render custom component for CTA
 * @param {array} classes: appends class to paraent element
 */
export const AdminListItem = (props) => {
  const { name, city, state, contact, tag, badge, showAlert, getActionButton: ActionButton, onClick, isButton = false, classes = [] } = props;

  const location = city && `${city}, ${state.toUpperCase()}`;

  const onClickHandler = () => {
    if (onClick) onClick();
  };

  const ListItemContainer = isButton ? Button : React.Fragment;
  const containerProps = isButton ? {
    className: 'listItemContainer',
    onClick: onClickHandler,
    disableRipple: true
  } : [];
  return (
    <div className={['ui-list-item-container', ...classes].join(' ')}>
      <ListItemContainer {...containerProps}>
        <ListItemUI ContainerComponent='div' className='ui-list-item'>
          <ListItemAvatar>
            <ProfileBadge primaryText={name} secondaryText={location} contact={contact} />
          </ListItemAvatar>
          <div className='ui-list-item--details-container'>
            {tag && <ListItemText primary={tag} className={`ui-list-item--tag ${tag}`} /> }
          </div>
          <div className='ui-list-item--icon-contaier'>
            {badge !== undefined && <span className={'ui-list-item--control-badge'}>{badge}</span>}
            {showAlert && <img className={'ui-list-item--alert-icon'} src={icon_alert} />}
          </div>
        </ListItemUI>
      </ListItemContainer>
      <div className='ui-list-item--action-button'>
        {ActionButton && <ActionButton />}
      </div>
    </div>
  );
};

AdminListItem.propTypes = {
  name: PropTypes.string.isRequired,
  city: PropTypes.string,
  state: PropTypes.string,
  contact: PropTypes.object,
  tag: PropTypes.string,
  badge: PropTypes.string,
  showAlert: PropTypes.bool,
  getActionButton: PropTypes.func,
  onClick: PropTypes.func,
  isButton: PropTypes.bool,
  classes: PropTypes.array
};
