/**
 *
 * @param actions a list of strings representing redux actions, eg. GET_CONTACTS, SET_CONTACTS
 * @returns an object with key/value pairs generated from the source actions.
 */
export const generateTypes = (...actions: string[]) =>
  Object.freeze(
    actions.reduce((res, action) => {
      res[action] = action;
      return res;
    }, {} as Record<string, string>)
  );

// generate a redux action
export const makeAction = <T>(type: string, payload?: T) => Object.freeze({ type, payload });
