import React from 'react';
import PropTypes from 'prop-types';

// v2 files
import { Sidebar } from './Sidebar';
import { AccessControls } from '../access-controls';
import './index.scss';
import { checkFormDirty } from '../../utils';

export const AccessPanel = (props) => {

  const _onChange = (key, value, formValid) => {
    const newManageUserPermissions = { ...props.manageUserPermissions };
    newManageUserPermissions[props.activeTab] = value;
    props.onChange(props.name, newManageUserPermissions, formValid);
  };

  const _changeTab = (activeTab) => {
    if (props.onChangeTab) {
      props.onChangeTab(activeTab);
    }
  };

  const _getSidebarItems = () => {
    const {
      accessConfig,
      manageUserPermissions,
      originalPermissions
    } = props;

    let sidebarItems = [];

    for (const key in accessConfig) {
      const icon = accessConfig[key].icon;
      const isDirty = checkFormDirty(key, manageUserPermissions, originalPermissions) === false;
      sidebarItems.push({ name: key, icon, isDirty });
    }

    return sidebarItems;
  };

  const {
    accessConfig,
    isSuperAdmin,
    activeTab,
    manageUserPermissions,
    userPermissions,
    disabled,
    audiencesList,
  } = props;

  const sidebarItems = _getSidebarItems();
  if (!accessConfig[activeTab]) return null;
  return (
    <div className={'access-container__panel-container'}>
      <Sidebar
        className={'access-container__panel-sidebar'}
        sidebarItems={sidebarItems}
        activeTab={activeTab}
        onChange={_changeTab}
      />
      <div className={'access-container__panel-content'}>
        <AccessControls
          disabled={disabled}
          key={activeTab}
          accessConfig={accessConfig[activeTab]}
          onChange={_onChange}
          manageUserPermissions={manageUserPermissions[activeTab]}
          userPermissions={userPermissions[activeTab]}
          isSuperAdmin={isSuperAdmin}
          name={activeTab}
          audiencesList={audiencesList[activeTab]}
        />
      </div>
    </div>
  );
};

AccessPanel.propTypes = {
  accessConfig: PropTypes.object,
  manageUserPermissions: PropTypes.object.isRequired,
  userPermissions: PropTypes.object.isRequired,
  isSuperAdmin: PropTypes.bool,
  activeTab: PropTypes.string,
  onChange: PropTypes.func,
  onChangeTab: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  audiencesList: PropTypes.object,
  originalPermissions: PropTypes.object,
};
