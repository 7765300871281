import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createOrUpdateRule, getAllRules, removeRule } from '../../../apis/ruleAPI';
import { showToast } from '../Attributes/components/Toaster/toaster.ducks';
import { ICreateRule, IRuleAdminRedux, RespState } from './rules.types';
import { TOAST_VARIANTS } from '../Attributes/components/Toaster/toaster.constants';

export const createRule = createAsyncThunk(
  'ruleAdmin/createRule',
  async (rule: ICreateRule, { dispatch, rejectWithValue }) => {
    try {
      const response = await createOrUpdateRule(rule);
      if (response.error) {
        throw response.error;
      }
      dispatch(getRules());
      dispatch(showToast({ type: TOAST_VARIANTS.SUCCESS, message: 'Rule created' }));

      return response;
    } catch (e: any) {
      dispatch(showToast({ type: TOAST_VARIANTS.ERROR, message: e.response.data }));
      return rejectWithValue(e);
    }
  }
);
export const rollBackRule = createAsyncThunk(
  'ruleAdmin/rollBackRule',
  async (rule: ICreateRule, { dispatch, rejectWithValue }) => {
    try {
      const response = await createOrUpdateRule(rule);
      if (response.error) {
        throw response.error;
      }
      dispatch(getRules());
      dispatch(showToast({ type: TOAST_VARIANTS.SUCCESS, message: 'Rule Reverted' }));

      return response;
    } catch (e: any) {
      dispatch(showToast({ type: TOAST_VARIANTS.ERROR, message: e.response.data }));
      return rejectWithValue(e);
    }
  }
);

export const getRules = createAsyncThunk(
  'ruleAdmin/getAllrules',
  async (query, { rejectWithValue }) => {
    try {
      const response = await getAllRules();
      if (response.error) {
        throw response.error;
      }
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteRule = createAsyncThunk(
  'ruleAdmin/deleteRule',
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await removeRule(id);
      if (response.error) {
        throw response.error;
      }
      dispatch(getRules());
      dispatch(showToast({ type: TOAST_VARIANTS.WARNING, message: 'Rule Deleted' }));

      return response;
    } catch (e: any) {
      dispatch(showToast({ type: TOAST_VARIANTS.ERROR, message: e.response.data }));

      return rejectWithValue(e);
    }
  }
);

const initialState: IRuleAdminRedux = {
  rule: [],
  rulesApiState: RespState.READY,
  previousRule: [],
  rules: []
};

const ruleAdminSlice = createSlice({
  name: 'ruleAdmin',
  initialState,
  reducers: {
    storeRule: (state, action) => {
      state.previousRule = state.rules.filter((rule) => rule['_id'] === action.payload);
      state.previousRule[0].id = state.previousRule[0]._id;
      delete state.previousRule[0].__v;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createRule.pending, (state) => {
      state.rulesApiState = RespState.CREATING;
    });
    builder.addCase(createRule.fulfilled, (state) => {
      state.rulesApiState = RespState.CREATED;
    });
    builder.addCase(createRule.rejected, (state) => {
      state.rulesApiState = RespState.CREATE_ERROR;
    });

    //Roll back rules
    builder.addCase(rollBackRule.pending, (state) => {
      state.rulesApiState = RespState.ROLLING_BACK;
    });
    builder.addCase(rollBackRule.fulfilled, (state) => {
      state.rulesApiState = RespState.ROLLED_BACK;
    });
    builder.addCase(rollBackRule.rejected, (state) => {
      state.rulesApiState = RespState.ROLLBACK_ERROR;
    });

    //get all rules
    builder.addCase(getRules.pending, (state) => {
      state.rulesApiState = RespState.LOADING;
    });
    builder.addCase(getRules.fulfilled, (state, action) => {
      state.rulesApiState = RespState.LOADED;
      state.rules = action.payload;
    });
    builder.addCase(getRules.rejected, (state) => {
      state.rulesApiState = RespState.LOAD_ERROR;
      state.rules = [];
    });

    //delete rule
    builder.addCase(deleteRule.pending, (state) => {
      state.rulesApiState = RespState.DELETING;
    });
    builder.addCase(deleteRule.fulfilled, (state) => {
      state.rulesApiState = RespState.DELETED;
    });
    builder.addCase(deleteRule.rejected, (state) => {
      state.rulesApiState = RespState.DELETED_ERROR;
    });
  }
});

export const { storeRule } = ruleAdminSlice.actions;

export default ruleAdminSlice.reducer;
