import React, { Fragment } from 'react';
import { MenuItem } from '@mui/material';
import Autosuggest from 'react-autosuggest';
import { bindActionCreators } from 'redux';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/icon_close_small.svg';
import { useAppDispatch } from '../../../../helpers/store';
import theme from '../../../../styles/overrides/cityfilter.module.scss';
import { capitalizeString } from '../../../../util/capitalizeString';
import { contactActions } from '../../contacts.ducks';
import { makeStyles } from 'tss-react/mui';

interface ISuggestion {
  city: string;
  state: string;
}

interface ICityFilterInputProps {
  className?: string;
  containerClassName?: string;
  filterCitySuggestions: ISuggestion[];
  isDisabled: boolean;
  onChange: (value: string) => void;
  onClear: () => void;
  onSelectSuggestion: (city: string) => void;
  value: string;
}

export const CityFilterInput = (props: ICityFilterInputProps) => {
  const {
    className,
    containerClassName,
    filterCitySuggestions,
    isDisabled,
    onChange,
    onClear,
    onSelectSuggestion,
    value
  } = props;

  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const actions = bindActionCreators(
    {
      clearFilterCitySuggestions: contactActions.clearFilterCitySuggestions,
      getFilterCitySuggestions: contactActions.getFilterCitySuggestions
    },
    dispatch
  );

  function handleInputChange(__: React.FormEvent<HTMLElement>, { newValue }: { newValue: string }) {
    onChange(newValue);
  }

  function getSuggestionValue(suggestion: ISuggestion) {
    return `${capitalizeString(suggestion.city)}, ${suggestion.state.toUpperCase()}`;
  }

  function renderSuggestion(suggestion: ISuggestion) {
    return (
      <MenuItem component="div">
        <div>
          <span>
            {capitalizeString(suggestion.city)}, {suggestion.state.toUpperCase()}
          </span>
        </div>
      </MenuItem>
    );
  }

  function onSuggestionsFetchRequested({ value }: { value: string }) {
    if (value && value.length >= 3) {
      actions.getFilterCitySuggestions(value);
    }
  }

  function onSuggestionsClearRequested() {
    actions.clearFilterCitySuggestions();
  }

  function onSuggestionSelected(
    __: React.FormEvent<HTMLElement>,
    { suggestion }: { suggestion: ISuggestion }
  ) {
    const city = suggestion.city ? capitalizeString(suggestion.city) : '';
    const state = suggestion.state.toUpperCase();
    const suggestionText = `${city}, ${state}`;
    onChange(suggestionText);
    onSelectSuggestion(city || '');
  }

  const inputProps = {
    value,
    onChange: handleInputChange,
    className,
    placeholder: 'City',
    disabled: isDisabled
  };
  const containerProps = {
    className: containerClassName
  };

  return (
    <Fragment>
      <Autosuggest
        theme={theme}
        containerProps={containerProps}
        suggestions={filterCitySuggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={onSuggestionSelected}
        inputProps={inputProps}
      />
      {value && value.length ? <CloseIcon className={classes.closeIcon} onClick={onClear} /> : null}
    </Fragment>
  );
};

const useStyles = makeStyles()(() => ({
  closeIcon: { cursor: 'pointer', position: 'absolute', right: '15px', top: 13 }
}));
