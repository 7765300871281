import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// MATERIAL UI
import { Button, AppBar, Toolbar } from '@material-ui/core';
import { submitBroadcastForm, approveBroadcastForm, declineBroadcastForm } from '../../actions/broadcastForm.actions';

const BroadcastApproveFooter = (props) => {
  let buttonDisabled = !props.isFormValid || !props.isFormDirty;
  return (
    <AppBar
      position='sticky'
      className='panel-footer'>
      <Toolbar>
        <div
          className='contact-form-button-container padding-sm'>
          {SecondaryButton(props)}
          {PrimaryButton(buttonDisabled, props)}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default connect(null, {
  submitBroadcastForm,
  declineBroadcastForm,
  approveBroadcastForm
})(BroadcastApproveFooter);

BroadcastApproveFooter.propTypes = {
  isFormValid: PropTypes.bool,
  isFormDirty: PropTypes.bool,
  onCancel: PropTypes.func
};

const SecondaryButton = (props) => {

  const handleClick = () => {
    props.declineBroadcastForm();
    props.onCancel();
  };

  let buttonLabel = 'Decline';
  return (
    <Button
      className={['button-primary', 'button-red', 'margin-xsm-right'].join(' ')}
      onClick={handleClick}
      disableRipple
      color='primary'>
      <span className={['button-primary-label', 'label-white'].join(' ')}>{buttonLabel}</span>
    </Button>
  );
};

const PrimaryButton = (buttonDisabled, props) => {
  let classes = [];
  let buttonLabel = 'Approve';
  classes.push('button-green');
  if (buttonDisabled) {
    classes.push('button-primary-disabled');
  }

  const handleClick = () => {
    return props.approveBroadcastForm();
  };

  return (
    <Button
      disableRipple
      onClick={handleClick}
      disabled={buttonDisabled}
      className={['button-primary', ...classes].join(' ')}
      color='primary'>
      <span className='button-primary-label label-white'>{buttonLabel}</span>
    </Button>
  );
};

PrimaryButton.propTypes = {
  approveBroadcastForm: PropTypes.func.isRequired
};

SecondaryButton.propTypes = {
  openModal: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired
};
