export function saveCSV (data, filename) {
  const uri = `data:text/csv;charset=utf-8;header=present,${encodeURIComponent(data)}`;
  const link = document.createElement('a');
  link.setAttribute('href', uri);
  link.setAttribute('download', filename);
  link.addEventListener('click', () => link.parentNode.removeChild(link));
  document.body.appendChild(link);
  link.click();
  link.remove();
}
