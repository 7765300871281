import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import MultiSelectDropDownWithChips from '../../v2/components/MultiSelectDropDown';


const accessConfig = {
  displayLabel: 'Audiences',
  description: 'Select the audiences you want this person to have access to.',
  tooltip: 'Select the audiences you want this person to have access to.',
  required: true,
  key: 'audiences',
  placeholder: 'Audiences',
};

class TargetSection extends Component {

  renderAudienceSelectorField = ({ input, label, }) => {
    const onChangeValue = (event, value, formValid) => {
      const newValue = value.map((valueItem) => valueItem.value);
      input.onChange(newValue);
    }
    const set = new Set();
    if (input.value?.forEach) {
      input?.value?.forEach(element => {
        set.add(element);
      });
    }

    return <MultiSelectDropDownWithChips
      onChange={onChangeValue}
      options={this.props.audiencesList || []}
      displayConfig={accessConfig}
      value={this.props.audiencesList.filter((audience) => set.has(audience.value))}
      disabled={this.props.disabled}
      isSuperAdmin={false}
    />

  }
  render() {
    return (
      <div style={{ marginBottom: 16 }}>
        {this.renderAudienceSelectorField(this.props)}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  // const locationOptions = getLocationOptionsByForm(state, props);
  return {
  };
}

export default connect(mapStateToProps, { change })(TargetSection);

TargetSection.propTypes = {
  disabled: PropTypes.bool,
  audiencesList: PropTypes.array
};
