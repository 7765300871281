import { Tooltip } from '@mui/material';
import { Droppable } from '../../../../../../components/drag-drop/Droppable';
import CloseIcon from '@mui/icons-material/Close';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import icon_help_filled from '../../../../../../assets/icons/icon_help-filled.svg';
import { IFormRule } from '../../../rules.types';
import { OPERATOR } from '../editRule.constants';
import { useStyles } from './formRule.styles';

export const FormRule = ({
  ruleSelected,
  name,
  handleName,
  description,
  handleDescription,
  isGlobalOperator,
  globalOperator,
  onChange,
  rule,
  operator,
  handleOperator,
  removeAttributeHandler,
  closeHandler,
  addHandler
}: IFormRule) => {
  const { classes } = useStyles();

  return (
    <>
      {/* Name field block */}
      <div className={classes.fieldBlock}>
        <div className={classes.fieldHeader}>
          <label>Name</label>
          <Tooltip
            placement="right"
            title="Name the rule. Once set cannot be changed at the moment."
          >
            <img className="iconHelp" src={icon_help_filled} />
          </Tooltip>
        </div>
        <input
          className={`${classes.fieldInput} ${
            Array.isArray(ruleSelected) && ruleSelected.length > 0 && classes.disabled
          }`}
          placeholder="Rule Name"
          value={name}
          onChange={(event) => handleName(event.target.value)}
          required
          disabled={Array.isArray(ruleSelected) && ruleSelected.length > 0}
        />
      </div>

      {/* Description field block */}
      <div className={classes.fieldBlock}>
        <div className={classes.fieldHeader}>
          <label>Description</label>
          <Tooltip placement="right" title="Describe the rule.">
            <img className="iconHelp" src={icon_help_filled} />
          </Tooltip>
        </div>
        <input
          className={classes.fieldInput}
          placeholder="Rule Description"
          value={description}
          onChange={(event) => handleDescription(event.target.value)}
          required
        />
      </div>
      {/* Create Rule field block */}
      <div className={classes.fieldBlock}>
        <div className={classes.fieldHeader}>
          <label>Create Rule</label>
          <Tooltip placement="right" title="Create the rule">
            <img className="iconHelp" src={icon_help_filled} />
          </Tooltip>
          {/*Global operator */}
          {isGlobalOperator && (
            <div className={classes.globalToggle}>
              <button
                className={`${classes.toggleButton} left ${
                  globalOperator === OPERATOR.OR ? classes.globalSelected : ''
                }`}
                onClick={() => onChange(OPERATOR.OR)}
              >
                OR
              </button>
              <button
                className={`${classes.toggleButton} right ${
                  globalOperator === OPERATOR.AND ? classes.globalSelected : ''
                }`}
                onClick={() => onChange(OPERATOR.AND)}
              >
                AND
              </button>
            </div>
          )}
        </div>
        <div className={classes.createRule}>
          {/* if type of attribute is an array then it is a string */}
          {rule.map((attribute, index) => (
            <div key={index} className={classes.scrollContainer}>
              {console.log(OPERATOR.OR === operator[index], 'OPERATOR.OR', operator)}
              <div className={classes.dragDropContainer}>
                {/*if type of attribute is an object then it is an object */}
                {attribute.attributeList.length > 1 && (
                  <div className={classes.dragDropToggle}>
                    <button
                      className={`${classes.toggleButton} left ${
                        operator[index] === OPERATOR.OR ? classes.globalSelected : ''
                      }`}
                      onClick={() => handleOperator(OPERATOR.OR, index)}
                    >
                      OR
                    </button>
                    <button
                      className={`${classes.toggleButton} right ${
                        operator[index] === OPERATOR.AND ? classes.globalSelected : ''
                      }`}
                      onClick={() => handleOperator(OPERATOR.AND, index)}
                    >
                      AND
                    </button>
                  </div>
                )}

                <Droppable data={operator[index]} index={index}>
                  {attribute.attributeList.map((attributeDetails, attributeIndex) => (
                    <div
                      className={classes.attributeBlock}
                      key={`${attributeDetails._id}-${attributeIndex}`}
                    >
                      <button className={classes.attributeButton}>
                        <div
                          style={{
                            color: 'inherit',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {attributeDetails.key}
                        </div>
                        <CloseIcon
                          style={{ color: 'inherit' }}
                          onClick={() => removeAttributeHandler(attributeIndex, index)}
                        />
                      </button>
                    </div>
                  ))}

                  {/* Disabled block */}
                  {attribute.attributeList.length < 1 && (
                    <div className={classes.attributeBlock}>
                      <button className={classes.attributeButton} disabled>
                        Drag & Drop your attribute here..
                        <CopyAllIcon />
                      </button>
                      <span className={classes.attributeClose}>
                        <CloseIcon onClick={() => closeHandler(index)} />
                      </span>
                    </div>
                  )}
                </Droppable>
              </div>
            </div>
          ))}
          {isGlobalOperator && (
            <button className={classes.addButton} onClick={addHandler}>
              + {globalOperator}
            </button>
          )}
        </div>
      </div>
    </>
  );
};
