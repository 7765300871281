import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
// project files
import { Dialog } from '@mui/material';
import { getBroadcastApprovalData, getBroadcastData } from '../../actions/broadcast.actions';
import { initializeBroadcastForm } from '../../actions/broadcastForm.actions';
import { openDrawer } from '../../actions/common.actions';
import { BroadcastPanel } from '../../components/broadcast-panel/BroadcastPanel';
import * as STATUS from '../../constants/messageStatus';
import { useAppDispatch, useAppSelector } from '../../helpers/store';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { BroadcastMessagesView } from './BroadcastMessagesView';
import { BroadcastViewDocumentTitle } from './constants';
import { getMessagesOfStatus } from './utils';

export const BroadcastView: React.FC = () => {
  const { broadcasts: allBroadcastMessages, broadcastApprovalMessages } = useAppSelector(
    (state) => state.broadcastData
  );

  const dispatch = useAppDispatch();
  const actions = bindActionCreators(
    { initializeBroadcastForm, getBroadcastApprovalData, getBroadcastData, openDrawer },
    dispatch
  );

  useSetDocumentTitle(BroadcastViewDocumentTitle);

  useEffect(() => {
    actions.getBroadcastData();
  }, []);

  const [showFormView, setShowFormView] = useState(false);

  const handleClickedNewMessage = useCallback(() => {
    actions.initializeBroadcastForm(null);
    setShowFormView(true);
  }, [actions]);

  const handleClickedViewMessage = useCallback(
    (message) => {
      actions.initializeBroadcastForm(message);
      setShowFormView(true);
    },
    [actions]
  );

  const handleCloseMessageForm = useCallback(() => {
    setShowFormView(false);
  }, []);

  const pendingMessages = useMemo(
    () => getMessagesOfStatus(broadcastApprovalMessages, STATUS.PENDING),
    [broadcastApprovalMessages]
  );

  // At mobile screen size, the modal should be full screen.
  const fullScreen = window.innerWidth < 768;

  return (
    <>
      <Dialog disableEscapeKeyDown fullScreen={fullScreen} maxWidth="md" open={showFormView}>
        <BroadcastPanel onCancel={handleCloseMessageForm} />
      </Dialog>
      <BroadcastMessagesView
        allMessages={allBroadcastMessages}
        getBroadcastApprovalData={actions.getBroadcastApprovalData}
        getBroadcastData={actions.getBroadcastData}
        pendingMessages={pendingMessages}
        handleClickedNewMessage={handleClickedNewMessage}
        onClickViewMessage={handleClickedViewMessage}
      />
    </>
  );
};
