import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// project files
import { signoutUser } from '../../actions/authentication.actions';
import { Logo } from '../../components/samparkLogo';

class SignOut extends Component {

  title = 'Logged out.';
  message = 'Jai swaminarayan & Das Na Das, you have been logged out.';

  componentDidMount () {
    if (!this.props.samparkAuthenticated) {
      console.log('no user. cannot naviate to /signout');
      this.props.history.push('/auth');
    }
    this.props.signoutUser();
  }

  render () {
    const title = 'Logged out.';
    const message = 'Jaiswaminarayan & Das Na Das, you have been logged out.';
    return (
      <Fragment>
        <Logo isNewAccount={false} />
        <div className='message-container' style={{marginBottom: 300}}>
          <div className='message-title'>
            <h4>
              {title}
            </h4>
          </div>
          <div className='message-content text'>
            {message}
          </div>
          <div style={{marginTop: 50}} >
            Click here to { <Link to='/auth'>Log In</Link> }
          </div>
        </div>
      </Fragment>
    );

  }
}

SignOut.propTypes = {
  samparkAuthenticated: PropTypes.bool.isRequired,
  signoutUser: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

function mapStateToProps (state) {
  return {
    samparkAuthenticated: state.auth.samparkAuthenticated
  };
}

export default connect(mapStateToProps, {signoutUser})(SignOut);
