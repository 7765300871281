import { LOGGEDINUSERINFOTOKEN, SAMPARKTOKEN } from '../config/config.tokens';
import { authActions } from '../constants/actions';
import { loginWithGoogle, logoutGoogle } from '../helpers/auth';
import { AppDispatch } from '../helpers/store';

/*
 * dispatches action when an auth error occursuse
 */
export function authError(error: string) {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: authActions.AUTH_ERROR,
      payload: error
    });
  };
}
/*
 * redirects user for google login and brings them back to sampark
 * returns google user object if succeeds
 */
export function signinUser() {
  return async (dispatch: AppDispatch) => {
    try {
      await loginWithGoogle();
    } catch (error) {
      return dispatch(authError('bad login info'));
    }
  };
}

/*
 * sign user out of google and then dispatch UNAUTH_USER action
 */
export function signoutUser() {
  return async (dispatch: AppDispatch) => {
    localStorage.removeItem(SAMPARKTOKEN);
    localStorage.removeItem(LOGGEDINUSERINFOTOKEN);
    await logoutGoogle();
    dispatch({ type: authActions.UNAUTH_USER });
  };
}
