import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { MembersType } from '../../../../types';
import { MembersList } from '../components/MembersList/MembersList';
import { SelectField } from '../../../../v2/components/SelectorField';
import { ISearchMemberQuery, addToFamily, getFamilyById, searchMembers } from '../familyTree.duck';
import { STATES } from '../../../../assets/data/us_states';
import FilterButtonGroup from '../components/FilterButtonGroup/FilterButtonGroup';
import { useAppDispatch, useAppSelector } from '../../../../helpers/store';
import SortButton from '../components/SortButton/SortButton';
import { Alert } from '@mui/material';
import { useMemberDetailsModal } from '../hooks/useMemberDetailsModal';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';

interface FamilyTreeSearchProps {
  members: MembersType[];
  maxPageNumbers: null | number;
  totalMember: number;
  isError: boolean;
  isLoading: boolean;
  isFamilyContentFilled: boolean;
}

const FamilyTreeSearch = ({
  members,
  maxPageNumbers,
  totalMember,
  isError,
  isFamilyContentFilled,
  isLoading
}: FamilyTreeSearchProps) => {
  const { classes } = useStyles();

  const dispatch = useAppDispatch();
  const familyRelation = useAppSelector((state) => state.admin.familyTree?.familyRelation);

  const { loading, fetchAndOpenMemberDetails } = useMemberDetailsModal();

  const [searchVal, setSearchVal] = useState('');
  const [stateVal, setStateVal] = useState('');
  const [genderVal, setGenderVal] = useState<string | undefined>();
  const [familyFilterVal, setFamilyFilterVal] = useState<boolean | undefined>();
  const [sortValue, setSortValue] = useState<string | undefined>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  const handleSearch = (query: ISearchMemberQuery) => {
    dispatch(searchMembers(query));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchVal(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      searchWithValues(1);
    }
  };

  const handleSelectState = (_: any, stateName: string) => {
    setStateVal(stateName);
  };

  const handleSelectGender = (selectedGenders: string[]) => {
    // If only one value is selected, then set value to use in query
    if (selectedGenders.length === 1) {
      setGenderVal(selectedGenders[0]);
    } else {
      setGenderVal(undefined);
    }
  };

  const handleSelectFamilyFilter = (familyFilters: string[]) => {
    // If only one value is selected, then set value to use in query
    if (familyFilters.length === 1) {
      setFamilyFilterVal(familyFilters[0] === 'assigned' ? true : false);
    } else {
      setFamilyFilterVal(undefined);
    }
  };

  const handleSelectFilter = (sortVal: string) => {
    setSortValue(sortVal);
  };

  const searchWithValues = (pageNumber: number) => {
    handleSearch({
      name: searchVal,
      state: stateVal,
      gender: genderVal as ISearchMemberQuery['gender'],
      hasFamily: familyFilterVal as ISearchMemberQuery['hasFamily'],
      sortValue,
      pageNumber
    });
  };
  const fetchMore = useCallback(() => {
    // if (!maxPageNumbers || pageNumber <= maxPageNumbers) {
    if (!isLoading) {
      setPageNumber(pageNumber + 1);

      searchWithValues(pageNumber + 1);
    }
    // }
  }, [maxPageNumbers, pageNumber]);

  useEffect(() => {
    setPageNumber(1);
    searchWithValues(1);
  }, [stateVal, genderVal, familyFilterVal, sortValue]);

  const handleAddToFamily = useCallback(
    (member: MembersType) => {
      // when family content  has family or member in it, add new member to it.
      // when family content is empty, if user doesnot have family, create new family
      if (isFamilyContentFilled || !member.familyId) {
        dispatch(addToFamily(member));
      } else {
        dispatch(getFamilyById(member.familyId));
      }
    },
    [addToFamily, dispatch, getFamilyById]
  );

  const onMemberClick = (id: string) => {
    fetchAndOpenMemberDetails(id);
  };

  return (
    <div className={classes.container}>
      {loading ? <LoadingSpinner className={classes.loading} /> : null}
      <header className={classes.header}>
        {isError ? <Alert severity={'error'}>Failed to Load contacts, try again</Alert> : null}
        <h3 className={classes.headerText}>Contacts ({totalMember})</h3>
      </header>
      <section className={classes.searchState}>
        <input
          className={classes.search}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          value={searchVal}
          placeholder={'Search name'}
        />
        <SelectField
          className={classes.state}
          containerClassName="contact-filter-state-input-container"
          placeholder={'State'}
          options={STATES}
          onChange={handleSelectState}
          label=""
          maxSelect={1}
          inputValue={stateVal}
        />
      </section>
      <section className={classes.filters}>
        <div className={classes.gender}>
          <h5 className={classes.filterHeader}>Gender</h5>
          <FilterButtonGroup
            buttons={[
              { text: 'M', value: 'male' },
              { text: 'F', value: 'female' }
            ]}
            onClickHandler={handleSelectGender}
          />
        </div>
        <div className={classes.assigned}>
          <h5 className={classes.filterHeader}>Assigned</h5>
          <FilterButtonGroup
            buttons={[
              { text: 'Assigned', value: 'assigned' },
              { text: 'Unassigned', value: 'unassigned' }
            ]}
            onClickHandler={handleSelectFamilyFilter}
          />
        </div>
        <div className={classes.sort}>
          <SortButton onClickHandler={handleSelectFilter} />
        </div>
      </section>
      {isLoading && pageNumber === 1 ? (
        <LoadingSpinner className={classes.loadingInner} />
      ) : (
        <MembersList
          className={classes.contentListContainer}
          contentClassName={classes.contentListItem}
          members={members}
          onAdd={handleAddToFamily}
          onClick={onMemberClick}
          selectedFamilyId={familyRelation?.id || ''}
          familyMembers={familyRelation?.members || []}
          showAddDisabled
          showAddButton
          showFamilyAssigned
          total={totalMember}
          fetchMore={fetchMore}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  container: {
    flex: '1 0 30%',
    background: '#fff',
    border: '1px solid #C3D2DF',
    borderRadius: '20px',
    maxHeight: '72vh',
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      flex: '1 0 40%'
    },
    [theme.breakpoints.up('xl')]: {
      flex: '1 0 440px',
      maxHeight: '76vh'
    }
  },
  contentListContainer: {
    marginTop: '1rem',
    height: 'calc(100% - 206px)',
    width: '100%',
    padding: theme.spacing(0, 0, 4),

    '&::-webkit-scrollbar': {
      borderRadius: '9px',
      width: '4px'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '9px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '9px',
      backgroundColor: 'darkgrey'
    }
  },
  contentListItem: {
    width: '100%'
  },
  header: {
    marginBottom: '1rem',
    padding: theme.spacing(2)
  },
  searchIcon: {
    flex: 1
  },
  headerText: {
    flex: 6
  },
  loading: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: 'rgba(0, 0, 0, 0.4)',
    zIndex: 2
  },
  loadingInner: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: 'rgba(0, 0, 0, 0.4)',
    zIndex: 2
  },
  sortButton: {
    flex: 1
  },
  searchState: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2)
  },
  search: {
    border: '1px solid #848EAC',
    borderRadius: '4px',
    color: '#848EAC',
    height: '53px',
    padding: '16px',
    fontSize: '18px',
    width: '72%'
  },
  state: {
    border: '1px solid #848EAC',
    height: '53px',
    '&:placeholder': {
      color: '#3A4463'
    }
  },
  filters: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: theme.spacing(0, 2),
    '> div': {
      marginRight: '2rem'
    },
    '> div:last-child': {
      marginRight: '0'
    }
  },
  filterHeader: {
    color: '#3A4463',
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    margin: '0 0 14px 0'
  },
  gender: {},
  assigned: {},
  sort: {}
}));

export default FamilyTreeSearch;
