import { Dialog, MenuItem, Select, SelectChangeEvent, Tab, Tabs } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getReadingData } from '../../actions/reading.actions';
import { initializeReadingForm } from '../../actions/reading.panel.actions';
import ReadingPanel from '../../components/reading-panel/ReadingPanel';
import { ALL, PRASANG, STORY, SUTRA, WORDOFTHEWEEK } from '../../constants/contentTypes';
import * as INTENT from '../../constants/panelIntents';
import { PENDING } from '../../constants/status';
import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { RootState } from '../../reducers/index.types';
import { StyledPrimaryButton } from '../../v2/components/PrimaryButton/PrimaryButton';
import { DataListCard } from './DataListCard';
import { CONTENT_FILTER_OPTIONS, documentTitle, tabValues } from './constants';
import { ContentObjectType, ReadingItemType } from './readingView.types';

export const ReadingView = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [selectedContentType, setSelectedContentType] = useState<ContentObjectType>(ALL);
  const [showModal, setShowModal] = useState(false);

  useSetDocumentTitle(documentTitle);

  const {
    all,
    prasangs,
    SUTRA: sutra,
    stories,
    words,
    showLoader
  } = useSelector((state: RootState) => state.readingView);
  const { formSubmitted } = useSelector((state: RootState) => state.readingPanel);
  const dispatch = useDispatch();
  const actions = bindActionCreators(
    {
      initializeReadingForm,
      getReadingData
    },
    dispatch
  );

  useEffect(() => {
    actions.getReadingData(selectedContentType, activeTab);
  }, []);

  useEffect(() => {
    if (formSubmitted) {
      handleCloseReadingModal();
      handleGetReadingData(selectedContentType, activeTab);
    }
  }, [formSubmitted]);
  /**
   * open panel to view clicked item
   */
  const handleViewItemClick = (item: ReadingItemType) => {
    actions.initializeReadingForm(item, INTENT.VIEW);
    handleOpenReadingModal();
  };
  /**
   * open panel to approve or decline clicked item
   */
  const handleManageItemClick = (item: ReadingItemType) => {
    actions.initializeReadingForm(item, INTENT.MANAGE);
    handleOpenReadingModal();
  };

  /**
   * create new content
   */
  const handleAddNewClick = () => {
    actions.initializeReadingForm(null, INTENT.CREATE);
    handleOpenReadingModal();
  };

  /**
   * handle tab change between view and approve
   */
  const handleTabChange = (event: ChangeEvent<any>, value: number) => {
    setActiveTab(value);
    handleGetReadingData(selectedContentType, value);
  };

  const handleGetReadingData = (type: ContentObjectType | undefined, by: number) => {
    actions.getReadingData(type, by);
  };

  const handleOpenReadingModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleCloseReadingModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const renderContentSection = () => {
    const handleSelectChange = (event: SelectChangeEvent) => {
      const { value } = event.target;
      const updatedValue = CONTENT_FILTER_OPTIONS.find((type) => type.value === value);
      if (updatedValue) {
        setSelectedContentType(updatedValue);
        handleGetReadingData(updatedValue, activeTab);
      }
    };

    return (
      <div className="content-view-section-all-content">
        <div className="content-view-section-all-content-select-row">
          <div className="form-field-text-container">
            <Select
              MenuProps={{
                style: {
                  marginTop: '0.5rem'
                },
                MenuListProps: {
                  disablePadding: true
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center'
                }
              }}
              onChange={handleSelectChange}
              variant="standard"
              className={['form-field-text', 'padding-xsm-left'].join(' ')}
              disableUnderline
              value={selectedContentType?.value}
            >
              {CONTENT_FILTER_OPTIONS.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.value}>
                    {option.name}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        </div>
        <div className="content-view-section-all-content-data">{renderContentListCard()}</div>
      </div>
    );
  };

  const renderContentListCard = () => {
    const tableData = getTableData();
    return (
      <DataListCard
        showLoader={showLoader}
        title={selectedContentType?.name}
        tableData={tableData}
      />
    );
  };

  const getTableData = () => {
    let tableData;
    switch (selectedContentType) {
      case ALL:
        tableData = all;
        break;
      case PRASANG:
        tableData = prasangs;
        break;
      case STORY:
        tableData = stories;
        break;
      case SUTRA:
        tableData = sutra;
        break;
      case WORDOFTHEWEEK:
        tableData = words;
        break;
      default:
        return { data: [], columns: [] };
    }
    if (tableData.data.length) {
      if (activeTab === 0) {
        tableData.action = {
          label: 'View',
          func: handleViewItemClick
        };
      } else if (activeTab === 1) {
        tableData.data = tableData.data.filter(
          (el: ReadingItemType) => el.status === PENDING.status
        );
        tableData.action = {
          label: 'Manage',
          func: handleManageItemClick
        };
      }
    }

    return tableData;
  };

  // At mobile screen size, the modal should be full screen.
  const fullScreen = window.innerWidth < 768;

  return (
    <div className="content-view-container">
      <Dialog disableEscapeKeyDown fullScreen={fullScreen} maxWidth="md" open={showModal}>
        <ReadingPanel onClose={handleCloseReadingModal} />
      </Dialog>
      <header className="content-header">
        <div className="content-header-row">
          <h2 className="content-title">Content to Sambandh</h2>
          <div>
            <StyledPrimaryButton onClick={handleAddNewClick}>New Content</StyledPrimaryButton>
          </div>
        </div>
        <Tabs className="broadcast-tabs" value={activeTab} onChange={handleTabChange}>
          {tabValues.map((tab, index) => {
            return (
              <Tab
                key={index}
                value={index}
                disableRipple
                label={
                  <div
                    style={{
                      color: activeTab === index ? '#067bc2' : '#2a3d4c',
                      fontWeight: '500'
                    }}
                  >
                    {tab.name}
                  </div>
                }
              />
            );
          })}
        </Tabs>
      </header>
      <div className="content-view-data-container">{renderContentSection()}</div>
    </div>
  );
};

export default ReadingView;
