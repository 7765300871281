import jwtDecode from 'jwt-decode';
import { SAMPARKTOKEN } from '../config/config.tokens';
import { SAMPARK } from '../constants/applications';
import { HOME, SIGNOUT } from '../constants/routes';
import { GETHOMEURL, checkUserPermission } from './userPermission';

interface IApplicationPermission {
  application: string;
  isSuperAdmin: boolean;
  user_id: string;
  access?: any;
}

interface IApplicationToken {
  name: string;
  status: string;
  permissions?: IApplicationPermission;
}

export interface IDecodedToken {
  uType: IApplicationToken[];
}

export const getLoggedInRedirectLink = () => {
  const RESPONSE = jwtDecode<IDecodedToken>(localStorage.getItem(SAMPARKTOKEN) || '').uType.find(
    (app) => app.name === SAMPARK
  );
  if (RESPONSE?.status !== 'APPROVED') {
    return SIGNOUT.value;
  }
  if (!RESPONSE?.permissions?.isSuperAdmin && !RESPONSE?.permissions?.access?.['contacts']) {
    return checkUserPermission(RESPONSE?.permissions?.access, GETHOMEURL); //TODO check this || ACCESS.value
  }
  return HOME.value;
};
