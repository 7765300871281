import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import {
  renderMaskedTextField,
  renderSelectField
} from './index';
const renderTimeFields = (fields) => {
  const readOnly = fields.readOnly || false;
  const label = fields.label || '';
  const required = fields.required || false;
  let classes = ['form-field-text', 'padding-xsm-left'];
  if (readOnly) classes.push('disabled-text-field');
  const meridianOptions = [
    {
      name: 'AM',
      value: 'am'
    },
    {
      name: 'PM',
      value: 'pm'
    }
  ];
  return (
    <Fragment>
      {label && <div className='form-field-label-row'>
        <label>{label} {required && <span className='form-field-required'>*</span>}</label>
      </div>}
      <div className='date-time-zone'>
        <div className='date-field' >
          <Field
            required
            readOnly={readOnly}
            name={fields.names[0]}
            placeholder='HH:MM'
            mask='99:99'
            component={renderMaskedTextField} />
        </div>
        <div className='time-field'>
          <Field
            required
            readOnly={readOnly}
            options={meridianOptions}
            name={fields.names[1]}
            component={renderSelectField} />
        </div>
      </div>
    </Fragment>
  );
};

export default renderTimeFields;
