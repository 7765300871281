import React, { useCallback, useEffect, useState } from 'react';
import { AttributeCompose } from './components/AttributeCompose/AttributeCompose';
import { AttributeModify } from './components/AttributeModify/AttributeModify';
import { useAppDispatch, useAppSelector } from '../../../helpers/store';
import { getAttributes, getFields, rollBackAttribute } from './attributeAdmin.ducks';
import { Toaster } from './components/Toaster/toaster';
import { hideToast, resetToast } from './components/Toaster/toaster.ducks';
import { useStyles } from './styles';

export const Attributes = () => {
  const [searchValue, setSearchValue] = useState('');

  //getting all the attributes
  let { attributes } = useAppSelector((state) => state.admin.attribute);
  const { previousAttribute } = useAppSelector((state) => state.admin.attribute);

  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetToast());
    dispatch(getAttributes());
    dispatch(getFields());
  }, []);

  const handleAttributeFilter = (value: React.SetStateAction<string>) => {
    setSearchValue(value);
  };

  const clearHandler = () => {
    setSearchValue('');
  };

  const handleUndo = useCallback(() => {
    dispatch(rollBackAttribute({ value: previousAttribute[0] }));
    dispatch(hideToast());
  }, [previousAttribute]);

  if (searchValue !== '') {
    attributes = attributes.filter(({ key: attributeKey }) =>
      attributeKey.toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  return (
    <>
      <div className={classes.toaster}>
        <Toaster handleUndo={handleUndo} />
      </div>

      <div className={classes.container}>
        <AttributeCompose
          handleAttributeFilter={handleAttributeFilter}
          value={searchValue}
          clearHandler={clearHandler}
        />
        <AttributeModify attributes={attributes} />
      </div>
    </>
  );
};
