export const JSONPLACEHOLDER = JSON.stringify(
  {
    _id: '',
    key: '',
    description: '',
    group: '',
    field: {
      name: '',
      datatype: 'string',
      labelValues: [],
      path: '',
      multiSelect: false,
      label: ''
    },
    definition: {
      fact: '',
      operator: '',
      value: [],
      path: '',
      field: ''
    }
  },
  null,
  2
);
