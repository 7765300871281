import http from '../helpers/http';
import { API } from '../config/config.constants';

export function submitApprovalForm(values) {
  return http
    .put(`${API.APPROVAL_PANEL.UPDATE_USER_ACCESS}`, values)
    .then((response) => {
      const data = response.data;
      if (data) {
        // ISSUE: backend is not sending response of structure (response.data.message)
        return data;
      } else {
        return response;
      }
    })
    .catch((error) => {
      return { error };
    });
}
