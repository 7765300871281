import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 24px 16px 24px',
    borderTop: '1px solid hsla(226, 28%, 88%, 1)',
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'white'
  },
  submitButton: {
    width: '148px',
    height: '50px',
    padding: '14px 16px',
    gap: '10px',
    borderRadius: '3px',
    backgroundColor: 'hsla(3, 100%, 66%, 1)',
    border: 'none',
    color: 'white',
    fontSize: '18px',
    fontWeight: 500,
    fontFamily: 'Rubik',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'hsl(3, 100%, 57%)'
    },
    '&:disabled': {
      backgroundColor: 'hsla(3, 100%, 66%, 0.5)',
      cursor: 'not-allowed'
    }
  },
  cancelButton: {
    width: '148px',
    height: '50px',
    padding: '14px 16px',
    gap: '10px',
    borderRadius: '3px',
    backgroundColor: 'transparent',
    border: 'none',
    color: 'hsla(203, 94%, 39%, 1)',
    fontSize: '18px',
    fontWeight: 500,
    fontFamily: 'Rubik',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'hsla(208, 30%, 82%, 0.2)'
    }
  }
}));
