import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  formatter: {
    display: 'flex',
    height: '28px'
  },
  selectedButton: {
    border: 'none',
    cursor: 'pointer',
    fontSize: `${16 / 16}rem`,
    backgroundColor: 'hsl(209, 100%, 55%);',
    borderRadius: '5px',
    color: 'white',
    ':first-child': {
      borderRadius: '5px 0 0 5px'
    },
    ':last-child': {
      borderRadius: '0 5px 5px 0'
    }
  },
  button: {
    border: 'none',
    cursor: 'pointer',
    fontSize: `${16 / 16}rem`,
    backgroundColor: 'rgb(231, 234, 243)',
    ':first-child': {
      borderRadius: '5px 0 0 5px'
    },
    ':last-child': {
      borderRadius: '0 5px 5px 0'
    },
    '&:hover': {
      backgroundColor: 'hsl(209, 100%, 55%);',
      borderRadius: '5px',
      color: 'white',
      ':first-child': {
        borderRadius: '5px 0 0 5px'
      },
      ':last-child': {
        borderRadius: '0 5px 5px 0'
      }
    }
  },
  header: {
    borderBottom: '1px solid hsla(225, 35%, 93%, 1)',
    padding: '24px',
    gap: '5px',
    display: 'flex',
    flexDirection: 'column'
  },
  headerTitle: {
    fontSize: '22px',
    fontWeight: 500
  },
  headerSubTitle: {
    fontSize: '14px',
    fontWeight: 400
  },
  headerClose: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  closeIcon: {
    alignSelf: 'flex-start',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none'
  }
}));
