import { Fragment } from 'react';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';

import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';
import { IRegistration, updateSelectedRegistration } from '../../yogAdmin.duck';
import { IMAGES_URL } from '../../../../../config/config.constants';
import { ReactComponent as CheckIcon } from '../../../../../assets/icons/icon_check_yog_admin.svg';
import { ReactComponent as CrossIcon } from '../../../../../assets/icons/icon_cross_yog_admin.svg';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/icon_close_small_black.svg';
import { ReactComponent as HoldIcon } from '../../../../../assets/icons/icon-hold_yog_admin.svg';
import { IOSSwitch } from '../Switch/Switch';
import { useAppDispatch, useAppSelector } from '../../../../../helpers/store';
import formatPhoneNumber from '../../../../../util/formatPhoneNumber';
import {
  REGISTRATION_STATUS,
  hideApproveRejectButton,
  showApprovedBatch
} from '../../constants/RegistrationStatus';
import { HeaderMessage } from './HeaderMessage';
import { isSuperAdminSelector } from '../../selector';
import { ReactComponent as RemoveHoldButton } from '../../../../../assets/icons/icon-remove-hold_yog_admin.svg';
import { CopyIcon } from '../../../../../components/CopyIcon';
import HoldStatusBanner from '../HoldStatusBanner';
import { getCountryNameFromAbbreviation } from '../../../../../util/countries';
import { useRegistrationOptions } from '../../useRegistrationOptions';

type IModalProps = {
  cancelCallBack: () => void;
  updateRegistration: (registration: IRegistration) => void;
  approveRegistration: (registration: IRegistration) => void;
  rejectRegistration: (registration: IRegistration) => void;
  handlePaidRegistration: (registration: IRegistration, paid: boolean) => void;
  openConfirmRemoveHoldModal: (registration: IRegistration) => void;
  isUpdatingRegistration: boolean;
  hideActions?: boolean;
  eventId: string;
};

export function ViewRegistrationModal({
  cancelCallBack,
  eventId,
  updateRegistration,
  approveRegistration,
  handlePaidRegistration,
  rejectRegistration,
  openConfirmRemoveHoldModal,
  hideActions = false
}: IModalProps) {
  const dispatch = useAppDispatch();
  const yogAdmin = useAppSelector((state) => state.admin.yogAdmin);
  const { selectedRegistration } = yogAdmin;

  if (selectedRegistration === null) {
    return null;
  }

  const {
    member,
    isStudent,
    hasMysteryGift,
    accommodationNeeded,
    isEditable = false,
    status,
    registrationId,
    onHoldStartDate
  } = selectedRegistration;
  const { firstName, lastName, city, state, email, phones, familyId, country } = member;
  const { classes } = useStyles({ isEditable });
  const isSuperAdmin = useAppSelector(isSuperAdminSelector);
  const showActions = !hideActions && isEditable && !(REGISTRATION_STATUS.PAID_OVERRIDE === status);
  const showRemoveHoldButton = status === REGISTRATION_STATUS.HOLD && isSuperAdmin;
  const showPaidRegistrationButton = showMarkPaidButton(status, isEditable, isSuperAdmin);
  const disableActionsForUserExceptSuperAdmin = !showActions || !isSuperAdmin;
  const profileImageUrl =
    member && member.profileImageUrl && member.profileImageUrl !== 'undefined'
      ? IMAGES_URL + '/' + member.profileImageUrl
      : '';

  const handleUpdateRegistration = (key: string, value: boolean) => {
    const updatedRegistration = {
      ...selectedRegistration,
      isStudent,
      hasMysteryGift,
      accommodationNeeded,
      [key]: value
    };
    updateRegistration(updatedRegistration);
    dispatch(updateSelectedRegistration(updatedRegistration));
  };
  const registrationOptions = useRegistrationOptions(eventId);

  return (
    <Fragment>
      <DialogTitle className={classes.dialogTitle}>
        <span>Manage Registration</span>
        <button onClick={cancelCallBack} className={classes.closeButton}>
          <CloseIcon />
        </button>
      </DialogTitle>
      <HeaderMessage status={status} isEditable={isEditable} hideActions={hideActions} />

      <DialogContent className={classes.container}>
        <div className={classes.avatarWrapper}>
          <Avatar
            className={classes.avatar}
            src={profileImageUrl}
            variant={'rounded'}
            sx={{ width: 150, height: 150 }}
          />
          {status != 'PENDING' ? (
            <div
              className={classNames(classes.statusBadge, {
                [classes.approvedStatusBadge]: showApprovedBatch.includes(status),
                [classes.rejectedStatusBadge]:
                  status === REGISTRATION_STATUS.REJECTED || status === REGISTRATION_STATUS.HOLD
              })}
            >
              {showApprovedBatch.includes(status) ? <CheckIcon /> : null}
              {status === 'REJECTED' ? <CrossIcon /> : null}
              {status === REGISTRATION_STATUS.HOLD ? <HoldIcon /> : null}
            </div>
          ) : null}
          <HoldStatusBanner status={status} fontSize={14} targetDate={onHoldStartDate} />
        </div>
        <div className={classes.metadata}>
          {registrationId ? (
            <div className={classes.copyIconContainer}>
              Copy Registration Id
              {
                <span>
                  <CopyIcon content={registrationId} />
                </span>
              }
            </div>
          ) : null}
          {familyId ? (
            <div className={classes.copyIconContainer}>
              Copy Family Id{<CopyIcon content={familyId} />}
            </div>
          ) : (
            <span className={classes.notFound}>No family found</span>
          )}
          {member?.memberId ? (
            <div className={classes.copyIconContainer}>
              Copy Member Id
              <CopyIcon content={member.memberId} />
            </div>
          ) : null}
        </div>
        <div className={classes.personalInfo}>
          <div className={classes.name}>
            {firstName} {lastName}
          </div>
          <div className={classes.location}>
            {city}, <span style={{ textTransform: 'uppercase' }}>{state}</span>,{' '}
            <span>{getCountryNameFromAbbreviation(country)}</span>
          </div>
        </div>
        <div className={classes.contactInfo}>
          <div>{email}</div>
          <div>
            {phones[0]?.value ? (
              formatPhoneNumber(phones[0]?.value)
            ) : (
              <span className={classes.notFound}>No phone number found</span>
            )}
          </div>
        </div>
        <div className={classes.controlSection}>
          {registrationOptions?.accommodation ? (
            <>
              <div className={classes.control}>
                <div>Are they a student?</div>
                <div>
                  <IOSSwitch
                    checked={isStudent}
                    disabled={disableActionsForUserExceptSuperAdmin}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleUpdateRegistration('isStudent', e.currentTarget.checked);
                    }}
                  />
                </div>
              </div>
              <div className={classes.control}>
                <div>Do they need accommodation?</div>
                <div>
                  <IOSSwitch
                    checked={accommodationNeeded}
                    disabled={disableActionsForUserExceptSuperAdmin}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleUpdateRegistration('accommodationNeeded', e.currentTarget.checked);
                    }}
                  />
                </div>
              </div>
              <div className={classes.control}>
                <div>Do they have mystery gift?</div>
                <div>
                  <IOSSwitch
                    checked={hasMysteryGift}
                    disabled={disableActionsForUserExceptSuperAdmin}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleUpdateRegistration('hasMysteryGift', e.currentTarget.checked);
                    }}
                  />
                </div>
              </div>
              {showPaidRegistrationButton ? (
                <div className={classNames(classes.control, classes.opacity)}>
                  <div>Mark this registration paid?</div>
                  <div>
                    <IOSSwitch
                      checked={status === REGISTRATION_STATUS.PAID_OVERRIDE}
                      disabled={hideActions}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handlePaidRegistration(selectedRegistration, e.currentTarget.checked);
                      }}
                    />
                  </div>
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      </DialogContent>
      {showActions && !hideApproveRejectButton.includes(status) ? (
        <DialogActions disableSpacing className={classes.buttonGroup}>
          <button
            onClick={() => approveRegistration(selectedRegistration)}
            disabled={!showActions || status === REGISTRATION_STATUS.APPROVED}
            className={classNames(classes.button, {
              [classes.approveButton]: status === 'APPROVED'
            })}
          >
            <CheckIcon /> Approve
          </button>
          <button
            onClick={() => rejectRegistration(selectedRegistration)}
            disabled={!showActions}
            className={classNames(classes.button, {
              [classes.rejectButton]: status === 'REJECTED'
            })}
          >
            <CrossIcon /> Reject
          </button>
        </DialogActions>
      ) : null}

      {showRemoveHoldButton ? (
        <DialogActions disableSpacing className={classes.buttonGroup}>
          <button
            disabled={false}
            className={classNames(classes.button, classes.holdButton, classes.opacity)}
            onClick={() => openConfirmRemoveHoldModal(selectedRegistration)}
          >
            {<RemoveHoldButton />}
            Remove Hold
          </button>
        </DialogActions>
      ) : null}
    </Fragment>
  );
}

const showPaidRegistration = [
  REGISTRATION_STATUS.APPROVED,
  REGISTRATION_STATUS.AUTO_APPROVED,
  REGISTRATION_STATUS.PAID_OVERRIDE,
  REGISTRATION_STATUS.HOLD
];

const showMarkPaidButton = (
  status: REGISTRATION_STATUS,
  isEditable: boolean,
  isSuperAdmin: boolean
) => {
  return showPaidRegistration.includes(status) && isEditable && isSuperAdmin;
};

const useStyles = makeStyles<{ isEditable: boolean }>()((theme, { isEditable }) => ({
  avatar: {
    backgroundColor: '#E8EBF4'
  },
  avatarWrapper: {
    position: 'relative',
    width: 'fit-content'
  },
  statusBadge: {
    position: 'absolute',
    top: '-5px',
    right: '-5px',
    height: '24px',
    width: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid white',
    borderRadius: '100%',
    '> svg': {
      filter: 'brightness(100)',
      height: '12px',
      width: '12px'
    }
  },
  approvedStatusBadge: {
    backgroundColor: '#38A772'
  },
  rejectedStatusBadge: {
    backgroundColor: '#C84240'
  },
  dialogTitle: {
    '> h2': {
      fontSize: '18px',
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontFamily: 'Rubik'
    }
  },
  closeButton: {
    background: 'transparent',
    border: '1px solid transparent',
    cursor: 'pointer'
  },
  approveButton: {
    backgroundColor: '#38A772 !important',
    color: '#FFFFFF !important',
    '> svg': {
      filter: 'brightness(100)'
    }
  },
  RemoveHoldButton: {
    backgroundColor: '#E8EBF4 !important',
    opacity: 1,
    color: '#5F698B !important'
  },
  holdButton: {
    backgroundColor: '#C84240 !important',
    color: '#FFFFFF !important',
    '> svg': {
      filter: 'brightness(100)'
    },
    opacity: 1
  },
  rejectButton: {
    backgroundColor: '#C84240 !important',
    color: '#FFFFFF !important',
    '> svg': {
      filter: 'brightness(100)'
    }
  },
  button: {
    backgroundColor: '#E8EBF4',
    ...(!isEditable && { opacity: '0.5' }),
    borderRadius: '8px',
    color: '#5F698B',
    fontSize: '18px',
    width: '100%',
    padding: '16px 32px',
    fontWeight: 'bold',
    border: '1px solid transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    cursor: 'pointer'
  },
  buttonGroup: {
    display: 'flex',
    gap: 8,
    padding: '16px 24px',
    justifyContent: 'space-evenly'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    marginBottom: '40px'
  },
  control: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '> *': {
      fontWeight: '500'
    }
  },
  opacity: {
    opacity: 1,
    alignItems: 'baseline'
  },
  controlSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10
  },
  name: {
    fontSize: '18px',
    fontWeight: '500',
    textTransform: 'capitalize'
  },
  location: {
    fontSize: '14px',
    textTransform: 'capitalize'
  },
  metadata: {
    fontSize: '12px',
    '> div': {
      fontSize: '12px',
      '> span': {
        textTransform: 'uppercase'
      }
    }
  },
  copyIconContainer: {
    display: 'flex'
  },
  personalInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4
  },
  contactInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    '> div': {
      fontSize: '14px'
    }
  },
  notFound: {
    opacity: 0.5
  }
}));
