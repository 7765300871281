import { approvalFormActions, commonActions } from '../constants/actions';
import * as USER_AUTH_STATUS from '../constants/status';
import * as APIs from '../apis/approvalAPIs';
import { SUBMIT_SAMPARK_FORM_ERROR, APPROVE_SAMPARK_FORM_ERROR, DECLINE_SAMPARK_FORM_ERROR } from '../constants/toasterMessages';
import { getAdminData } from './admin.actions';
import {ActionCreators as SamparkAdminActionCreator} from '../views/Admin/SamparkAdmin/samparkAdmin.duck';

export function submitVerifiedInviteeApprovalForm (manageUserId, manageUserPermissions, isSuperAdmin) {
  return async (dispatch, getState) => {
    const formSubmitObject = buildFormSubmitObject(manageUserId, manageUserPermissions, isSuperAdmin);
    // console.log(formSubmitObject);
    const submitForm = await APIs.submitApprovalForm(formSubmitObject);
    if (!submitForm.error) {
      dispatch(SamparkAdminActionCreator.fetchSamparkAdminData());
      // getAdminData() to fetch updated users data (invited, verifed, approved, pending)

      dispatch({type: commonActions.CLOSE_DRAWER});
      dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: submitForm.message, type: commonActions.SUCCESS_TOASTER } });
    } else {
      const { error } = submitForm.error;
      if (error.response && error.response.data) {
        console.log(error);
      } else {
        console.log(error);
      }
      dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: SUBMIT_SAMPARK_FORM_ERROR, type: commonActions.ERROR_TOASTER } });
    }
  };
}

function buildFormSubmitObject (manageUserId, manageUserPermissions, isSuperAdmin) {
  let objectToSubmit = {
    user_id: manageUserId,
    application: 'sampark',
    access: manageUserPermissions
  };
  return objectToSubmit;
}

export function initFormSubmission () {
  return async (dispatch, getState) => {
    return dispatch({ type: approvalFormActions.FORM_SUBMITTED, value: false });
  };
};
