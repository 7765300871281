import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import './index.scss';

export function Contact (props) {
  return (
    <SvgIcon {...props}>
      <g clipPath='url(#clip0)'>
        <path d='M7.08333 9.99992C8.23393 9.99992 9.16667 9.06718 9.16667 7.91659C9.16667 6.76599 8.23393 5.83325 7.08333 5.83325C5.93274 5.83325 5 6.76599 5 7.91659C5 9.06718 5.93274 9.99992 7.08333 9.99992Z' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M4.16699 17.5V15.4167C4.16699 13.8058 5.47283 12.5 7.08366 12.5C8.69449 12.5 10.0003 13.8058 10.0003 15.4167V17.5' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M12.5 7.5H15.8333' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M12.5 10.8333H15.8333' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M17.4997 17.5H2.49967C1.57884 17.5 0.833008 16.7542 0.833008 15.8333V4.16667C0.833008 3.24583 1.57884 2.5 2.49967 2.5H17.4997C18.4205 2.5 19.1663 3.24583 19.1663 4.16667V15.8333C19.1663 16.7542 18.4205 17.5 17.4997 17.5Z' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export function Followup (props) {
  return (
    <SvgIcon {...props}>
      <path d='M13.7277 1.83325L10.0913 5.46962L13.7277 9.10598' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11 5.46948H14.6364C16.6445 5.46948 18.2727 7.09766 18.2727 9.10585V16.3786' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.27246 21.8333L11.9088 18.1969L8.27246 14.5605' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10.9998 18.1969H7.36341C5.35523 18.1969 3.72705 16.5688 3.72705 14.5606V7.28784' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18.2727 21.8332C19.7789 21.8332 21 20.6122 21 19.1059C21 17.5997 19.7789 16.3787 18.2727 16.3787C16.7665 16.3787 15.5454 17.5997 15.5454 19.1059C15.5454 20.6122 16.7665 21.8332 18.2727 21.8332Z' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3.72727 7.2878C5.2335 7.2878 6.45455 6.06676 6.45455 4.56052C6.45455 3.05429 5.2335 1.83325 3.72727 1.83325C2.22104 1.83325 1 3.05429 1 4.56052C1 6.06676 2.22104 7.2878 3.72727 7.2878Z' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIcon>
  );
}

export function Smruti (props) {
  return (
    <SvgIcon {...props}>
      <path d='M1.40234 16.7378L7.3339 9.11108L14.9392 18.8889' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M13.5561 8.2223C14.5379 8.2223 15.3339 7.42636 15.3339 6.44453C15.3339 5.46269 14.5379 4.66675 13.5561 4.66675C12.5743 4.66675 11.7783 5.46269 11.7783 6.44453C11.7783 7.42636 12.5743 8.2223 13.5561 8.2223Z' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M15.3336 18.8889H4.66688C2.70333 18.8889 1.11133 17.2969 1.11133 15.3333V4.66664C1.11133 2.70308 2.70333 1.11108 4.66688 1.11108H15.3336C17.2971 1.11108 18.8891 2.70308 18.8891 4.66664V15.3333C18.8891 17.2969 17.2971 18.8889 15.3336 18.8889Z' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIcon>
  );
}

export function Swadhay (props) {
  return (
    <SvgIcon {...props}>
      <path d='M10 3.33325V18.3333' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16.9828 1.14044L10.0002 2.88888L3.01759 1.14044C2.02527 0.941327 1.11133 1.78044 1.11133 2.89066V15.6266C1.11133 16.4862 1.67052 17.2231 2.439 17.3769L10.0002 18.8889L17.5614 17.3769C18.3299 17.2231 18.8891 16.4862 18.8891 15.6266V2.89066C18.8891 1.78044 17.9752 0.941327 16.9828 1.14044V1.14044Z' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIcon>
  );
}

export function Events (props) {
  return (
    <SvgIcon {...props}>
      <g clipPath='url(#clip0)'>
        <path d='M0.833008 8.33325H19.1663' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M17.4997 19.1667H2.49967C1.57884 19.1667 0.833008 18.42 0.833008 17.5V4.16667C0.833008 3.24583 1.57967 2.5 2.49967 2.5H17.4997C18.4205 2.5 19.1663 3.24667 19.1663 4.16667V17.5C19.1663 18.42 18.4197 19.1667 17.4997 19.1667Z' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M5 0.833252V4.99992' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M15 0.833252V4.99992' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M13.7503 15.8334C14.9009 15.8334 15.8337 14.9007 15.8337 13.7501C15.8337 12.5995 14.9009 11.6667 13.7503 11.6667C12.5997 11.6667 11.667 12.5995 11.667 13.7501C11.667 14.9007 12.5997 15.8334 13.7503 15.8334Z' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export function Broadcast (props) {
  return (
    <SvgIcon {...props}>
      <g clipPath='url(#clip0)'>
        <path d='M15.833 6.66675H16.6663C18.0472 6.66675 19.1663 7.78591 19.1663 9.16675C19.1663 10.5476 18.0472 11.6667 16.6663 11.6667H15.833' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M0.833008 6.66675V11.6667C0.833008 12.5876 1.57884 13.3334 2.49967 13.3334H10.833L15.833 16.6667V1.66675L10.833 5.00008H2.49967C1.57884 5.00008 0.833008 5.74591 0.833008 6.66675Z' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M5.83301 13.3333L8.33301 19.1666' strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export function HelpInfo (props) {
  // eslint-disable-next-line react/prop-types
  const { fill } = props;
  return (
    <SvgIcon {...props}>
      <path d='M10.2439 2.25526C7.9029 -0.0857383 4.0954 -0.0857383 1.7544 2.25526C-0.586102 4.59576 -0.586102 8.40426 1.7544 10.7443C2.9254 11.9153 4.4619 12.4998 5.9994 12.4998C7.5369 12.4998 9.0734 11.9153 10.2439 10.7448C12.5844 8.40426 12.5844 4.59576 10.2439 2.25526ZM6.4994 9.50276C6.4994 9.77876 6.2754 10.0028 5.9994 10.0028C5.7234 10.0028 5.4994 9.77876 5.4994 9.50276C5.4994 9.50176 5.4999 9.50076 5.4999 9.49976C5.4999 9.49876 5.4994 9.49826 5.4994 9.49726C5.4994 9.22126 5.7234 8.99726 5.9994 8.99726C6.2754 8.99726 6.4994 9.22126 6.4994 9.49726C6.4994 9.49826 6.4989 9.49926 6.4989 9.50026C6.4989 9.50126 6.4994 9.50176 6.4994 9.50276ZM8.4994 5.49726C8.4994 5.96626 8.2754 6.41476 7.8999 6.69676L6.2994 7.89676C6.2094 7.96426 6.1044 7.99676 5.9999 7.99676C5.8474 7.99676 5.6979 7.92776 5.5994 7.79676C5.4334 7.57576 5.4784 7.26226 5.6994 7.09676L7.2994 5.89676C7.4244 5.80276 7.4989 5.65326 7.4989 5.49676V4.99676C7.4989 4.72126 7.2749 4.49676 6.9989 4.49676H5.4989C5.2229 4.49676 4.9989 4.72126 4.9989 4.99676C4.9989 5.27276 4.7754 5.49676 4.4989 5.49676C4.2224 5.49676 3.9989 5.27276 3.9989 4.99676C3.9989 4.16976 4.6719 3.49676 5.4989 3.49676H6.9989C7.8259 3.49676 8.4989 4.16976 8.4989 4.99676V5.49726H8.4994Z'
        fill={fill} />
    </SvgIcon>
  );
}

export function Cancel (props) {
  // eslint-disable-next-line react/prop-types
  const { fill } = props;
  return (
    <SvgIcon {...props}>
      <path
        fill={fill}
        fillRule='evenodd'
        d='M10.301 9l6.989 6.988a1.002 1.002 0 0 1-.002 1.422l.122-.122a1 1 0 0 1-1.423.002L9 10.3 2.012 17.29a1.002 1.002 0 0 1-1.422-.002l.122.122a1 1 0 0 1-.002-1.422L7.698 9 .71 2.012A1.002 1.002 0 0 1 .712.59L.59.712A1 1 0 0 1 2.012.71L9 7.7 15.987.71a1.003 1.003 0 0 1 1.423.002L17.288.59a1 1 0 0 1 .002 1.422L10.3 9z'
      />
    </SvgIcon>
  );
}

export function ArrowLeft (props) {
  // eslint-disable-next-line react/prop-types
  const { fill = '#2A3D4C' } = props;
  return (
    <SvgIcon {...props} style={{fill: 'none'}}>
      <path
        d='M9 1L1 8L9 15'
        stroke={fill}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round' />
      <path
        d='M1 8H19'
        stroke={fill}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round' />
    </SvgIcon>
  );
}

export function CloseIcon (props) {
  return (
    <SvgIcon {...props}>
      <path
        fill='#3A4463'
        fillRule='evenodd'
        d='M10.301 9l6.989 6.988a1.002 1.002 0 0 1-.002 1.422l.122-.122a1 1 0 0 1-1.423.002L9 10.3 2.012 17.29a1.002 1.002 0 0 1-1.422-.002l.122.122a1 1 0 0 1-.002-1.422L7.698 9 .71 2.012A1.002 1.002 0 0 1 .712.59L.59.712A1 1 0 0 1 2.012.71L9 7.7 15.987.71a1.003 1.003 0 0 1 1.423.002L17.288.59a1 1 0 0 1 .002 1.422L10.3 9z'
      />
    </SvgIcon>
  );
}

// eslint-disable-next-line react/prop-types
export function Icon ({name, ...props}) {
  switch (name) {
    case 'Contact':
      return <Contact {...props} />;
    case 'Followup':
      return <Followup {...props} />;
    case 'Smruti':
      return <Smruti {...props} />;
    case 'Swadhay':
      return <Swadhay {...props} />;
    case 'Events':
      return <Events {...props} />;
    case 'Broadcast':
      return <Broadcast {...props} />;
    case 'HelpInfo':
      return <HelpInfo {...props} />;
    case 'Cancel':
      return <Cancel {...props} />;
    case 'ArrowLeft':
      return <ArrowLeft {...props} />;
    case 'CloseIcon':
      return <CloseIcon {...props} />;
    default:
      return null;
  }
}
