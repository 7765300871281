import { contactFormActions } from '../constants/actions';

export default function contactFormReducer(
  state = { formSubmitted: false, clickedUser: null },
  action) {
  switch (action.type) {
    case contactFormActions.INITIALIZE_READ_ONLY_CONTACT_FORM:
    case contactFormActions.INITIALIZE_CONTACT_FORM:
      return { ...state, clickedUser: action.value, formSubmitted: false };
    case contactFormActions.SUBMIT_CONTACT_FORM:
      return { ...state, formSubmitted: true };
    case contactFormActions.STORE_AVATAR_IMAGE:
      return { ...state, avatarImage: action.value };
    default:
      return state;
  }
}
