import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import InfoIcon from '@mui/icons-material/Info';
import ReportIcon from '@mui/icons-material/Report';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useStyles } from './warningModal.styles';
import { IVariant, IWarningModal } from './warningModal.types';

const ICONS_BY_VARIANT = {
  notice: InfoIcon,
  warning: InfoIcon,
  success: CheckCircleIcon,
  error: ReportIcon
};

const COLOR_BY_VARIANT: IVariant = {
  success: { 'background-color': '120, 90%, 96%', color: '120, 80%, 35%' },
  error: { 'background-color': '345, 90%, 96%', color: '345, 100%, 50%' },
  warning: { 'background-color': '40, 100%, 94%', color: '35, 100%, 46%' },
  notice: { 'background-color': '235,0%, 100%', color: '235, 100%, 50%' }
};

const boxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '14px',
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  gap: '21px'
};

export const WarningModal = ({
  isOpen,
  handleSubmit,
  handleClose,
  variant = 'warning',
  children
}: IWarningModal) => {
  const Tag = ICONS_BY_VARIANT[variant as keyof IVariant];
  const theme = COLOR_BY_VARIANT[variant as keyof IVariant];

  const { classes } = useStyles({
    textColor: theme['color'],
    backgroundColor: theme['background-color']
  });
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={boxStyle}>
        <div className={classes.wrapper}>
          <div className={classes.iconWrapper}>
            <Tag style={{ fontSize: '100px', color: `hsl(${theme['color']})` }} />
          </div>
          <div className={classes.messageHeader}>Are you sure?</div>
          <div className={classes.message}>{children}</div>
        </div>
        <div>
          <button className={classes.createButton} onClick={handleSubmit}>
            Yes
          </button>
          <button className={classes.cancelButton} onClick={handleClose}>
            Cancel
          </button>
        </div>
      </Box>
    </Modal>
  );
};
