import { Chip } from '@material-ui/core';
import { IOption } from '../../index';
import icon_close_mini from '../../../../../assets/icons/icon_close_mini.svg';

const Chips = ({ tags, onDeleteClick, disabled }: IChips) => {
  return (
    <div>
      {tags.map((tag, index) => (
        <Chip
          disabled={disabled}
          onDelete={() => {
            onDeleteClick(index);
          }}
          deleteIcon={<img src={icon_close_mini} style={{ height: '10px', margin: '0' }} />}
          className="chip"
          key={index}
          label={<span className="chip-label">{tag.name}</span>}
        />
      ))}
    </div>
  );
};

interface IChips {
  disabled: boolean;
  tags: IOption[];
  onDeleteClick: (index: number) => void;
}

export default Chips;
