import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import icon_checked from '../../assets/icons/icon_checked_large.svg';
import icon_unchecked from '../../assets/icons/icon_unchecked_large.svg';

const renderCheckboxField = ({
  label = '',
  input,
  inline,
  className = '',
  ...props
}) => {
  let classes = ['section-checkbox-field-container', className];
  // if (input.value) classes.push('margin-sm-bottom');
  const inlineCheckbox = (
    <Fragment>
      <div className={[...classes].join(' ')}>
        <Checkbox
          {...input}
          {...props}
          disableRipple
          checkedIcon={<img src={icon_checked} />}
          icon={<img src={icon_unchecked} />}
          className='form-radio-group'
          checked={input.value}
          value={input.name}
          onClick={props.onChange}
        />
        {label
          ? <div style={{ marginBottom: '0' }} className='form-field-label-row'>
            <label style={{marginRight: '0.5rem'}}>{label}</label>
          </div> : null
        }
      </div>
    </Fragment>
  );

  const checkboxWithLabel = (
    <Fragment>
      <div className={[...classes].join(' ')}>
        <Checkbox
          {...input}
          {...props}
          disableRipple
          checkedIcon={<img src={icon_checked} />}
          icon={<img src={icon_unchecked} />}
          className='form-radio-group'
          checked={input.value}
          value={input.name}
          onClick={props.onClick}
        />
      </div>
      { label
        ? <div style={{ marginBottom: '0' }} className='form-field-label-row'>
          <label style={{marginRight: '0.5rem'}}>{label}</label>
        </div> : null
      }
    </Fragment>
  );

  if (inline) {
    return inlineCheckbox;
  } else {
    return checkboxWithLabel;
  }
};

export default renderCheckboxField;

renderCheckboxField.propTypes = {
  input: PropTypes.any,
  label: PropTypes.string
};
