import { useMemo, useState } from 'react';
import { ReactComponent as SearchIcon } from '../../../../../assets/icons/icon_search_grey.svg';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ReactComponent as AlertIcon } from '../../../../../assets/icons/icon_alert.svg';

import { ReactComponent as CaretIcon } from '../../../../../assets/icons/icon_caret_up.svg';
import { useAppSelector } from '../../../../../helpers/store';
import { Draggable } from '../../../../../components/drag-drop/Draggable';
import { DragOverlay } from '@dnd-kit/core';
import { IAttributes } from '../../../Attributes/attributes.types';
import { IAttributeList } from '../../rules.types';
import { useStyles } from './attributeList.styles';

export const AttributeList = (props: IAttributeList) => {
  const [searchValue, setSearchValue] = useState('');
  const { classes } = useStyles();

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const attributeList: IAttributes[] = useAppSelector((state) => state.admin.attribute.attributes);

  const filteredAttribute =
    attributeList.length > 0
      ? useMemo(() => {
          return attributeList.filter(({ key: attributeKey }) =>
            attributeKey.toLowerCase().includes(searchValue)
          );
        }, [searchValue])
      : [];

  const groupAttributes: { [key: string]: IAttributes[] } =
    filteredAttribute.length > 0
      ? filteredAttribute.reduce((prev: any, curr: any) => {
          prev[curr.group] = (prev[curr.group] || []).concat(curr);
          return prev;
        }, {})
      : {};
  const resultList: string[] = Object.keys(groupAttributes);

  return (
    <div className={classes.containerAccordian}>
      <div className={classes.containerHeaderList}>
        <label>
          <input
            className={classes.containerSearch}
            placeholder="Attribute List"
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <SearchIcon className={classes.iconSearch} />
        </label>
        <p className={classes.subHeader}>
          <span>{resultList.length === 0 ? 'No' : filteredAttribute.length}</span> attributes found
        </p>
      </div>
      <div className={classes.accordianWrapper}>
        {resultList.length === 0 ? (
          <div className={classes.alertBanner}>
            <AlertIcon className={classes.alertIcon} />
            <p className={classes.alertText}>No attributes found</p>
          </div>
        ) : (
          resultList?.map((groupName, index) => (
            <Accordion key={index} sx={{ boxShadow: 'none', position: 'initial' }}>
              <AccordionSummary
                expandIcon={<CaretIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={classes.listHeader}>
                  {groupName === 'undefined' ? 'No group selected' : groupName}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.containerList}>
                  {groupAttributes[groupName].map((attribute) => (
                    <Draggable key={attribute._id} id={attribute.key} data={attribute}>
                      <label key={attribute._id} className={classes.attribute}>
                        <label className={classes.attributeLabel}>{attribute.key}</label>
                      </label>
                    </Draggable>
                  ))}
                  <DragOverlay dropAnimation={null}>
                    <label className={classes.attribute}>
                      {props.activeId ? (
                        <label className={classes.attributeLabel}>{props.activeId}</label>
                      ) : null}
                    </label>
                  </DragOverlay>
                </div>
              </AccordionDetails>
            </Accordion>
          ))
        )}
      </div>
    </div>
  );
};
