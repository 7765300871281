import { useState } from 'react';
import { IOption } from '..';

export const useMultiSelectDropDownChips = ({
  onChange,
  options,
  value,
  eventName,
  isRequired
}: IUseMultiSelectDropDownChips) => {
  const [inputValue, setInputValue] = useState(getInputValue(value, options));

  const onMultiSelectDropdown = (event: Event, value: IOption[]) => {
    setInputValue(value);
    const formValidNew = !isRequired || (isRequired && value.length > 0);
    onChange(eventName, value, formValidNew);
  };

  const onDeleteClick = (deletedIndex: number) => {
    const newValue: IOption[] = [];
    inputValue.forEach((currentValue, index) => {
      if (deletedIndex !== index) {
        newValue.push(currentValue);
      }
    });
    onMultiSelectDropdown(new Event(eventName), newValue);
  };
  return { inputValue, onMultiSelectDropdown, onDeleteClick };
};

export const getInputValue = (value: IOption[], options: IOption[]) => {
  const set = new Set();
  value.forEach((element) => set.add(element.value));
  return options.filter((element) => set.has(element.value));
};

interface IUseMultiSelectDropDownChips {
  options: IOption[];
  value: IOption[];
  eventName: string;
  isRequired: boolean;
  onChange: (event: string, value: IOption[], formValid: boolean) => void;
}
