import Stage from '../../../util/stage';
import http from '../../../helpers/http';
import { generateTypes, makeAction } from '../../../util/actions';
import { API } from '../../../config/config.constants';
import { commonActions } from '../../../constants/actions';

const TYPES = generateTypes(
  'GET_SAMBANDH_APPOVAL_DATA', 'SET_SAMBANDH_APPOVAL_DATA', 'RESET_SAMBANDH_APPOVAL_DATA', 'ERROR_SAMBANDH_APPOVAL_DATA'
);

const Actions = Object.freeze({
  get: () => makeAction(TYPES.GET_SAMBANDH_APPOVAL_DATA),
  set: (data) => makeAction(TYPES.SET_SAMBANDH_APPOVAL_DATA, { data }),
  reset: () => makeAction(TYPES.RESET_SAMBANDH_APPOVAL_DATA),
  error: (err) => makeAction(TYPES.ERROR_SAMBANDH_APPOVAL_DATA, { err })
});

export const ActionCreators = Object.freeze({
  fetchSambandhApprovals: () => (dispatch) => {
    dispatch(Actions.get());

    http.get(API.ADMIN_VIEW.SAMBANDH_PENDING_USERS)
      .then(({data}) => {
        const { members } = data;
        dispatch(Actions.set(members));
      })
      .catch(err => {
        dispatch(Actions.error(err));
        dispatch({
          type: commonActions.SHOW_HIDE_TOASTER,
          value: {
            displayToaster: true,
            message: 'Error loading sampark admin data',
            type: commonActions.ERROR_TOASTER
          }
        });
      });
  },
  resetSambandhApprovals: () => (dispatch) => dispatch(Actions.reset())
});

const initialState = Object.freeze({
  stage: Stage.READY,
  sambandhApprovals: []
});

const reducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case TYPES.GET_SAMBANDH_APPOVAL_DATA:
      return {
        ...state,
        stage: Stage.LOADING
      };
    case TYPES.SET_SAMBANDH_APPOVAL_DATA:
      return {
        ...state,
        stage: Stage.LOADED,
        sambandhApprovals: payload.data
      };
    case TYPES.RESET_SAMBANDH_APPOVAL_DATA:
      return initialState;
    case TYPES.ERROR_SAMPARK_ADMIN_DATA:
      return {
        ...state,
        stage: Stage.LOAD_ERROR
      };
    default:
      return state;
  }
};

export default reducer;
