import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isValid, isDirty, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
// PROJECT FILES
import { closeDrawer } from '../../actions/common.actions';
import { SAMBANDH_APPROVAL_FORM } from '../../constants/forms';
import PersonalInfoSection from './PersonalInfoSection';
import BasicInfoSection from './BasicInfoSection';
import SambandhApprovalFooter from './SambandhApprovalFooter';
// icons
import icon_close from '../../assets/icons/icon_cross.svg';

class SambandhPanel extends Component {
  constructor (props) {
    super(props);
  }

  render () {
    const { isFormDirty, isFormValid, approval, selectedProfileImage } = this.props;
    let uploadingImage = false;
    if (selectedProfileImage instanceof Object) {
      uploadingImage = true;
    }
    return (
      <div className='sambandh-approval-form'>
        <div className='sambandh-approval-form-stick-top'>
          <div className='sambandh-approval-form-top'>
            <div className='padding-left padding-right'>
              <div className='sambandh-approval-form-title-row'>
                <h4 className='title-text padding-xsm-right'>DETAILS</h4>
                <img
                  className='sambandh-approval-form-dismiss'
                  onClick={this.props.closeDrawer}
                  src={icon_close} />
              </div>
              <div className='sambandh-approval-form-info'>
                <BasicInfoSection initialValues={approval} />
              </div>
            </div>
          </div>
          <div className='sambandh-approval-form-fields padding-left padding-right'>
            <PersonalInfoSection initialValues={approval} />
          </div>
        </div>
        <SambandhApprovalFooter
          uploadingImage={uploadingImage}
          isFormDirty={isFormDirty}
          isFormValid={isFormValid} />
      </div>
    );
  }
}

const selector = formValueSelector(SAMBANDH_APPROVAL_FORM);
function mapStateToProps (state) {
  return {
    approval: state.sambandhApproval.storedSambandhUser,
    formSubmitted: state.sambandhApproval.submitted,
    selectedAddress: selector(state, 'addressDetails'),
    selectedProfileImage: selector(state, 'profileImageUrl'),
    isFormValid: isValid(SAMBANDH_APPROVAL_FORM)(state),
    isFormDirty: isDirty(SAMBANDH_APPROVAL_FORM)(state)
  };
}

export default connect(mapStateToProps, {closeDrawer})(SambandhPanel);

SambandhPanel.propTypes = {
  approval: PropTypes.object.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  isFormValid: PropTypes.bool.isRequired,
  isFormDirty: PropTypes.bool.isRequired,
  selectedProfileImage: PropTypes.any
};
