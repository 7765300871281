import React, { Fragment } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
// material
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@material-ui/core';
// Project Files
import { closeModal } from '../../actions/common.actions';
import { contactActions } from '../../views/Contacts/contacts.ducks';

class InviteMemberModal extends React.Component {
  constructor (props) {
    super(props);
  }

  handleClick = () => {
    this.props.inviteContactToSampark(this.props.contact.id);
    return this.props.closeModal();
  }

  render () {

    return (
      <Fragment>
        <DialogTitle>Invite contact to sampark</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to invite <span style={{textTransform: 'capitalize'}}>{`${this.props.contact.firstName}`}</span> to sampark?
          </DialogContentText>
        </DialogContent>
        <DialogActions disableActionSpacing>
          <Button disableRipple className='button-primary cancel-button' onClick={this.props.closeModal}>
            <span className='button-primary-label'>Cancel</span>
          </Button>
          <Button
            disableRipple
            className='button-primary button-orange'
            onClick={this.handleClick}>
            <span className='button-primary-label label-white'>Confirm</span>
          </Button>
        </DialogActions>
      </Fragment>
    );
  }
}

export default connect(null, {
  closeModal,
  inviteContactToSampark: contactActions.inviteContactToSampark
})(InviteMemberModal);

InviteMemberModal.propTypes = {
  closeModal: PropType.func.isRequired,
  inviteContactToSampark: PropType.func.isRequired,
  contact: PropType.object.isRequired
};
