import { Checkbox, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import { ILabelValues, ISearchSelect } from '../../attributes.types';
import { useStyles } from './searchSelect.styles';

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export const MultiSelect = ({
  inputValue,
  options,
  placeholder = '',
  onChange,
  ...delegated
}: ISearchSelect) => {
  const { classes } = useStyles();
  const handleToggle = (val: string) => {
    const currentIndex = inputValue.indexOf(val);
    const newChecked = [...inputValue];
    if (currentIndex === -1) {
      newChecked.push(val);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    onChange(newChecked);
  };
  return (
    <Select
      {...delegated}
      placeholder={placeholder}
      value={inputValue}
      input={<OutlinedInput />}
      renderValue={(selected) => {
        return selected.join(', '); // Render the selected values as a comma-separated list
      }}
      MenuProps={MenuProps}
      classes={{
        root: classes.rootSelect,
        outlined: classes.outlineSelect
      }}
      multiple={true}
      required
    >
      {options?.map((option: ILabelValues, index: number) => {
        const { label, val } = option;
        return (
          <MenuItem
            key={index}
            value={val}
            classes={{
              selected: classes.selected,
              root: classes.rootSelected
            }}
            onClick={() => handleToggle(val)}
          >
            <Checkbox
              classes={{
                checked: classes.checked,
                root: classes.rootChecked
              }}
              checked={inputValue.includes(val)}
            />
            <ListItemText primary={label} />
          </MenuItem>
        );
      })}
    </Select>
  );
};
