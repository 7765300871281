import Color from 'color';

export const styles = {
  container: {
    margin: '0rem auto'
  },
  view: {
    margin: '0.1rem'
  },
  header: {
    marginBottom: '1rem'
  },
  title: {
    fontSize: '24px',
    color: '#2A3D4C',
    fontWeight: 500
  },
  controls: {
    margin: 'auto 0'
  },
  emptyMessage: {
    color: '#899EB0',
    textAlign: 'center',
    marginTop: '3.5rem'
  },
  loader: {
    marginTop: '3.5rem'
  },
  submitButton: {
    background: '#FF5A52',
    borderRadius: '7px',

    '&:hover': {
      background: Color('#FF5A52').darken(0.2).hex()
    }
  },
  submitLabel: {
    color: 'white',
    fontSize: '16px',
    textTransform: 'none',
    fontWeight: 'bold'
  }
};
