import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { getReadingTemplate } from '../../views/ReadingView/getReadingTemplate';

dayjs.extend(customParseFormat);

const MISSING_TITLE_CONTENT_MESSAGE = 'Please fill both content & title in at least one language';

const validate = (values, props) => {
  // console.log(values);
  let errors = {};
  const { content, title } = values;
  const { type } = props;
  const template = getReadingTemplate(type.value);
  // markdown and title field should be filled in at least 1 language
  if ((!content.english || !title.english) &&
      (!content.gujarati || !title.gujarati)) {

    errors.content = {
      english: '',
      gujarati: ''
    };
    errors.title = {
      english: '',
      gujarati: ''
    };

    if (!content.english || !title.english) {
      errors.content.english = MISSING_TITLE_CONTENT_MESSAGE;
      errors.title.english = MISSING_TITLE_CONTENT_MESSAGE;
    }

    if (!content.gujarati || !title.gujarati) {
      errors.content.gujarati = MISSING_TITLE_CONTENT_MESSAGE;
      errors.title.gujarati = MISSING_TITLE_CONTENT_MESSAGE;
    }
  }

  // markdown must not be equal to template
  if (content.english && title.english) {
    if (content.english === template.content.english) {
      errors.content = {
        english: 'Please replace template content'
      };
    }
  }

  // markdown must not be equal to template
  if (content.english.gujarati && title.gujarati) {
    if (content.gujarati === template.content.gujarati) {
      errors.content = {
        gujarati: 'Please replace template content'
      };
    }
  }

  // publish form date must be filled
  if (!values.publishFormDate) {
    errors.publishFormDate = 'Please enter a date to publish content';
  }
  // publish form time must be filled
  if (!values.publishFormTime) {
    errors.publishFormTime = {
      meridian: 'Please select AM or PM',
      value: 'Please enter a time to publish'
    };
  }
  // publish form time must be a valid time
  if (values.publishFormTime) {
    const { publishFormTime } = values;
    const { meridian, value } = publishFormTime;
    if (!meridian || !value) {
      errors.publishFormTime = {
        merdian: '',
        value: ''
      };

      if (!meridian) {
        errors.publishFormTime.meridian = 'Please select AM or PM';
      }
      if (!value) {
        errors.publishFormTime.value = 'Please enter a time to publish';
      }
    }
    if (publishFormTime.value) {
      const timeFull = dayjs(publishFormTime.value, 'hh:mm', true);
      const timeHour = timeFull.get('hour');
      const timeMinute = timeFull.get('minute');
      if (!(timeHour <= 12) || !(timeMinute < 60)) {
        errors.publishFormTime = {
          value: 'Please enter a valid date & time'
        };
      }
    }
  }
  return errors;
};

export default validate;
