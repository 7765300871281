import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  rootSelect: {
    height: '40px',
    '&:focus': {
      backgroundColor: 'transparent'
    }
  },
  outlineSelect: {
    border: '1px solid hsla(225, 19%, 60%, 1)' // Change the border color here
  },
  rootChecked: {
    color: 'hsla(225, 35%, 93%, 1)'
  },
  checked: {
    color: 'hsl(209, 57%, 47%)'
  },
  rootSelected: {
    height: '3rem'
  },
  selected: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)'
    }
  }
}));
