// user has not been invited
export const UNAUTHORIZED = {
  status: 'UNAUTHORIZED',
  title: 'Unauthorized Access',
  message:
    'This application is for authorized users only. If you have access to this app, please check your login and try again.'
};

// user has been invited and is pending verification
export const INVITED = {
  status: 'INVITED'
};

// user has verified account after being invited
export const VERIFIED = {
  status: 'VERIFIED',
  title: 'Thanks for the info!',
  message:
    'We have received your information. Our team is working quickly to verify your information and setup your account. We will notify you as soon as your account is ready.'
};

// users access form is pending approval from super admin
export const PENDING = {
  status: 'PENDING',
  title: 'Thanks for the info!',
  message:
    'We have received your information. Our team is working quickly to verify your information and setup your account. We will notify you as soon as your account is ready.'
};

// user has been approved to use application, and has been assigned access
export const APPROVED = {
  status: 'APPROVED'
};

// users access form has been declined - We tell users who are declined that there is a scheduled maintenance
export const DECLINED = {
  status: 'DECLINED',
  title: 'Scheduled maintenance',
  message: 'The website is currently down for maintenance.'
};

export const ERROR = {
  status: 'ERROR',
  title: 'Whoops! Something went wrong.',
  message:
    'It seems there was an issue logging in. If you have access to this app, please check your login and try again.'
};
