import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td
} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const COLUMNS = [
  'firstName',
  'lastName',
  'gender',
  'age',
  'email',
  'city',
  'state',
  'country',
  // 'attendingYagna',
  // 'accommodation1 (5th July)',
  // 'accommodation2 (6th July)',
  // 'localTransportation',
  // 'airportTransportation',
  'registrationGroupId'
];

const columnNames = {
  'firstName': 'First Name',
  'lastName': 'Last Name',
  'gender': 'Gender',
  'age': 'age',
  'email': 'Email',
  'city': 'City',
  'state': 'State',
  'country': 'Country',
  // 'attendingYagna': 'Yagna',
  // 'accommodation1 (5th July)': 'Acc 5th',
  // 'accommodation2 (6th July)': 'Acc 6th',
  // 'localTransportation': 'Local Transport',
  // 'airportTransportation': 'Airport Transport'
  'registrationGroupId': 'Reg Id'
};

class ContactsView extends React.Component {
  render = () => {
    const { registeredMembers, pageSize, page, pages, totalCount } = this.props;

    return (
      <div className='yog-report'>
        <div className='top-controls'>
          <span className='total-count'>Total Count: {totalCount}</span>
          <button onClick={() => this.props.onDownloadReport()}>Export</button>
        </div>
        <Table>
          <Thead>
            <Tr className='table-header'>
              <Th key='sr'>#</Th>
              {
                COLUMNS.map((column) => {
                  return (<Th key={column}>
                    {columnNames[column].toUpperCase()}
                  </Th>);
                })
              }
            </Tr>
          </Thead>
          <Tbody>
            {
              registeredMembers.map((row, index) => {
                return (
                  <Tr key={`${row._id}-${index}`}>
                    <Td key={`${row._id}-${index}-sr`}>{ (index + 1) + ((page * pageSize) - pageSize) }</Td>
                    {
                      COLUMNS.map((column) => {
                        const className = row[column] === false ? 'negative-color' : row[column] === true ? 'positive-color' : '';
                        return (
                          <Td className={className} key={`${row._id}-${index}-${column}`}>{ ((row[column]) + '').toUpperCase() }</Td>
                        );
                      })
                    }
                  </Tr>
                );
              })
            }
          </Tbody>
        </Table>
        <div className='pagination-controls'>
          <button disabled={!(page > 1)} onClick={this.prevPage} id='btn_prev'> &lt; Prev</button>
          <span className='current-page-no'>{`${page} of ${pages}`}</span>
          <button disabled={!(page < pages)} onClick={this.nextPage} id='btn_next'>Next &gt; </button>
        </div>
      </div>
    );
  };

  prevPage = () => {
    this.props.onPrevPage();
  };

  nextPage = () => {
    this.props.onNextPage();
  };

}

ContactsView.propTypes = {
  registeredMembers: PropTypes.any,
  pageSize: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number,
  onDownloadReport: PropTypes.func,
  totalCount: PropTypes.number,
  onNextPage: PropTypes.func,
  onPrevPage: PropTypes.func
};

export default ContactsView;
