import { Button, styled } from '@mui/material';

export const StyledContactListActionsContainer = styled('section', {
  name: 'StyledContactListActionsContainer'
})(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',
  justifyContent: 'flex-end',

  [theme.breakpoints.up('sm')]: {
    justifyContent: 'space-between'
  }
}));

export const StyledContactListActionButton = styled(Button, {
  name: 'StyledContactListActionButton'
})(({ theme }) => ({
  display: 'flex',
  gap: '4px',
  whiteSpace: 'nowrap',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'column'
  },

  '&:disabled': {
    opacity: 0.5
  }
}));
