import { Button } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import * as STATUS from '../../../constants/messageStatus';
import BroadcastMessageMenu from '../../../components/menus/BroadcastMessageMenu';
import { IBroadcastMessage } from '../BroadcastView.types';

dayjs.extend(utc);

interface IMessageListItemProps {
  message: IBroadcastMessage;
  onClickViewMessage: (message: IBroadcastMessage) => void;
}

export const MessageListItem = (props: IMessageListItemProps) => {
  const { message, onClickViewMessage } = props;

  const handleClick = () => {
    onClickViewMessage(message);
  };

  const handleCreateCopy = () => {
    const { audiences, message: body, title } = message;

    const newMessage = Object.assign(
      {},
      {
        audiences,
        message: body,
        title
      }
    );
    onClickViewMessage(newMessage);
  };

  const createdAt = message.createdAt ? dayjs(message.createdAt).format('MMM D, YYYY') : '';

  const scheduledFor = message?.schedule
    ? `${dayjs(message.schedule.at).format('MM/DD/YYYY')} at ${dayjs(message.schedule.at).format(
        'h:mma'
      )}`
    : '';

  const createdBy = message?.createdBy
    ? `${message.createdBy.firstName} ${message.createdBy.lastName}`
    : '';

  return (
    <div className="message-list-card-container">
      <div className="message-content-created-scheduled-container">
        <div className="message-content-container">
          <div className="content label">{message.title}</div>
          <div className="content">{createdAt}</div>
        </div>
        <div className="message-createdBy-container">
          <div className="label">Created By</div>
          <div className="content capitalize">{createdBy}</div>
        </div>
        <div className="message-scheduled-container">
          <div className="label">Scheduled For</div>
          <div>{scheduledFor}</div>
        </div>
      </div>
      {message.status ? (
        <div className="status-button-more-container">
          <div className={['message-status', message.status].join(' ').toLowerCase()}>
            {message.status}
          </div>
          <div className="message-button-container">
            <Button className="message-manage-button" onClick={handleClick}>
              {getButtonLabelByStatus(message.status)}
            </Button>
          </div>
          <BroadcastMessageMenu
            onCreateCopy={handleCreateCopy}
            message={{ status: message.status, _id: message._id }}
          />
        </div>
      ) : null}
    </div>
  );
};

const getButtonLabelByStatus = (status: string): string => {
  switch (status) {
    case STATUS.PENDING:
    case STATUS.APPROVED:
    case STATUS.EXPIRED:
    case STATUS.SENT:
    case STATUS.CANCELLED:
      return 'View';
    case STATUS.DECLINED:
      return 'Comments';
    default:
      throw new Error(`${status} is not a valid message status`);
  }
};
