import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    position: 'relative',
    height: '100%'
  },
  jsonInput: {
    height: 'calc(100% - 150px)',
    resize: 'none',
    border: 'none',
    padding: ' 8px',
    '&:invalid': {
      borderColor: 'red'
    }
  },
  alert: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '5px',
    fontSize: `${16 / 16}rem`,
    padding: '8px'
  }
}));
