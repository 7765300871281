import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

interface FilterButtonProps {
  className?: string;
  text: string;
  onClick?: (value: boolean) => void;
  selected?: boolean;
}

const FilterButton = ({ className, text, onClick, selected }: FilterButtonProps) => {
  const { classes } = useStyles();

  const [selectedValue, setSelectedValue] = useState<boolean>(false);

  function handleButtonClick() {
    setSelectedValue(!selectedValue);
    if (onClick) {
      onClick(!selectedValue);
    }
  }

  return (
    <div
      className={[classes.button, selected ? classes.selected : null, className].join(' ')}
      onClick={handleButtonClick}
    >
      {text}
    </div>
  );
};

const useStyles = makeStyles()({
  button: {
    backgroundColor: '#E8EBF4',
    borderRadius: '8px',
    color: '#5F698B',
    cursor: 'pointer',

    display: 'flex',
    justifyContent: 'center',
    padding: '8px 10px',

    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '20px'
  },
  selected: {
    backgroundColor: '#ff5a52',
    color: 'white'
  }
});

export default FilterButton;
