import { IAllFields, IValueDropdown } from '../../../../attributes.types';
import { SingleSelect } from '../../../SearchSelect/SingleSelect';
import { TextField } from '../../../TextField/TextField';
import {
  ALL_OPERATORS,
  ARRAY_OPERATORS,
  NUMERICI_OPERATORS,
  STRING_OPERATORS
} from './operatorDropdown.constants';

export const OperatorDropdown = ({ field, handleOperator, operator }: IValueDropdown) => {
  const getOptions = (field: IAllFields | undefined) => {
    let options = ALL_OPERATORS;
    if (field?.datatype === 'labelValues' && field?.multiSelect) {
      options = ARRAY_OPERATORS;
    }
    if (
      (field?.datatype === 'labelValues' && !field?.multiSelect) ||
      field?.datatype === 'string'
    ) {
      options = STRING_OPERATORS;
    }
    if (field?.datatype === 'number') {
      options = NUMERICI_OPERATORS;
    }
    return options;
  };
  const options = getOptions(field);

  const onChangeHandler = (value: string | string[]) => {
    const newOperator = options.find((item) => item.val === value);
    if (!newOperator) {
      return;
    }
    handleOperator(newOperator);
  };
  return (
    <TextField label="Operator" tooltipValue="Operator">
      <SingleSelect
        placeholder="Operator"
        inputValue={operator.val}
        options={options}
        onChange={onChangeHandler}
      />
    </TextField>
  );
};
