import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import ImageUploader from '../ImageUploader';

const renderImageGroup = ({
  label = null,
  fields,
  meta: { touched, error, warning },
  required = false
}) => {
  return (
    <Fragment>
      {label && <div className='form-field-label-row'>
        <label>{label} {required && <span className='form-field-required'>*</span>}</label>
      </div>}
      <div className='form-field-images-container'>
        <Field
          name={`${fields.name}[${0}]`}
          component={ImageUploader}
        />
        {touched &&
          ((error && <div className='form-field-error'>{error}</div>) ||
            (warning && <div className='form-field-error'>{warning}</div>))}
      </div>
    </Fragment>
  );
};

export default renderImageGroup;

renderImageGroup.propTypes = {
  fields: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.any,
  required: PropTypes.bool
};
