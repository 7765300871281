import { Alert } from '@mui/material';
import { REGISTRATION_STATUS } from '../../constants/RegistrationStatus';

interface IHeaderMessage {
  status: REGISTRATION_STATUS;
  isEditable: boolean;
  hideActions: boolean;
}

export const HeaderMessage = ({ status, isEditable, hideActions }: IHeaderMessage) => {
  if (status === REGISTRATION_STATUS.HOLD) {
    return <Alert severity={'error'}>This registration is on Hold</Alert>;
  }
  const showActions = !hideActions && isEditable;
  if (showActions && status === REGISTRATION_STATUS.PENDING) {
    return null;
  }
  const severity = REGISTRATION_STATUS.REJECTED === status ? 'warning' : 'info';
  if (showActions) {
    return <Alert severity={severity}> This registration is {status}</Alert>;
  }
  return <Alert severity={'warning'}>You cannot edit this registration</Alert>;
};
