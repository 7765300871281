import { userAccessActions } from '../constants/actions';

export default function userAccessReducer (
  state = {
    userAccessObject: {},
    isSuperAdmin: false,
    userAccount: {}
  },
  action) {

  switch (action.type) {
    case userAccessActions.GET_USER_ACCESS_OBJECT:
      return { ...state, userAccessObject: action.value };
    case userAccessActions.GET_IS_SUPER_ADMIN:
      return { ...state, isSuperAdmin: action.value };
    case userAccessActions.GET_MY_USER_ACCOUNT:
      return { ...state, userAccount: action.value };
    case userAccessActions.GET_MY_USER_ACCOUNT_ERROR:
      return { ...state, error: action.value };
    default:
      return { ...state };
  }
}
