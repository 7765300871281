import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import './styles.scss';

const styles = theme => ({
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    padding: theme.spacing()
  }
});

const themes = ['blue', 'gold', 'lavender', 'grey'];

class MouseOverPopover extends React.Component {
  state = {
    anchorEl: null
  };

  handlePopoverOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  renderCategory (data) {
    return data.map(({category, tags}, cindex) => {
      if (!tags.length) {
        return null;
      }

      return (
        <dl key={`dl-${cindex + 1}`}>
          <dt className='category' key={`category-${cindex + 1}`}>{category}</dt>
          {
            tags.map((tag, index) => (<dd className={`tag ${themes[cindex]}`} key={`tag-${index + 1}`} >{tag}</dd>))
          }
        </dl>
      );
    });
  }

  renderPopOver (total) {
    const { classes, data = [] } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <Popover
        id='mouse-over-popover'
        className={classes.popover}
        classes={{
          paper: classes.paper
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={this.handlePopoverClose}
        disableRestoreFocus
      >
        <div className='tags-popover'>
          {this.renderCategory(data)}
        </div>
      </Popover>
    );
  }

  render () {
    const { data = [] } = this.props;
    const total = data.reduce((prev, {tags}) => { return prev + tags.length; }, 0);

    return (
      <div className='tag-count'>
        <Typography
          aria-owns={open ? 'mouse-over-popover' : null}
          aria-haspopup='true'
          onMouseEnter={this.handlePopoverOpen}
          onMouseLeave={this.handlePopoverClose}>
          {`${total} Tags`}
        </Typography>
        {total > 0 && this.renderPopOver(this.props)}
      </div>
    );
  }
}

MouseOverPopover.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array
};

export default withStyles(styles)(MouseOverPopover);
