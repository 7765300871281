import http from '../helpers/http';
import { API } from '../config/config.constants';

export function getEvents () {
  return http
    .get(API.EVENTS_VIEW.EVENTS_API)
    .then((response) => {
      let events = response.data.events;
      if (events && events instanceof Array) {
        return events;
      } else {
        return { error: response };
      }
    })
    .catch((error) => {
      return { error };
    });
}

export function submitEvent (eventFormData) {
  if (eventFormData.id) {
    return http
      .put(API.EVENTS_VIEW.EVENTS_API, eventFormData)
      .then((response) => {
        const submitResponse = response.data;
        if (submitResponse) {
          return submitResponse;
        } else {
          return { error: response };
        }
      })
      .catch((error) => {
        return { error };
      });
  } else {
    return http
      .post(API.EVENTS_VIEW.EVENTS_API, eventFormData)
      .then((response) => {
        const submitResponse = response.data;
        if (submitResponse) {
          return submitResponse;
        } else {
          return { error: response };
        }
      })
      .catch((error) => {
        return { error };
      });
  }
}

export function uploadEventImagesAPI (eventID, eventImagesFormData) {
  if (eventID && typeof eventID === 'string' && eventID.length) {
    return http
      .post(API.EVENTS_VIEW.EVENT_GALLERY_API + eventID, eventImagesFormData)
      .then((response) => {
        const uploadImagesResponse = response.data;
        if (uploadImagesResponse) {
          return uploadImagesResponse;
        } else {
          return { error: response };
        }
      })
      .catch((error) => {
        return { error };
      });
  }
};

export function addImageGalleryToEventAPI (eventID, eventImageURLsObject) {
  if (eventID && typeof eventID === 'string' && eventImageURLsObject && eventImageURLsObject.images && eventImageURLsObject.images.length) {
    return http
      .post(`${API.EVENTS_VIEW.EVENTS_API}/${eventID}/gallary`, eventImageURLsObject)
      .then((response) => {
        const addImageGalleryToEventResponse = response.data;
        if (addImageGalleryToEventResponse) {
          return addImageGalleryToEventResponse;
        } else {
          return { error: response };
        }
      })
      .catch((error) => {
        return { error };
      });
  }
}
