import { useMemo } from 'react';
import { ReactComponent as CloseIcon } from '../../assets/icons/icon_close_mini.svg';
import { ReactComponent as TickIcon } from '../../assets/icons/icon_tick.svg';
import { commonActions } from '../../constants/actions';
import { useToastIconStyles } from './Toaster.styles';

interface IToastIconProps {
  toasterMessageType?: string;
}

export const ToastIcon = ({ toasterMessageType }: IToastIconProps) => {
  const { classes, cx } = useToastIconStyles();

  const toastIconInfo = useMemo(() => {
    if (toasterMessageType === commonActions.SUCCESS_TOASTER) {
      return { Icon: TickIcon, className: classes.successStatus };
    }
    if (toasterMessageType === commonActions.ERROR_TOASTER) {
      return { Icon: CloseIcon, className: classes.errorStatus };
    }

    return null;
  }, [toasterMessageType]);

  if (!toastIconInfo) {
    return null;
  }

  const { className, Icon } = toastIconInfo;

  return (
    <div className={cx(classes.toasterStatus, className)}>
      <Icon className={classes.statusIcon} />
    </div>
  );
};
