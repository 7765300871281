import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  dialogTitle: {
    '> h2': {
      fontSize: '18px',
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontFamily: 'Rubik'
    }
  },
  note: {
    fontSize: '14px',
    marginTop: '16px',
    backgroundColor: '#E8EBF4',
    padding: '4px 8px',
    borderRadius: '4px'
  },
  text: {
    fontFamily: 'Rubik'
  },
  closeButton: {
    background: 'transparent',
    border: '1px solid transparent',
    cursor: 'pointer'
  },
  rejectButton: {
    flexGrow: 1
  },
  button: {
    backgroundColor: '#E8EBF4',
    borderRadius: '8px',
    color: '#5F698B',
    fontSize: '18px',
    padding: '16px 32px',
    fontWeight: 'bold',
    border: '1px solid transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    cursor: 'pointer'
  },
  buttonGroup: {
    display: 'flex',
    gap: 8,
    padding: '16px 24px',
    justifyContent: 'space-evenly'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    fontFamily: 'Rubik',
    marginBottom: '16px'
  },
  name: {
    fontWeight: 'bold',
    textTransform: 'capitalize'
  }
}));
