import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// material
import { Button } from '@material-ui/core';

import { useFilter } from '../../../hooks/useFilter';
import { Search } from '../../../components/Search/Search';
import { AdminListItem } from '..';
import icon_caret_right from '../../../assets/icons/icon_caret_right.svg';
import { isLoading, isDone, isError } from '../../../util/stage';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';

import './index.scss';

export const FollowUpKaryakartaTab = (props) => {
  const {
    items = [],
    title,
    type,
    onModalOpen,
    showSearch = true,
    onClick,
    stage,
    emptyMessage,
    selectedItem
    // onMenuClick
  } = props;

  const [ filteredData, handleSetSearchValue, searchValue ] = useFilter({
    initialSearchValue: '',
    data: items,
    keys: ['firstName', 'lastName'],
    debounce: true
  });

  const renderFollowupKKAdminItems = () => {
    if (filteredData && filteredData instanceof Array && filteredData.length) {
      return filteredData.map((obj, index) => {
        if (obj) {
          let locationCity = '';
          let locationState = '';
          if (obj.addressDetails) {
            locationCity = obj.addressDetails.city;
            locationState = obj.addressDetails.state;
          }
          const name = obj.firstName + ' ' + obj.lastName;
          const badge = obj.followUpCount >= 0 && obj.followUpCount;

          const onClickHandler = () => {
            if (onClick) {
              onClick(obj);
            }
          };

          const getSelectKKIcon = () => {
            return <img className={'iconButton'} src={icon_caret_right} />;
          };

          const listButtonStyle = selectedItem && selectedItem.memberID === obj.memberID ? 'listButton selectedListItem' : 'listButton';

          return (
            <Button key={index} className={listButtonStyle} onClick={onClickHandler} disableRipple>
              <AdminListItem
                name={name}
                city={locationCity}
                state={locationState}
                contact={obj}
                badge={badge.toString()}
                getActionButton={getSelectKKIcon}
              />
            </Button>
          );
        }
      });
    }

    return null;
  };

  const renderFollowupKKContactItems = () => {
    if (filteredData && filteredData instanceof Array && filteredData.length) {
      return filteredData.map((obj, index) => {
        if (obj) {
          let locationCity = '';
          let locationState = '';
          if (obj.addressDetails) {
            locationCity = obj.addressDetails.city;
            locationState = obj.addressDetails.state;
          }
          const name = obj.firstName + ' ' + obj.lastName;

          const onClickHandler = () => {
            if (onModalOpen) {
              onModalOpen(obj);
            }
          };

          // const handleMenuClick = (e) => {
          //   if (onMenuClick) {
          //     onMenuClick(e);
          //   }
          // };

          // const onEditClick = () => {
          //   return (
          //     <Button key={index} className={'editButton'} onClick={handleMenuClick} disableRipple>
          //       <img className={'iconButton'} src={icon_overflow_active} />
          //     </Button>
          //   );
          // };

          const showAlert = !obj.tags || !obj.tags.followUp || !obj.tags.followUp.length === 0;

          return (
            <AdminListItem
              key={index}
              name={name}
              city={locationCity}
              state={locationState}
              contact={obj}
              showAlert={!!showAlert}
              // getActionButton={onEditClick} // ToDo fix menu dialog position
              onClick={onClickHandler}
              isButton
            />
          );
        }
      });
    }

    return null;
  };

  const renderDataItems = (type) => {
    // need to use Constants for these types and refactor functions
    switch (type) {
      case 'follow-up-kk-contacts':
        return renderFollowupKKContactItems();
      default:
        return renderFollowupKKAdminItems();
    }
  };

  const renderCardBody = () => {
    if (isLoading(stage)) {
      return <LoadingSpinner />;
    } else if (isError(stage)) {
      return (
        <div className='message'>
          An error occured loading data.;
        </div>
      );
    } else if (isDone(stage) && !items.length) {
      return (
        <div className='message'>
          {emptyMessage}
        </div>
      );
    } else if (isDone(stage) && items.length) {
      return (
        <Fragment>
          { showSearch ? (
            <div className={'search'}>
              <Search
                searchValue={searchValue}
                onInputChange={handleSetSearchValue}
              />
            </div>
          ) : null
          }
          <div className='manage-data-items'>
            {
              renderDataItems(type)
            }
          </div>
        </Fragment>
      );
    }
  };

  return (
    <div className='manage-data-card-container'>
      <header className='manage-data-card-header'>
        <h3 className='manage-data-card-title'>{title}</h3>
      </header>
      {
        renderCardBody()
      }
    </div>
  );
};

FollowUpKaryakartaTab.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onModalOpen: PropTypes.func,
  showSearch: PropTypes.bool,
  onClick: PropTypes.func,
  selectedItem: PropTypes.object,
  // onMenuClick: PropTypes.func,
  stage: PropTypes.symbol.isRequired,
  emptyMessage: PropTypes.string.isRequired
};
