import React, { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';

import FamilyTreeSearch from './FamilyTreeSearch';
import FamilyTreeContent from './FamilyTreeContent';
import { RespState, searchMembers } from './familyTree.duck';
import { useAppDispatch, useAppSelector } from '../../../helpers/store';

const FamilyTree = () => {
  const { classes } = useStyles();

  useEffect(() => {
    dispatch(searchMembers({ name: '', pageNumber: 1 }));
  }, []);

  const dispatch = useAppDispatch();
  const familyTree = useAppSelector((state) => state.admin.familyTree);

  return (
    <div className={classes.container}>
      <FamilyTreeSearch
        isLoading={familyTree.getUserApiState === RespState.PENDING}
        members={familyTree.members}
        isFamilyContentFilled={familyTree.familyRelation.members.length > 0}
        maxPageNumbers={familyTree.paginationMeta.maxPageNumber}
        totalMember={familyTree.paginationMeta.total}
        isError={
          familyTree.loading === RespState.FAILED || familyTree.getUserApiState === RespState.FAILED
        }
      />
      <FamilyTreeContent />
    </div>
  );
};

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px'
  }
}));

export default FamilyTree;
