import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { Fragment } from 'react';

type IModalProps = {
  title: string;
  detailMessage: string;
  cancelCallBack: () => void;
  confirmCallBack: () => void;
};

export function SaveModal({ title, detailMessage, cancelCallBack, confirmCallBack }: IModalProps) {
  return (
    <Fragment>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{detailMessage}</DialogContentText>
      </DialogContent>
      <DialogActions disableSpacing>
        <Button disableRipple className="button-primary cancel-button" onClick={cancelCallBack}>
          <span className="button-primary-label">Cancel</span>
        </Button>
        <Button
          disableRipple
          className="button-primary"
          style={{ backgroundColor: '#26C972' }}
          onClick={confirmCallBack}
        >
          <span className="button-primary-label label-white">Save</span>
        </Button>
      </DialogActions>
    </Fragment>
  );
}
