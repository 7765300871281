export const CANCEL_INVITE = {
  name: 'Cancel Sampark Invite',
  value: 'cancel_invite'
};

export const SHARE_CONTACT = {
  name: 'Share Contact',
  value: 'share_contact',
  disabled: true
};

export const INVITE_CONTACT = {
  name: 'Invite Contact to Sampark',
  value: 'invite_contact'
};

export const INVITE_CONTACT_TO_WORKSHOP = {
  name: 'Invite Contact to Shibir',
  value: 'invite_contact_workshop'
};

export const MY_ACCOUNT = {
  name: 'My Account',
  value: 'my_account',
  disabled: false
};

export const SETTINGS = {
  name: 'Settings',
  value: 'settings',
  disabled: true
};

export const LOGOUT = {
  name: 'Logout',
  value: 'logout'
};

export const DELETE_CONTACT = {
  name: 'Delete Contact',
  value: 'delete_contact'
};

export const FAQ = {
  name: 'FAQs',
  value: 'faqs',
  disabled: true
};

export const CONTACT_US = {
  name: 'Contact Us',
  value: 'contact_us'
};

export const CANCEL_BROADCAST = {
  name: 'Cancel Broadcast',
  value: 'cancel_broadcast'
};

export const COPY_BROADCAST = {
  name: 'Create a copy',
  value: 'create_copy_broadcast'
};

export const CREATE_COPY_EVENT = {
  name: 'Create a copy',
  value: 'create_copy_event'
};

export const VIEW_EVENT_DETAILS = {
  name: 'View Details',
  value: 'view_event_details'
};
