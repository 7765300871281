import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import AddressInput from './AddressInput';
import { ENV } from '../../config/config.constants';
import * as environments from '../../constants/environments';

const renderAddressField = ({
  label = null,
  input,
  placeholder,
  required = false,
  meta: { form, touched, error, warning }
}) => {
  let classes = ['form-field-text', 'padding-xsm-left'];
  if (touched && error) classes.push('form-field-text-error-state');
  const smartyStreetsEnabled = [environments.PROD, environments.QA, environments.DEV, environments.STAGE, environments.LOCAL];
  return (
    <Fragment>
      {label && <div className='form-field-label-row'>
        <label>{label} {required && <span className='form-field-required'>*</span>}</label>
      </div>}
      <div className='form-field-text-container'>
        { (smartyStreetsEnabled.indexOf(ENV) === -1)
          ? <input
            className={classes.join(' ')}
            {...input}
            placeholder={placeholder} />
          : <AddressInput
            className={classes.join(' ')}
            {...input}
            form={form}
            placeholder={placeholder}
          />
        }
        {touched &&
          ((error && <div className='form-field-error'>{error}</div>) ||
            (warning && <div className='form-field-error'>{warning}</div>))}
      </div>
    </Fragment>
  );
};

export default renderAddressField;

renderAddressField.propTypes = {
  input: PropTypes.any,
  placeholder: PropTypes.string,
  meta: PropTypes.any,
  label: PropTypes.string,
  addNew: PropTypes.string,
  required: PropTypes.bool
};
