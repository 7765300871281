import { useDraggable } from '@dnd-kit/core';
import { ReactChild, ReactFragment, ReactPortal } from 'react';
import { makeStyles } from 'tss-react/mui';

export const Draggable = (props: {
  id: string | number;
  data?: Record<string, any>;
  children: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined;
}) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: props.id,
    data: props.data
  });
  const { classes } = useStyles();

  return (
    <div ref={setNodeRef} className={classes.root} {...listeners} {...attributes}>
      {props.children}
    </div>
  );
};

const useStyles = makeStyles()(() => ({
  root: {
    border: 'none',
    backgroundColor: 'transparent'
  }
}));
