import React, { Component } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
// material
// project files
import { openModal, openDrawer } from '../../actions/common.actions';
import { DRAWER_COMPONENTS } from '../../config/config.constants';
import icon_hamburger from '../../assets/icons/icon_hamburger_blue.svg';

class ResponsiveHeaderMenu extends Component {
  constructor (props) {
    super(props);
    this.state = {
      anchorEl: null
    };
  }

  render () {
    const { openDrawer } = this.props;

    let classes = this.props.classes ? this.props.classes.join(' ') : '';
    return (
      <div className={classes}>
        <img
          src={icon_hamburger}
          className='hamburger-nav'
          onClick={() => openDrawer(DRAWER_COMPONENTS.NAV_PANEL)}
        />
      </div>
    );
  }
};

export default connect(
  null, { openModal, openDrawer }
)(ResponsiveHeaderMenu);

ResponsiveHeaderMenu.propTypes = {
  openDrawer: PropType.func.isRequired,
  classes: PropType.arrayOf(PropType.string)
};
