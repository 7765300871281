import { yogActions } from '../constants/actions';

export default function YogReducer (
  state = {
    currentPage: 1
  },
  action) {

  switch (action.type) {
    case yogActions.GET_YOG_REPORT:
      const { registeredMembers, count } = action.payload;
      return { ...state, registeredMembers, count };
    case yogActions.GOTO_NEXT_PAGE: {
      const { currentPage } = state;
      return { ...state, currentPage: currentPage + 1 };
    }
    case yogActions.GOTO_PREV_PAGE: {
      const { currentPage } = state;
      return { ...state, currentPage: currentPage - 1 };
    }
    default:
      return { ...state };
  }

};
