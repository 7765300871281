import { validateAllFields, checkFormDirty, formatPermissionsFromAPI } from '../../utils';

// TODO: need to fix redux state - ApprovalForm and AccessForm

export const ACCESS_FORM_ACTIONS = {
  INIT_FORM: 'INIT_FORM',
  INIT_FORM_VALUES: 'INIT_FORM_VALUES',
  CHANGE_ACCESS_FORM: 'CHANGE_ACCESS_FORM',
  CHANGE_ACTIVE_TAB: 'CHANGE_ACTIVE_TAB',
  SUBMIT_FORM_ERRORS: 'SUBMIT_FORM_ERRORS',
  RESET_FORM: 'RESET_FORM',
};

const initialState = {
  activeTab: '',
  originalPermissions: {},
  manageUserPermissions: {},
  manageUserId: undefined,
  manageUserName: '',
  formStatus: undefined,
  isFormDirty: false,
  isFormValid: true,
};

export function initForm (approval) {
  const { formStatus, manageUserName, manageUserId, manageUserPermissions } = formatPermissionsFromAPI(approval);
  return (dispatch, getState) => dispatch({
    type: ACCESS_FORM_ACTIONS.INIT_FORM,
    value: {
      activeTab: '',
      isFormDirty: false,
      isFormValid: true,
      formStatus,
      manageUserId,
      manageUserName,
      manageUserPermissions,
      originalPermissions: manageUserPermissions
    }
  });
}

export function changeAccess (value, approval) {
  const { manageUserPermissions: originalPermissions } = formatPermissionsFromAPI(approval);
  const isFormValid = validateAllFields(value);
  const isFormDirty = (checkFormDirty(undefined, value, originalPermissions) === false);
  const manageUserPermissions = {...value};
  return {
    type: ACCESS_FORM_ACTIONS.CHANGE_ACCESS_FORM,
    value: {
      manageUserPermissions,
      isFormDirty,
      isFormValid
    }
  };
}

export function changeActiveTab (activeTab) {
  return {
    type: ACCESS_FORM_ACTIONS.CHANGE_ACTIVE_TAB,
    value: {
      activeTab
    }
  };
}

export function submitErrors (formName, formFieldName, error) {
  return {
    type: ACCESS_FORM_ACTIONS.SUBMIT_FORM_ERRORS,
    formName,
    formFieldName,
    error
  };
}

export function resetForm () {
  return (dispatch, getState) => dispatch({
    type: ACCESS_FORM_ACTIONS.RESET_FORM
  });
}

const reducer = (state = initialState, {type, value}) => {
  switch (type) {
    case ACCESS_FORM_ACTIONS.INIT_FORM:
      return {
        ...state,
        ...value
      };
    case ACCESS_FORM_ACTIONS.CHANGE_ACCESS_FORM: {
      const { manageUserPermissions, isFormDirty, isFormValid } = value;
      return {
        ...state,
        manageUserPermissions,
        isFormDirty,
        isFormValid
      };
    }
    case ACCESS_FORM_ACTIONS.CHANGE_ACTIVE_TAB: {
      const { activeTab } = value;
      return {
        ...state,
        activeTab
      };
    }
    case ACCESS_FORM_ACTIONS.SUBMIT_FORM_ERRORS: {
      const { formName, formFieldName, error } = value;
      const { formStatus } = state[formName];
      let newFormStatus = { ...formStatus };

      if (formFieldName) {
        newFormStatus = {
          ...formStatus,
          [formFieldName]: error
        };
      }

      return {
        ...state,
        formStatus: newFormStatus
      };
    }
    case ACCESS_FORM_ACTIONS.RESET_FORM:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
