import http from '../helpers/http';
import { GOOGLE_ADDRESS_VALIDATION_API } from '../config/config.constants';
import { capitalizeString } from '../util/capitalizeString';

export function getValidateFullAddressObj (street = "", city = "", state = "") {
  // NEW DATA for GOOGLE API
  const data  = {
    "address": {
      "regionCode": "US",
      "locality": city.trim(),
      "addressLines": [street.trim()]
    }
  };
  return http
    .post(GOOGLE_ADDRESS_VALIDATION_API, data)
    .then((response) => {
      if (response && response.data && response.data.result) {
        const { address: { postalAddress }, geocode, uspsData } = response.data.result;
        const { 
          standardizedAddress: { 
            firstAddressLine,
            zipCode
          }, 
          county, 
          postOfficeState: state, 
          postOfficeCity: city
        } = uspsData;
  
        return {
          street: capitalizeString(firstAddressLine) ?? '',
          city: capitalizeString(city),
          state,
          postalCode: zipCode,
          county: county ? capitalizeString(county) : '',
          location: geocode.location
        };
      } else {
        return { error: 'No address found' };
      }
    }).catch((error) => {
      console.error('address validation error', error);
    });
};
