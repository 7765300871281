import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

const renderMaskedTextField = ({
  label = null,
  input,
  mask = '',
  placeholder,
  meta: { touched, error, warning },
  readOnly = false,
  required = false
}) => {
  let classes = ['form-field-text', 'padding-xsm-left'];
  if (readOnly) classes.push('disabled-text-field');
  if (touched && error) classes.push('form-field-text-error-state');
  return (
    <Fragment>
      {label && <div className='form-field-label-row'>
        <label>{label} {required && <span className='form-field-required'>*</span>}</label>
      </div>}
      <div className='form-field-text-container'>
        <InputMask
          className={classes.join(' ')}
          {...input}
          // only mask value if length is less than 10!
          mask={input.value.length <= 10 ? mask : null}
          disabled={readOnly}
          placeholder={placeholder} />
        {touched &&
          ((error && <div className='form-field-error'>{error}</div>) ||
            (warning && <div className='form-field-error'>{warning}</div>))}
      </div>
    </Fragment>
  );
};

export default renderMaskedTextField;

renderMaskedTextField.propTypes = {
  input: PropTypes.any,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  meta: PropTypes.any,
  label: PropTypes.string,
  addNew: PropTypes.string,
  readOnly: PropTypes.bool,
  mask: PropTypes.string,
};
