import { yogActions } from '../constants/actions';
import { fetchRegisteredMembers, exportRegisteredMembers } from '../apis/yogAPIs';

export function getRegisteredMembers (pageNo, pageSize, dispatch) {
  fetchRegisteredMembers(pageNo, pageSize)
    .then((data) => {
      dispatch({ type: yogActions.GET_YOG_REPORT, payload: data });
    });
}

export function downloadRegisteredMembers (dispatch) {
  exportRegisteredMembers()
    .then((data) => {
      dispatch({ type: yogActions.DOWNLOAD_YOG_REPORT, payload: data });
    });
}

export function gotoNextPage (dispatch) {
  dispatch({ type: yogActions.GOTO_NEXT_PAGE });
}

export function gotoPrevPage (dispatch) {
  dispatch({ type: yogActions.GOTO_PREV_PAGE });
}

export function getCurrentPage (dispatch) {
  return (dispatch) => {
    return dispatch({ type: yogActions.GET_CURRENT_PAGE });
  };
}

export function setCurrentPage (pageNo) {
  return (dispatch) => {
    return dispatch({ type: yogActions.SET_CURRENT_PAGE, value: pageNo });
  };
}
