import React, { Fragment } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, isValid } from 'redux-form';
// material
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@material-ui/core';
// Project Files
import { closeModal } from '../../actions/common.actions';
import * as FORMS from '../../constants/forms';
import { renderTextAreaField } from '../form-fields';
import { declineBroadcastForm } from '../../actions/broadcastForm.actions';

class RejectBroadcastMessageModal extends React.Component {
  constructor (props) {
    super(props);
  }

  handleClick = () => {
    this.props.declineBroadcastForm();
    return this.props.closeModal();
  }

  render () {
    const isFormValid = this.props.isFormValid;
    let primaryButtonClasses = [];
    if (!isFormValid) {
      primaryButtonClasses.push('button-primary-disabled');
    }
    return (
      <Fragment>
        <DialogTitle>Decline message</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Reason for declining the message. Would you like to suggest a change or revision?
          </DialogContentText>
        </DialogContent>
        <DeclineBroadcastForm />
        <DialogActions disableActionSpacing>
          <Button disableRipple className='button-primary cancel-button' onClick={this.props.closeModal}>
            <span className='button-primary-label'>Cancel</span>
          </Button>
          <Button
            disabled={!isFormValid}
            disableRipple
            className={['button-primary', 'button-orange', ...primaryButtonClasses].join(' ')}
            onClick={this.handleClick}>
            <span className='button-primary-label label-white'>Confirm</span>
          </Button>
        </DialogActions>
      </Fragment>
    );
  }
}

function mapStateToProps (state) {
  return {
    isFormValid: isValid(FORMS.DECLINE_BROADCAST_FORM)(state)
  };
}

export default connect(mapStateToProps, { closeModal, declineBroadcastForm })(RejectBroadcastMessageModal);

RejectBroadcastMessageModal.propTypes = {
  closeModal: PropType.func.isRequired,
  isFormValid: PropType.bool.isRequired,
  declineBroadcastForm: PropType.func.isRequired
};

let DeclineBroadcastForm = (props) => {
  return (
    <Field
      name='declinedReason'
      component={renderTextAreaField}
      placeholder='Eg: Change this message to include 2nd session’s start time'
    />
  );
};

const validate = (values) => {
  let errors = {};

  if (!values.declinedReason) {
    errors.declinedReason = 'Reason for declining message is mandatory';
  }

  return errors;
};

DeclineBroadcastForm = reduxForm({
  form: FORMS.DECLINE_BROADCAST_FORM,
  validate
})(DeclineBroadcastForm);
