export const STATES = [
  {
    name: 'alabama',
    abbreviation: 'al',
    value: 'al'
  },
  {
    name: 'alaska',
    abbreviation: 'ak',
    value: 'ak'
  },
  {
    name: 'arizona',
    abbreviation: 'az',
    value: 'az'
  },
  {
    name: 'arkansas',
    abbreviation: 'ar',
    value: 'ar'
  },
  {
    name: 'california',
    abbreviation: 'ca',
    value: 'ca'
  },
  {
    name: 'colorado',
    abbreviation: 'co',
    value: 'co'
  },
  {
    name: 'connecticut',
    abbreviation: 'ct',
    value: 'ct'
  },
  {
    name: 'delaware',
    abbreviation: 'de',
    value: 'de'
  },
  {
    name: 'florida',
    abbreviation: 'fl',
    value: 'fl'
  },
  {
    name: 'georgia',
    abbreviation: 'ga',
    value: 'ga'
  },
  {
    name: 'hawaii',
    abbreviation: 'hi',
    value: 'hi'
  },
  {
    name: 'idaho',
    abbreviation: 'id',
    value: 'id'
  },
  {
    name: 'illinois',
    abbreviation: 'il',
    value: 'il'
  },
  {
    name: 'indiana',
    abbreviation: 'in',
    value: 'in'
  },
  {
    name: 'iowa',
    abbreviation: 'ia',
    value: 'ia'
  },
  {
    name: 'kansas',
    abbreviation: 'ks',
    value: 'ks'
  },
  {
    name: 'kentucky',
    abbreviation: 'ky',
    value: 'ky'
  },
  {
    name: 'louisiana',
    abbreviation: 'la',
    value: 'la'
  },
  {
    name: 'maine',
    abbreviation: 'me',
    value: 'me'
  },
  {
    name: 'maryland',
    abbreviation: 'md',
    value: 'md'
  },
  {
    name: 'massachusetts',
    abbreviation: 'ma',
    value: 'ma'
  },
  {
    name: 'michigan',
    abbreviation: 'mi',
    value: 'mi'
  },
  {
    name: 'minnesota',
    abbreviation: 'mn',
    value: 'mn'
  },
  {
    name: 'mississippi',
    abbreviation: 'ms',
    value: 'ms'
  },
  {
    name: 'missouri',
    abbreviation: 'mo',
    value: 'mo'
  },
  {
    name: 'montana',
    abbreviation: 'mt',
    value: 'mt'
  },
  {
    name: 'nebraska',
    abbreviation: 'ne',
    value: 'ne'
  },
  {
    name: 'nevada',
    abbreviation: 'nv',
    value: 'nv'
  },
  {
    name: 'new hampshire',
    abbreviation: 'nh',
    value: 'nh'
  },
  {
    name: 'new jersey',
    abbreviation: 'nj',
    value: 'nj'
  },
  {
    name: 'new mexico',
    abbreviation: 'nm',
    value: 'nm'
  },
  {
    name: 'new york',
    abbreviation: 'ny',
    value: 'ny'
  },
  {
    name: 'north carolina',
    abbreviation: 'nc',
    value: 'nc'
  },
  {
    name: 'north dakota',
    abbreviation: 'nd',
    value: 'nd'
  },
  {
    name: 'ohio',
    abbreviation: 'oh',
    value: 'oh'
  },
  {
    name: 'oklahoma',
    abbreviation: 'ok',
    value: 'ok'
  },
  {
    name: 'oregon',
    abbreviation: 'or',
    value: 'or'
  },
  {
    name: 'pennsylvania',
    abbreviation: 'pa',
    value: 'pa'
  },
  {
    name: 'rhode island',
    abbreviation: 'ri',
    value: 'ri'
  },
  {
    name: 'south carolina',
    abbreviation: 'sc',
    value: 'sc'
  },
  {
    name: 'south dakota',
    abbreviation: 'sd',
    value: 'sd'
  },
  {
    name: 'tennessee',
    abbreviation: 'tn',
    value: 'tn'
  },
  {
    name: 'texas',
    abbreviation: 'tx',
    value: 'tx'
  },
  {
    name: 'utah',
    abbreviation: 'ut',
    value: 'ut'
  },
  {
    name: 'vermont',
    abbreviation: 'vt',
    value: 'vt'
  },
  {
    name: 'virginia',
    abbreviation: 'va',
    value: 'va'
  },
  {
    name: 'washington',
    abbreviation: 'wa',
    value: 'wa'
  },
  {
    name: 'washington dc',
    abbreviation: 'dc',
    value: 'dc'
  },
  {
    name: 'west virginia',
    abbreviation: 'wv',
    value: 'wv'
  },
  {
    name: 'wisconsin',
    abbreviation: 'wi',
    value: 'wi'
  },
  {
    name: 'wyoming',
    abbreviation: 'wy',
    value: 'wy'
  }
];
