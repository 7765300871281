import http from '../helpers/http';
import * as CONSTANTS from '../config/config.constants';
import { SAMPARKTOKEN } from '../config/config.tokens';
import { ALL } from '../constants/contentTypes';

export function getReadingDataAPI (type, updatedBy) {

  let api = getReadingDataAPIURL(type, updatedBy);
  return http
    .get(api, {
      headers: { 'Authorization': 'Bearer ' + localStorage.getItem(SAMPARKTOKEN) }
    })
    .then((response) => {
      const data = response.data;
      if (data) {
        return data;
      } else {
        return { error: response };
      }
    })
    .catch((error) => {
      return { error };
    });
}

export function submitReadingContentAPI (readingFormData) {
  if (readingFormData._id) {
    return http
      .put(CONSTANTS.API.READING_VIEW.READING_CONTENT, readingFormData)
      .then((response) => {
        const submitResponse = response.data;
        if (submitResponse) {
          return submitResponse;
        } else {
          return { error: response };
        }
      })
      .catch((error) => {
        return { error };
      });
  } else {
    return http
      .post(CONSTANTS.API.READING_VIEW.READING_CONTENT, readingFormData)
      .then((response) => {
        const submitResponse = response.data;
        if (submitResponse) {
          return submitResponse;
        } else {
          return { error: response };
        }
      })
      .catch((error) => {
        return { error };
      });
  }
}

export function changeReadingStatus (id, status, declinedReason) {
  return http
    .put(`${CONSTANTS.API.READING_VIEW.READING_CONTENT}/${id}`, {
      status,
      declinedReason
    })
    .then((response) => {
      let readingContent = response.data;
      if (readingContent) {
        return readingContent;
      } else {
        return { error: response };
      }
    })
    .catch((error) => {
      return { error };
    });
}

function getReadingDataAPIURL (type, updatedBy) {

  let queryString = '';

  if (type !== ALL.value) {
    queryString += `?type=${type}`;
  }

  if (updatedBy && updatedBy === 'others') {
    if (queryString.includes('?')) {
      queryString += `&updateBy=${updatedBy}`;
    } else {
      queryString += `?updateBy=${updatedBy}`;
    }
  }

  return CONSTANTS.API.READING_VIEW.READING_CONTENT.concat(`${queryString}`);
}
