import { iconProps } from './icons-types';

export const iconSwadhyay = ({ stroke }: iconProps) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2005_1523)">
        <path
          d="M9 3V16.5"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.2844 1.02642L9 2.60002L2.71564 1.02642C1.82255 0.847219 1 1.60242 1 2.60162V14.064C1 14.8376 1.50327 15.5008 2.19491 15.6392L9 17L15.8051 15.6392C16.4967 15.5008 17 14.8376 17 14.064V2.60162C17 1.60242 16.1775 0.847219 15.2844 1.02642V1.02642Z"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2005_1523">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
