import { broadcastFormActions } from '../constants/actions';

const intialState = {
  clickedMessage: null,
  formSubmitted: false,
  audiences: [],
  initializing: false
};

export default function eventFormReducer(
  state = {
    ...intialState
  },
  action) {
  switch (action.type) {
    case broadcastFormActions.INITIALIZE_BROADCAST_FORM:
      return { ...state, clickedMessage: action.value, formSubmitted: false };
    case broadcastFormActions.BROADCAST_FORM_INITIALIZING:
      return { ...state, initializing: true };
    case broadcastFormActions.BROADCAST_FORM_INITIALIZED:
      return { ...state, initializing: false };
    case broadcastFormActions.CLEAR_INITIAL_VALUES:
      return {
        ...state,
        clickedMessage: null,
        audiences: [],
      };
    case broadcastFormActions.SUBMIT_BROADCAST_FORM:
      return { ...state, formSubmitted: true };
    case broadcastFormActions.RESET_BROADCAST_FORM:
      return { ...state, formSubmitted: false }
    default:
      return state;
  }
};
