import { Button, AppBar, Toolbar } from '@material-ui/core';
import * as FORMS from '../constants/forms';
import { closeModal } from '../actions/common.actions';
import { submitContactForm, verifyAndSubmitContactForm } from '../actions/contactForm.actions';
import { getMyUserAccountData } from '../actions/userAccess.actions';
import { contactActions } from '../views/Contacts/contacts.ducks';
import { CONTACTS_PAGE_SIZE } from '../views/Contacts/Contacts.const';
import { GetMembersArgs } from '../views/Contacts/useMembers';
import { useAppDispatch, useAppSelector } from '../helpers/store';

interface PanelFooterProps {
  form: string;
  isFormValid?: boolean;
  isFormDirty?: boolean;
  uploadingImage: boolean;
  isUnverified?: boolean;
  getMembers: (getMemberArgs: GetMembersArgs) => void;
}

const PanelFooter = (props: PanelFooterProps) => {
  const { form } = props;
  let buttonDisabled = !props.isFormValid || !props.isFormDirty;
  // if the contact is unverified we want to bypass isFormDirty
  if (props.isUnverified) {
    buttonDisabled = !props.isFormValid;
  }
  return (
    <AppBar position="sticky" className="panel-footer">
      <Toolbar>
        <div className="contact-form-button-container padding-sm">
          <SecondaryButton form={form} />
          <PrimaryButton buttonDisabled={buttonDisabled} {...props} />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default PanelFooter;

const SecondaryButton = ({ form }: { form: string }) => {
  const dispatch = useAppDispatch();

  let buttonLabel = '';
  const buttonClasses = [];
  const labelClasses: string[] = [];

  switch (form) {
    case FORMS.CONTACT_INFO:
    case FORMS.USER_PROFILE_FORM:
      buttonLabel = 'Cancel';
      buttonClasses.push('cancel-button');
      break;
    default:
      throw new Error(`${form} is not a valid option`); /* ` */
  }
  return (
    <Button
      className={['button-primary', ...buttonClasses].join(' ')}
      onClick={() => {
        switch (form) {
          case FORMS.CONTACT_INFO:
          case FORMS.USER_PROFILE_FORM:
            dispatch(closeModal());
            break;
          default:
            throw new Error(`${form} is not a valid option`); /* ` */
        }
      }}
      disableRipple
      color="primary"
    >
      <span className={['button-primary-label', ...labelClasses].join(' ')}>{buttonLabel}</span>
    </Button>
  );
};

interface PrimaryButtonProps extends PanelFooterProps {
  buttonDisabled?: boolean;
}

const PrimaryButton = ({
  form,
  buttonDisabled,
  isUnverified,
  getMembers,
  uploadingImage
}: PrimaryButtonProps) => {
  const { searchValue } = useAppSelector((state) => state.contactsData);
  const dispatch = useAppDispatch();

  let buttonLabel = '';
  const classes = [];
  switch (form) {
    case FORMS.CONTACT_INFO:
      buttonLabel = isUnverified ? 'Verify & Save' : 'Save';
      classes.push('button-orange');
      break;
    case FORMS.USER_PROFILE_FORM:
      buttonLabel = 'Save Profile';
      classes.push('button-orange');
      break;
    default:
      throw new Error(`${form} is not a valid option`); /* ` */
  }
  if (buttonDisabled) {
    classes.push('button-primary-disabled');
  }
  const onSubmit = isUnverified ? verifyAndSubmitContactForm : submitContactForm;
  return (
    <Button
      disableRipple
      onClick={async () => {
        switch (form) {
          case FORMS.CONTACT_INFO:
            // eslint-disable-next-line no-case-declarations
            const submitContactFormResults: any = await dispatch(
              onSubmit(FORMS.CONTACT_INFO, uploadingImage)
            );
            if (!submitContactFormResults.error) {
              getMembers({
                pageNumber: 1,
                pageSize: CONTACTS_PAGE_SIZE,
                search: searchValue
              });
              dispatch(closeModal());
            }
            break;
          case FORMS.USER_PROFILE_FORM:
            // eslint-disable-next-line no-case-declarations
            const submitUserProfileFormResults: any = await dispatch(
              onSubmit(FORMS.USER_PROFILE_FORM)
            );
            if (!submitUserProfileFormResults.error) {
              if (uploadingImage) {
                if (submitUserProfileFormResults && submitUserProfileFormResults.id) {
                  dispatch(
                    contactActions.uploadContactAvatar(
                      FORMS.USER_PROFILE_FORM,
                      submitUserProfileFormResults.id
                    )
                  );
                } else {
                  dispatch(contactActions.uploadContactAvatar(FORMS.USER_PROFILE_FORM));
                }
                location.reload();
              }
              dispatch(getMyUserAccountData());
            }
            break;
          default:
            console.log('submitted');
        }
      }}
      disabled={buttonDisabled}
      className={['button-primary', ...classes].join(' ')}
      color="primary"
    >
      <span className="button-primary-label label-white">{buttonLabel}</span>
    </Button>
  );
};
