import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isValid, isDirty } from 'redux-form';
import PropTypes from 'prop-types';
// MATERIAL UI
// PROJECT FILES
import EventsForm from './EventsForm';
import EventsPanelFooter from './EventsPanelFooter';
import { EVENT_FORM } from '../../constants/forms';
import { getEventsData } from '../../actions/events.actions';
import { resetEventForm } from '../../actions/eventForm.actions';
import PanelLoading from '../PanelLoading';
// icons
import icon_close from '../../assets/icons/icon_cross.svg';

class EventsPanel extends Component {
  constructor (props) {
    super(props);
  }

  componentDidUpdate () {
    if (this.props.formSubmitted) {
      this.props.getEventsData();
      this.props.onClose()
      this.props.resetEventForm();
    }
  }

  render () {
    const { isFormDirty, isFormValid, event, isLoading, eventImages, onClose } = this.props;

    let uploadingImage = false;

    if (eventImages && eventImages instanceof Array && eventImages.length && eventImages[0] instanceof File) {
      // only will be true if the event image in redux store is a File
      uploadingImage = true;
    }
    return isLoading ? <PanelLoading /> : (
      <div className='event-panel'>
        <div className='padding-left padding-right'>
          <div className='event-panel-top-row'>
            <h4 className='title-text padding-xsm-right'>DETAILS</h4>
            <div className='event-form-top-row-buttons'>
              <img
                className='event-form-dismiss'
                onClick={onClose}
                src={icon_close} />
            </div>
          </div>
          <EventsForm initialValues={event} />
        </div>
        <EventsPanelFooter
          form={EVENT_FORM}
          isFormDirty={isFormDirty}
          isFormValid={isFormValid}
          onClose={onClose}
          uploadingImage={uploadingImage}
        />
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    event: state[EVENT_FORM].event,
    isFormValid: isValid(EVENT_FORM)(state),
    isFormDirty: isDirty(EVENT_FORM)(state),
    formSubmitted: state[EVENT_FORM].formSubmitted,
    eventImages: state[EVENT_FORM].eventImages,
    isLoading: state[EVENT_FORM].initializing
  };
}

export default connect(mapStateToProps, { getEventsData, resetEventForm })(EventsPanel);

EventsPanel.propTypes = {
  event: PropTypes.object,
  isFormValid: PropTypes.bool.isRequired,
  isFormDirty: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  formSubmitted: PropTypes.bool.isRequired,
  getEventsData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  eventImages: PropTypes.array
};
