import { commonActions } from '../constants/actions';

export default function commonReducer (
  state = {
    displayToaster: false,
    message: null,
    type: null,
    showDrawer: false,
    drawerComponent: '',
    showModal: false,
    modalComponent: '',
    modalProps: {},
    globalLoader: false,
    isDevMode: 'false'
  },
  action) {

  switch (action.type) {
    case commonActions.SHOW_HIDE_TOASTER:
      return { ...state, displayToaster: action.value.displayToaster, message: action.value.message, type: action.value.type };
    case commonActions.OPEN_DRAWER:
      return { ...state, showDrawer: true, drawerComponent: action.value.drawerComponent };
    case commonActions.CLOSE_DRAWER:
      return { ...state, showDrawer: false, drawerComponent: '' };
    case commonActions.OPEN_MODAL:
      const props = action.value.props ? action.value.props : {};
      return { ...state, modalComponent: action.value.modalComponent, modalProps: props, showModal: true };
    case commonActions.CLEAR_MODAL_COMPONENT:
      return { ...state, modalComponent: '', modalProps: {} };
    case commonActions.CLOSE_MODAL:
      return { ...state, showModal: false };
    case commonActions.DISPLAY_GLOBAL_LOADER:
      return { ...state, globalLoader: true };
    case commonActions.HIDE_GLOBAL_LOADER:
      return { ...state, globalLoader: false };
    case commonActions.SHOW_DEV_MODE:
      return { ...state, isDevMode: action.value };
    default:
      return { ...state };
  }
}
