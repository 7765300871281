import CheckCircle from '@mui/icons-material/CheckCircle';
import Report from '@mui/icons-material/Report';
import { JSONPLACEHOLDER } from '../createAttributeModal.constants';
import { useStyles } from './JsonAttribute.styles';
export interface IJsonAttribute {
  jsonData: string;
  handleJsonData: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  isError: boolean | undefined;
  errorMessage: string;
}

export const JsonAttribute = ({
  jsonData,
  handleJsonData,
  isError,
  errorMessage
}: IJsonAttribute) => {
  const { classes } = useStyles();
  return (
    <div className={classes.formWrapper}>
      <textarea
        className={classes.jsonInput}
        value={jsonData}
        placeholder={JSONPLACEHOLDER}
        onChange={handleJsonData}
        required
      />

      {isError && (
        <div className={`${classes.alert} ${classes.error}`}>
          <Report />
          <p style={{ color: 'hsl(345, 100%, 50%)' }}>{errorMessage}</p>
        </div>
      )}
      {isError !== undefined && !isError && (
        <div className={`${classes.alert} ${classes.success}`}>
          <CheckCircle />
          <p style={{ color: 'hsl(120, 80%, 35%)' }}>Your JSON format looks good !!</p>
        </div>
      )}
    </div>
  );
};
