import React from 'react';
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';

import SignIn from '../components/auth/SignIn';
import SignOut from '../components/auth/SignOut';
import SignUp from '../components/auth/SignUp';
import SignUpStatus from '../components/auth/SignUpStatus';
import { Toaster } from '../components/Toaster/Toaster';
import { hideToaster } from '../actions/common.actions';
import { useSelector } from 'react-redux';
import { RootState } from '../reducers/index.types';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

type SignInLayoutProps = RouteComponentProps & {
  //add props
};

const SignInLayout = (props: SignInLayoutProps) => {
  const notOnLanding = props.history.location.pathname !== '/auth';

  const dispatch = useDispatch();
  const actions = bindActionCreators({ hideToaster }, dispatch);

  const {
    displayToaster,
    message: toasterMessage,
    type: toasterMessageType
  } = useSelector((state: RootState) => state.commonData);

  return (
    <div className={'signin-layout'}>
      <main className={'signin-container' + (notOnLanding ? ' flex-container' : '')}>
        <Switch>
          <Route path="/auth" exact component={SignIn} />
          <Route path="/auth/signout" component={SignOut} />
          <Route path="/auth/signup" component={SignUp} />
          <Route path="/auth/signup_status" component={SignUpStatus} />
          <Redirect to="/auth" />
        </Switch>
      </main>
      <Toaster
        displayToaster={displayToaster}
        toasterMessage={toasterMessage}
        toasterMessageType={toasterMessageType}
        hideToaster={actions.hideToaster}
      />
    </div>
  );
};

export default SignInLayout;
