import * as React from 'react';
import PropTypes from 'prop-types';
// icon

export const Header = ({ user, closeDrawer }) => {
  return (
    <div>
      <h2 className='grant-access-title'>Grant Access</h2>
      <div>Permission settings for <span className={'access-container__panel-user-name'}>{user}</span></div>
    </div>
  );
};

Header.propTypes = {
  user: PropTypes.any.isRequired,
  closeDrawer: PropTypes.func
};
