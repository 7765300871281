import { useEffect } from 'react';

/**
 * Hook to set the documents title when a
 * component (likely a Page/View) is rendered.
 * @param title
 */
export const useSetDocumentTitle = (title: string) => {
  useEffect(() => {
    document.title = title;
  }, []);
};
