import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../../helpers/store';
import { createRule, deleteRule, storeRule } from '../../ruleAdmin.ducks';
import { WarningModal } from '../../../Attributes/components/Modal/WarningModal/WarningModal';
import { useKeyPress } from '../../../../../v2/hooks/useKeyPress';
import { IEditRule, IRuleObject } from '../../rules.types';
import { FormRule } from './FormRule/FormRule';
import { JsonRule } from './JsonRule/JsonRule';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';
import { OPERATOR } from './editRule.constants';
import { useStyles } from './editRule.styles';

const initializeOperator = (ruleSelected: IRuleObject[]) => {
  const operatorList: string[] = [];
  const rules = Object.values(ruleSelected[0].rule.conditions)[0];
  rules.map((rule: any) => {
    if (typeof rule === 'string') {
      operatorList.push(
        Object.keys(ruleSelected[0].rule.conditions)[0] === OPERATOR.ANY
          ? OPERATOR.OR
          : OPERATOR.AND
      );
    } else {
      const rawOperator = Object.keys(rule)[0];
      operatorList.push(rawOperator === OPERATOR.ANY ? OPERATOR.OR : OPERATOR.AND);
    }
  });
  return operatorList;
};

export const EditRule = ({
  globalOperator,
  handleGlobalOperator,
  ruleHandler,
  rule,
  removeAttributeHandler,
  removeRule,
  editHandler,
  ruleSelected,
  handleRule,
  closeRuleHandler
}: IEditRule) => {
  const [name, setName] = useState<string>(ruleSelected[0]._id || '');
  const [description, setDescription] = useState<string>(ruleSelected[0].name || '');
  const [operator, setOperator] = useState<string[]>(
    initializeOperator(ruleSelected) || [OPERATOR.OR]
  );
  const [isGlobalOperator] = useState<boolean>(true);
  const [deleteId, setDeleteId] = useState<string>('');
  const [isWarningModalOpen, setIsWarningModalOpen] = useState<boolean>(false);
  const [isJsonType, setIsJsonType] = useState<boolean>(false);
  const [jsonData, setJsonData] = useState<string>();
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('No data');

  const dispatch = useAppDispatch();

  const { classes } = useStyles();

  useEffect(() => {
    try {
      if (jsonData && JSON.parse(jsonData)) {
        setIsError(false);
        setErrorMessage('Looks good');
      }
    } catch (e) {
      setIsError(true);
      setErrorMessage('JSON parsing error');
    }
  }, [jsonData]);

  const addHandler = (): void => {
    ruleHandler();
  };

  const onChange = (value: string): void => {
    handleGlobalOperator(value);
  };

  const closeHandler = (ruleIndex: number): void => {
    removeRule(ruleIndex);
  };

  const deleteHandler = (ruleId: string): void => {
    dispatch(storeRule(ruleId));
    dispatch(deleteRule(ruleId));
    setDeleteId('');
  };

  const submitHandler = () => {
    if (isJsonType) {
      try {
        const data = jsonData ? JSON.parse(jsonData) : null;
        if (data?._id) data.id = data._id;
        if (Array.isArray(ruleSelected) && ruleSelected.length > 0) {
          dispatch(createRule({ rule: data, method: 'PUT' }));
        } else {
          dispatch(createRule({ rule: data, method: 'POST' }));
        }
        editHandler();
      } catch (e) {
        setIsError(true);
        setErrorMessage('JSON parsing error');
      }
    } else {
      //rule is an Array
      //rule will have operator and attributeList
      //attributeList will have object 1 or more
      //if there is 1 object then string is added
      //if there are more objects then object is added

      const rules = rule.map((attribute, index) => {
        if (attribute.attributeList.length === 1) {
          return `<${attribute.attributeList[0].key}>`;
        } else {
          const innerRule = attribute.attributeList.map((item) => {
            return `<${item.key}>`;
          });
          return {
            [operator[index] === OPERATOR.OR ? OPERATOR.ANY : OPERATOR.ALL]: innerRule
          };
        }
      });

      const mainRule = isGlobalOperator
        ? {
            id: name,
            name: description,
            rule: {
              conditions: {
                [globalOperator === OPERATOR.OR ? OPERATOR.ANY : OPERATOR.ALL]: rules
              }
            }
          }
        : {
            id: name,
            name: description,
            rule: {
              conditions: rules[0]
            }
          };

      if (ruleSelected instanceof Array && ruleSelected.length > 0) {
        dispatch(createRule({ rule: mainRule, method: 'PUT' }));
      } else {
        dispatch(createRule({ rule: mainRule, method: 'POST' }));
      }
      editHandler();
    }
  };

  const handleOperator = (value: string, ruleIndex: number): void => {
    console.log(value, ruleIndex, 'value, ruleIndex before');
    const newOperator = [...operator];
    newOperator[ruleIndex] = value;
    console.log(newOperator, 'newOperator', value, ruleIndex, 'value, ruleIndex');
    setOperator(newOperator);
    const newRule = rule.map((item, index) => {
      if (index === ruleIndex) {
        item.operator = value;
      }
      return item;
    });
    handleRule(newRule);
  };

  const handleName = (value: string) => {
    setName(value);
  };

  const handleDescription = (value: string) => {
    setDescription(value);
  };

  const handleOpenModal = (value: string): void => {
    setIsWarningModalOpen(true);
    setDeleteId(value);
  };
  const handleCloseModal = () => {
    setIsWarningModalOpen(false);
    editHandler();
  };

  const handleSubmitModal = () => {
    handleCloseModal();
    deleteHandler(deleteId);
  };

  const handleFormatType = (e: BaseSyntheticEvent, value: boolean) => {
    e.preventDefault();
    setIsJsonType(value);
    if (value && Array.isArray(ruleSelected) && ruleSelected.length > 0) {
      setJsonData(JSON.stringify(ruleSelected[0], null, 2));
    }
  };

  const handleJsonData = (event: BaseSyntheticEvent) => {
    setJsonData(event.target.value);
  };

  useKeyPress('Escape', closeRuleHandler);
  useKeyPress('Enter', submitHandler);

  return (
    <div className={classes.containerEdit}>
      <WarningModal
        isOpen={isWarningModalOpen}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmitModal}
        variant="warning"
      >
        Do you really want to delete this rule?
      </WarningModal>
      <Header
        isJsonType={isJsonType}
        handleFormatType={handleFormatType}
        ruleSelected={ruleSelected}
        handleOpenModal={handleOpenModal}
        name={name}
      />
      <div className={classes.containerMain}>
        {!isJsonType ? (
          <FormRule
            ruleSelected={ruleSelected}
            name={name}
            handleName={handleName}
            description={description}
            handleDescription={handleDescription}
            isGlobalOperator={isGlobalOperator}
            globalOperator={globalOperator}
            onChange={onChange}
            rule={rule}
            operator={operator}
            handleOperator={handleOperator}
            removeAttributeHandler={removeAttributeHandler}
            closeHandler={closeHandler}
            addHandler={addHandler}
          />
        ) : (
          <JsonRule
            jsonData={jsonData}
            handleJsonData={handleJsonData}
            isError={isError}
            errorMessage={errorMessage}
          />
        )}
      </div>
      <Footer closeRuleHandler={closeRuleHandler} submitHandler={submitHandler} />
    </div>
  );
};
