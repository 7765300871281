import { useState, useEffect } from 'react';
import { useDebounce } from './useDebounce';

interface IUseFilterProps<T extends object, K extends keyof T> {
  data: T[];
  debounce?: boolean;
  initialSearchValue?: string;
  keys: K[];
  minLength?: number;
}

// Hook to help filter an array of objects based on a search term. Takes a set of keys to filter against, to
// prvent comparing against all keys of the object.
export function useFilter<T extends object, K extends keyof T>({
  data = [],
  debounce = false,
  initialSearchValue = '',
  keys,
  minLength = 3
}: IUseFilterProps<T, K>): [T[], (value: string) => void, string] {
  const [filteredData, setFilteredData] = useState(data);
  const [searchValue, setSearchValue] = useState(initialSearchValue);

  // If debounce is true, use the debounced value instead of the search term.
  const value = debounce ? useDebounce(searchValue) : searchValue;
  // if data changes & searchValue is empty
  // show all data
  useEffect(() => {
    if (value.length === 0) {
      setFilteredData(data);
    }
  }, [data]);

  // If search term changes and it is shorter than the minLength, show all the data. Otherwise, filter the
  // data against the keys provided.
  useEffect(() => {
    if (value.length < minLength) {
      setFilteredData(data);
      return;
    }

    const lowerCaseSearchValue = value.toLowerCase();

    // Loops through the data and filter against the keys provided.
    const dataToKeep = data.filter((d) => {
      return keys.some((key) => {
        return (d[key] as string).toLowerCase().includes(lowerCaseSearchValue);
      });
    });

    setFilteredData(dataToKeep);
  }, [value]);

  const handleSetSearchValue = (s: string) => {
    setSearchValue(s);
  };

  return [filteredData, handleSetSearchValue, searchValue];
}
