import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// MATERIAL UI
import { Button, AppBar, Toolbar } from '@material-ui/core';
// PROJECT FILES
import { closeDrawer } from '../../actions/common.actions';
import { submitEventForm, uploadEventFormImage } from '../../actions/eventForm.actions';
import { getEventsData } from '../../actions/events.actions';

const EventsPanelFooter = (props) => {
  let buttonDisabled = !props.isFormValid || !props.isFormDirty;

  return (
    <AppBar
      position='sticky'
      className='panel-footer'>
      <Toolbar>
        <div
          className='contact-form-button-container padding-sm'>
          <SecondaryButton
            onClick={props.onClose} />
          <PrimaryButton
            buttonDisabled={buttonDisabled}
            submitEventForm={props.submitEventForm}
            uploadEventFormImage={props.uploadEventFormImage}
            uploadingImage={props.uploadingImage}
            getEventsData={props.getEventsData}
            onClose={props.onClose}
          />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default connect(null, {
  submitEventForm,
  uploadEventFormImage,
  getEventsData
})(EventsPanelFooter);

EventsPanelFooter.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  submitEventForm: PropTypes.func.isRequired,
  getEventsData: PropTypes.func.isRequired,
  uploadEventFormImage: PropTypes.func.isRequired,
  uploadingImage: PropTypes.bool.isRequired,
  isFormValid: PropTypes.bool,
  isFormDirty: PropTypes.bool
};

const SecondaryButton = (props) => {
  let buttonLabel = '';
  let buttonClasses = [];
  let labelClasses = [];
  buttonLabel = 'Cancel';
  buttonClasses.push('cancel-button');
  return (
    <Button
      className={['button-primary', ...buttonClasses].join(' ')}
      onClick={props.onClick}
      disableRipple
      color='primary'>
      <span className={['button-primary-label', ...labelClasses].join(' ')}>{buttonLabel}</span>
    </Button>
  );
};

const PrimaryButton = (props) => {
  let buttonLabel = '';
  let classes = [];
  buttonLabel = 'Save';
  classes.push('button-orange');
  if (props.buttonDisabled) {
    classes.push('button-primary-disabled');
  }

  const handleClick = async () => {
    const submitEventFormResults = await props.submitEventForm();
    if (props.uploadingImage) {
      if (submitEventFormResults && submitEventFormResults.id) {
        await props.uploadEventFormImage(submitEventFormResults.id);
      } else {
        await props.uploadEventFormImage();
      }
    }
  };

  return (
    <Button
      disableRipple
      onClick={handleClick}
      disabled={props.buttonDisabled}
      className={['button-primary', ...classes].join(' ')}
      color='primary'>
      <span className='button-primary-label label-white'>{buttonLabel}</span>
    </Button>
  );
};

SecondaryButton.propTypes = {
  onClick: PropTypes.func
};

PrimaryButton.propTypes = {
  onClose: PropTypes.func.isRequired,
  submitEventForm: PropTypes.func.isRequired,
  uploadEventFormImage: PropTypes.func.isRequired,
  uploadingImage: PropTypes.bool.isRequired,
  getEventsData: PropTypes.func.isRequired,
  buttonDisabled: PropTypes.bool.isRequired
};
