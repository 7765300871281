export const VERIFY_USER = 'verifyUser';
export const CONTACT_INFO = 'contactForm';
export const APPROVAL_FORM = 'approvalForm';
export const APPROVAL_VIEW = 'approvalView';
export const CONTACT_US_FORM = 'contactUsForm';
export const FILTER_FORM = 'filterForm';
export const EVENT_FORM = 'eventsForm';
export const BROADCAST_FORM = 'broadcastForm';
export const BROADCAST_APPROVAL = 'broadcastApproval';
export const DECLINE_BROADCAST_FORM = 'declineBroadcastForm';
export const SAMBANDH_APPROVAL_FORM = 'sambandhApprovalForm';
export const REJECT_SAMBANDH_FORM = 'rejectSambandhForm';
export const READING_FORM = 'readingForm';
export const REJECT_READING_FORM = 'rejectReadingForm';
export const USER_PROFILE_FORM = 'userProfileForm';
export const ACCESS_FORM = 'accessForm';
