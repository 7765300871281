import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  disabled: {
    userSelect: 'none',
    pointerEvents: 'none'
  },
  addButton: {
    border: 'none',
    cursor: 'pointer',
    width: '72px',
    height: '21px',
    fontSize: '16px',
    fontWeight: '500',
    color: 'hsla(211, 100%, 50%, 1)',
    fontFamily: 'Rubik',
    backgroundColor: 'transparent',
    '&:hover': {
      color: 'hsla(211, 100%, 40%, 1)'
    }
  },
  attributeClose: {
    display: 'flex',
    alignItems: 'baseline',
    gap: '14px',
    position: 'absolute',
    right: '30px'
  },
  attributeButton: {
    backgroundColor: 'hsla(226, 19%, 46%, 1)',
    border: 'none',
    width: '300px',
    height: '36px',
    borderRadius: 'inherit',
    padding: '7px',
    color: 'hsla(216, 100%, 99%, 1)',
    fontSize: '16px',
    textAlign: 'left',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    '&:focus': {
      backgroundColor: 'hsla(226, 19%, 40%, 1)'
    },
    '&:hover': {
      backgroundColor: 'hsla(226, 19%, 40%, 1)'
    },
    '&:disabled': {
      backgroundColor: 'transparent',
      border: '1px dashed hsla(225, 19%, 60%, 1)',
      color: 'hsla(225, 19%, 60%, 1)',
      padding: '7px 16px'
    }
  },
  attributeBlock: {
    borderRadius: '7px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  selected: {
    backgroundColor: 'hsl(209, 100%, 55%)',
    color: 'white',
    borderRadius: 4
  },
  dragDropToggle: {
    border: 'none',
    backgroundColor: 'inherit',
    borderTopLeftRadius: '7px',
    borderTopRightRadius: 'inherit',
    position: 'absolute',
    height: '28px',
    width: '105px',
    top: '-27px',
    left: '0px',
    display: 'flex',
    padding: '2px'
  },
  dragDropContainer: {
    padding: '16px',
    marginTop: '27px',
    maxWidth: '100%',
    backgroundColor: 'hsla(225, 35%, 93%, 1)',
    borderRadius: '0 7px 7px 7px',
    position: 'relative',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline'
  },
  scrollContainer: {
    marginBottom: '35px'
  },
  createRule: {
    marginBottom: '80px',
    scrollBehavior: 'smooth',
    overflowY: 'auto',
    height: 'calc(72vh - 450px)',
    overflowAnchor: 'none',
    position: 'relative'
  },
  fieldBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    minWidth: '342px'
  },
  fieldHeader: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    color: 'hsla(226, 19%, 46%, 1)',
    alignItems: 'center',
    height: 28
  },
  fieldInput: {
    height: 40,
    border: `1px solid hsla(224, 27%, 81%, 1)`,
    borderRadius: 3,
    paddingLeft: '16px',
    fontSize: '1rem',

    '&::placeholder': {
      fontFamily: 'inherit',
      fontWeight: 400,
      fontSize: 16
    },

    '&:hover': {
      border: `1px solid hsla(225, 19%, 60%, 1)`
    }
  },
  globalToggle: {
    backgroundColor: 'hsla(225, 35%, 93%, 1)',
    borderRadius: '3px',
    height: '28px'
  },
  toggleButton: {
    height: '100%',
    flex: 1,
    cursor: 'pointer',
    border: 'none',
    backgroundColor: 'inherit',
    color: 'hsla(226, 19%, 46%, 1)',
    borderRadius: 3,
    fontSize: '1rem',
    '&:hover': {
      filter: 'brightness(90%)',
      borderRadius: 4
    },
    '&:focus': {
      backgroundColor: 'hsl(209, 100%, 55%)',
      color: 'white'
    },
    '&--left': {
      borderTopLeftRadius: 5,
      paddingRight: 4,
      marginRight: 1
    },
    '&--right': {
      paddingLeft: 4,
      marginLeft: 1,
      borderTopRightRadius: 5
    }
  },
  globalSelected: {
    backgroundColor: 'hsl(209, 100%, 55%)',
    color: 'white',
    borderRadius: 3
  }
}));
