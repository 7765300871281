import React, { FC } from 'react';
import { connect } from 'react-redux';
import { change, touch } from 'redux-form';
// PROJECT FILES
import * as contactFormActions from '../../actions/contactForm.actions';
import { displayToaster } from '../../actions/common.actions';
import { commonActions } from '../../constants/actions';
import { ADDRESS_DATA_ERROR } from '../../constants/toasterMessages';
import { getValidateFullAddressObj } from '../../apis/googlePlacesAPI';
import GoogleAutocomplete from '../GoogleAutocomplete';
import { GOOGLE_ADDRESS_API_KEY } from '../../config/config.constants';

interface AddressInputProps {
  addressSuggestions: unknown[];
  className?: string;
  displayToaster: (message: string, type: string) => void;
  value: string;
  change: (
    form: string,
    field: string,
    value: any,
    touch?: boolean | undefined,
    persistentSubmitErrors?: boolean | undefined
  ) => void;
  name: string;
  form: string;
  touch: (form: string, ...fields: string[]) => void;
}

const AddressInput: FC<AddressInputProps> = ({
  change,
  className,
  displayToaster,
  form,
  name,
  touch,
  value
}) => {
  const handleInputChange = (value: string) => {
    change(form, name, value);
  };

  const handleSuggestedAddressClick = async (suggestion: any) => {
    const fullAddressObject = await getValidateFullAddressObj(
      `${suggestion.street_line} ${suggestion.address2}`,
      suggestion.city,
      suggestion.state
    );

    if (!fullAddressObject || fullAddressObject.error) {
      displayToaster(ADDRESS_DATA_ERROR, commonActions.ERROR_TOASTER);
      return;
    }

    const parentField = name.split('.')[0];
    change(form, `${parentField}.address1`, suggestion.street_line);
    change(form, `${parentField}.address2`, suggestion.address2);
    change(form, `${parentField}.city`, fullAddressObject.city ?? suggestion.city);
    change(
      form,
      `${parentField}.state`,
      (fullAddressObject.state ?? suggestion.state).toLowerCase()
    );
    change(form, `${parentField}.zip`, fullAddressObject.postalCode);
    change(form, `${parentField}.lat`, fullAddressObject.location.latitude);
    change(form, `${parentField}.lon`, fullAddressObject.location.longitude);
    change(form, `${parentField}.county`, fullAddressObject.county);
  };

  const handleInputBlur = () => {
    touch(form, name);
  };

  return (
    <GoogleAutocomplete
      apiKey={GOOGLE_ADDRESS_API_KEY ?? ''}
      className={className}
      handleChange={handleInputChange}
      handleBlur={handleInputBlur}
      placeholder={'Address 1'}
      value={value}
      onSuggestionClick={handleSuggestedAddressClick}
      onLoadFailed={(error) => {
        console.error('~~~~ Errorr Google Autocomplete : ', error);
      }}
    />
  );
};

function mapStateToProps(state: any) {
  return {
    addressSuggestions: state.contactForm.addressSuggestions
  };
}

export default connect(mapStateToProps, { ...contactFormActions, change, displayToaster, touch })(
  AddressInput
);
