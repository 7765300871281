import { iconProps } from './icons-types';

export const iconMedia = ({ stroke }: iconProps) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2005_1526)">
        <path
          d="M1.26147 15.064L6.59987 8.20001L13.4447 17"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.2001 7.40001C13.0838 7.40001 13.8001 6.68367 13.8001 5.80001C13.8001 4.91636 13.0838 4.20001 12.2001 4.20001C11.3164 4.20001 10.6001 4.91636 10.6001 5.80001C10.6001 6.68367 11.3164 7.40001 12.2001 7.40001Z"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.8 17H4.2C2.4328 17 1 15.5672 1 13.8V4.2C1 2.4328 2.4328 1 4.2 1H13.8C15.5672 1 17 2.4328 17 4.2V13.8C17 15.5672 15.5672 17 13.8 17Z"
          stroke={stroke}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2005_1526">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
