import { INameField } from '../../../../attributes.types';
import { TextField } from '../../../TextField/TextField';
import { useStyles } from './field.styles';

export const NameField = ({ name, handleName }: INameField) => {
  const { classes } = useStyles();

  return (
    <TextField label="Name" tooltipValue={'Name cannot contain spaces.'}>
      <input
        className={classes.fieldInput}
        placeholder="Name"
        value={name}
        type="text"
        onChange={(event) => handleName(event.target.value)}
        pattern="^[^\s]+$"
        title={'Name cannot contain spaces.'}
        required
      />
    </TextField>
  );
};
