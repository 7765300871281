export const CANCEL_BROADCAST_MODAL = 'cancel_broadcast_modal';
export const CONTACT_FORM = 'contact_form';
export const CONTACT_INFO_READ_ONLY = 'contact_info_read_only';
export const CONTACT_US_MODAL = 'contact_us_modal';
export const DELETE_ALBUM_MODAL = 'delete_album_modal';
export const DELETE_CONTACTS_MODAL = 'delete_contacts_modal';
export const ERROR_MODAL = 'error_modal';
export const EVENT_GALLERY_MODAL = 'event_gallery_modal';
export const FILTER_FORM = 'filter_form';
export const FOLLOW_UP_MODAL = 'follow_up_modal';
export const INVITE_MEMBER_MODAL = 'invite_member_modal';
export const REJECT_BROADCAST_MODAL = 'reject_broadcast_modal';
export const REJECT_READING_MODAL = 'reject_reading_modal';
export const REJECT_SAMBANDH_MODAL = 'reject_sambandh_modal';
export const USER_ACCOUNT_PANEL = 'user_account_panel';
export const VIEW_READING_MODAL = 'view_reading_modal';
