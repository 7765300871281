import http from '../helpers/http';
import { API } from '../config/config.constants';

export function getPendingSambandhUsers () {
  return http
    .get(`${API.ADMIN_VIEW.SAMBANDH_PENDING_USERS}`)
    .then((response) => {
      let data = response.data;
      if (data.members) {
        return data.members;
      } else {
        return { error: response };
      }
    })
    .catch((error) => {
      return { error };
    });
}

export function updateSambandhUserStatus (_id, status, notes) {
  return http
    .put(`${API.ADMIN_VIEW.SAMBANDH_UPDATE_USER_STATUS}${status}`,
      {
        _id,
        notes
      })
    .then((response) => {
      const data = response.data;
      if (data) {
        return data;
      } else {
        return response;
      }
    })
    .catch((error) => {
      return { error: error };
    });
}
