import icon_plus from '../../../../assets/icons/icon_plus.svg';
import { StyledPrimaryButton } from '../../../../v2/components/PrimaryButton/PrimaryButton';

interface IAddContactButtonProps {
  onClick: () => void;
}

export const AddContactButton = ({ onClick }: IAddContactButtonProps) => {
  const largeWidthScreen = window ? window.innerWidth > 600 : false;

  if (largeWidthScreen) {
    return <StyledPrimaryButton onClick={onClick}>New Contact</StyledPrimaryButton>;
  }

  return (
    <StyledPrimaryButton onClick={onClick}>
      <img height={25} width={25} src={icon_plus} /> Add
    </StyledPrimaryButton>
  );
};
