import { makeStyles } from 'tss-react/mui';

export const useSideNavStyles = makeStyles()(() => ({
  link: {
    textDecoration: 'none',
    fontSize: '16px',
    fontWeight: '500',
    color: '#848EAC',
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    margin: 8
  },
  activeLink: {
    color: '#2256BF',
    background: '#d7e0f8',
    borderRadius: 10
  },
  linkContainer: {
    padding: '16px 24px',
    display: 'flex',
    gap: 8,
    color: 'inherit',
    alignItems: 'center'
  },
  '@media (max-device-width : 736px)': {
    link: {
      paddingBottom: 0
    }
  },
  topDiv: {
    display: 'flex',
    gap: 8,
    padding: '12px 24px',
    color: '#3A4463',
    height: 68
  },
  topIconContainer: {
    height: 24,
    width: 24
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    color: 'inherit',
    alignItems: 'center',
    justifyContent: 'center'
  },
  text: {
    overflow: 'hidden',
    textWrap: 'nowrap'
  },
  samparkIcon: {
    height: 24
  }
}));
