import React from 'react';
import { getDaysTillHold } from '../../util/utils';
import { REGISTRATION_STATUS } from '../../constants/RegistrationStatus';
import { useStyles } from './style';

interface HoldStatusBannerProps {
  targetDate: string | undefined;
  status: REGISTRATION_STATUS;
  fontSize?: number;
}

const HoldStatusBanner: React.FC<HoldStatusBannerProps> = ({ targetDate, status, fontSize }) => {
  const days = getDaysTillHold(targetDate);
  const { classes } = useStyles({ days, fontSize });
  if (status === REGISTRATION_STATUS.HOLD) {
    return <div className={classes.holdStatusIndicator}>On Hold</div>;
  }
  if (status !== REGISTRATION_STATUS.APPROVED || !days) {
    return null;
  }
  if (days > 3) {
    return null;
  }
  return (
    <div className={classes.holdStatusIndicator}>
      {days} {days === 1 ? 'day' : 'days'} to pay
    </div>
  );
};

export default HoldStatusBanner;
