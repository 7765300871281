import { HEADER_TABS } from '../../constants/tabGroups';
import { useAppSelector } from '../../helpers/store';
import { growthbook } from '../../helpers/growthbook';
import getTabOptions from '../../util/getTabOptions';
import { ISSUPERADMIN, USERROUTES, checkUserPermission } from '../../util/userPermission';

export function useHeaderTabs() {
  const userAccess = useAppSelector((state) => state.userAccess);
  const userRoutes: any = checkUserPermission(userAccess, USERROUTES);

  const isSuperAdmin = checkUserPermission(userAccess, ISSUPERADMIN);

  const tabValues = getTabOptions(isSuperAdmin, HEADER_TABS, userRoutes);
  const isYogAdminFeatureEnabled = growthbook.getFeatureValue('sampark.yog-admin-tab', false);
  if (!isYogAdminFeatureEnabled) {
    return tabValues.filter((tab: any) => tab.name !== 'Yog Admin');
  }
  return tabValues;
}
