import { AttributeGroupDropdown } from './AttributeGroupDropdown';
import { DescriptionField } from './DescriptionField';
import { NameField } from './NameField';
import { OperatorDropdown } from './OperatorDropdown';
import ValueDropdown from './ValueDropdown';
import { SingleSelect } from '../../../SearchSelect/SingleSelect';
import { TextField } from '../../../TextField/TextField';
import { IAllFields, IValueType } from '../../../../attributes.types';
import { useStyles } from './formAttribute.styles';

export interface IFormAttribute {
  name: string;
  handleName: (val: string) => void;
  description: string;
  handleDescription: (val: string) => void;
  attributeGroup: string;
  handleAttributeGroup: (groupObject: IValueType) => void;
  allAttributeGroups: { value: string; label: string }[];
  allFields: IAllFields[];
  field: IAllFields | undefined;
  handleField: (value: string | string[]) => void;
  operator: { label: string; val: string };
  handleOperator: (valueObject: { val: string; label: string }) => void;
  fieldValue: string | string[];
  handleFieldValueChange: (val: string | string[] | React.ChangeEvent<HTMLInputElement>) => void;
}
export const FormAttribute = ({
  name,
  handleName,
  description,
  handleDescription,
  attributeGroup,
  handleAttributeGroup,
  allAttributeGroups,
  allFields,
  field,
  handleField,
  operator,
  handleOperator,
  fieldValue,
  handleFieldValueChange
}: IFormAttribute) => {
  const { classes } = useStyles();

  return (
    <div className={classes.formWrapper}>
      {/* Name value */}
      <NameField name={name} handleName={handleName} />

      {/* Description value */}
      <DescriptionField description={description} handleDescription={handleDescription} />

      {/* Attribute Group value */}
      <AttributeGroupDropdown
        attributeGroup={attributeGroup}
        handleAttributeGroup={handleAttributeGroup}
        allAttributeGroups={allAttributeGroups}
      />

      {/* Field value */}
      <TextField label="Field" tooltipValue="Field">
        <SingleSelect
          placeholder="Field"
          inputValue={field?.name}
          options={allFields?.map((fields) => {
            return { label: fields.label, val: fields.name };
          })}
          onChange={handleField}
        />
      </TextField>

      {/* Operator value */}
      <OperatorDropdown operator={operator} field={field} handleOperator={handleOperator} />

      {/* Label Values */}
      <ValueDropdown
        field={field}
        fieldValue={fieldValue}
        handleFieldValueChange={handleFieldValueChange}
      />
    </div>
  );
};
