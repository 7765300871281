import { useRef, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { bindActionCreators } from 'redux';
import { change } from 'redux-form';
import { makeStyles } from 'tss-react/mui';
import { displayToaster } from '../../actions/common.actions';
import { storeAvatarImage } from '../../actions/contactForm.actions';
import { ReactComponent as CameraIcon } from '../../assets/icons/icon_camera.svg';
import { IMAGES_URL } from '../../config/config.constants';
import { commonActions } from '../../constants/actions';
import { useAppDispatch } from '../../helpers/store';
import { MembersType } from '../../types';
import { AvatarBadge } from './AvatarBadge';

interface IAvatarUploaderProps {
  className: string;
  contact: MembersType;
  displayCameraIcon: boolean;
  height: number;
  width: number;
  meta: {
    form: string;
  };
  input: HTMLInputElement;
  avatarComponentClasses: string;
}

export function AvatarUploader(props: IAvatarUploaderProps) {
  const {
    avatarComponentClasses,
    className,
    contact,
    displayCameraIcon,
    height,
    input,
    meta,
    width
  } = props;

  const { classes, cx } = useStyles({ badgeSize: 0.33 * height, height, width });
  const dispatch = useAppDispatch();
  const actions = bindActionCreators({ change, displayToaster, storeAvatarImage }, dispatch);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [avatar, setAvatar] = useState<string | null>(
    contact?.profileImageUrl ? `${IMAGES_URL}/${contact.profileImageUrl}` : null
  );

  function fileSelectedHandler(selectedFile: File) {
    if (selectedFile && (selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/png')) {
      // Display image on avatar
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) {
          setAvatar(event.target.result as string);
        }
      };
      actions.change(meta.form, input.name, selectedFile);
      actions.storeAvatarImage(selectedFile);
      reader.readAsDataURL(selectedFile);
    } else {
      actions.displayToaster('Only .png and .jpeg images are allowed', commonActions.ERROR_TOASTER);
    }
  }

  function renderAvatarCameraIconOrContactInitials() {
    // If we have an avatar, then don't render anything inside as the avatar will be rendered
    // otherwise, render either camera icon or initials inside of the avatar component
    if (avatar) {
      return null;
    } else if (displayCameraIcon) {
      return <CameraIcon />;
    } else if (contact.firstName && contact.lastName) {
      return `${contact.firstName.charAt(0)}${contact.lastName.charAt(0)}`;
    } else {
      return 'AA';
    }
  }

  function handleInputClick() {
    return fileInputRef.current?.click();
  }

  return (
    <div className={cx('avatar-uploader', className)}>
      <Avatar
        className={cx('avatar-image', avatarComponentClasses, classes.avatar)}
        src={avatar || ''}
        onClick={handleInputClick}
      >
        {renderAvatarCameraIconOrContactInitials()}
      </Avatar>
      <div>
        <input
          ref={fileInputRef}
          type="file"
          className="avatar-file-input"
          onChange={(event) => {
            if (event.target.files?.[0]) {
              fileSelectedHandler(event.target.files[0]);
            }
          }}
        />
      </div>
      <AvatarBadge badgeClasses={[classes.badge]} contact={contact} />
    </div>
  );
}

const useStyles = makeStyles<{ badgeSize: number; height: number; width: number }>()(
  (__, { badgeSize, height, width }) => ({
    avatar: {
      height: `${height}px`,
      width: `${width}px`,
      fontSize: width * 0.45
    },
    badge: {
      top: height + 16 - badgeSize,
      left: -badgeSize,
      height: badgeSize,
      weight: badgeSize
    }
  })
);
