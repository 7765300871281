import { commonActions } from '../constants/actions';
import { CONTACT_US_SUBMIT_SUCCESS, CONTACT_US_SUBMIT_ERROR } from '../constants/toasterMessages';
import { submitContactUsFormAPI } from '../apis/commonAPIs';

export function displayToaster (message, type) {
  return (dispatch) => {
    return dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message, type } });
  };
};

export function hideToaster () {
  return (dispatch) => {
    return dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: false } });
  };
};

export function openDrawer (drawerComponent) {
  return (dispatch) => {
    return dispatch({type: commonActions.OPEN_DRAWER, value: { drawerComponent }});
  };
}

export function closeDrawer () {
  return (dispatch) => {
    return dispatch({type: commonActions.CLOSE_DRAWER});
  };
}

export function openModal (modalComponent, modalProps) {
  return (dispatch) => {
    return dispatch({type: commonActions.OPEN_MODAL, value: { modalComponent, props: modalProps }});
  };
}

export function closeModal () {
  return (dispatch) => {
    dispatch({type: commonActions.CLOSE_MODAL});
    return dispatch({type: commonActions.CLEAR_MODAL_COMPONENT});
  };
}

export function submitContactUsForm () {
  return async (dispatch, getState) => {
    const { values } = getState().form.contactUsForm;
    const submitContactUsFormResults = await submitContactUsFormAPI(values);
    if (!submitContactUsFormResults || submitContactUsFormResults.error) {
      return dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: CONTACT_US_SUBMIT_ERROR, type: commonActions.ERROR_TOASTER } });
    } else {
      return dispatch({ type: commonActions.SHOW_HIDE_TOASTER, value: { displayToaster: true, message: CONTACT_US_SUBMIT_SUCCESS, type: commonActions.SUCCESS_TOASTER } });
    }
  };
}

export function triggerDevFlag (value) {
  return dispatch => dispatch({ type: commonActions.SHOW_DEV_MODE, value: value });
}
