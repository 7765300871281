import React, { Fragment } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
// material
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@material-ui/core';
// Project Files
import { closeModal } from '../../actions/common.actions';

class ErrorModal extends React.Component {
  constructor (props) {
    super(props);
  }

  render () {

    return (
      <Fragment>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.props.errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions disableActionSpacing>
          <Button
            disableRipple
            className='button-primary button-orange'
            onClick={this.props.closeModal}>
            <span className='button-primary-label label-white'>Okay</span>
          </Button>
        </DialogActions>
      </Fragment>
    );
  }
}

export default connect(null, { closeModal })(ErrorModal);

ErrorModal.propTypes = {
  closeModal: PropType.func.isRequired,
  errorMessage: PropType.string.isRequired
};
