import http from '../helpers/http';
import { API } from '../config/config.constants';

export function sendWorkshopInvitation(memberId: string) {
  return http.post(API.INVITATION.GENERATE_INVITATION(memberId)).then((response) => {
    // If the response status code is 200, the invite has been sent successfully
    if (response.status === 200) {
      return { success: true };
    } else {
      return { success: false };
    }
  });
}

export function getInvitationStatusForMembers(memberIds: string[]) {
  return http
    .post(API.INVITATION.GET_INVITATION_STATUS_FOR_MEMBER_IDS, {
      memberIds
    })
    .then((response) => {
      // Response should always return object with members array
      return response.data?.members;
    });
}
