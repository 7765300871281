import React from 'react';
import { Field, Fields } from 'redux-form';
import {
  renderDatePicker,
  renderTimeFields
} from './index';

const renderDateAndTimeFields = (fields) => {
  const readOnly = fields.readOnly || false;
  const label = fields.label || '';
  const required = fields.required || false;
  const dateLimit = fields.dateLimit || ['', ''];
  const timeLimit = fields.timeLimit || ['', ''];
  let classes = ['form-field-text', 'padding-xsm-left'];
  if (readOnly) classes.push('disabled-text-field');

  return (
    <div className='date-time-container'>
      {label && <div className='form-field-label-row'>
        <label>{label} {required && <span className='form-field-required'>*</span>}</label>
      </div>}
      <div className='date-time-fields'>
        <div className='date-fields' >
          <Field
            required
            readOnly={readOnly}
            name={fields.names[0]}
            minDate={dateLimit[0]}
            maxDate={dateLimit[1]}
            component={renderDatePicker} />
        </div>
        <div className='time-fields'>
          <Fields
            required
            readOnly={readOnly}
            minTime={timeLimit[0]}
            maxTime={timeLimit[1]}
            names={[`${fields.names[1]}.value`, `${fields.names[1]}.meridian`]}
            component={renderTimeFields} />
        </div>
      </div>
    </div>
  );
};

export default renderDateAndTimeFields;
