const validate = (values, props) => {
  const errors = {};
  const numbersOnly = /^\d{10}$/;
  const isEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!values.gender) {
    errors.gender = 'Please select a gender';
  }

  if (!values.firstName) {
    errors.firstName = 'Please enter a first name';
  }

  if (!values.lastName) {
    errors.lastName = 'Please enter a last name';
  }

  if (values.addressDetails) {
    if (!values.addressDetails.city) {
      errors.addressDetails = {
        city: 'Please enter a city'
      };
    }

    if (!values.addressDetails.state) {
      errors.addressDetails = {
        state: 'Please select a state'
      };
    }
  }

  if (values.contactDetails) {
    const { contactDetails } = values;
    if (!contactDetails.email && !contactDetails.cell) {
      errors.contactDetails = {
        email: 'Please enter either an email address or phone number',
        cell: 'Please enter either an email address or phone number'
      };
    }
    if (contactDetails.email && !isEmail.test(contactDetails.email)) {
      errors.contactDetails = {
        email: 'Please enter a valid email address'
      };
    }

    if (contactDetails.cell && !numbersOnly.test(contactDetails.cell)) {
      errors.contactDetails = {
        cell: 'Please enter a valid phone number'
      };
    }
  }

  return errors;
};

export default validate;
