import { makeStyles } from 'tss-react/mui';

export const useToasterStyles = makeStyles()(() => ({
  closeIcon: {
    cursor: 'pointer',
    filter: 'contrast(0)'
  },
  messageContainer: {
    display: 'flex',
    alignItems: 'center'
  }
}));

export const useToastIconStyles = makeStyles()(() => ({
  errorStatus: {
    backgroundColor: '#d63230'
  },
  statusIcon: {
    filter: 'brightness(100)',
    height: '1rem'
  },
  successStatus: {
    backgroundColor: '#8ac926'
  },
  toasterStatus: {
    alignItems: 'center',
    borderRadius: '100%',
    display: 'flex',
    height: '1.5rem',
    justifyContent: 'center',
    marginRight: '1rem',
    width: '1.5rem'
  }
}));
