import * as React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';

export const Sidebar = ({ className, sidebarItems, onChange, activeTab }) => {
  return (
    <div className={className} >
      {sidebarItems.map((sidebarItem, i) => {
        return <SidebarItem key={i} item={sidebarItem} isActive={activeTab === sidebarItem.name} isDirty={sidebarItem.isDirty} onChange={onChange} />;
      })}
    </div>
  );
};

const SidebarItem = ({ item, onChange, isActive, isDirty = false }) => {
  const Icon = item.icon;
  return (
    <a className={classnames('access-container__panel-sidebar-item', isActive ? 'active' : 'inactive')} onClick={(event) => { event.preventDefault(); onChange(item.name); }} >
      <Icon name={item.icon} stroke={'currentColor'} />
      <span className={isActive ? 'active' : 'inactive'}>{item.name}</span>
      {isDirty && <span className={'dirtyBadge'} />}
    </a>
  );
};

SidebarItem.propTypes = {
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  isActive: PropTypes.bool.isRequired,
  isDirty: PropTypes.bool
};

Sidebar.propTypes = {
  className: PropTypes.string,
  sidebarItems: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  activeTab: PropTypes.string
};
