import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  bindActionCreators
} from 'redux';
import { withStyles } from '@material-ui/core/styles';

import { checkUserPermission, ISSUPERADMIN } from '../../../util/userPermission';
import { oneOf, isError } from '../../../util/stage';
import { ActionCreators } from './samparkAdmin.duck';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { SamparkAdminTab } from '../../../v2/components/admin-tabs';

const useStyles = withStyles({
  errorContainer: {
    margin: '0rem auto',
    maxWidth: '650px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  }
});

const SamparkAdmin = (props) => {
  const {
    data,
    isSuperAdmin = false,
    setAccessModalOpen,
    actions,
    stage,
    classes
  } = props;

  const {
    approvedUsers
  } = data;

  useEffect(() => {
    actions.fetchSamparkAdminData();

    return () => actions.resetSamparkAdminData();
  }, []);

  if (isError(stage)) {
    return (
      <div className={classes.errorContainer}>
        An error has occured while loading data.
        <br />
        If problem persists, please submit a ticket using the Contact Us feature.
      </div>
    );
  }

  if (oneOf('READY', 'LOADING')(stage)) {
    return <LoadingSpinner />;
  }

  return (
    <div className='admin-data-user-access-container'>
      <SamparkAdminTab
        isSuperAdmin={isSuperAdmin}
        title='Manage User Access'
        type='manage'
        items={approvedUsers}
        showSearch
        onModalOpen={setAccessModalOpen}
      />
    </div>
  );
};

SamparkAdmin.propTypes = {
  data: PropTypes.shape({
    approvedUsers: PropTypes.any
  }).isRequired,
  stage: PropTypes.symbol.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  setAccessModalOpen: PropTypes.func.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ admin: { samparkAdmin }, userAccess }) => ({
  ...samparkAdmin,
  isSuperAdmin: checkUserPermission(userAccess, ISSUPERADMIN)
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(useStyles(SamparkAdmin));
