import React, { Fragment } from 'react';
import { Tooltip, Select, MenuItem, Link } from '@material-ui/core';
import { userenderSelectFieldStyle } from './renderSelectField.style';
import { WrappedFieldProps } from 'redux-form';
import { IOption } from '../../v2/components/MultiSelectDropDown';

interface IrenderSelectField extends WrappedFieldProps {
  label: string;
  options: IOption[];
  tooltip: boolean;
  tooltipText: string;
  multiple: boolean;
  maxSelect: number;
  readOnly: boolean;
  required: boolean;
  onClearClicked?: () => void;
}
const renderSelectField = ({
  input,
  label,
  multiple = false,
  meta: { touched, error, warning },
  options = [],
  maxSelect,
  tooltip = false,
  tooltipText = '',
  required = false,
  readOnly = false,
  onClearClicked
}: IrenderSelectField) => {
  const classes = ['form-field-text', 'padding-xsm-left'];
  if (readOnly) classes.push('disabled-text-field');
  if (touched && error) classes.push('form-field-text-error-state');
  const { classes: makeStyleClasses } = userenderSelectFieldStyle();
  const formFieldLabelClasses = [makeStyleClasses.formFieldLabelRow];
  if (onClearClicked) {
    formFieldLabelClasses.push(makeStyleClasses.formFieldClear);
  }
  return (
    <Fragment>
      {label && (
        <div className={formFieldLabelClasses.join(' ')}>
          <label style={{ marginRight: '0.5rem' }}>
            {label} {required && <span className={makeStyleClasses.formFieldRequired}>*</span>}
          </label>
          {tooltip && (
            <Tooltip placement="top" title={tooltipText}>
              <div className="form-field-tooltip-icon">?</div>
            </Tooltip>
          )}
          {onClearClicked && !readOnly ? (
            <Link onClick={onClearClicked} className={makeStyleClasses.tagClearLink}>
              clear
            </Link>
          ) : null}
        </div>
      )}
      <div className="form-field-text-container">
        {options && options.length ? (
          <Select
            {...input}
            multiple={multiple}
            MenuProps={{
              PaperProps: {
                style: {
                  border: '1px solid #c3d2df'
                }
              },
              MenuListProps: {
                disablePadding: true
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }}
            className={classes.join(' ')}
            disableUnderline
            disabled={readOnly}
            defaultValue={multiple ? [options[0].value] : ''}
            value={multiple && typeof input.value === 'string' ? [input.value] : input.value}
          >
            {options.map((option: any, index: number) => {
              let disabled = false;
              if (multiple && maxSelect) {
                const isSelected = input.value.indexOf(option.value) !== -1;
                const maxLimitReached = input.value.length === maxSelect;
                disabled = multiple && maxLimitReached && !isSelected;
              }
              return (
                <MenuItem
                  style={{
                    fontFamily: 'Rubik',
                    color: '#2A3D4C'
                  }}
                  disabled={disabled}
                  key={index}
                  value={option.value}
                >
                  {option.name}
                </MenuItem>
              );
            })}
          </Select>
        ) : null}
        {touched &&
          ((error && <div className="form-field-error">{error}</div>) ||
            (warning && <div className="form-field-error">{warning}</div>))}
      </div>
    </Fragment>
  );
};

export default renderSelectField;
