import { StyledContactListActionsContainer } from './ContactListActions.styles';
import { IContactsListActionsProps } from './ContactsListActions.types';
import { buildContactsListActions } from './buildContactsListActions';

export const ContactsListActions = ({ actionListProps }: IContactsListActionsProps) => {
  const actions = buildContactsListActions(actionListProps);
  return (
    <StyledContactListActionsContainer>
      {actions.map((Action) => Action)}
    </StyledContactListActionsContainer>
  );
};
