import React, { Component } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
// material
import { Menu, MenuItem } from '@material-ui/core';
// project files
import getMenuOptions from '../../util/getMenuOptions';
import { CREATE_COPY_EVENT } from '../../constants/menuOptions';
import { EVENT_MENU } from '../../constants/menus';
import { HoverImage } from '../hover-image/HoverImage';
import icon_overflow from '../../assets/icons/icon_overflow.svg';
import icon_overflow_active from '../../assets/icons/icon_overflow_active.svg';
import { openModal } from '../../actions/common.actions';

class EventMenu extends Component {
  constructor (props) {
    super(props);
    this.state = {
      anchorEl: null
    };
  }

  // open menu
  handleMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  // handle menu options click
  handleMenuClose = value => {
    const {
      onCreateCopy,
    } = this.props;

    this.setState(
      { anchorEl: null },
      () => {
        switch (value) {
          case CREATE_COPY_EVENT:
            onCreateCopy();
            break;
          default:
        }
      }
    );
  }

  render () {
    const { anchorEl } = this.state;
    const menuOptions = getMenuOptions(EVENT_MENU);
    let classes = this.props.classes ? this.props.classes.join(' ') : '';
    return (
      <div className={classes}>
        <HoverImage
          style={{display: 'flex'}}
          src={icon_overflow}
          hoverSrc={icon_overflow_active}
          onClick={this.handleMenuOpen} />
        <Menu
          MenuListProps={{
            disablePadding: true
          }}
          style={{marginTop: '0.5rem'}}
          anchorEl={anchorEl}
          anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
          getContentAnchorEl={null}
          open={Boolean(anchorEl)}
          onClose={() => {
            this.handleMenuClose();
          }}>
          {menuOptions.map((option, index) => {
            return (
              <MenuItem
                key={index}
                disabled={option.disabled}
                onClick={() => {
                  this.handleMenuClose(option);
                }}>
                {option.name}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  }
};

export default connect(
  null, { openModal }
)(EventMenu);

EventMenu.propTypes = {
  classes: PropType.arrayOf(PropType.string),
  onCreateCopy: PropType.func,
};
