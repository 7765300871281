import React, { Fragment } from 'react';
import Chip from '@material-ui/core/Chip';
import icon_close_mini from '../../assets/icons/icon_close_mini.svg';
import { WrappedFieldInputProps } from 'redux-form';
import { styled } from '@mui/material/styles';

//Regex to validate email
const emailRegEx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export interface IRenderAdditionalEmail {
  label?: string;
  input: WrappedFieldInputProps;
  disabled: boolean;
  fields: any;
  placeholder: string;
  max: number;
  meta: { touched: any; error: any; warning: any; dirty: any };
}

export const renderAdditionalEmail = ({
  label,
  fields,
  max = 4,
  disabled = false,
  input,
  placeholder,
  meta: { touched, error, warning }
}: IRenderAdditionalEmail) => {
  disabled = fields.length >= max || disabled;
  const classes = ['form-field-text-area', 'padding-xsm-left'];
  if (touched && error) {
    classes.push('form-field-text-error-state');
  }

  const addEmailHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Only allow add if we have less than 4 interest
    if (fields.length < max) {
      const trimmedValue = event.target.value.trim();

      // If value is not an empty string and is a valid email, add it to the list
      if (trimmedValue && trimmedValue !== '' && emailRegEx.test(trimmedValue)) {
        fields.push(trimmedValue);
      }
      event.target.value = '';
    }
  };

  const handleOnKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // 188 (comma), 13 (enter);
    if (event.which === 188 || event.which === 13) {
      addEmailHandler(event as unknown as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <Fragment>
      {label && (
        <div className="form-field-label-row">
          {label && <label style={{ marginRight: '0.5rem' }}>{label}</label>}
        </div>
      )}
      <div className="form-field-tags-container">
        <input
          type="text"
          className={['form-field-text padding-xsm-left', disabled ? 'disabled-input' : null].join(
            ' '
          )}
          {...input}
          placeholder={placeholder}
          onBlur={addEmailHandler}
          onKeyUp={handleOnKeyUp}
          disabled={disabled}
        />
        {(touched && error && <div className="form-field-error">{error}</div>) ||
          (warning && <div className="form-field-error">{warning}</div>)}
        <div className="form-field-tags-list">
          {fields.getAll() &&
            fields.getAll().map((tag: any, index: any) => {
              return (
                <StyledChip
                  onDelete={() => {
                    fields.remove(index);
                  }}
                  deleteIcon={<img src={icon_close_mini} />}
                  key={index}
                  label={<span>{tag}</span>}
                />
              );
            })}
        </div>
      </div>
    </Fragment>
  );
};

const StyledChip = styled(Chip)`
  --ocean-blue: #067bc2;
  &.Sampark-MuiChip-root {
    margin: 1rem 0.5rem 0 0;
    background-color: white;
    border: 3px solid var(--ocean-blue);
    border-radius: 3px;
    justify-content: space-between;
    text-transform: capitalize;
    min-width: 75px;
    min-height: 2rem;
    border-radius: 4px;
    text-transform: none;
    user-select: none;
  }
  .Sampark-MuiChip-deleteIcon {
    height: 10px;
    margin: 0;
  }

  .Sampark-MuiChip-label {
    font-size: 14px;
    font-weight: 500;
    color: var(--ocean-blue);
    font-family: 'Rubik', sans-serif;
  }
`;
