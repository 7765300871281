import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import SecondaryButton from '../../components/SecondaryButton';
import { ReadingItemType } from './readingView.types';

interface ITableColumn {
  classes?: string[];
  date?: string;
  name: string;
  value: string;
}

interface ITableData {
  action: { func: (row: ReadingItemType) => void; label: string };
  columns: ITableColumn[];
  data: ReadingItemType[];
}

interface IDataListCardProps {
  tableData: ITableData;
  showLoader: boolean;
  title: string;
}

export const DataListCard = (props: IDataListCardProps) => {
  const { showLoader, tableData, title } = props;

  if (showLoader) {
    return <LoadingSpinner />;
  }

  const { action, columns, data } = tableData;

  const copyColumns = [...columns];
  if (action) {
    copyColumns.push({ name: '', value: 'action' });
  }

  return (
    <div className="data-list-card-container">
      {title && (
        <header className="data-list-card-header">
          <span className="data-list-card-title">{title}</span>
        </header>
      )}
      <div className="data-list-items">
        <Table>
          <TableHead>
            <TableRow>
              {copyColumns.map((field, i) => (
                <TableCell key={`${field.name}-${i}`}>{field.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => {
              return (
                <TableRow key={row._id}>
                  {copyColumns.map((field) => {
                    const { classes, value: columnKey } = field;
                    const value = row[columnKey as keyof ReadingItemType];
                    const className =
                      columnKey === 'status' ? (value as string).toLowerCase() : null;
                    if (columnKey === 'action') {
                      const handleClick = () => {
                        action.func(row);
                      };

                      return (
                        <TableCell key={`${row._id}-${field.name}`}>
                          <SecondaryButton label={action.label} handleClick={handleClick} />
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell key={`${row._id}-${field.name}`}>
                          <span className={classnames(classes, className)}>
                            {value && typeof value === 'string'
                              ? field.date
                                ? dayjs(value).format(field.date)
                                : value.toLowerCase()
                              : ''}
                          </span>
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
