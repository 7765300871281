export function buildNameValueArray(array: []) {
  return array.map((option) => {
    return {
      name: option,
      value: option
    };
  });
}

export function mapObjectToOptionValueArray(objectToMap: { [key: string]: string }) {
  const options: { name: string; value: string }[] = [];
  Object.keys(objectToMap).forEach((value: string) => {
    options.push({
      name: objectToMap[value],
      value: value
    });
  });
  return options;
}

export const buildMultiCheckboxOptions = (name: string, tags: any) => {
  if (!tags || tags.length === 0) {
    return {
      name,
      value: []
    };
  }
  return {
    name,
    value: tags.map((attr: any) => ({
      name: attr.name,
      value: attr.id ?? attr.value,
      description: attr.description ?? ''
    }))
  };
};
