import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Avatar from '@mui/material/Avatar';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Button } from '@material-ui/core';

import { MembersType } from '../../../../../types';
import { IMAGES_URL } from '../../../../../config/config.constants';
import { FamilyMemberType } from '../../familyTree.duck';
import FamilyAssignedTag from './FamilyAssignedTag';
import { SelectField } from '../../../../../v2/components/SelectorField';
import { OptionType } from './MembersList';

import { ReactComponent as ShareIcon } from '../../../../../assets/icons/share_icon.svg';
import { formatAddress } from '../../util/utils';
import { Address } from '../../../../../gql/generated';

type MembersListItemProps = {
  className?: string;
  id: string;
  member: MembersType | FamilyMemberType;
  addressOptions?: OptionType[];
  isEditMode?: boolean;
  onAddressChange?: (member: FamilyMemberType) => void;
  showAddButton: boolean;
  showFamilyAssigned?: boolean;
  showRemoveButton: boolean;
  showAssignButton: boolean;
  showAddDisabled?: boolean;
  showShareIcon: boolean;
  isSelected?: boolean;
  onClick?: (id: string) => void;
  onAdd?: (member: MembersType) => void;
  onRemove?: (memberId: string) => void;
  onAssign?: (memberId: string) => void;
};

export const MembersListItem = ({
  className,
  id,
  member,
  onAdd,
  onRemove,
  onAssign,
  addressOptions = [],
  isEditMode = false,
  onAddressChange,
  onClick,
  showAddButton = false,
  showFamilyAssigned = false,
  showRemoveButton = false,
  showAssignButton = false,
  showAddDisabled = false,
  isSelected = false,
  showShareIcon = false
}: MembersListItemProps) => {
  const { classes, cx } = useStyles({ isSelected });

  if (!member || !member._id) return null;

  const { _id, firstName, lastName, addressDetails } = member;
  const {
    address1 = '',
    city = '',
    state = '',
    country = ''
  } = addressDetails as NonNullable<Address>;
  const src =
    member && member.profileImageUrl && member.profileImageUrl !== 'undefined'
      ? IMAGES_URL + '/' + member.profileImageUrl
      : '';

  const currentAddress = formatAddress(address1, city, state, country);
  const findAndSelectAddress = (address: OptionType) =>
    addressOptions.find((addr) => addr.name === address.name) || address;

  const [selectedAddress, setSelectedAddress] = useState<OptionType>(
    findAndSelectAddress({
      name: currentAddress,
      value: addressDetails || ''
    })
  );

  const renderAddress = () => {
    return isEditMode ? (
      <SelectField
        classes={{
          notchedOutline: classes.addressNotchedOutline
        }}
        className={classes.addressDropdown}
        containerClassName="contact-filter-state-input-container"
        options={addressOptions}
        onChange={(name: string, value: string | Address) => {
          setSelectedAddress({ name, value });
          if (onAddressChange) {
            onAddressChange({
              ...member,
              _id,
              addressDetails: value as Address
            });
          }
        }}
        label=""
        maxSelect={1}
        inputValue={findAndSelectAddress(selectedAddress)?.value}
      />
    ) : (
      <p className={classes.address}>{currentAddress}</p>
    );
  };

  return (
    <div className={classes.container}>
      <button
        id={id}
        className={cx(classes.listItemContainer, className)}
        onClick={() => {
          if (onClick) {
            onClick(id);
          }
        }}
      >
        <div className={classes.listContent}>
          <Avatar
            className={classes.avatar}
            src={src}
            variant={'rounded'}
            sx={{ width: 74, height: 74 }}
          />
          <div className={classes.listItemDetails}>
            {showFamilyAssigned ? <FamilyAssignedTag /> : null}
            <h4 className={classes.name}>{`${firstName} ${lastName}`}</h4>
            {renderAddress()}
          </div>
        </div>
        {showAddButton ? (
          <button
            className={classes.addButton}
            disabled={showAddDisabled && isSelected}
            aria-disabled={showAddDisabled && isSelected}
            onClick={(e) => {
              e.stopPropagation();
              if (onAdd) {
                onAdd(member as MembersType);
              }
            }}
          >
            {!showShareIcon ? <AddCircleIcon className={classes.addButtonIcon} /> : <ShareIcon />}
          </button>
        ) : null}
      </button>
      {showRemoveButton ? (
        <button
          className={classes.removeButton}
          onClick={() => {
            if (member._id && onRemove) {
              onRemove(member._id);
            }
          }}
        >
          <RemoveCircleIcon className={classes.removeButtonIcon} />
        </button>
      ) : null}
      {showAssignButton ? (
        <Button
          className={classes.assignButton}
          classes={{
            startIcon: classes.startIcon
          }}
          startIcon={<AddOutlinedIcon />}
          onClick={() => {
            if (member._id && onAssign) {
              onAssign(member._id);
            }
          }}
          disableRipple
        >
          Assign
        </Button>
      ) : null}
    </div>
  );
};

const useStyles = makeStyles<{ isSelected: boolean }>()((theme, { isSelected }) => ({
  addButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    '&:disabled': {
      cursor: 'default',
      '& svg': {
        fill: '#C1C8DB'
      }
    }
  },
  addButtonIcon: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    fill: '#26C98E'
  },
  addressDropdown: {
    border: 'none',
    height: '34px',
    background: '#E8EBF4',
    borderRadius: '100px',
    textTransform: 'capitalize',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    },
    '&:hover': {
      border: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
      }
    }
  },
  addressNotchedOutline: {
    border: 'none'
  },
  assignButton: {
    backgroundColor: '#26C98E',
    color: 'white',
    border: 'none',
    padding: '8px',
    marginLeft: theme.spacing(2),
    borderRadius: '100px',
    textTransform: 'capitalize',
    boxShadow: 'none',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#26C98E',
      color: 'white'
    },
    width: '78px',
    height: '33px'
  },
  address: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '17px',
    color: '#5F698B',
    textTransform: 'capitalize'
  },
  avatar: {},
  container: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 'calc(100% - 50px)',
    [theme.breakpoints.up('md')]: {
      minWidth: 'calc(100% - 70px)'
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: 'calc(100% - 120px)'
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: 'calc(100% - 200px)'
    }
  },
  listItemContainer: {
    cursor: 'pointer',
    alignItems: 'center',
    display: 'flex',
    border: 'none',
    borderRadius: '9px',
    justifyContent: 'space-between',
    margin: '8px 0',
    width: '100%',
    background: isSelected ? 'rgba(193, 200, 219, 0.4)' : 'none',
    textAlign: 'left',
    boxShadow: 'none',
    '&:disabled': {
      cursor: 'default'
    }
  },
  listContent: {
    alignItems: 'center',
    display: 'flex',
    gap: 4
  },
  listItemDetails: {
    alignItems: 'flex-start',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '0 16px',
    '& p': {
      margin: 0
    }
  },
  name: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '21px',
    color: '#3A4463',
    textTransform: 'capitalize'
  },
  removeButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    marginLeft: theme.spacing(2)
  },
  removeButtonIcon: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    fill: '#C84240'
  },
  startIcon: {
    marginRight: '0px'
  }
}));
