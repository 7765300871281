import dayjs from 'dayjs';
import { capitalizeString } from '../../../../util/capitalizeString';

export const formatAddress = (address1 = '', city = '', state = '', country = '') => {
  return `${capitalizeString(
    `${address1 ? address1 + ',' : ''} ${city ? city + ',' : ''}`
  )} ${state?.toUpperCase()} ${country?.toUpperCase()}`;
};

export const getDaysTillHold = (holdDate: string | undefined) => {
  if (holdDate) {
    return dayjs(holdDate).diff(dayjs(new Date()), 'd') + 1;
  }
  return;
};
