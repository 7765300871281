import {
  BoldItalicUnderlineToggles,
  UndoRedo,
  headingsPlugin,
  CreateLink,
  linkPlugin,
  linkDialogPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
  Separator,
  InsertThematicBreak,
  BlockTypeSelect,
  tablePlugin,
  ConditionalContents,
  InsertImage,
  uuidv4
} from '@mdxeditor/editor';
import './MDXEditorPlugins.scss';
import { ListsToggle } from './ListToggle';
import useSignInUrl from '../../hooks/useSignInUrl';
import brokenImage from '../../assets/icons/broken-image.svg';

export const KitchenSinkToolbar: React.FC<{ hideLink?: boolean }> = ({ hideLink = false }) => {
  return (
    <ConditionalContents
      options={[
        {
          fallback: () => (
            <div className="kitchen-sink-toolbar-container">
              <UndoRedo />
              <Separator />
              <BlockTypeSelect />
              <BoldItalicUnderlineToggles />
              <Separator />
              <ListsToggle />
              <Separator />
              <InsertThematicBreak />
              {/* <TableEditor/> */}
              {!hideLink ? <CreateLink /> : null}
              <InsertImage />
            </div>
          )
        }
      ]}
    />
  );
};

export const imageUploadHandler = (prefix: string) => {
  const fetchImage = async (file: File): Promise<string> => {
    try {
      const id = uuidv4();
      return await useSignInUrl(file, id, prefix);
    } catch (error) {
      return await brokenImage;
    }
  };
  return fetchImage;
};

export const ALL_PLUGINS_WITHOUT_TOOLBAR = [
  listsPlugin(),
  tablePlugin(),
  linkPlugin(),
  linkDialogPlugin(),
  quotePlugin(),
  headingsPlugin({ allowedHeadingLevels: [1, 2, 3] }),
  thematicBreakPlugin()
];
export const ALL_PLUGINS = [
  toolbarPlugin({ toolbarContents: () => <KitchenSinkToolbar /> }),
  ...ALL_PLUGINS_WITHOUT_TOOLBAR
];

export const ALL_PLUGINS_NO_LINK = [
  toolbarPlugin({ toolbarContents: () => <KitchenSinkToolbar hideLink /> }),
  ...[
    listsPlugin(),
    tablePlugin(),
    quotePlugin(),
    headingsPlugin({ allowedHeadingLevels: [1, 2, 3] }),
    thematicBreakPlugin()
  ]
];
