import { BroadcastView } from '../views/BroadcastView';
import EventsView from '../views/EventsView';
import ReadingView from '../views/ReadingView';
import ThakorjiSmrutiesView from '../views/ThakorjiSmrutiesView';
import ContactsView from '../views/Contacts';
import YogView from '../views/yog-reports/Container';
import DashboardView from '../views/DashboardView';
import AdminView from '../views/Admin';
import FollowUpView from '../views/FollowUp';
import FollowUpDetails from '../views/FollowUpDetails';
import * as PERMISSIONS from './permissions';
import { iconFollowup } from '../assets/icons/icon-followup';
import { iconBroadcast } from '../assets/icons/icon-broadcasts';
import { iconContracts } from '../assets/icons/icon-contacts';
import { iconEvents } from '../assets/icons/icon-events';
import { iconHome } from '../assets/icons/icon-home';
import { iconSwadhyay } from '../assets/icons/icon-swadhyay';
import { iconMedia } from '../assets/icons/icon-media';
import { iconShield } from '../assets/icons/icon-shield';
import { YogShield } from '../assets/icons/icon-yog-shield';
import { YogAdminContainer } from '../views/Admin/YogAdmin/YogAdminContainer';

const samparkBaseUrl = '/sampark';

/**
 * NEW SAMPARK ROUTES
 */

export interface SAMPARK_ROUTE {
  name: string;
  value: string;
  component: React.ReactNode;
  hideHeader?: boolean;
  hideInNav?: boolean;
  permission: string;
  icon: React.ReactNode;
}

export const ADMIN = {
  name: 'Admin',
  value: `${samparkBaseUrl}/admin`,
  component: AdminView,
  permission: PERMISSIONS.CONTACTS,
  icon: iconShield
};

export const FOLLOW_UP_DETAILS = {
  name: 'Follow Up Details',
  value: `${samparkBaseUrl}/followup/:id`,
  component: FollowUpDetails,
  hideHeader: true,
  hideInNav: true,
  permission: PERMISSIONS.FOLLOWUP,
  icon: iconFollowup
};

export const FOLLOW_UP = {
  name: 'Follow Up',
  value: `${samparkBaseUrl}/followup`,
  component: FollowUpView,
  permission: PERMISSIONS.FOLLOWUP,
  icon: iconFollowup
};

/**
 * AUTH ROUTES
 */

export const SIGN_IN = {
  name: 'Sign In',
  value: `/auth`
};

export const SIGNOUT = {
  name: 'Sign out',
  value: `/auth/signout`
};

export const BROADCAST = {
  name: 'Broadcast',
  value: `${samparkBaseUrl}/broadcast`,
  permission: PERMISSIONS.BROADCAST,
  component: BroadcastView,
  search: false,
  icon: iconBroadcast
};

// New CONTACTS - TODO Update component
export const CONTACTS = {
  name: 'Contacts',
  value: `${samparkBaseUrl}/contacts`,
  permission: PERMISSIONS.CONTACTS,
  component: ContactsView,
  icon: iconContracts,
  search: true
};

export const YOG = {
  name: 'YOG',
  value: `${samparkBaseUrl}/yog`,
  permission: PERMISSIONS.CONTACTS,
  component: YogView,
  search: false
};

export const EVENTS = {
  name: 'Events',
  value: `${samparkBaseUrl}/events`,
  permission: PERMISSIONS.EVENTS,
  component: EventsView,
  search: false,
  icon: iconEvents
};

export const HOME = {
  name: 'Home',
  value: `${samparkBaseUrl}/home`,
  permission: PERMISSIONS.CONTACTS,
  component: DashboardView,
  icon: iconHome,
  search: true
};

export const SWADHYAY = {
  name: 'Swadhyay',
  value: `${samparkBaseUrl}/swadhyay`,
  permission: PERMISSIONS.SWADHYAY,
  component: ReadingView,
  icon: iconSwadhyay,
  search: false
};

export const SMRUTI_DARSHAN = {
  name: 'Smruti Darshan',
  value: `${samparkBaseUrl}/smruti_darshan`,
  permission: PERMISSIONS.SMRUTI,
  component: ThakorjiSmrutiesView,
  icon: iconMedia,
  search: false
};

export const YOG_ADMIN = {
  name: 'Yog Admin',
  value: `${samparkBaseUrl}/yog-admin`,
  component: YogAdminContainer,
  permission: PERMISSIONS.CONTACTS,
  icon: YogShield
};

export const allPermissionRoutes: SAMPARK_ROUTE[] = [
  HOME,
  CONTACTS,
  FOLLOW_UP_DETAILS,
  FOLLOW_UP,
  // YOG,
  EVENTS,
  BROADCAST,
  SWADHYAY,
  SMRUTI_DARSHAN,
  ADMIN,
  YOG_ADMIN
];
