import { createSlice } from '@reduxjs/toolkit';
import { TOAST_VARIANTS } from './toaster.constants';

const toasterSlice = createSlice({
  name: 'toaster',
  initialState: {
    toastMessage: '',
    toastVariant: TOAST_VARIANTS.SUCCESS,
    show: true,
    hide: false
  },
  reducers: {
    showToast: (state, action) => {
      switch (action.payload.type) {
        case TOAST_VARIANTS.SUCCESS:
          state.toastVariant = TOAST_VARIANTS.SUCCESS;
          state.toastMessage = action.payload.message;
          state.show = true;
          break;

        case TOAST_VARIANTS.ERROR:
          state.toastVariant = TOAST_VARIANTS.ERROR;
          state.toastMessage = action.payload.message;
          state.show = true;

          break;
        case TOAST_VARIANTS.WARNING:
          state.toastVariant = TOAST_VARIANTS.WARNING;
          state.toastMessage = action.payload.message;
          state.show = true;
          break;

        default:
        // no match
      }
    },
    hideToast: (state) => {
      state.show = false;
      state.hide = true;
    },
    resetToast: (state) => {
      state.show = false;
      state.hide = false;
    }
  }
});

export const { showToast, hideToast, resetToast } = toasterSlice.actions;
export default toasterSlice.reducer;
