import React from 'react';
import sampark_logo from '../assets/icons/sampark.svg';
import Link from 'react-router-dom/es/Link';
import PropTypes from 'prop-types';

export const Logo = (props) => {
  return (
    <Link to={{
      pathname: '/auth',
      state: (props.isNewAccount) ? {loginWithNewAccount: true} : null
    }}>
      <div className='sampark-logo'>
        <img src={sampark_logo} />
      </div>
    </Link>
  );
};

Logo.propTypes = {
  isNewAccount: PropTypes.bool.isRequired
};
