import { AddContactButton } from '../AddContactButton/AddContactButton';
import { ContactsListActions } from '../ContactsListActions/ContactsListActions';
import { FilterChips } from '../FilterChips';
import { SearchCityStateFilter } from '../SearchCityStateFilter/SearchCityStateFilter';
import { StyledContactsCount, StyledHeading, StyledPageHeaderContainer } from './PageHeader.styles';
import { IPageHeaderProps } from './PageHeader.types';

export const PageHeader = ({
  actionsListProps,
  canAddContact,
  contactsCount,
  handleClickNewContact
}: IPageHeaderProps) => {
  return (
    <>
      <StyledPageHeaderContainer>
        <StyledHeading>
          Contacts
          <StyledContactsCount> {contactsCount}</StyledContactsCount>
        </StyledHeading>
        {canAddContact ? <AddContactButton onClick={handleClickNewContact} /> : null}
      </StyledPageHeaderContainer>
      <div className="contact-header-utility-buttons-container">
        <section className="contact-header-search-container">
          <SearchCityStateFilter />
        </section>
        <ContactsListActions actionListProps={actionsListProps} />
      </div>
      <FilterChips />
      {!actionsListProps.cardView && <div className="header-bottom-border"></div>}
    </>
  );
};
